import React from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import dataTableStyles from '@dealroadshow/uikit/core/components/Table/DataTable/dataTable.scss';
import validationSelectors from '@/condor/application/actions/finsight/import/bond/validation/selectors';
import { proceedPreValidationErrors } from '@/condor/application/actions/finsight/import/bond/validation/actions';
import { reset as resetAll } from '@/condor/application/actions/finsight/import/bond/upload/actions';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import columns from './columns';

import styles from '@/condor/ui/components/Finsight/Import/modules/Validation/ValidationTable/validationTable.scss';
import formStyles from '@/condor/ui/common/Layout/FormWrapper/formWrapper.scss';

const PotentialErrorsTable = () => {
  const potentialErrors = useSelector(validationSelectors.getPotentialErrors);
  const dispatch = useDispatch();

  const onProceed = () => {
    dispatch(proceedPreValidationErrors());
  };

  const onCancel = () => {
    dispatch(resetAll());
  };

  return (
    <TableWrapper
      label="Upload Validation Errors"
      className={ styles.tableWrapper }
      contentClassName={ styles.tableContent }
    >
      <DataTable
        containerClassName={ styles.tableContainer }
        className={ cn(styles.dataTable, dataTableStyles.isHoverable) }
        dataTest="validationDataTable"
        columns={ columns }
        data={ potentialErrors }
      />
      <div className={ styles.buttonsContainer }>
        <Button
          type="submit"
          onClick={ onProceed }
          variant={ ButtonVariantType.action }
          className={ formStyles.successBtn }
          title="Proceed"
          dataTest="reuploadXlsButton"
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ onCancel }
          title="Cancel"
          dataTest="cancelButton"
        />
      </div>
    </TableWrapper>
  );
};

export default PotentialErrorsTable;
