import React, { useState, useEffect } from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ResponsiveModal as Modal, Spinner } from '@dealroadshow/uikit';
import styles from './toggleActivation.scss';
import { useDataroomCondorActionsContext } from '@/dataroom/application/condor/DataroomCondorActionsContext';

const ToggleActivation = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { details: { dataroomDetails: { displayName, isActive }, getDataroomDetails },
    reactivate: { isFetching: isReactivateFetching, reactivate },
    deactivate: { isDeactivating, deactivate },
  }
    = useDataroomCondorActionsContext();

  const handleActivationChange = async () => {
    if (isActive) {
      const success = await deactivate([], null);
      if (success) {
        await getDataroomDetails();
      }
    } else {
      const success = await reactivate();
      if (success) {
        await getDataroomDetails();
      }
    }
  };

  const isFetching = isReactivateFetching || isDeactivating;

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  useEffect(() => {
    if (!isFetching) {
      handleCloseModal();
    }
  }, [isFetching]);

  const footer = (
    <div className={ styles.buttonsWrp }>
      <Button
        variant={ isActive ? ButtonVariantType.warning : ButtonVariantType.action }
        onClick={ handleActivationChange }
        title={ isActive ? 'Deactivate' : 'Reactivate' }
        dataTest={ isActive ? 'deactivateModal' : 'activateModal' }
        disabled={ isFetching }
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ handleCloseModal }
        title="Cancel"
        dataTest="cancelButton"
        disabled={ isFetching }
      />
    </div>
  );

  return (
    <>
      <Button
        onClick={ handleOpenModal }
        variant={ ButtonVariantType.text }
        dataTest={ isActive ? 'deactivate' : 'reactivate' }
        title={
            isActive ? 'Deactivate Data Room' : 'Reactivate Data Room'
          }
      />
      { isModalVisible && (
        <Modal
          onCloseClicked={ handleCloseModal }
          isVisible
          title={ isActive ? 'Deactivate Data Room' : 'Reactivate Data Room' }
          footer={ footer }
          className={ styles.modalWrp }
          dataTest="toggleActivationModal"
        >
          <div className={ styles.messagesWrp }>
            <p className={ isActive ? styles.deactivateMessage : styles.activateMessage }>
              { isActive ? 'Are you sure you want to deactivate "' : 'Are you sure you want to reactivate "' }
              <strong>{ displayName }</strong>
              { '"?' }
            </p>
          </div>
          <Spinner
            isVisible={ isFetching }
            centered
            overlay
          />
        </Modal>
      ) }
    </>
  );
};

export default ToggleActivation;
