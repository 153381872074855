import { getIssuerNameCellEe } from './helpers';
import { getDealCell, getAcceptanceTimestampCell } from '../helpers';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

export default [
  {
    name: 'finsightDeal',
    title: 'Finsight Deal',
    cellCallback: getDealCell,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 200,
  },
  {
    name: 'issuerName',
    title: 'SEC Issuer Name',
    cellCallback: getIssuerNameCellEe,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 300,
  },
  {
    name: 'assetClass',
    title: 'SEC Asset Class',
    cellCallback: ({ row }) => row.data['SEC-HEADER']['ABS-ASSET-CLASS'],
    className: alignStyles.alignLeft,
    sortable: true,
    width: 200,
  },
  {
    name: 'acceptanceTimestamp',
    title: 'Acceptance Timestamp',
    cellCallback: getAcceptanceTimestampCell,
    className: alignStyles.alignRight,
    sortable: true,
    width: 200,
  },
];
