import {
  statusCellCallback,
  authorCellCallback,
  createdAtCellCallback,
  regionCellCallback,
  textFromArray,
} from './cellCallbacks';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

export const isActiveColumn = {
  name: 'isActive',
  title: 'Status',
  minWidth: 70,
  maxWidth: 70,
  width: 70,
  cellCallback: statusCellCallback,
  className: alignStyles.alignCenter,
};

export const commonColumns = [
  {
    name: 'author',
    title: 'Author',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: authorCellCallback,
  },
  {
    name: 'products',
    title: 'Products',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: textFromArray,
  },
  {
    name: 'regions',
    title: 'Regions',
    maxWidth: 116,
    minWidth: 116,
    cellCallback: regionCellCallback,
  },
  {
    name: 'createdAt',
    title: 'Created at',
    maxWidth: 109,
    minWidth: 109,
    sortable: true,
    cellCallback: createdAtCellCallback,
  },
];
