import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string | string[] | Record<string, any>,
  fieldName: string,
  fieldCaption?: string,
  isPlural?: boolean,
}

export const validateRequiredFieldWithArrayValue = (params: IParams): IValidationError => {
  if (!params.value || (Array.isArray(params.value) && params.value.length === 0)) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Field' } ${ (params.isPlural && params.fieldCaption) ? 'are' : 'is' } required` };
  }
  return null;
};
