import cn from 'classnames';
import styles from './tableWrapper.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps {
  children: React.ReactNode,
  className?: string,
  contentClassName?: string,
  labelClassName?: string,
  filterComponent?: React.ReactNode,
  label?: string,
  toolbar?: React.ReactNode,
}

const TableWrapper = (props: IProps) => (
  <>
    <div className={ cn(styles.tableWrapperContainer, props.className) }>
      <div className={ cardStyles.cardHeader }>
        { props.label && (
          <div className={ cn(cardStyles.cardHeaderTitle, headerStyles.isH3, spacesStyles.ptm, props.labelClassName) }>
            { props.label }
          </div>
        ) }
        { props.toolbar }
        { props.filterComponent && (
          <div className={ cardStyles.cardHeaderActions }>
            { props.filterComponent }
          </div>
        ) }
      </div>
    </div>
    <div className={ cn(styles.tableWrapperContent, props.contentClassName) }>
      { props.children }
    </div>
  </>
);

export default TableWrapper;
