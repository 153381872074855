import { useEffect, useMemo, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { IGroupThemeData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ThemesData';
import { IOptionTheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { getOptionsForGroupSelectWithFilters } from '@/condor/application/Finsight/CreditFlowResearch/Articles/helpers';

const useThemes = () => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { regions, products } = useRegionsProductsContext();

  const [themesData, setThemesData] = useState<IGroupThemeData[]>([]);

  const loadThemesData = async () => {
    try {
      const groupThemesData = await creditFlowResearchRepository.getGroupThemesData();
      setThemesData(groupThemesData);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    loadThemesData();
  }, []);

  const themeOptions = useMemo<IOption<IOptionTheme>[]>(
    () => getOptionsForGroupSelectWithFilters(
      products,
      regions,
      themesData,
      'themeName',
      'themes',
    ),
    [products, regions, themesData],
  );

  return { themeOptions };
};

export default useThemes;
