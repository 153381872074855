import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import { ISubscriptionsPayload } from '@/finsight/domain/vo/Subscriptions';
import { IUserSubscriptionsResponse } from '@/finsight/domain/vo/Subscriptions/userSubscriptions';

@Dependencies(FinsightWebRpcDispatcher)
class SubscriptionsRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  getSubscribersListByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscribers', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  updateUserSubscriptionsByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.update_by_admin', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  subscribeMultipleUsersByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.multiple_subscriptions', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {object}
   */
  getUserSubscriptionsByAdmin = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscription_list_by_admin', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @returns {object}
   */
  getSubscriptionsListByAdmin = async (payload = {}) => {
    let request = new Request('finsight_web.subscription.get_subscriptions', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  getUserSubscriptionsBySSID = async (): Promise<IUserSubscriptionsResponse> => {
    let request = new Request('finsight_web.subscription.get_my_subscriptions_by_ssid', {});
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {object}
   */
  getUserSubscriberHash = async (payload) => {
    let request = new Request('finsight_web.subscription.get_subscriber_by_subscription_hash', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {object}
   */
  updateUserSubscriptionsBySSID = async (payload: ISubscriptionsPayload): Promise<null> => {
    let request = new Request('finsight_web.subscription.update_my_subscriptions_by_ssid', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {object} payload
   * @returns {object}
   */
  updateUserSubscriptionsByHash = async (payload) => {
    let request = new Request('finsight_web.subscription.update_my_subscriptions_by_hash', payload);
    let response = await this.rpc.request(request);
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise<*>}
   */
  createUserSubscriptionBySSID = async (payload) => {
    let response = await this.rpc.request(new Request('finsight_web.subscription.subscribe_by_ssid', payload));
    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Promise<*>}
   */
  createUserSubscriptionByEmail = async (payload) => {
    let response = await this.rpc.request(new Request('finsight_web.subscription.subscribe_by_email', payload));
    return response.getResult().payload;
  };

  checkUserSubscriptionStatus = async (payload: ISubscriptionsPayload): Promise<boolean> => {
    let response = await this.rpc.request(new Request('finsight_web.subscription.check_status', payload));
    return response.getResult().payload;
  };
}

export default SubscriptionsRepository;
