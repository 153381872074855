import React from 'react';
import { PanelGroup, Panel, PanelArrow } from '@dealroadshow/uikit/core/components/PanelGroup';
import IconExpand from '@dealroadshow/uikit/core/components/Icon/IconExpand';
import IconCollapse from '@dealroadshow/uikit/core/components/Icon/IconCollapse';
import FilterItem from './FilterItem';
import styles from '../filters.scss';

interface IProps {
  groupId: string | number,
  itemsCount?: number,
  selectedItemsCount?: number,
  children: React.ReactNode,
  checkboxComponent: React.ReactNode,
  isExpanded?: boolean,
  showArrow?: boolean,
  dataTest?: string,
  withCounter?: boolean,
}

const arrow = (
  <PanelArrow
    icon={ IconExpand }
    arrowWrapperClassName={ styles.panelGroupFilterNestedHeaderArrowIcon }
  />
);

const arrowActive = (
  <PanelArrow
    isActive
    iconActive={ IconCollapse }
    arrowWrapperClassName={ styles.panelGroupFilterNestedHeaderArrowIcon }
  />
);

function FilterGroup(props: IProps) {
  const {
    groupId,
    itemsCount,
    selectedItemsCount,
    children,
    checkboxComponent,
    isExpanded = true,
    showArrow = true,
    dataTest = '',
    withCounter = true,
  } = props;

  return (
    <PanelGroup
      prefixCls="panelGroupFilterNested"
      dataTest={ dataTest }
      defaultActiveKey={ isExpanded ? [String(groupId)] : [] }
    >
      <Panel
        id={ String(groupId) }
        key={ groupId }
        showArrow={ showArrow }
        arrow={ arrow }
        arrowActive={ arrowActive }
        className={ styles.panelGroupFilterNestedItem }
        headerClass={ styles.panelGroupNestedHeader }
        arrowWrapperClass={ styles.panelGroupFilterNestedHeaderArrowWrapper }
        contentClass={ styles.panelGroupFilterNestedContent }
        header={ (
          <FilterItem
            itemsCount={ withCounter && itemsCount }
            selectedItemsCount={ withCounter && selectedItemsCount }
          >
            { checkboxComponent }
          </FilterItem>
        ) }
        destroyOnToggle={ false }
      >
        <div className={ styles.filterNestedWrp }>
          { children }
        </div>
      </Panel>
    </PanelGroup>
  );
}

export default React.memo(FilterGroup);
