import React from 'react';
import { CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import {
  getCellDisplayDateFromNumber,
  openProfileCellWrapper,
} from '@/condor/ui/common/Layout/helpers';
import UpdatedAtCell from './UpdatedAtCell';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';

/**
 * @param {Object} cellProps
 */
export const nameCellCallback = (cellProps) => openProfileCellWrapper(
  {
    href: `${ CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL }/${ cellProps.row.id }`,
    className: linkStyles.link,
    children: cellProps.cellData,
  },
);

/**
 * @param {Object} cellProps
 */
export const createdAtCellCallback = (cellProps) => getCellDisplayDateFromNumber(cellProps.row.createdAt);

/**
 * @param {Object} cellProps
 */
export const updatedAtCellCallback = (cellProps) => <UpdatedAtCell cellProps={ cellProps } />;
