import React, { useCallback } from 'react';
import cn from 'classnames';
import { TRANCHES_VISIBLE } from '@/condor/ui/components/Finsight/DealProfile/constants';

import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Icon, IconType } from '@dealroadshow/uikit';
import { useTranchesContext } from '../../TranchesContext';
import { getNewTrancheInitialValues } from '../../../helpers/useDealInitialValues';

import styles from './addTrancheButton.scss';

interface IProps {
  disabled: boolean,
  addTranche: (tranche: any) => void,
  ratingAgencies: any[],
  tranchesLength: number,
}

const AddTrancheButton = (
  {
    disabled,
    addTranche,
    ratingAgencies,
    tranchesLength,
  }: IProps,
) => {
  const { setTranchesViewOffset } = useTranchesContext();

  const onClick = useCallback(
    () => {
      addTranche(getNewTrancheInitialValues(ratingAgencies));

      if (tranchesLength >= TRANCHES_VISIBLE) {
        setTranchesViewOffset(tranchesLength - (TRANCHES_VISIBLE - 1));
      }
    },
    [tranchesLength],
  );

  return (
    <div className={ styles.addTrancheBtnContainer }>
      <Button
        disabled={ disabled }
        className={ cn(styles.addTrancheBtn, { [styles.disabled]: disabled }) }
        variant={ ButtonVariantType.text }
        onClick={ onClick }
        dataTest="condorAddTrancheButton"
      >
        <Icon
          type={ IconType.add }
          className={ styles.addTrancheIcon }
        />
        <span className={ styles.addTrancheLabel }>Add Tranche</span>
      </Button>
    </div>
  );
};

export default AddTrancheButton;
