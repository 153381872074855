import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Spinner, Modal } from '@dealroadshow/uikit';
import styles from './deleteDealModal.scss';

interface IProps {
  onClose: () => void,
  isVisible: boolean,
  isDeleting: boolean,
  deleteDeal: (dealId: string) => void,
  dealId: string,
}

const DeleteDealModal = (
  {
    isVisible,
    isDeleting,
    onClose,
    dealId,
    deleteDeal,
  }: IProps,
) => (
  <Modal
    className={ styles.deleteDealModal }
    onCloseClicked={ onClose }
    isVisible={ isVisible }
    title="Delete Confirmation"
    dataTest="deleteDealProfileConfirmationModal"
    footer={ !isDeleting && (
      <>
        <Button
          onClick={ () => deleteDeal(dealId) }
          variant={ ButtonVariantType.action }
          className={ styles.deleteBtn }
          title="Delete"
          dataTest="condorDeleteDealModalButton"
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ onClose }
          title="Cancel"
          dataTest="condorDeleteDealModalCancelButton"
        />
      </>
    ) }
  >
    { !isDeleting && (
      <span>Are you sure you want to delete this deal?</span>
    ) }
    <Spinner isVisible={ isDeleting } />
  </Modal>
);

export default DeleteDealModal;
