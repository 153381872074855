import { useCallback } from 'react';
import { Spinner } from '@dealroadshow/uikit';
import SubscriptionGroup from '@/finsight/ui/components/SubscriptionForm/Base/components/SubscriptionGroup';
import FooterButtons from '@/finsight/ui/components/SubscriptionForm/Base/components/FooterButtons';
import UnsubscribeSection from '@/finsight/ui/components/SubscriptionForm/Base/components/UnsubscribeSection';
import EmailSelectField from '@/finsight/ui/components/SubscriptionForm/Base/components/EmailSelectField';
import { setEmailsFromChecked } from '@/condor/ui/components/FinsightManager/ManagePlans/Subscribers/AddSubscribersModal/helpers';
import { UserPlanType } from '@/users/domain/UserPlan';
import { emailRegexp } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import type { ICheckedEmails } from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';
import type { IBaseSubscriptionFormProps } from '@/finsight/ui/components/SubscriptionForm/types';

interface IUseRenderFieldsProps extends Omit<IBaseSubscriptionFormProps, 'validate' | 'submitForm' | 'formName' | 'initialValues'> {
  checkedEmails: ICheckedEmails,
  isChecking: boolean,
}

export const useRenderFields = ({
  isSubmitting,
  subscriptionsData,
  checkedEmails,
  email,
  isChecking,
  withEmailSelect,
  withUnsubscribe,
  submitButtonTitle,
  onCancel,
}: IUseRenderFieldsProps) => {
  return useCallback(
    ({ values, invalid, pristine }) => {
      const selectedEmails = setEmailsFromChecked(
        email ? [{ isValid: true, label: email, value: email }] : values?.subscribersListSelect,
        checkedEmails,
      );
      const selectedEmailsPlans = selectedEmails.map(({ userPlan }) => userPlan || UserPlanType.STARTER);

      return (
        <>
          <Spinner
            overlay
            isAbsolute
            isVisible={ isSubmitting || isChecking }
          />
          { withEmailSelect && <EmailSelectField emailRegexp={ emailRegexp } /> }
          {
            subscriptionsData.map((dataItem) => (
              <SubscriptionGroup
                key={ dataItem.groupId || dataItem.id }
                dataItem={ dataItem }
                values={ values }
                email={ email }
                selectedEmailsPlans={ selectedEmailsPlans }
              />
            ))
          }
          { withUnsubscribe && <UnsubscribeSection /> }
          <FooterButtons
            submitButtonTitle={ submitButtonTitle }
            invalid={ invalid }
            pristine={ pristine }
            isSubmitting={ isSubmitting }
            onCancel={ onCancel }
          />
        </>
      );
    },
    [subscriptionsData, isSubmitting, checkedEmails, email, isChecking],
  );
};
