import { useMemo } from 'react';
import CountrySelect from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/CountrySelect';
import IndustriesSelect from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/IndustriesSelect';
import SectorSelect from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/SectorSelect';
import ParentIssuerSelect from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/ParentIssuerSelect';
import ThemesSelect from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/ThemesSelect';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import { Spinner } from '@dealroadshow/uikit';

interface DealFlowFieldGroupProps {
  disabled?: boolean,
  renderRestrictedAccess?: (children: React.ReactElement, componentName: string) => React.ReactElement,
  setOpenExplanation?: (componentName: string) => void,
}

const DealFlowFieldGroup = ({
  disabled,
  setOpenExplanation,
  renderRestrictedAccess,
}: DealFlowFieldGroupProps) => {
  const { isLoading } = useCommentaryDealFlowContext();
  const fields = useMemo(() => [
    ThemesSelect,
    ParentIssuerSelect,
    IndustriesSelect,
    SectorSelect,
    CountrySelect,
  ], []);

  const handleClick = (componentName: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenExplanation?.(componentName);
  };

  return (
    <div data-test="dealFlowFieldGroup">
      { fields.map((Component) => {
        const componentName = Component.name;
        const field = (
          isLoading ? <Spinner centered /> : (
            <div key={ componentName } onClick={ handleClick(componentName) }>
              <Component disabled={ disabled } />
            </div>
          )
        );
        return disabled && renderRestrictedAccess
          ? renderRestrictedAccess(field, componentName)
          : field;
      }) }
    </div>
  );
};

export default DealFlowFieldGroup;
