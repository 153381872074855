import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { Icon, IconType, StatusIndicator, Tooltip, TruncateTextWithTooltip } from '@dealroadshow/uikit';

import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';
import DateCell from '@/Framework/UI/Molecules/Tables/CellCallbacks/DateCell';
import { CONDOR_FINSIGHT_OPEN_MARKET_PROFILES, CONDOR_FINSIGHT_OPEN_MARKET_EVENTS } from '@/condor/ui/common/constants';
import condor from '@/condor/infrastructure/condorUrl';
import { IListEvent } from '@/openMarket/domain/Condor/vo/IEvent';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useEventsListContext } from './EventsListContext';

import styles from './eventsList.scss';

interface ICellProps {
  row: IListEvent,
}

const getEditUrl = (id: string) => `${ CONDOR_FINSIGHT_OPEN_MARKET_EVENTS }/${ id }/edit`;

export const StatusCell = ({ row }: ICellProps) => {
  return (
    <Tooltip content={ capitalize(row.status) }>
      <span data-test={ row.status }>
        <StatusIndicator status={ row.status } className={ styles.statusIndicator } />
      </span>
    </Tooltip>
  );
};

export const NameCell = ({ row }: ICellProps) => (
  <Tooltip content="Open Event Profile">
    <Link data-test="edit" to={ getEditUrl(row.eventId) }>
      { decodeSymbols(row.name) }
    </Link>
  </Tooltip>
);

export const ProfilesCell = ({ row }: ICellProps) => {
  if (row.profiles.length === 0) {
    return '-';
  }

  const tooltipContent = (
    <div className={ styles.profilesTooltip }>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th className={ styles.profileName }>Profile</th>
            <th>Display tab</th>
          </tr>
        </thead>
        <tbody>
          { row.profiles.map((profile, index) => (
            <tr key={ profile.id }>
              <th>{ index + 1 }</th>
              <td className={ styles.profileName }>{ decodeSymbols(profile.name) }</td>
              <td>
                { profile.displayTabs.length > 0
                  ? profile.displayTabs.map((tab) => decodeSymbols(tab.name)).join(', ')
                  : '-' }
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  );

  return (
    <Tooltip content={ tooltipContent } maxWidth={ 480 }>
      <span className={ styles.profilesTooltipAction }>
        { row.profiles.length > 1 && <span className={ styles.profilesCount }>{ row.profiles.length }</span> }
        { row.profiles
          .map((profile) => (
            <Link to={ `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/${ profile.id }/edit` } target="_blank" key={ profile.id }>
              { decodeSymbols(profile.name) }
            </Link>
          ))
          .reduce((acc, link) => [acc, acc.length > 0 && ', ', link], []) }
      </span>
    </Tooltip>
  );
};

export const RoadshowCell = ({ row }: ICellProps) => {
  return (
    <Link
      to={ condor.getUrlPathname(`/roadshows/roadshow/${ row.roadshowId }/deal-settings`) }
      data-test="roadshowLink"
      target="_blank"
    >
      <Icon type={ IconType.link } className={ styles.roadshowLinkIcon } />
    </Link>
  );
};

export const CountryCell = ({ row }: ICellProps) => <TruncateTextWithTooltip value={ row.country ?? '-' } />;

export const RegionCell = ({ row }: ICellProps) => <TruncateTextWithTooltip value={ row.region ?? '-' } />;

const MILLISECONDS_IN_SECOND = 1000;

export const ExpirationDate = ({ row }: ICellProps) => {
  return (
    <DateCell
      date={ row.expirationDate * MILLISECONDS_IN_SECOND }
      timeZone={ row.timezone.timeZone }
      timeZoneAbbr={ row.timezone.abbr }
      withoutTime
    />
  );
};

export const ActionsCell = ({ row }: ICellProps) => {
  const { openDeleteModal } = useEventsListContext();
  const deleteHandler = () => openDeleteModal(row);

  return (
    <span>
      <Tooltip
        content="Edit"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Link data-test="edit" className={ styles.actionButton } to={ getEditUrl(row.eventId) }>
          <Icon type={ IconType.pencil } />
        </Link>
      </Tooltip>
      <Tooltip
        content="Delete"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Button dataTest="delete" variant={ ButtonVariantType.link } className={ styles.actionButton } onClick={ deleteHandler }>
          <Icon type={ IconType.trash } isGrey />
        </Button>
      </Tooltip>
    </span>
  );
};
