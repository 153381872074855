import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, arrayPush, arrayRemove, arrayMove } from 'redux-form';
import ExternalLinks from './ExternalLinks';
import * as dealSettingsFormConstants from '../../constants';

const selector = formValueSelector(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME);

function mapStateToProps(state) {
  return {
    collection: selector(state, 'viewerCustomLinks'),
    fieldName: 'viewerCustomLinks',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onHandleAdd: (value) => arrayPush(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, 'viewerCustomLinks', value),
    onHandleRemove: (index) => arrayRemove(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, 'viewerCustomLinks', index),
    onHandleMove: (oldIndex, newIndex) => arrayMove(dealSettingsFormConstants.DEAL_SETTINGS_FORM_NAME, 'viewerCustomLinks', oldIndex, newIndex),
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalLinks);
