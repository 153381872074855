import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import selectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';
import BaseSubscriptionForm from '@/finsight/ui/components/SubscriptionForm/Base';
import validate from '@/finsight/ui/components/SubscriptionForm/validate';
import { MANAGE_SUBSCRIPTIONS_FORM_NAME } from '@/condor/ui/components/Finsight/Subscribers/constants';

const stateSelector = (state) => ({
  isSubmitting: selectors.isSubmitting(state),
  initialValues: selectors.getSubscriptionsFormValues(state),
  subscriptionsData: selectors.getSubscriptionsData(state),
});

const ManageSubscribeFormContainer = (props) => {
  const { isSubmitting, initialValues, subscriptionsData } = useReduxSelector(stateSelector);

  return (
    <BaseSubscriptionForm
      { ...props }
      isSubmitting={ isSubmitting }
      initialValues={ initialValues }
      subscriptionsData={ subscriptionsData }
      validate={ validate }
      formName={ MANAGE_SUBSCRIPTIONS_FORM_NAME }
    />
  );
};

export default ManageSubscribeFormContainer;
