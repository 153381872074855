import React from 'react';
import { jsx } from 'slate-hyperscript';
import { camelCase } from 'lodash';
import { Text } from 'slate';
import escapeHtml from 'escape-html';
import capitalize from '@/Framework/dataHelpers/string/capitalize';
import { TSlateNode } from '@/Framework/UI/Molecules/Form/RichTextInput/interfaces';

const getInlineStyle = (element: HTMLElement): React.CSSProperties => Array
  .from(element.style)
  .reduce(
    (style, name) => {
      style[camelCase(name as string)] = element.style[name];
      return style;
    },
    {},
  );

const ELEMENT_TAGS = {
  P: () => ({ type: 'paragraph' }),
  A: (el: HTMLElement) => ({ type: 'link', href: el.getAttribute('href') }),
  DIV: (el: HTMLElement) => {
    const style = el.getAttribute('style');
    const { textAlign } = getInlineStyle(el);
    const type = `align${ capitalize(textAlign) }`;
    return { type, style };
  },
  UL: () => ({ type: 'bulleted-list' }),
  OL: () => ({ type: 'numbered-list' }),
  LI: () => ({ type: 'list-item' }),
};

const TEXT_TAGS = {
  I: () => ({ italic: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SPAN: (el: HTMLElement) => {
    const style = el.getAttribute('style');
    const color = style.includes('color');
    const fontSize = style.includes('font-size');
    const value = style.split(':')[1];
    if (color) {
      return { type: 'color', color: value };
    }
    if (fontSize) {
      return { type: 'fontSize', fontSize: value };
    }
    return { type: '', style };
  },
};

export const serialize = (node: TSlateNode): string => {
  if (Text.isText(node)) {
    const {
      text,
      bold,
      italic,
      underline,
      fontSize,
      color,
    } = node;
    let string = escapeHtml(text);
    if (bold) {
      string = `<b>${ string }</b>`;
    }
    if (italic) {
      string = `<i>${ string }</i>`;
    }
    if (underline) {
      string = `<u>${ string }</u>`;
    }
    if (fontSize) {
      string = `<span style='font-size: ${ fontSize }'>${ string }</span>`;
    }
    if (color) {
      string = `<span style='color: ${ color }'>${ string }</span>`;
    }
    return string;
  }

  const {
    children: nodeChildren,
    type,
    href,
  } = node;

  let children = nodeChildren
    .map(serialize)
    .flat()
    .join('');

  switch (type) {
    case 'alignRight':
      return `<div style="text-align: right">${ children }</div>`;
    case 'alignLeft':
      return `<div style="text-align: left">${ children }</div>`;
    case 'alignCenter':
      return `<div style="text-align: center">${ children }</div>`;
    case 'paragraph':
      return `<p>${ children }</p>`;
    case 'link':
      return `<a href="${ escapeHtml(href) }" target="_blank">${ children }</a>`;
    case 'bulleted-list':
      return `<ul>${ children }</ul>`;
    case 'list-item':
      return `<li>${ children }</li>`;
    case 'numbered-list':
      return `<ol>${ children }</ol>`;
    default:
      return children;
  }
};

export const deserialize = (el: HTMLElement) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  const { nodeName } = el;

  let children = Array.from(el.childNodes)
    .map(deserialize)
    .flat();

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};
