import React, { memo, useEffect } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { Switch, NavLink } from 'react-router-dom';

import {
  CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL,
  CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL,
  CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL,
  CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL,
} from '@/condor/ui/common/constants';

import Route from '@/Framework/Router/ReactRouter/Route';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import MigrateModal from '@/condor/ui/components/Finsight/Companies/MigrateModal';
import CompaniesProfileForm from './CompaniesProfileForm';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';

import DealsList from './DealsList';
import floatStyles from '@dealroadshow/uikit/core/styles/helpers/floats.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import styles from './companyProfile.scss';
import btnStyles from '@dealroadshow/uikit/core/components/Button/button.scss';

interface IProps {
  isSpinnerVisible: boolean,
  isMigrateModalVisible: boolean,
  item: any,
  reset: () => void,
  init: () => void,
}

const CompanyProfile = (
  {
    isSpinnerVisible,
    init,
    reset,
    item,
    isMigrateModalVisible,
  }: IProps,
) => {
  useEffect(() => {
    init();
    return reset;
  }, []);

  const { pathname } = useLocation();

  const isCreating = pathname === CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL;

  return (
    <PageWrapper
      className={ styles.companyProfile }
      label={ isCreating ? 'Create Company' : item.name }
      headerComponent={ !isCreating && (
        <>
          <a
            data-test="dealListCreateDealButton"
            href={ `${ CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL }?issuerId=${ item.id }` }
            className={ cn(
              styles.createDealButton,
              btnStyles.action,
              btnStyles.btnIcon,
              spacesStyles.plxl,
              spacesStyles.prxl,
            ) }
          >
            <span>Create Deal</span>
            <Icon
              type={ IconType.plusBold }
              className={ cn(spacesStyles.mll, styles.createDealIcon) }
            />
          </a>
          <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
            <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
              <NavLink
                activeClassName={ pageStyles.isActiveNavLink }
                to={ `${ CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/${ item.id }` }
                data-test="companyProfileTab"
              >
                Profile
              </NavLink>
              <NavLink
                activeClassName={ pageStyles.isActiveNavLink }
                to={ `${ CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${ item.id }` }
                data-test="companyDealsListTab"
              >
                Deals
              </NavLink>
            </div>
          </div>
        </>
      ) }
    >
      <Switch>
        <Route
          path={ [
            CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL,
            `${ CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/${ item.id }`,
          ] }
          component={ CompaniesProfileForm }
        />
        <Route
          path={ `${ CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL }/${ item.id }` }
          component={ DealsList }
        />
      </Switch>
      { isMigrateModalVisible && (
        <MigrateModal />
      ) }
      <Spinner
        overlay
        isFixed
        isVisible={ isSpinnerVisible }
      />
    </PageWrapper>
  );
};

export default memo(CompanyProfile);
