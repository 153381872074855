import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import { useDIContext } from '@/Framework/DI/DIContext';
import useUpdateEffect from '@/Framework/hooks/useUpdateEffect';
import AnalyticsTrackRepository from '@/dealroadshow/infrastructure/repository/analytics/AnalyticsTrackRepository';
import { Filter, Search, FilterSelect } from '@/condor/ui/common/FilterGroup';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import StatusIndicator from '@dealroadshow/uikit/core/components/StatusIndicator';
import * as constants from '@/condor/ui/common/constants';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import {
  TableFilterInterface,
} from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealFiles/reducers/tableFilter';
import { dealFilesTableFilterConfig } from '@/condor/ui/components/DealRoadshows/constants';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import hText from '@dealroadshow/uikit/core/styles/helpers/text.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './styles.scss';

interface IProps {
  match: {
    params: {
      roadshowId: string,
    },
  },
  setDealFilesTableFilterSearch: (e) => void,
  setDealFilesTableFilterPagination: (e) => void,
  setDealFilesTableFilterPerPage: (e) => void,
  setDealFilesTableFilterRadio: (e) => void,
  setDealFilesTableSort: (e) => void,
  getDealFiles: (e) => void,
  downloadFile: (e) => void,
  resetDealFiles: () => void,
  tableFilter: TableFilterInterface,
  dealFiles: [],
  isFetching: boolean,
  totalCount: number,
}

const initialFilter = {
  search: '',
  radio: {
    status: 'all',
    type: 'all',
  },
  paginationGroup: {
    perPage: 50,
    activePage: 1,
  },
  sort: {
    sortBy: 'id',
    sortOrder: 'desc',
  },
};

const DealFiles = ({
  match,
  setDealFilesTableFilterSearch,
  setDealFilesTableFilterPagination,
  setDealFilesTableFilterPerPage,
  setDealFilesTableFilterRadio,
  setDealFilesTableSort,
  getDealFiles,
  downloadFile,
  resetDealFiles,
  tableFilter,
  dealFiles,
  isFetching,
  totalCount,
}: IProps) => {
  const searchNode = useRef(null);
  const { container } = useDIContext();
  const analyticsTrackRepository = container.get<AnalyticsTrackRepository>(AnalyticsTrackRepository);

  useEffect(() => {
    getFiles(initialFilter);

    return () => resetDealFiles();
  }, []);

  useUpdateEffect(() => {
    getFiles(tableFilter);
  }, [tableFilter]);

  // @ts-ignore
  const trackFileDownload = (roadshowId, fileName, fileType, originalName) => analyticsTrackRepository.track('roadshow.admin.file.download', {
    fileName,
    roadshowId,
    originalName,
    fileType,
  });

  const getFiles = useCallback(({ sort, search, radio, paginationGroup }) => {
    const filterRequest = {
      roadshowId: match.params.roadshowId,
      sortBy: sort.sortBy,
      sortOrder: sort.sortOrder,
      search,
      filterStatus: radio.status,
      filterType: radio.type,
      page: paginationGroup.activePage,
      perPage: paginationGroup.perPage,
    };
    getDealFiles(filterRequest);
  }, [match]);

  const handleFilterChange = useCallback((type, value, name = '') => {
    switch (type) {
      case 'radio':
        setDealFilesTableFilterRadio({ value, group: name });
        searchNode.current.clearSearch();
        break;
      case 'search':
        setDealFilesTableFilterSearch({ value });
        break;
      default:
        break;
    }
  }, [searchNode]);

  const handleTableSortChange = useCallback(
    (sortBy, sortOrder) => setDealFilesTableSort({ sortBy, sortOrder }), [],
  );

  const handleFileDownload = useCallback((file) => {
    if (downloadFile) {
      trackFileDownload(match.params.roadshowId, file.name, file.componentTypeName, file.originalName);
      downloadFile(file);
    }
  }, []);

  const cellIdCallback = (cellProps) => (
    <div className="roadshow-id-clip ">
      { cellProps.cellData }
    </div>
  );

  const cellPublishedCallback = ({ row }) => {
    const status = row.deletedAt ? 'unpublished' : 'published';
    return (
      <StatusIndicator status={ status } title={ status } className={ styles.statusIndicator } />
    );
  };

  const cellDealNameCallback = (cellProps) => <span className="red">{ cellProps.cellData }</span>;

  const cellActionCallback = ({ row }) => (
    <Button
      variant={ ButtonVariantType.action }
      onClick={ () => handleFileDownload(row) }
      className={ styles.actionButton }
      title="Download"
      dataTest="downloadButton"
    />
  );

  /**
   * @param {Object} page
   */
  const handlePageChange = useCallback(
    (page) => setDealFilesTableFilterPagination({ value: page.selected }), [],
  );

  /**
   * @param {Object} perPage
   */
  const handleItemsPerPageChange = useCallback(
    (perPage) => setDealFilesTableFilterPerPage({ value: perPage.value }), [],
  );

  /**
   * @return {ReactElement}
   */
  const tableColumns = useMemo(() => [
    {
      name: 'status',
      title: 'Status',
      width: 76,
      className: cn(alignStyles.flexCenter, styles.statusCell),
      cellCallback: cellPublishedCallback,
      sortable: true,
    },
    {
      name: 'id',
      title: 'Unique ID',
      width: 106,
      className: cn('wrapper-id', hText.nowrap),
      cellCallback: cellIdCallback,
      sortable: true,
    },
    {
      name: 'name',
      title: 'Name',
      minWidth: 300,
      cellCallback: cellDealNameCallback,
      sortable: true,
    },
    {
      name: 'originalName',
      title: 'File Name',
      minWidth: 300,
      sortable: true,
    },
    {
      name: 'componentTypeName',
      title: 'File Type',
      width: 108,
      className: alignStyles.alignRight,
      sortable: true,
      cellCallback: ({ row }) => (row.componentType === 'commercial_media' ? 'Media' : row.componentTypeName),
    },
    {
      name: 'updatedAt',
      title: 'Uploaded',
      width: 181,
      cellCallback: ({ row }) => format(
        getDateFromUnixTime(row.updatedAt),
        constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT,
      ),
      sortable: true,
    },
    {
      name: 'deletedAt',
      title: 'Deleted',
      width: 170,
      cellCallback: ({ row }) => (
        row.deletedAt
          ? format(getDateFromUnixTime(row.deletedAt), constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT)
          : ''
      ),
      sortable: true,
    },
    {
      name: 'action',
      title: '',
      width: 108,
      className: alignStyles.alignCenter,
      cellCallback: cellActionCallback,
    },
  ], []);

  return (
    <PageWrapper>
      <TableWrapper
        label="Analytics"
        className={ styles.tableWrapper }
        filterComponent={ (
          <Filter
            config={ dealFilesTableFilterConfig }
            state={ tableFilter }
            onChange={ handleFilterChange }
          >
            <div className={ styles.filters }>
              <FilterSelect
                dataTest="condorDealRoadshowsDealFilesFilter"
                formFieldClassName={ styles.formField }
              />
              { /* @ts-ignore */ }
              <Search forwardRef ref={ searchNode } />
            </div>
          </Filter>
        ) }
      >
        <DataTable
          columns={ tableColumns }
          dataTest="roadshowDealFilesDataTable"
          data={ dealFiles }
          sortBy={ tableFilter.sort.sortBy }
          sortOrder={ tableFilter.sort.sortOrder }
          onSortChange={ handleTableSortChange }
          loadingComponent={ Spinner }
          isFetching={ isFetching }
        />
        <Paginator
          className={ cardStyles.cardInner }
          totalCount={ totalCount }
          page={ tableFilter.paginationGroup.activePage }
          perPage={ tableFilter.paginationGroup.perPage }
          onItemsPerPageChange={ handleItemsPerPageChange }
          onPageChange={ handlePageChange }
          dataTest="dealFilesSettings"
        />
      </TableWrapper>
    </PageWrapper>
  );
};

export default DealFiles;
