import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import config from '@/Framework/config';
import { DataTable, dataTableStyles, Paginator, Spinner, Icon, IconType } from '@dealroadshow/uikit';
import QueryFilterFormContainer from '../components/QueryFilterForm/QueryFilterFormContainer';
import PreviewCellModal from '../components/PreviewCellModal';
import PreviewJsonCellModal from '../components/PreviewJsonCellModal';
import NotificationManager from '@/Framework/Notification/NotificationManager';

import spaceStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './databaseTableItemList.scss';

interface IProps {
  goToDatabase: (dataSource: any, database: any, table: any) => void,
  getList: (option: { [key: string]: any }) => void,
  setBreadcrumb: (options: {
    name: string,
    url: string,
  }) => void,
  resetState: () => void,
  itemsPerPageTableData: (page: { [key: string]: any }) => void,
  paginateTableData: (page: { [key: string]: any }) => void,
  sortTableData: (sortBy: string, sortOrder: string) => void,
  removeBreadcrumb: (breadcrumb: string) => void,
  path: { [key: string]: any }[],
  columns: { [key: string]: any }[],
  collection: { [key: string]: any }[],
  isFetching: boolean,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: string,
  totalCount: number,
  reloadSalt: number,
  queryFilter: { [key: string]: any },
}

let queryFilter = null;

class Databases extends Component<IProps, {
  previewCellData: { [key: string]: any },
  previewJsonCellData: { [key: string]: any },
}> {
  constructor(props) {
    super(props);
    this.state = {
      previewCellData: null,
      previewJsonCellData: null,
    };

    this.convertPath = this.convertPath.bind(this);
    this.setToDefaultRequestData = this.setToDefaultRequestData.bind(this);
    this.renderPreviewCellModal = this.renderPreviewCellModal.bind(this);
    this.onSelectCell = this.onSelectCell.bind(this);
    this.renderQueryFilter = this.renderQueryFilter.bind(this);
    this.getList = this.getList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    this.cellDataCallback = this.cellDataCallback.bind(this);

    this.onAddToFilter = this.onAddToFilter.bind(this);
    this.setDataToPreviewJsonCellModal = this.setDataToPreviewJsonCellModal.bind(this);
    this.removeDataToPreviewJsonCellModal = this.removeDataToPreviewJsonCellModal.bind(this);
    this.getDataToPreviewJsonCellModal = this.getDataToPreviewJsonCellModal.bind(this);
    this.renderPreviewJsonCellModal = this.renderPreviewJsonCellModal.bind(this);

    this.isCanShowList = this.isCanShowList.bind(this);
    this.getColumns = this.getColumns.bind(this);

    this.getDataToPreviewCellModal = this.getDataToPreviewCellModal.bind(this);
    this.setDataToPreviewCellModal = this.setDataToPreviewCellModal.bind(this);
    this.removeDataToPreviewCellModal = this.removeDataToPreviewCellModal.bind(this);
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.columns, prevProps.columns)) {
      this.removeBreadcrumb();
      this.goToDatabase(this.props.path);
      this.setToDefaultRequestData();
      this.getList({ ...this.props, sortBy: null });
    } else if (
      prevProps.page !== this.props.page
      || prevProps.perPage !== this.props.perPage
      || prevProps.sortBy !== this.props.sortBy
      || prevProps.sortOrder !== this.props.sortOrder
    ) {
      this.getList(this.props);
    } else if (
      !isEqual(this.props.queryFilter, prevProps.queryFilter)
      && this.props.page !== 1
    ) {
      this.handlePageChange({ selected: 1 });
    } else if (
      !isEqual(this.props.queryFilter, prevProps.queryFilter)
      && this.props.page === 1
    ) {
      this.getList(this.props);
    } else if (this.props.reloadSalt !== prevProps.reloadSalt) {
      this.getList(this.props);
    }
  }

  componentWillUnmount() {
    this.removeBreadcrumb();
    this.setToDefaultRequestData();
  }

  setToDefaultRequestData() {
    this.props.resetState();
    if (queryFilter) {
      queryFilter.clear();
    }
  }

  // TODO:Fix after release @Zeleniy
  // eslint-disable-next-line react/sort-comp
  goToDatabase(path) {
    const { dataSource, database, table } = this.convertPath(path);
    if (!!dataSource && !!database && !!table) {
      this.setBreadcrumb(dataSource, database, table);
      this.props.goToDatabase(dataSource, database, table);
    }
  }

  setBreadcrumb(dataSource, database, table) {
    this.props.setBreadcrumb({
      name: `${ dataSource }\\${ database }\\${ table }`,
      url: `${ config.tenant.condor.routes.base }/technical/databases/${ dataSource }/${ database }/${ table }`,
    });
  }

  removeBreadcrumb() {
    const { dataSource, database, table } = this.convertPath(this.props.path);
    this.props.removeBreadcrumb(`${ config.tenant.condor.routes.base }/technical/databases/${ dataSource }/${ database }/${ table }`);
  }

  init(props) {
    const { columns } = props;
    if (columns) {
      this.getList(props);
    }
  }

  isCanShowList() {
    const { columns } = this.props;
    return !!columns;
  }

  getList(props) {
    const { page, perPage, sortBy, sortOrder, columns, path, queryFilter } = props;
    const { dataSource, database, table } = this.convertPath(path);
    if (!columns) {
      return;
    }
    if (!sortBy) {
      this.handleSortChange(columns[0].name, sortOrder);
      return;
    }
    this.props.getList({
      filter: queryFilter,
      dataSource,
      database,
      table,
      sortBy,
      sortOrder,
      page,
      perPage,
    });
  }

  handleSortChange(sortBy: string, sortOrder: string) {
    this.props.sortTableData(sortBy, sortOrder);
  }

  handleItemsPerPageChange(perPage) {
    this.props.itemsPerPageTableData(perPage.value);
  }

  handlePageChange(page) {
    this.props.paginateTableData(page.selected);
  }

  /** Table json modal * */

  cellDataCallback({ row, cellName }) {
    let columnType = this.getColumnsType(cellName);
    let cellData = row[cellName];
    let iconType = null;
    let content = null;
    let showJsonFieldModal = false;
    let showPreviewFieldModal = false;
    let showIcon = true;

    if (columnType === 'jsonb' && !!cellData) {
      // content = row[cellName];
      iconType = IconType.filtering;
      showJsonFieldModal = true;
      content = '...';
    } else if (columnType === 'text' && !!cellData) {
      // content = row[cellName];
      showPreviewFieldModal = true;
      content = `${ cellData.substring(0, 40) }...`;
      // preview
      iconType = IconType.dealroadshow;
    } else {
      content = cellData;
      iconType = IconType.add;
    }
    return (
      <div>
        { !!content && showIcon && (
          <Icon
            type={ iconType }
            className={ styles.addFieldToFilter }
            onClick={ () => {
              if (showJsonFieldModal) {
                this.setDataToPreviewJsonCellModal(cellName, JSON.parse(cellData), this.props.columns[0].name);
              } else if (showPreviewFieldModal) {
                this.setDataToPreviewCellModal(cellName, cellData, (cellName === 'cuePoints' || cellName === 'errorreason'));
              } else {
                this.onSelectCell(cellName, cellData);
              }
            } }
          />
        ) }
        { content }
      </div>
    );
  }

  onSelectCell(key, value) {
    this.onAddToFilter({
      root: key,
      key,
      value,
    });
  }

  /** preview cell* */
  getDataToPreviewCellModal() {
    return this.state.previewCellData;
  }

  setDataToPreviewCellModal(title, data, isJson) {
    this.setState({
      previewCellData: {
        title,
        data,
        isJson,
      },
    });
  }

  removeDataToPreviewCellModal() {
    this.setState({
      previewCellData: null,
    });
  }

  renderPreviewCellModal() {
    return (
      <PreviewCellModal
        showItem={ this.getDataToPreviewCellModal() }
        onClose={ this.removeDataToPreviewCellModal }
      />
    );
  }

  /** preview select json* */

  setDataToPreviewJsonCellModal(root, data, title) {
    this.setState({
      previewJsonCellData: {
        root,
        data,
        title,
      },
    });
  }

  removeDataToPreviewJsonCellModal() {
    this.setState({
      previewJsonCellData: null,
    });
  }

  getDataToPreviewJsonCellModal() {
    return this.state.previewJsonCellData;
  }

  renderPreviewJsonCellModal() {
    return (
      <PreviewJsonCellModal
        onClose={ this.removeDataToPreviewJsonCellModal }
        onSelect={ this.onAddToFilter }
        onSelectNotSupport={ () => {
          NotificationManager.error('Array not support yet');
        } }
        showItem={ this.getDataToPreviewJsonCellModal() }
      />
    );
  }

  getColumns() {
    const { columns } = this.props;
    return columns.map((column) => ({
      name: column.name,
      title: column.name,
      cellCallback: this.cellDataCallback,
      sortable: this.getColumnsType(column.name) !== 'jsonb',
    }));
  }

  getColumnsType(columnFind) {
    const { columns } = this.props;

    let columnsFind = columns.filter((column) => columnFind === column.name);
    if (columnsFind.length) {
      return columnsFind[0].type;
    }
    return 'not found';
  }

  convertPath(path) {
    return {
      dataSource: path[0],
      database: path[1],
      table: path[2],
    };
  }

  onAddToFilter(data) {
    queryFilter.addNewField(data);
  }

  renderQueryFilter() {
    return (
      <QueryFilterFormContainer
        ref={ (node) => {
          if (node) {
            queryFilter = node.wrappedInstance;
          }
        } }
      />
    );
  }

  render() {
    const { sortBy, sortOrder, totalCount, page, perPage } = this.props;

    if (!this.isCanShowList()) {
      return null;
    }

    return (
      <>
        { this.renderQueryFilter() }
        <div className={ spaceStyles.mbm } />
        <Paginator
          perPage={ perPage }
          totalCount={ totalCount }
          page={ page }
          onPageChange={ this.handlePageChange }
          onItemsPerPageChange={ this.handleItemsPerPageChange }
          dataTest="dataBaseTableItem"
        />
        <div className={ spaceStyles.mbm } />
        <DataTable
          className={ dataTableStyles.isHoverable }
          dataTest="databasesDataTable"
          columns={ this.getColumns() }
          data={ this.props.collection }
          loadingComponent={ Spinner }
          onSortChange={ this.handleSortChange }
          isFetching={ this.props.isFetching }
          sortBy={ sortBy }
          sortOrder={ sortOrder }
        />
        <div className={ spaceStyles.mbm } />
        <Paginator
          perPage={ perPage }
          totalCount={ totalCount }
          page={ page }
          onPageChange={ this.handlePageChange }
          onItemsPerPageChange={ this.handleItemsPerPageChange }
          dataTest="dataBaseTableItem"
        />
        { this.renderPreviewCellModal() }
        { this.renderPreviewJsonCellModal() }
      </>
    );
  }
}

export default Databases;
