import React, { PureComponent } from 'react';
import * as H from 'history';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Modal } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

interface IProps {
  when: boolean | ((currentLocation: H.Location, nextLocation?: H.Location, action?: H.Action) => boolean),
  disableNative?: boolean,
  title?: string,
  text?: string,
}

interface IState {
  isVisible: boolean,
}

class AreYouSureModal extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  /**
   * @param {Function} onCancel
   */
  onCancelClick = (onCancel) => {
    if (onCancel) {
      onCancel();
    }
    this.setIsVisible(false);
  };

  /**
   * @param {Boolean} isVisible
   */
  setIsVisible = (isVisible) => this.setState({ isVisible });

  renderModal = ({ isActive, onConfirm, onCancel }) => {
    const {
      title = 'Are You Sure?',
      text = 'Are you sure you want to leave this form? Any changes you made will be lost.',
    } = this.props;

    if (isActive && !this.state.isVisible) {
      this.setIsVisible(true);
    }

    return (
      <Modal
        onCloseClicked={ () => this.onCancelClick(onCancel) }
        isVisible={ this.state.isVisible }
        title={ title }
        dataTest="areYouSureFormModal"
        footer={ (
          <>
            <Button
              onClick={ onConfirm }
              variant={ ButtonVariantType.action }
              title="Yes"
              dataTest="yesButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              onClick={ onCancel }
              title="No"
              dataTest="noButton"
            />
          </>
        ) }
      >
        { text }
      </Modal>
    );
  };

  render() {
    const { disableNative = true, when } = this.props;

    return (
      <NavigationPrompt
        disableNative={ disableNative }
        when={ when }
      >
        { this.renderModal }
      </NavigationPrompt>
    );
  }
}

export default AreYouSureModal;
