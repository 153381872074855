import ArticlesTable from './ArticlesTable';
import ListTabs, { ETabType } from '@/condor/ui/components/Finsight/CreditFlowResearch/common/ListTabs';
import { SectorsIndustriesContextProvider } from '@/condor/application/Dictionary/SectorsIndustries';

const ArticlesList = () => (
  <>
    <ListTabs type={ ETabType.articles } dataTest="condorArticlesTabs" />
    <ArticlesTable />
  </>
);

export default () => (
  <SectorsIndustriesContextProvider>
    <ArticlesList />
  </SectorsIndustriesContextProvider>
);
