import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import IndustryRepository from '@/finsight/infrastructure/repository/IndustryRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import type { IIndustry } from '@/dealroadshow/domain/vo/industry/Industry';

export const useIndustryList = (initial: IIndustry[]) => {
  const [collection, setCollection] = useState<IIndustry[]>(initial || []);
  const [isFetching, setIsFetching] = useState(false);
  const { container } = useDIContext();

  const getIndustriesList = useCallback(async () => {
    setIsFetching(true);
    try {
      const industryRepository = container.get(IndustryRepository);
      const industryList = await industryRepository.getList();
      setCollection(industryList);
    } catch (error) {
        NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    collection,
    getIndustriesList,
  };
};
