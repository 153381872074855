import { useRef } from 'react';
import { useStore } from 'react-redux';
import ReduxFormAdapter from './ReduxFormAdapter';
import { usePristineForms } from './hooks/usePristineForms';
import { useInvalidForms } from './hooks/useInvalidForms';

type TFormName = string;

export interface IFinalFormInstance {
  submit: () => Promise<unknown> | void,
  getState: () => {
    values: { [fieldName: string]: any },
    errors: { [fieldName: string]: string },
    submitErrors: { [fieldName: string]: string },
    initialValues: { [fieldName: string]: any },
  },
  subscribe: (callback: (FormState: any) => void, subscribers: { [subscriber: string]: boolean }) => () => void,
  [key: string]: any,
}

interface IReduxFormInstance {
  submit: () => Promise<unknown> | void,
  form: string,
  [key: string]: any,
}

interface IOnSubmitPayloadParserFn {
  (values: any): any,
}

interface IOnSubmitFailedFn {
  (values: any, errors: { [fieldName: string]: string }): void,
}

interface IForms {
  [formName: TFormName]: {
    form: IFinalFormInstance,
    onSubmitPayloadParser: IOnSubmitPayloadParserFn,
    onSubmitFailed: IOnSubmitFailedFn,
  },
}

interface ISubmitFormData {
  values: { [fieldName: string]: any },
  errors: { [fieldName: string]: string },
}

export interface IOnSubmitData {
  [formName: string]: ISubmitFormData,
}

interface IRegisterForm {
  name: TFormName,
  formInstance: IFinalFormInstance | IReduxFormInstance,
  onSubmitPayloadParser: IOnSubmitPayloadParserFn,
  onSubmitFailed?: IOnSubmitFailedFn,
  isReduxForm?: boolean,
}

const REQUIRED_FORM_INSTANCE_FIELDS = ['submit'];

const REQUIRED_REDUX_FORM_INSTANCE_FIELDS = [
  ...REQUIRED_FORM_INSTANCE_FIELDS,
  'form', // redux-form specific field with name of form
];

const REQUIRED_FINAL_FORM_INSTANCE_FIELDS = [...REQUIRED_FORM_INSTANCE_FIELDS, 'getState', 'subscribe'];

const validateFormInstance = (formName, formInstance, isReduxForm) => {
  const formInstanceFields = Object.keys(formInstance);
  const requiredFormFields = isReduxForm ? REQUIRED_REDUX_FORM_INSTANCE_FIELDS : REQUIRED_FINAL_FORM_INSTANCE_FIELDS;
  requiredFormFields.forEach((fieldName) => {
    if (!formInstanceFields.includes(fieldName)) {
      throw new Error(`Field ${ fieldName } form instance ${ formName } not found`);
    }
  });
};

export const useFormsManager = () => {
  const forms = useRef<IForms>({});
  const reduxState = useRef(null);
  reduxState.current = useStore();

  const { invalidSubmit, setInvalidForms } = useInvalidForms(forms);
  const { isPristine, subscribeCheckPristineForms, unsubscribeCheckPristineForms } = usePristineForms(forms);

  const getFormByName = (formName: TFormName) => forms.current[formName]?.form;

  const unregisterForm = (formName: TFormName) => {
    if (forms.current[formName]) {
      unsubscribeCheckPristineForms(formName);
      delete forms.current[formName];
    }
  };

  const registerForm = ({
    name,
    formInstance,
    onSubmitPayloadParser = (payload) => payload,
    onSubmitFailed = () => null,
    isReduxForm = false,
  }: IRegisterForm) => {
    if (!forms.current[name]) {
      validateFormInstance(name, formInstance, isReduxForm);
      forms.current[name] = {
        form: (!isReduxForm ? formInstance : new ReduxFormAdapter(formInstance, reduxState)) as IFinalFormInstance,
        onSubmitPayloadParser,
        onSubmitFailed,
      };
      subscribeCheckPristineForms(name);
    }
    return unregisterForm.bind(null, name);
  };

  const resetForm = () => {
    Object.keys(forms.current).forEach((formName) => {
      const { form } = forms.current[formName];
      form.restart();
    });
  };

  const onSubmit = async (): Promise<IOnSubmitData> => {
    const results: IOnSubmitData = {};

    const promiseList = Object.keys(forms.current).map(async (formName) => {
      const { form, onSubmitPayloadParser, onSubmitFailed } = forms.current[formName];
      form.submit();
      const { values, errors, submitErrors } = form.getState();
      const allErrors = { ...errors, ...submitErrors };

      if (Object.keys(allErrors).length) {
        setInvalidForms((invalidForms) => [...invalidForms, formName]);
        onSubmitFailed(values, allErrors);
      }

      const formPayload = await onSubmitPayloadParser(values);
      results[formName] = { values: formPayload, errors: allErrors };
    });

    await Promise.all(promiseList);

    return results;
  };

  return {
    invalidSubmit,
    registerForm,
    getFormByName,
    isPristine,
    resetForm,
    onSubmit,
  };
};
