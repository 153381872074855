import { CheckboxInput } from '@dealroadshow/uikit';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import styles from '@/finsight/ui/components/SubscriptionForm/Base/styles.scss';
import commonStyles from '@/finsight/ui/components/SubscriptionForm/styles.scss';

const UnsubscribeSection = ({ className }: { className?: string }) => (
  <div className={ className }>
    <h3 className={ styles.heading }>Unsubscribe All</h3>
    <div className={ commonStyles.tileRow }>
      <Field
        type="checkbox"
        name="unsubscribeFromAll"
        dataTest="unsubscribeFromAll"
        label="Unsubscribe me from all FINSIGHT communications"
        className={ commonStyles.checkboxItem }
        component={ CheckboxInput }
      />
    </div>
  </div>
);

export default UnsubscribeSection;
