import UpdatedAtCell from './UpdatedAtCell';

import styles from './styles.scss';

export const nameCellCallback = ({ cellData }) => (
  <span className={ styles.name }>{ cellData || '-' }</span>
);

export const updatedAtCellCallback = (props) => (
  <UpdatedAtCell { ...props } />
);
