import { createGroupedOptions } from './commonHelpers';
import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';

export const createIndustryOptions = (
  regions: { id: string, abbreviation: string }[],
  industries: { name: string, subindustryList: { id: string, name: string }[] }[],
): IOption[] => {
  return createGroupedOptions(regions, industries.map((industry) => ({
    name: industry.name,
    subItems: industry.subindustryList,
  })));
};
