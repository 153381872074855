import { connect } from 'react-redux';

import { Paginator } from '@dealroadshow/uikit';

import { itemsPerPage, paginate, getFilingsByFilter } from '@/condor/application/actions/finsight/filings/collection/actions';
import selectors from '@/condor/application/actions/finsight/filings/collection/selectors';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';

const mapStateToProps = (state) => (
  {
    totalCount: selectors.getTotalCount(state),
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    className: cardStyles.cardInner,
  }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  onItemsPerPageChange: ({ value: perPage }) => {
    dispatch(itemsPerPage(perPage));
    dispatch(getFilingsByFilter(ownProps.types));
  },
  onPageChange: ({ selected: page }) => {
    dispatch(paginate(page));
    dispatch(getFilingsByFilter(ownProps.types));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Paginator);
