import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IDataroomListItem } from '@/dataroom/domain/vo/condor/IDataroomListItem';
import { IDataroomDetails } from '@/dataroom/domain/vo/condor/IDataroomDetails';
import { IFetchCollectionPayload, IFetchCollectionResponse } from '@/Framework/State/useFetchCollection';

@Dependencies(JsonRpcDispatcherFactory)
class CondorRepository {
  protected constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  getList = async (
    payload: IFetchCollectionPayload,
  ): Promise<IFetchCollectionResponse<IDataroomListItem>> => {
    const request = new Request('condor.queries.dataroom.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDataroomById = async (payload: { dataroomId: number }): Promise<IDataroomDetails> => {
    const request = new Request('condor.queries.dataroom.settings.view', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  editDataroomName = async (payload: { dataroomId: number, displayName: string }) => {
    const request = new Request('condor.commands.dataroom.settings.update', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  reactivate = async (payload: { dataroomId: number }): Promise<boolean> => {
    const request = new Request('condor.commands.dataroom.management.reactivate', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default CondorRepository;
