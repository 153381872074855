import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';
import type { ISelectOptions } from '@/finsight/ui/components/SubscriptionForm/types';

export const createSimpleOptions = (items: { id: string, name: string }[]): ISelectOptions[] => {
  return items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

export const createGroupedOptions = (
  regions: { id: string, abbreviation: string }[],
  items: { name: string, subItems: { id: string, name: string }[] }[],
): IOption<ISelectOptions>[] => {
  return regions.map((region) => ({
    value: region.id,
    label: region.abbreviation,
    groups: items.map((item) => ({
      label: item.name,
      options: item.subItems.map((subItem) => ({
        label: subItem.name,
        value: `${ subItem.id }_${ region.abbreviation }`,
        inputValue: subItem.id,
        regionName: region.abbreviation,
        regionId: region.id,
      })),
    })),
  }));
};
