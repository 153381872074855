import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { StatusIndicator, StatusIndicatorType } from '@dealroadshow/uikit/core/components/StatusIndicator';
import { getCellDisplayDateFromString } from '@/condor/ui/common/Layout/helpers';

import styles from './styles.scss';

export const textFromArray = ({ cellData }: { cellData: string[] }) => (
  <>{ cellData.length > 0 ? cellData.join(', ') : '-' }</>
);

export const statusCellCallback = ({ cellData }: { cellData: boolean }) => {
  return (
    <Tooltip
      content={ cellData ? 'Active' : 'Inactive' }
    >
      <StatusIndicator
        status={ cellData ? StatusIndicatorType.published : StatusIndicatorType.expired }
      />
    </Tooltip>
  );
};

export const themeOrCategoryCellCallback = ({ cellData }: { cellData: string[] }) => {
  if (cellData.length > 1) {
    return (
      <Tooltip
        content={ (
          <div className={ styles.tooltipContent }>
            { cellData.map((entity: string) => (
              <p className={ styles.tooltipItem } key={ entity }>{ entity }</p>
            )) }
          </div>
        ) }
        className={ styles.themeTooltip }
      >
        <span
          className={ styles.categoryOrThemeItem }
        >
          { cellData[0] }
        </span> <span className={ styles.categoryOrThemeItem }>...</span>
      </Tooltip>
    );
  }
  return (
    <span className={ styles.categoryOrThemeItem }>{ cellData[0] }</span>
  );
};

export const authorCellCallback = ({ cellData: { name } }) => (
  <>{ name }</>
);

export const regionCellCallback = ({ cellData }: { cellData: string[] }) => {
  if (cellData.length > 2) {
    return (
      <Tooltip
        content={ cellData.join(', ') }
      >
        { cellData.slice(0, 2).join(', ') }, ...
      </Tooltip>
    );
  }
  return textFromArray({ cellData });
};

export const createdAtCellCallback = ({ cellData }: { cellData: string }) => {
  return (
    <>
      { getCellDisplayDateFromString(cellData) }
    </>
  );
};
