import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useHistory } from 'react-router-dom';
import { ArticleFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  IArticleUpdatePayload,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';
import { CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST } from '@/condor/ui/common/constants';
import { defaultInitialFormData } from './constants';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { getArticleFormDataByArticle, getSavePayload } from './helpers';
import useCompany from './useCompany';
import { IArticle } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { IDeal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Deal';

interface IUserArticleProfile {
  fromArticle?: string,
  articleId?: string,
  setTitle?: (title: string) => void,
}

const useArticleProfile = ({ fromArticle, articleId, setTitle }: IUserArticleProfile) => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const { loadParentByIds, loadIssuerByIds } = useCompany();
  const { push } = useHistory();
  const { regions } = useRegionsProductsContext();
  const [initialFormData, setInitialFormData] = useState<ArticleFormData>(defaultInitialFormData);
  const [isInitialFetching, setIsInitialFetching] = useState(false);
  const [initialArticle, setInitialArticle] = useState<IArticle>(null);
  const [isActive, setIsActive] = useState(false);

  const createOrUpdateArticle = async (values: ArticleFormData) => {
    const payload = getSavePayload(values);

    try {
      if (payload.id) {
        await creditFlowResearchRepository.updateArticle(payload as IArticleUpdatePayload);
      } else {
        await creditFlowResearchRepository.createArticle(payload);
      }
      NotificationManager.success(`Post has been successfully ${ payload.id ? 'updated' : 'saved' }`);
      push(CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const loadDealsInfo = async (deals: string[]): Promise<IDeal[]> => {
    if (isEmpty(deals)) {
      return [];
    }

    const data = await creditFlowResearchRepository.getDealInfoList({ filters: { includedDealIds: deals } });

    return data.collection;
  };

  const onLoadArticleById = async (id: string, isClone: boolean) => {
    try {
      setIsInitialFetching(true);

      const article = await creditFlowResearchRepository.getArticleById(id);

      if (isClone) {
        article.id = undefined;
        article.isActive = false;
        article.attachments = [];
      }

      setTitle(article.headline);
      setInitialArticle(article);
      setIsActive(article.isActive);

      const [themes, deals, parents, issuers] = await Promise.all([
        creditFlowResearchRepository.getArticlesThemes(),
        loadDealsInfo(article.deals),
        loadParentByIds(article.parents),
        loadIssuerByIds(article.issuers),
      ]);
      const articleFormData = getArticleFormDataByArticle({
        article,
        themes,
        deals,
        regions,
        parents,
        issuers,
      });

      setInitialFormData(articleFormData);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsInitialFetching(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(regions) && (articleId || fromArticle)) {
      onLoadArticleById(articleId || fromArticle, !!fromArticle);
    }
  }, [articleId, fromArticle, regions]);

  useEffect(() => () => { setInitialArticle(null); }, []);

  return {
    isEdit: !!articleId,
    isClone: !!fromArticle,
    initialFormData,
    isInitialFetching,
    initialArticle,
    isActive,
    createOrUpdateArticle,
    setIsActive,
  };
};

export default useArticleProfile;
