import { SidebarWidth } from '@/dealroadshow/domain/SidebarWidth';
import { ISecurityAndDisclaimerFormValues, VerificationState } from './interfaces';

export const SECURITY_AND_DISCLAIMER_FORM_NAME = 'securityAndDisclaimerForm';

export const DEFAULT_WATERMARKS_OPACITY = 8;

export const DISCLAIMER_MAX_LENGTH = 65535;

export const ROADSHOW_FORM_DISCLAIMER = `The information contained in this presentation may be confidential and is provided by the Company to you solely for your reference to allow you to make an evaluation of the Company. Any reproduction, dissemination or onward transmission of this presentation or the information contained herein is prohibited. By accepting delivery of this presentation, you acknowledge and agree to comply with the foregoing restrictions.\n
This presentation is only being provided to persons that are authorized and/or qualified to view the material, and may require that you be either a (i) "Qualified Institutional Buyers", as defined in Rule 144A under the U.S. Securities Act of 1933, as amended (the "Securities Act"), or (ii) non-US persons outside the United States, in compliance with Regulation S under the Securities Act. By viewing this presentation, you warrant and acknowledge that you are either authorized to access this material or that you fall within one of the applicable categories (i) and (ii) above.\n
In addition, this presentation may include forward-looking statements that reflect the Company’s current views with respect to future events and financial performance. These views may be based on a number of assumptions and are subject to various risks. Such forward-looking statements are not guarantees of future performances and no assurance can be given that any future events will occur, that projections will be achieved or that the Company’s assumptions will prove to be correct. Actual results may differ materially from those projected, and the Company does not undertake to revise any such forward-looking statements to reflect future events or circumstances.\n
By accessing the roadshow, you agree to only process the personal data contained in the documents to the extent necessary and in accordance with our Privacy Policy and Terms of Use.\n
By clicking “I Agree” below, you agree with the terms of this disclaimer.`;

export const SECURITY_AND_DISCLAIMER_FORM_FIELD_NAMES: Record<string, keyof ISecurityAndDisclaimerFormValues> = {
  REQUEST_MEETING_ENABLED: 'requestMeetingEnabled',
  INVESTOR_QUESTIONS_ENABLED: 'investorQuestionsEnabled',
  CASE_SENSITIVE_ENTRY_CODES_DISABLED: 'caseSensitiveEntryCodes',
  WATERMARK_ENABLED: 'watermarkEnabled',
  WATERMARK_OPACITY: 'watermarkOpacity',
  PDF_WATERMARK_ENABLED: 'pdfWatermarkEnabled',
  MOBILE_DISABLED: 'mobileDisabled',
  CORPORATE_EMAIL_CHECKED: 'corporateEmailChecked',
  SIDEBAR_WIDTH: 'sidebarWidth',
  DISCLAIMER: 'disclaimer',
  INVESTOR_EMAIL_VERIFICATION: 'investorEmailVerification',
  EVERCALL_CHAT_LINK: 'evercallChatLink',
  EVERCALL_CHAT_LINK_ID: 'evercallChatLinkId',
  EVERCALL_CHAT_LINK_ID_ERROR: 'evercallChatLinkIdError',
};

export const SECURITY_AND_DISCLAIMER_DEFAULT_FORM_VALUES: ISecurityAndDisclaimerFormValues = {
  watermarkEnabled: true,
  pdfWatermarkEnabled: false,
  investorEmailVerification: VerificationState.BypassDeactivated,
  mobileDisabled: false,
  corporateEmailChecked: false,
  sidebarWidth: SidebarWidth.Standard,
  disclaimer: ROADSHOW_FORM_DISCLAIMER,
  watermarkOpacity: `${ DEFAULT_WATERMARKS_OPACITY }%`,
  caseSensitiveEntryCodes: false,
  investorQuestionsEnabled: false,
  requestMeetingEnabled: false,
  evercallChatLink: null,
  evercallChatLinkId: null,
  evercallChatLinkIdError: null,
};
