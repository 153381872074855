import { format } from 'date-fns';

import {
  useArticlesActionsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesList/ArticlesActionsContext';
import { ActionModalType, ResearchType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import ActionModal from 'src/condor/ui/components/Finsight/CreditFlowResearch/common/ActionModal';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT, TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';

import styles from './styles.scss';

const ArticlesTableModals = () => {
  const {
    isModalOpen,
    article,
    modalType,
    closeModal,
    isRequestPerforming,
    handleDeleteArticle,
    handleSendEmail,
  } = useArticlesActionsContext();

  if (!isModalOpen || !modalType) {
    return null;
  }

  const isNeedToShowEmailHistory = ActionModalType.RESEND_EMAIL === modalType && !!article.mailingRequests.length;
  const handleAction = modalType === ActionModalType.DELETE_POST ? handleDeleteArticle : handleSendEmail;

  return (
    <ActionModal
      isModalOpen={ isModalOpen }
      modalType={ modalType }
      title={ article.headline }
      authorName={ article.author.name }
      tags={ article.themes }
      researchType={ ResearchType.POST }
      isRequestPerforming={ isRequestPerforming }
      closeModal={ closeModal }
      handleAction={ handleAction }
    >
      { isNeedToShowEmailHistory && (
        <div className={ styles.emailHistory }>{
          article.mailingRequests.map((requestTime) => (
            <div key={ requestTime } className={ styles.emailHistoryRow }>
              <p className={ styles.emailHistoryRowTitle }>Email Alert sent at:</p>
              <p className={ styles.emailHistoryRowDate }>
                { format(utcToZonedDate(TIMEZONE_DEFAULT, new Date(requestTime)),
                  MONTH_UNARY_DAY_YEAR_DATE_FULLTIME_FORMAT,
                ) } ET
              </p>
            </div>
          ))
        }
        </div>
      ) }
    </ActionModal>
  );
};

export default ArticlesTableModals;
