import React, { useState } from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Radio, { RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import Link from '@/Framework/Router/ReactRouter/Link';
import SearchInput from '@/Framework/UI/Molecules/Form/SearchInput/SearchInput';
import { CONDOR_EVERCALL_LIST_FILTERS_RADIO_OPTIONS } from './constants';
import { CONDOR_EVERCALL_GROUP_CREATE } from '@/condor/ui/common/constants';
import { useCallsCollectionContext } from '@/evercall/application/condor/call/collection/CallsCollectionContext';
import styles from './callsList.scss';

const CallsListFilters = () => {
  const [searchValue, setSearchValue] = useState('');

  const {
    state: {
      isFetching,
      selectedFilter,
    },
    debouncedSearch,
    setSelectedFilter,
  } = useCallsCollectionContext();

  return (
    <div className={ styles.filtersContainer }>
      <RadioGroup
        name="evercallsListFiltersRadioGroup"
        disabled={ isFetching }
        onChange={ setSelectedFilter }
        isNarrow
        selectedValue={ selectedFilter }
        className={ styles.radioFiltersContainer }
        dataTest="evercallsListFiltersRadioGroup"
      >
        {
          CONDOR_EVERCALL_LIST_FILTERS_RADIO_OPTIONS.map((option) => (
            <Radio
              dataTest={ option.value }
              key={ option.value }
              value={ option.value }
              label={ option.label }
            />
          ))
        }
      </RadioGroup>
      <SearchInput
        isNarrow
        dataTest="searchField"
        name="search"
        inputClassName={ styles.searchFilterInput }
        value={ searchValue }
        placeholder="Filter by Name, Group Name, Roadshow ID or Speaker/Participant ID"
        onChange={ (event) => {
          setSearchValue(event.target.value);
          debouncedSearch(event.target.value);
        } }
      />
      <Link to={ CONDOR_EVERCALL_GROUP_CREATE }>
        <Button
          className={ styles.createGroupButton }
          variant={ ButtonVariantType.action }
          dataTest="createGroupButton"
        >
          Create Group
          <Icon
            className={ styles.createGroupIcon }
            type={ IconType.plusBold }
          />
        </Button>
      </Link>
    </div>
  );
};

export default CallsListFilters;
