import cn from 'classnames';
import { Field, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { Textarea } from '@dealroadshow/uikit';
import UploadImageInput from '@/openMarket/ui/common/Condor/UploadImageInput';
import DisplayTabs from '../../fields/DisplayTabs';
import { FormFieldName } from '../../constants';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import formStyles from '../../profileForm.scss';
import styles from './customization.scss';

const Customization = () => {
  return (
    <div className={ cardStyles.cardInnerHorizontal } data-test="customizationSection">
      <Field
        label="Header Logo"
        name={ FormFieldName.HEADER_LOGO }
        uploadName="logo"
        component={ UploadImageInput }
        dataTest="upload_logo"
      />
      <Field
        label="Header description"
        name={ FormFieldName.HEADER_DESCRIPTION }
        dataTest={ FormFieldName.HEADER_DESCRIPTION }
        component={ Textarea }
        textareaClassName={ formStyles.disclaimerTextarea }
        isNarrow
        isEnabledCountdownMessage
        maxLength={ 1000 }
      />
      <div className={ styles.displayTabs }>
        <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>Display Tabs</div>
        <p className={ styles.displayTabsDescription }>
          Choose the tabs you would like to display or create custom ones.
        </p>
        <FieldArray name={ FormFieldName.DISPLAY_TABS } component={ DisplayTabs } />
      </div>
    </div>
  );
};

export default Customization;
