import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

export const getInventoryColumns = () => ([
  {
    name: 'status',
    title: 'Status',
    sortable: true,
    width: 50,
  },
  {
    name: 'sector',
    title: 'Sector',
    sortable: true,
    className: alignStyles.alignLeft,
    width: 100,
  },
  {
    name: 'name',
    title: 'Bond',
    sortable: true,
    className: alignStyles.alignLeft,
    width: 150,
  },
  {
    name: 'originalFace',
    title: 'Orig Face',
    sortable: true,
    className: alignStyles.alignCenter,
    width: 150,
    tooltipContent: 'Original Face',
  },
  {
    name: 'currentFace',
    title: 'Curr Face',
    sortable: true,
    className: alignStyles.alignCenter,
    width: 150,
    tooltipContent: 'Current Face',
  },
  {
    name: 'wal',
    title: 'Wal',
    sortable: true,
    className: alignStyles.alignLeft,
    tooltipContent: 'Weighted Average Life',
    width: 70,
  },
  {
    name: 'price',
    title: 'Price',
    sortable: true,
    className: alignStyles.alignLeft,
    width: 200,
  },
  {
    name: 'dealer',
    title: 'Dealer',
    sortable: true,
    className: alignStyles.alignLeft,
    tooltipContent: 'Broker-Dealer',
    width: 100,
  },
  {
    name: 'aging',
    title: 'Aging',
    sortable: true,
    className: alignStyles.alignRight,
    tooltipContent: 'Days Live on FINSIGHT B&I',
    width: 100,
  },
  {
    name: 'addedAt',
    title: 'Date added',
    sortable: true,
    className: alignStyles.alignRight,
    width: 120,
  },
  {
    name: 'deleteIcon',
    title: '',
    sortable: false,
    className: alignStyles.alignRight,
    width: 100,
  },
]);
