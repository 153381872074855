import React from 'react';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useSelector } from 'react-redux';
import selectors from '@/condor/application/actions/accounts/item/selectors';
import { useUploadSsoCertificateContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';

const DeleteCertificateModal = () => {
  const { id: accountId } = useSelector((state) => selectors.getItemData(state));
  const { certificateForDelete, setCertificateForDelete, deleteCertificate } = useUploadSsoCertificateContext();

  const buttons = (
    <>
      <Button
        title="Delete"
        variant={ ButtonVariantType.action }
        dataTest="ssoDeleteCertificateModalButtonDelete"
        className={ styles.deleteButton }
        onClick={ () => deleteCertificate(certificateForDelete.id, accountId) }
      />
      <Button
        title="Cancel"
        variant={ ButtonVariantType.text }
        dataTest="ssoDeleteCertificateModalButtonCancel"
        onClick={ () => setCertificateForDelete(null) }
      />
    </>
  );

  return (
    <Modal
      title="Delete Certificate"
      dataTest="ssoDeleteCertificateModal"
      className={ styles.deleteModal }
      isVisible={ !!certificateForDelete }
      onCloseClicked={ () => setCertificateForDelete(null) }
      footer={ buttons }
    >
      <div className={ styles.deleteMessageContainer }>
        <span>Please confirm you would like to delete</span>
        <span className={ styles.fileName }>{ ` ${ certificateForDelete?.name }.` }</span>
      </div>
    </Modal>
  );
};

export default DeleteCertificateModal;
