import { Dependencies } from 'constitute';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';

@Dependencies(CondorRpcDispatcher)
class CompaniesRepository {
  private readonly condorRpc: JsonRpcDispatcher;

  constructor(condorRpc) {
    this.condorRpc = condorRpc;
  }

  listStats = async (payload = {}) => {
    let request = new Request('condor.analytics.account.get_accounts_stats', payload);
    let response = await this.condorRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  listStatsById = async (payload = {}): Promise<{ payload: IAccountProfileItem }> => {
    let request = new Request('condor.analytics.account.get_account_stats_by_id', payload);
    let response = await this.condorRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default CompaniesRepository;
