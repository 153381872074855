import { connect } from 'react-redux';
import { CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import {
  sort,
  getCondorFinsightCompanyDealsByFilter,
} from '@/condor/application/actions/finsight/company/deals/collection/actions';
import {
  submitCondorFinsightCompanyDealsInlineForm,
  resetCondorFinsightCompanyDealItem,
} from '@/condor/application/actions/finsight/company/deals/item/actions';

import columns from './columns';
import validate from './dealRowValidator';
import EditableDataTable from '@/condor/ui/common/Layout/EditableDataTable';
import selectors from '@/condor/application/actions/finsight/company/deals/collection/selectors';
import itemSelectors from '@/condor/application/actions/finsight/company/deals/item/selectors';

import { dataTableStyles } from '@dealroadshow/uikit';

const mapStateToProps = (state, ownProps) => (
  {
    className: dataTableStyles.isHoverable,
    columns,
    collection: selectors.getCollectionData(state),
    isCollectionFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    selectedItemId: itemSelectors.isInlineEditing(state) ? itemSelectors.getItemData(state)?.id : undefined,
    isUpdating: itemSelectors.isUpdating(state),
    dataTest: 'condorDealsTable',
    formProps: {
      form: CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME,
      validate,
      initialValues: ownProps.initialValues,
    },
  });

const mapDispatchToProps = (dispatch) => (
  {
    onSubmit: async (formData) => {
      await dispatch(submitCondorFinsightCompanyDealsInlineForm(formData));
      dispatch(resetCondorFinsightCompanyDealItem());
      dispatch(getCondorFinsightCompanyDealsByFilter());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getCondorFinsightCompanyDealsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableDataTable);
