import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import { Icon, IconType } from '@dealroadshow/uikit';
import StatusIndicator, { StatusIndicatorType } from '@dealroadshow/uikit/core/components/StatusIndicator';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ICellProps } from '@dealroadshow/uikit/core/components/Table/DataTable';
import { useProfilesListContext } from './ProfileListContext';
import { IListProfile } from '@/openMarket/domain/Condor/vo/IProfile';
import {
  CONDOR_FINSIGHT_OPEN_MARKET_PROFILES,
  CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE,
} from '@/condor/ui/common/constants';
import decodeSymbols from '@/Framework/dataHelpers/string/decodeSymbols';

import styles from './profilesList.scss';

const getEditUrl = (id: string) => `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/${ id }/edit`;

export const statusCellCallback = ({ row }: ICellProps<IListProfile>) => {
  const status = row.isPublished ? StatusIndicatorType.published : StatusIndicatorType.unpublished;
  const dataTest = row.isPublished ? 'published' : 'draft';

  return (
    <Tooltip content={ capitalize(status) }>
      <span data-test={ dataTest }>
        <StatusIndicator status={ status } className={ styles.statusIndicator } />
      </span>
    </Tooltip>
  );
};

export const nameCellCallback = ({ row }: ICellProps<IListProfile>) => (
  <Tooltip content="Open Profile">
    <Link data-test="edit" to={ getEditUrl(row.id) } className={ styles.name }>
      { decodeSymbols(row.name) }
    </Link>
  </Tooltip>
);

export const ActionButtons = ({ row }: ICellProps<IListProfile>) => {
  const { openDeleteModal } = useProfilesListContext();
  const deleteHandler = () => openDeleteModal(row);

  return (
    <div>
      <Tooltip
        content="Add New Event"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Link
          data-test="addNewEvent"
          className={ styles.actionButton }
          to={ {
            pathname: CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE,
            state: { profile: { id: row.id, name: row.name } },
          } }
        >
          <Icon type={ IconType.addCircle } />
        </Link>
      </Tooltip>
      <Tooltip
        content="View Events"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Link
          data-test="viewEvents"
          to={ `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/${ row.id }/events` }
        >
          <Button
            dataTest="viewEventsButton"
            variant={ ButtonVariantType.link }
            disabled={ row.totalEvents === 0 }
            className={ styles.actionButton }
          >
            <Icon type={ IconType.list } />
          </Button>
        </Link>
      </Tooltip>
      <Tooltip
        content="Edit"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Link data-test="edit" className={ styles.actionButton } to={ getEditUrl(row.id) }>
          <Icon type={ IconType.pencil } />
        </Link>
      </Tooltip>
      <Tooltip
        content="Delete"
        wrpClassName={ styles.actionButtonTooltip }
        containerClassName={ styles.actionButtonTooltipContainer }
        hideOnClick
      >
        <Button
          dataTest="delete"
          variant={ ButtonVariantType.link }
          className={ styles.actionButton }
          onClick={ deleteHandler }
        >
          <Icon type={ IconType.trash } isGrey />
        </Button>
      </Tooltip>
    </div>
  );
};
