import IconSendEmail from '@dealroadshow/uikit/core/components/Icon/IconSendEmail';
import IconEmailChecked from '@dealroadshow/uikit/core/components/Icon/IconEmailChecked';
import { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

import styles from './styles.scss';

const sendButtonTitle = (isSendEmail: boolean) => (
  <span>
    { isSendEmail ? <IconSendEmail className={ styles.icon } /> : <IconEmailChecked className={ styles.icon } /> }
    { isSendEmail ? 'Send Email' : 'Resend Email' }
  </span>
);

export default {
  [ActionModalType.DELETE_STAT]: {
    variant: ButtonVariantType.warning,
    title: 'Delete',
    dataTest: 'statsModalDeleteButton',
  },
  [ActionModalType.DELETE_POST]: {
    variant: ButtonVariantType.warning,
    title: 'Delete',
    dataTest: 'articlesModalDeleteButton',
  },
  [ActionModalType.SEND_EMAIL]: {
    variant: ButtonVariantType.action,
    title: sendButtonTitle(true),
    dataTest: 'articlesModalSendEmailButton',
  },
  [ActionModalType.RESEND_EMAIL]: {
    variant: ButtonVariantType.action,
    title: sendButtonTitle(false),
    dataTest: 'articlesModalResendEmailButton',
  },
  [ActionModalType.UNPUBLISH]: {
    variant: ButtonVariantType.warning,
    title: 'Unpublish',
    dataTest: 'articlesModalUnpublishButton',
  },
};
