import cn from 'classnames';
import React from 'react';
import { Spinner, DataTable, Paginator, dataTableStyles } from '@dealroadshow/uikit';
import { useDataroomCondorListingContext } from '@/dataroom/application/condor/DataroomCondorListingContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './dataroomsList.scss';
import { columns } from './columns';

const DataroomsList = () => {
  const { tenant } = useDataroomTenantContext();

  const {
    isFetching,
    collection,
    totalCount,
    page,
    perPage,
    sortBy,
    sortOrder,
    sort,
    paginate,
    setItemsPerPage,
  } = useDataroomCondorListingContext();

  const handlePageChange = (page : { selected: number }) => {
    paginate(page.selected);
  };

  const handleItemsPerPageChange = (perPage: { value: number }) => {
    setItemsPerPage(perPage.value);
  };

  return (
    <>
      <DataTable
        containerClassName={ styles.dataTableContainer }
        className={ cn(dataTableStyles.isHoverable, styles.dataroomTable) }
        dataTest="dataRoomsDataTable"
        columns={ columns(tenant) }
        data={ collection }
        isFetching={ isFetching }
        loadingComponent={ Spinner }
        onSortChange={ sort }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
      />
      <Paginator
        className={ cardStyles.cardInner }
        dataTest="dataRoomListPagination"
        totalCount={ totalCount }
        page={ page }
        perPage={ perPage }
        onItemsPerPageChange={ handleItemsPerPageChange }
        onPageChange={ handlePageChange }
      />
    </>
  );
};

export default DataroomsList;
