import { getFormatInTimeZoneDate } from '@/Framework/DateTime/getFormatInTimeZoneDate';
import { TIMEZONE_DEFAULT } from '@/Framework/DateTime/dateFormats';

import styles from './styles.scss';

export const getAuthor = (author: string) => (
  <p className={ styles.author }>{ `by ${ author }` }</p>
);

export const getPreviewDate = (date: string) => {
  if (!date) {
    return null;
  }
  return (
    <p className={ styles.date }>{ getFormatInTimeZoneDate(date, TIMEZONE_DEFAULT) } ET</p>
  );
};

export const getThemes = (themes: string[]) => (
  <div data-test="previewThemes">
    { themes.map((theme) => (<span className={ styles.themeItem } key={ theme }>{ theme }</span>)) }
  </div>
);

export const getAlertsHistory = (alerts: string[]) => {
  return (
    <>
      { alerts.map((alert) => (
        <p key={ alert } className={ styles.emailAlertItem }>
          <span className={ styles.emailAlertLabel }>
            Email Alert sent at:
          </span>
          <span>
            { getFormatInTimeZoneDate(alert, TIMEZONE_DEFAULT) } ET
          </span>
        </p>
      )) }
    </>
  );
};
