import { useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import ContactRepository from '@/finsight/infrastructure/repository/ContactRepository';
import { IUserPlanResponse } from '@/users/domain/vo/IUserPlanResponse';
import { initUserPlan } from '@/finsight/application/User/UserPlan/helpers';

const useGetUserPlan = (contactId = '') => {
  const { container } = useDIContext();
  const contactRepository = container.get(ContactRepository);
  const [userPlan, setUserPlan] = useState<IUserPlanResponse>(initUserPlan);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getUserPlan = async () => {
    setIsFetching(true);
    try {
      const userPlan = !contactId
        ? await contactRepository.getUserCurrentPlan()
        : await contactRepository.getUserPlanById({ contactId });
      setUserPlan(userPlan);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getUserPlan();
  }, []);

  return {
    userPlan: userPlan.plan,
    isFetching,
  };
};

export default useGetUserPlan;
