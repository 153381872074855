import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Spinner, Modal } from '@dealroadshow/uikit';
import styles from './deleteModal.scss';

interface IProps {
  title: string,
  disclaimer: React.ReactNode,
  isLoading: boolean,
  close: () => void,
  confirm: () => void,
}

const DeleteModal = ({ title, disclaimer, isLoading, close, confirm }: IProps) => {
  const footer = (
    <>
      <Button
        className={ styles.deleteButton }
        variant={ ButtonVariantType.action }
        onClick={ confirm }
        title="Delete"
        dataTest="deleteButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ close }
        title="Cancel"
        dataTest="cancelButton"
      />
    </>
  );

  return (
    <Modal
      title={ title }
      isVisible
      closeOnEsc
      hideOnOverlayClicked
      className={ styles.deleteModal }
      onCloseClicked={ close }
      footer={ footer }
      dataTest="deleteModal"
    >
      <div className={ styles.deleteMessageContainer }>{ disclaimer }</div>
      <Spinner overlay isVisible={ isLoading } />
    </Modal>
  );
};

export default DeleteModal;
