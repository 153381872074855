import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import { IArticle, ISimpleIndustry, ISimpleSector } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { IPreviewData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/PreviewData';
import ISector from '@/finsight/domain/vo/ISector';
import IIndustry from '@/finsight/domain/vo/IIndustry';
import ISubsector from '@/finsight/domain/vo/ISubsector';
import ISubindustry from '@/finsight/domain/vo/ISubindustry';
import { ISingleArticle } from '@/finsight/domain/vo/CreditFlow/Article';
import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';
import { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import {
  IGroupProductItem,
  IGroupThemeData,
  IGroupThemeItem,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ThemesData';
import {
  IGroupCategory,
  IGroupCategoryData,
  IGroupProduct,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IGroupCategory';
import { IOptionTheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { IOptionCategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';

const processEntities = (
  entities: ISimpleSector[] | ISimpleIndustry[],
  entityList: ISector[] | IIndustry[],
  entityKey: 'sectorId' | 'industryId',
  subentityKey: 'subsectorId' | 'subindustryId',
  subentityList: 'subsectorList' | 'subindustryList',
) => {
  const sectorsIndustries = [];
  const subsectorsSubindustries = [];
  entities.forEach((entity) => {
    const { [entityKey]: entityId, [subentityKey]: subentityId } = entity;
    const currentEntity = entityList.find(({ id }) => id === entityId);
    const articleEntity = omit(currentEntity, [subentityList]);
    const articleSubentity = currentEntity[subentityList].find(({ id }) => id === subentityId);
    if (!sectorsIndustries.find(({ id }) => id === articleEntity.id)) {
      sectorsIndustries.push(articleEntity);
    }
    if (articleSubentity && !subsectorsSubindustries.find(({ id }) => id === articleSubentity.id)) {
      subsectorsSubindustries.push(articleSubentity);
    }
  });
  return { sectorsIndustries, subsectorsSubindustries };
};

export const mapSectorsIndustries = (
  article: IArticle | IPreviewData | ISingleArticle,
  sectors: ISector[],
  industries: IIndustry[],
  showSector: boolean,
  showIndustry: boolean,
) => {
  if (showSector) {
    return processEntities(article.sectors, sectors, 'sectorId', 'subsectorId', 'subsectorList');
  }

  if (showIndustry) {
    return processEntities(article.industries, industries, 'industryId', 'subindustryId', 'subindustryList');
  }
  return { sectorsIndustries: [], subsectorsSubindustries: [] };
};

export const addSectorIndustryToData = (
  data,
  sectorsIndustries: ISector[] | IIndustry[],
  subsectorsSubindustries: ISubsector[] | ISubindustry[],
) => {
  const updatedData = { ...data };
  if (sectorsIndustries.length === 1) {
    updatedData.sectorOrIndustry = sectorsIndustries[0] || null;
    if (subsectorsSubindustries.length === 1) {
      updatedData.subsectorOrSubindustry = subsectorsSubindustries[0] || null;
    }
  }
  return updatedData;
};

type TCollection = (IGroupThemeData | IGroupCategoryData)[];
type TOption = IOptionTheme | IOptionCategory;

export const getOptionsForGroupSelectWithFilters = (
  products: IProduct[],
  regions: IRegion[],
  data: TCollection,
  viewNameItemKey: 'themeName' | 'displayName',
  itemsListKey: 'themes' | 'categories',
): IOption<TOption>[] => {
  if (isEmpty(products) || isEmpty(regions)) {
    return [];
  }

  const productMap = new Map(
    products.map((product) => [
      product.id,
      `${ product.name } (${ product.abbreviation })`,
    ]),
  );

  const dataMap = new Map(
    data.map(({ regionId, products }) => [regionId, products]),
  );

  const completeOption = (item: IGroupThemeItem | IGroupCategory, regionAbbreviation = '') => ({
    label: item[viewNameItemKey],
    value: item.id,
    regionName: regionAbbreviation,
  });

  const completeGroup = (productItem: IGroupProductItem | IGroupProduct, regionAbbreviation = '') => {
    const { productId } = productItem;

    return {
      label: productMap.get(productId) || '',
      value: productId,
      options: productItem[itemsListKey].map((item) => completeOption(item, regionAbbreviation)),
    };
  };

  return regions.map(({ id, abbreviation }) => ({
    label: abbreviation,
    value: id,
    groups: dataMap.get(id)?.map((product) => completeGroup(product, abbreviation)) || [],
  }));
};
