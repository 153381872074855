import { ReactNode } from 'react';
import { Modal } from '@dealroadshow/uikit/core/components/Modal';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { ActionModalType, ResearchType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import ModalFooter from './ModalFooter';
import { stringsConfig, mapTypeToDescription } from './modalConfig';

import styles from './styles.scss';

interface IArticleActionModalProps {
  isModalOpen: boolean,
  modalType: ActionModalType,
  title: string,
  authorName: string,
  tags: string[],
  isRequestPerforming?: boolean,
  closeModal: () => void,
  handleAction: () => void,
  researchType?: ResearchType,
  children?: ReactNode,
}

const ActionModal = ({
  isModalOpen,
  modalType,
  title,
  authorName,
  tags,
  isRequestPerforming = false,
  closeModal,
  handleAction,
  researchType = ResearchType.POST,
  children,
}: IArticleActionModalProps) => {
  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isVisible={ isModalOpen && !!modalType }
      onCloseClicked={ closeModal }
      title="Are you sure?"
      closeOnEsc
      footer={ (
        <ModalFooter
          modalType={ modalType }
          isRequestPerforming={ isRequestPerforming }
          closeModal={ closeModal }
          handleAction={ handleAction }
        />
      ) }
      className={ styles.articlesModal }
      dataTest={ `${ modalType }Modal` }
    >
      <p className={ styles.modalDescription }>{ mapTypeToDescription[modalType] }</p>
      <div className={ styles.modalTable }>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>{ stringsConfig[researchType].title }</p>
          <p className={ styles.modalTableHeadline }>{ title }</p>
        </div>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>{ stringsConfig[researchType].author }</p>
          <p>{ authorName }</p>
        </div>
        <div className={ styles.modalRow }>
          <p className={ styles.modalTableTitle }>{ stringsConfig[researchType].tagsTitle }</p>
          <p className={ styles.modalTableTags }>
            { tags.map((tag: string) => <span key={ tag } className={ styles.modalTableTag }>{ tag }</span>) }
          </p>
        </div>
      </div>
      { children }
      <Spinner isVisible={ isRequestPerforming } overlay isAbsolute />
    </Modal>
  );
};

export default ActionModal;
