import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import ProductRepository from '@/finsight/infrastructure/repository/ProductRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import type { IProduct } from '@/finsight/domain/vo/Product';

export const useProductsList = (initial: IProduct[]) => {
  const [collection, setCollection] = useState<IProduct[]>(initial || []);
  const [isFetching, setIsFetching] = useState(false);
  const { container } = useDIContext();

  const getProductsList = useCallback(async () => {
    setIsFetching(true);
    try {
      const productRepository = container.get(ProductRepository);
      const products = await productRepository.getList();
      setCollection(products);
    } catch (error) {
        NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    collection,
    getProductsList,
  };
};
