import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';
import { IStat, IStatToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';

export const mapCategoriesProductsRegions = (
  stat: IStat,
  categoriesCollection: ICategory[],
  products: IProduct[],
  regions: IRegion[],
): IStatToDisplay => {
  const regionAbbreviations = new Set<string>();
  const productAbbreviations = new Set<string>();

  const categories = stat.categories.map((categoryId: string) => {
    const currentCategory = categoriesCollection.find(({ id }) => id === categoryId);

    productAbbreviations.add(products.find((product) => product.id === currentCategory.productId)?.abbreviation);

    const themeRegion = regions.find((region) => region.id === currentCategory.regionId);

    regionAbbreviations.add(themeRegion.abbreviation);

    return `${ themeRegion.abbreviation } ${ currentCategory.displayName }`;
  });

  return {
    ...stat,
    categories,
    products: Array.from(productAbbreviations),
    regions: Array.from(regionAbbreviations),
  };
};
