import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useState } from 'react';
import CondorRepository from '@/dataroom/infrastructure/repository/CondorRepository';

const useReactivate = (dataroomId: number) => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);

  const reactivate = async (): Promise<boolean> => {
    const condorRepository = container.get(CondorRepository);
    const errorHandler = container.get(DataroomErrorHandler);

    setIsFetching(true);

    try {
      await condorRepository.reactivate({ dataroomId });
      return true;
    } catch (error) {
      errorHandler.handleError(error);
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    reactivate,
  };
};

export default useReactivate;
