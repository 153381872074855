import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import isEqual from 'lodash/isEqual';
import { RadioGroup, Radio, FormField } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import UploadPresentationContainer from './UploadPresentationContainer';
import PresentationDraggable from '../PresentationDraggable';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './dealSlidesForm.scss';
import cn from 'classnames';

interface IProps {
  match: any,
  slideTypes: any[],
  slideType: string,
  initialValues: any,
  presentation: any,
  presentationSlides: any,
  isFetching: boolean,
  handleSubmit: () => void,
  updateDeaSlides: () => void,
  initUploadPresentation: (presentation: any) => void,
  initUploadPresentationSlides: (slideShow: any, slideTypes: any) => void,
  uploadPresentationSlidesOrder: () => void,
  uploadPresentationSlidePreviewUpdate: () => void,
  uploadPresentationSlideCancel: () => void,
  getSlideTypes: () => void,
  getDealSlides: (roadshowId: string) => void,
  resetDealSlides: () => void,
  reset: () => void,
  pristine: boolean,
}

class DealSlides extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      slidesChanged: [],
    };
    this.addSlideChange = this.addSlideChange.bind(this);
    this.removeSlideChange = this.removeSlideChange.bind(this);
  }

  componentDidMount() {
    const { roadshowId } = this.props.match.params;
    this.props.getDealSlides(roadshowId);
    this.props.getSlideTypes();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.initialValues, this.props.initialValues)
        && Object.keys(this.props.initialValues).length) {
      if (this.props.initialValues.presentation) {
        this.props.initUploadPresentation(this.props.initialValues.presentation);
        this.props.initUploadPresentationSlides(
          this.props.initialValues.presentation.slideShow,
          this.props.slideTypes,
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.resetDealSlides();
  }

  addSlideChange(slide) {
    this.setState((prevState) => ({
      slidesChanged: prevState.slidesChanged.concat(slide),
    }));
  }

  removeSlideChange(slide) {
    let slideIndex = this.state.slidesChanged.indexOf(slide);
    this.setState((prevState) => ({
      slidesChanged: [
        ...prevState.slidesChanged.slice(0, slideIndex),
        ...prevState.slidesChanged.slice(slideIndex + 1),
      ],
    }));
  }

  hasChanges() {
    // compare presentation match
    if (this.hasNewPresentation(this.props.initialValues.presentation, this.props.presentation.presentationOrigin)) {
      return true;
    }
    // compare slides match
    if (this.state.slidesChanged.length) {
      return true;
    }
    return !this.props.pristine;
  }

  hasNewPresentation(initialPresentation, newPresentation) {
    if (!initialPresentation && newPresentation) {
      return true;
    }
    if (!initialPresentation || !newPresentation) {
      return false;
    }
    return initialPresentation.origin.url !== newPresentation.url;
  }

  render() {
    return (
      <form
        onSubmit={ this.props.handleSubmit }
        autoComplete="off"
        data-test="dealSlidesForm"
      >
        <div className={ cn(cardStyles.cardContainer, styles.formCard) }>
          <h3>Deal Slides</h3>
          <Field
            name="slideType"
            label="Slide Type:"
            component={ (fieldProps) => (
              <RadioGroup
                { ...fieldProps.input }
                selectedValue={ fieldProps.input.value }
                dataTest="dealSlidesFormRadioGroup"
              >
                { this.props.slideTypes.map((type) => (
                  <Radio
                    key={ type.value }
                    dataTest={ type.label }
                    label={ type.label }
                    value={ type.value }
                  />
                )) }
              </RadioGroup>
            ) }
          />

          <FormField>
            <div className={ formStyles.formLabel }>
              Upload New Presentation:
            </div>
            <UploadPresentationContainer />
          </FormField>

          <FormField>
            <div className={ formStyles.formLabel }>
              Slides:
            </div>
            <PresentationDraggable
              presentation={ this.props.presentation }
              presentationSlides={ this.props.presentationSlides }
              addSlideChange={ this.addSlideChange }
              removeSlideChange={ this.removeSlideChange }
              uploadPresentationSlideCancel={ this.props.uploadPresentationSlideCancel }
              uploadPresentationSlidesOrder={ this.props.uploadPresentationSlidesOrder }
              // @ts-ignore
              uploadPresentationSlidePreviewUpdate={ this.props.uploadPresentationSlidePreviewUpdate }
              slideType={ this.props.slideType }
              slideTypes={ this.props.slideTypes }
            />
          </FormField>
        </div>
        <br />
        <Button
          variant={ ButtonVariantType.success }
          type="submit"
          disabled={ this.props.isFetching || !this.hasChanges() }
          // @ts-ignore
          value="save"
          title="Save"
          dataTest="saveButton"
        />
        <Button
          variant={ ButtonVariantType.secondary }
          disabled={ this.props.isFetching }
          onClick={ () => {
            this.props.initUploadPresentation(this.props.initialValues.presentation);
            this.props.initUploadPresentationSlides(
              this.props.initialValues.presentation.slideShow,
              this.props.slideTypes,
            );
            this.props.reset();
          } }
          title="Cancel"
          dataTest="cancelButton"
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'dealSlidesForm',
  enableReinitialize: true,
})(DealSlides);
