import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import CreditFlowResearchRepository from '@/finsight/infrastructure/repository/CreditFlowResearchRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import type { IMarketTheme } from '@/finsight/domain/vo/CreditFlow/IMarketTheme';
import { HostNameKey } from '@/finsight/ui/components/SubscriptionForm/const';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';

export const useThemesList = (themesList?: IMarketTheme[] | ITheme[]) => {
  const [collection, setCollection] = useState<IMarketTheme[] | ITheme[]>(themesList || []);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { container } = useDIContext();
  const { code } = TenantConfig.fromHostname();

  const getThemesList = useCallback(async () => {
    setIsFetching(true);
    try {
      const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
      const themes = code === HostNameKey.finsight
        ? await creditFlowResearchRepository.getMarketThemesList()
        : await creditFlowResearchRepository.getThemesList();
      setCollection(themes);
      setIsInitialized(true);
    } catch (error) {
        NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    isInitialized,
    collection,
    getThemesList,
  };
};
