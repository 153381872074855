import React, { SyntheticEvent } from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { RequestSubmitType } from '@/condor/application/FinsightManager/ManagePlans/constants';
import {
  initialSubmitData,
  useApproveDenyFormContext,
} from '@/condor/application/FinsightManager/ManagePlans/ApproveDenyFormContext';
import { Checkbox } from '@dealroadshow/uikit';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

interface IProps {
  handleSubmit: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>,
}

const FooterModal = ({ handleSubmit }: IProps) => {
  const {
    selectedSubmitData: { typeSubmit },
    setSelectedSubmitData,
    setIsNotificationEnabled,
    isNotificationEnabled,
  } = useApproveDenyFormContext();

  const typeVariantSubmitBtn = typeSubmit === RequestSubmitType.APPROVE
    ? ButtonVariantType.action
    : ButtonVariantType.warning;

  const onChangeNotification = (event) => {
    setIsNotificationEnabled(event.target.checked);
  };

  return (
    <div className={ alignStyles.flexSpaceBetween }>
      <div>
        <Button
          type="submit"
          variant={ typeVariantSubmitBtn }
          title={ typeSubmit }
          dataTest={ `${ typeSubmit }UserPlanRequestFormSubmit` }
          onClick={ handleSubmit }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ () => setSelectedSubmitData(initialSubmitData) }
          title="Cancel"
          dataTest={ `${ typeSubmit }UserPlanRequestFormCancel` }
        />
      </div>
      <Checkbox
        dataTest={ `${ typeSubmit }UserPlanRequestNotificationCheckbox` }
        label="Send email notification"
        checked={ isNotificationEnabled }
        onChange={ onChangeNotification }
      />
    </div>
  );
};

export default FooterModal;
