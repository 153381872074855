import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { getSlideTypes, getDealSlides, updateDealSlides, resetDealSlides } from '../actions';
import DealSlidesForm from './DealSlidesForm';
import { initUploadPresentation } from './actions/presentation';
import {
  initUploadPresentationSlides,
  uploadPresentationSlidesOrder,
  uploadPresentationSlideCancel,
} from './actions/presentationSlides';

const selector = formValueSelector('dealSlidesForm');

function mapStateToProps(state) {
  return {
    slideType: selector(state, 'slideType') || 'png',
    slideTypes: state.dealRoadshows.roadshow.dealSlides.slideTypes.options,
    initialValues: state.dealRoadshows.roadshow.dealSlides.data.initialValues,
    presentation: state.upload.presentation,
    presentationSlides: state.upload.presentationSlides,
    isFetching: state.dealRoadshows.roadshow.dealSlides.data.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSlideTypes,
    initUploadPresentation,
    initUploadPresentationSlides,
    uploadPresentationSlidesOrder,
    uploadPresentationSlideCancel,
    getDealSlides,
    resetDealSlides,
    onSubmit: updateDealSlides,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealSlidesForm);
