import React, { useEffect } from 'react';
import cn from 'classnames';
import DataroomForm from '@/dataroom/ui/components/Condor/EditDataroom/DataroomForm/DataroomForm';
import DataroomCondorActionsContext, { useDataroomCondorActionsContext } from '@/dataroom/application/condor/DataroomCondorActionsContext';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from './editDataroom.scss';

const EditDataroom = () => {
  const { details: { getDataroomDetails, dataroomDetails } } = useDataroomCondorActionsContext();

  useEffect(() => {
    getDataroomDetails();
  }, []);

  return (
    <>
      { dataroomDetails && (
        <div>
          <div className={ pageStyles.pageHeader }>
            <div
              className={ cn(
                pageStyles.pageHeaderTitle,
                headersStyles.isH2,
                styles.pageHeaderTitle,
              ) }
              data-test="pageHeaderTitle"
            >
              { dataroomDetails?.displayName }
            </div>
          </div>
          <div className={ styles.pageDescription }>
            Settings
          </div>
          <DataroomForm />
        </div>
      ) }
    </>
);
};

export default (props) => (
  <DataroomCondorActionsContext dataroomId={ props.match.params.dataroomId }>
    <EditDataroom />
  </DataroomCondorActionsContext>
);
