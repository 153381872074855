import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';

import type { IMarketTheme } from '@/finsight/domain/vo/CreditFlow/IMarketTheme';
import type { IRegion } from '@/finsight/domain/vo/Region';
import type { IProduct } from '@/finsight/domain/vo/Product';

export const createThemeOptions = (
  themes: IMarketTheme[],
  products: IProduct[],
  regions: IRegion[],
): IOption[] => {
  const productMap = new Map(products.map((p) => [p.id, p]));
  const regionMap = new Map(regions.map((r) => [r.id, r]));

  const themesByRegion = themes.reduce((acc, theme) => {
    const region = regionMap.get(theme.regionId);
    if (!region) return acc;

    if (!acc[theme.regionId]) {
      acc[theme.regionId] = {
        value: region.id,
        label: region.abbreviation,
        groups: [],
      };
    }

    const product = productMap.get(theme.productId);
    if (!product) return acc;

    let productGroup = acc[theme.regionId].groups.find(
      (g) => g.label === (product.displayName || product.name),
    );

    if (!productGroup) {
      productGroup = {
        label: product.displayName || product.name,
        options: [],
      };
      acc[theme.regionId].groups.push(productGroup);
    }

    // @ts-ignore
    productGroup.options.push({
      label: theme.themeName,
      value: theme.id,
      productId: product.id,
      regionName: region.abbreviation,
      regionId: region.id,
    });

    return acc;
  }, {} as Record<string, IOption>);

  return Object.values(themesByRegion);
};
