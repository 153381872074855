import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { IProduct } from '@/finsight/domain/vo/Product';
import { ICreditFlowSubfilters, EIncludedStatuses } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';

const getProductIdsByAbbreviation = (products: IProduct[], abbreviation: string) => {
  if (abbreviation === 'all') {
    return products.map((p) => p.id);
  }
  const product = products.find((p) => p.abbreviation.toLowerCase() === abbreviation.toLowerCase());
  return product ? [product.id] : [];
};

const useCFRListsFilters = () => {
  const { productType }: { productType: string } = useParams();
  const { products, regions } = useRegionsProductsContext();
  const [listFilters, setListFilters] = useState<ICreditFlowSubfilters>({
    includedStatus: EIncludedStatuses.ALL,
    includedRegionIds: regions.map((region) => region.id),
    includedProductIds: getProductIdsByAbbreviation(products, productType),
  });

  useEffect(() => {
    setListFilters((prevState) => ({
      ...prevState,
      includedProductIds: getProductIdsByAbbreviation(products, productType),
    }));
    }, [productType]);

  useEffect(() => {
    setListFilters((prevState) => ({
      ...prevState,
    }));
  }, []);

  const setIncludedStatus = (status: EIncludedStatuses) => {
    setListFilters((prevState) => ({
      ...prevState,
      includedStatus: status,
    }));
  };

  const setIncludedRegions = (values: { value: string, label: string }[]) => {
    setListFilters((prevState) => ({
      ...prevState,
      includedRegionIds: values.map(({ value }) => value),
    }));
  };

  return {
    listFilters,
    productType,
    setIncludedStatus,
    setIncludedRegions,
  };
};

const CreditFlowResearchListsFiltersContext = createContext<ReturnType<typeof useCFRListsFilters>>(null);

export const useCreditFlowResearchListsFilters = () => {
  const context = useContext(CreditFlowResearchListsFiltersContext);
  if (!context) {
    throw new Error('useCreditFlowResearchListsFilters must be used within a CreditFlowResearchListsFiltersContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CreditFlowResearchListsFiltersContextProvider = ({ children }: IProps) => (
  <CreditFlowResearchListsFiltersContext.Provider value={ useCFRListsFilters() }>
    { children }
  </CreditFlowResearchListsFiltersContext.Provider>
);

export default CreditFlowResearchListsFiltersContextProvider;
