import { useHistory } from 'react-router';
import { Button, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import AreYouSureModal from '@/condor/ui/components/Finsight/CreditFlowResearch/common/AreYouSureModal';
import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST } from '@/condor/ui/common/constants';

import styles from './styles.scss';

const StatProfileButtons = () => {
  const { push } = useHistory();
  const { invalid, dirty, submitting, submitSucceeded } = useFormState();
  const isDisabled = invalid || !dirty || submitting;

  return (
    <>
      <div className={ styles.formButtonsContainer }>
        <div>
          <Button
            variant={ ButtonVariantType.action }
            dataTest="saveDraft"
            title="Save Draft"
            type="submit"
            disabled={ isDisabled }
          />
          <Button
            variant={ ButtonVariantType.text }
            dataTest="cancel"
            title="Cancel"
            disabled={ submitting }
            onClick={ () => push(CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST) }
          />
        </div>
        { /* TODO: here will be unpublish button */ }
      </div>
      <AreYouSureModal
        when={ dirty && !submitting && !submitSucceeded }
        disableNative={ false }
      />
    </>
  );
};

export default StatProfileButtons;
