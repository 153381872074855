import React, { useEffect, useState } from 'react';
import cn from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { Field as FieldComponent, FormRenderProps } from 'react-final-form';

import { useCheckBoxArrayContext } from '@/Framework/UI/Molecules/CheckBoxArray/CheckboxArrayContext';
import { IFetchCollectionPayload } from '@/Framework/State/useFetchCollection';
import { IUpdateProgress } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import EditableTable from '@/condor/ui/common/EnhancedTagging/EditableTable';
import { Modal, IColumn, IRowData } from '@dealroadshow/uikit';
import BulkEditingUpdateProgressModal from './BulkEditingUpdateProgressModal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import BulkEditRow from './BulkEditRow';

import styles from './bulkEditingModal.scss';

const PER_PAGE = 100;

interface IProps {
  isBulkModalVisible: boolean,
  setIsBulkModalVisible: (isVisible: boolean) => void,
  getCollection: (payload: IFetchCollectionPayload) => Promise<{ collection: IRowData[], totalCount: number }>,
  columns: IColumn[],
  updateCollection: (payload: IRowData[]) => void,
  updateStatus: IUpdateProgress,
  clearUpdateStatus: () => void,
  checkedRoadshows: string[],
  setCheckedRoadshows: (newValues: string[]) => void,
}

const BulkEditingModal = ({
  isBulkModalVisible,
  setIsBulkModalVisible,
  getCollection,
  columns,
  updateCollection,
  updateStatus,
  clearUpdateStatus,
  checkedRoadshows,
  setCheckedRoadshows,
}: IProps) => {
  const [isFetchingTable, setIsFetchingTable] = useState(false);
  const [collection, setCollection] = useState<IRowData[]>([]);

  const fetchOnePageCollection = async (checkedRoadshows: string[], page: number) => {
    const { collection } = await getCollection({
      sortBy: 'createdAt',
      sortOrder: 'desc',
      includedIds: checkedRoadshows,
      perPage: PER_PAGE,
      page,
    });

    return collection;
  };

  const fetchCollection = async () => {
    try {
      setIsFetchingTable(true);

      const pages = Math.ceil(checkedRoadshows.length / PER_PAGE);
      const collection = await Promise.all(
        Array.from({ length: pages }).map((_, index) => {
          const page = index + 1;

          return fetchOnePageCollection(checkedRoadshows, page);
        }),
      ).then((collections) => collections.flat());

      setCollection(collection);
    } finally {
      setIsFetchingTable(false);
    }
  };

  const { reset: checkboxesReset } = useCheckBoxArrayContext();
  const [modifiedCollection, setModifiedCollection] = useState<IRowData[]>(collection);
  const [isConfirmCloseModalVisible, setIsConfirmCloseModalVisible] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const totalCount = modifiedCollection?.length ?? 0;

  useEffect(() => {
    setModifiedCollection(collection);
  }, [collection]);

  useEffect(() => {
    if (isBulkModalVisible) {
      fetchCollection();
    }
  }, [isBulkModalVisible]);

  useEffect(() => {
    if (!modifiedCollection) {
      setIsBulkModalVisible(false);
      checkboxesReset();
      setCheckedRoadshows([]);
    }
  }, [modifiedCollection]);

  const onSubmit = () => updateCollection(modifiedCollection);

  const saveConfirm = () => {
    checkboxesReset();
    setCheckedRoadshows([]);
    clearUpdateStatus();
    setIsBulkModalVisible(false);
  };

  const handleOnClose = () => {
    if (isDirtyForm) {
      setIsConfirmCloseModalVisible(true);
    } else {
      setIsBulkModalVisible(false);
    }
  };

  return (
    <>
      <Modal
        isVisible={ isBulkModalVisible }
        className={ cn({ [styles.modalIsFetching]: isFetchingTable }, styles.modal) }
        title={ totalCount ? `Bulk editing (${ totalCount })` : 'Bulk editing' }
        onCloseClicked={ handleOnClose }
        showCloseButton={ !isFetchingTable }
        dataTest="bulkEditingModal"
        footer={ (
          <div className={ styles.footer }>
            <Button
              onClick={ onSubmit }
              variant={ ButtonVariantType.action }
              title="Save Changes"
              dataTest="saveButton"
              disabled={ isFetchingTable }
            />
            <Button
              onClick={ handleOnClose }
              variant={ ButtonVariantType.text }
              title="Cancel"
              dataTest="cancelButton"
              disabled={ isFetchingTable }
            />
          </div>
        ) }
      >
        <EditableTable
          isBulkEdit
          name="bulkEditingForm"
          collection={ modifiedCollection?.length ? modifiedCollection : collection }
          columns={ columns }
          isFetching={ isFetchingTable }
          bulkEditRow={ ({ fieldComponent, formProps }) => (
            <BulkEditRow
              fieldComponent={ fieldComponent as typeof FieldComponent }
              formProps={ formProps as FormRenderProps }
              setModifiedCollection={ setModifiedCollection }
              setIsDirtyForm={ setIsDirtyForm }
            />
          ) }
        />
      </Modal>
      <Modal
        isVisible={ isConfirmCloseModalVisible }
        className={ styles.discardModal }
        title="Discard Bulk Edits"
        dataTest="discardBulkModal"
        showCloseButton={ false }
        footer={ (
          <>
            <Button
              onClick={ () => setIsBulkModalVisible(false) }
              variant={ ButtonVariantType.warning }
              title="Cancel Bulk Editing"
              dataTest="discardButton"
              disabled={ isFetchingTable }
            />
            <Button
              onClick={ () => setIsConfirmCloseModalVisible(false) }
              variant={ ButtonVariantType.text }
              title="Cancel"
              dataTest="cancelButton"
              disabled={ isFetchingTable }
            />
          </>
        ) }
      >
        Are you sure you want to cancel these bulk edits? Any changes made will not be saved.
      </Modal>
      <BulkEditingUpdateProgressModal confirm={ saveConfirm } updateStatus={ updateStatus } collection={ collection } />
    </>
  );
};

export default BulkEditingModal;
