import cn from 'classnames';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import noop from 'lodash/noop';
import formatExtensionsByMimeType from '@/Framework/dataHelpers/formatters/formatExtensionsByMimeType';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';
import { getMaxSizeValue } from '@/Framework/UI/Organisms/Upload/helpers/getMaxSizeValue';
import styles from './styles.scss';
import uploadStyles from '@/Framework/UI/Organisms/Upload/upload.scss';
import tableSheet from '@/condor/ui/components/Finsight/Import/modules/Upload/assets/table-sheet.svg';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import { Icon, IconType } from '@dealroadshow/uikit';

interface IProps {
  uploadFile: any,
  uploadFileProcess: any,
  dropzoneHelperClassName: string,
  onUpload: (acceptedFiles: any[]) => void,
  allowedFileTypes: string,
  allowedFileText: string,
  downloadTemplateText: string,
  formFieldName: string,
  uploadName: string,
  maxSize?: number,
  maxSizeUnits: string,
  maxSizeErrorMessage: string,
  onTemplateDownload?: () => void,
}

const defaultProps = {
  maxSize: Infinity,
  onTemplateDownload: noop,
};

const ZERO_SIZE = 0;

class UploadDropzoneOneFile extends Component<IProps, any> {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = { showMaxSizeError: false };
    this.onDropCallback = this.onDropCallback.bind(this);
  }

  // TODO: fix duplicate
  onDropCallback(acceptedDropFiles, rejectedDropFiles) {
    const {
      onUpload,
      allowedFileTypes,
      allowedFileText,
      formFieldName,
      uploadName,
      maxSize,
      maxSizeUnits,
    } = this.props;
    if ((acceptedDropFiles.length + rejectedDropFiles.length) === 1) {
      let acceptedFiles = [];
      let rejectedFiles = [];

      if (acceptedDropFiles.length) {
        const isZeroBytesFile = acceptedDropFiles.every((file) => file.size === ZERO_SIZE);

        acceptedFiles = acceptedDropFiles.filter((file) => file.size <= maxSize && !isZeroBytesFile);
        rejectedFiles = acceptedDropFiles.filter((file) => file.size > maxSize || isZeroBytesFile);

        if (acceptedFiles.length) {
          onUpload(acceptedFiles);
          this.setState({ showMaxSizeError: false });
        }
        if (rejectedFiles.length) {
          if (this.props.maxSizeErrorMessage) {
            this.setState({ showMaxSizeError: true });
          } else if (isZeroBytesFile) {
            const message = getMessage(
              messageCodes.UPLOAD_ZERO_SIZE,
              { fileName: rejectedFiles[0].name },
            );
            NotificationManager.error(message);
          } else {
            const message = getMessage(
              messageCodes.UPLOAD_MAX_SIZE,
              {
                size: getMaxSizeValue(maxSize, maxSizeUnits),
                units: maxSizeUnits,
              },
            );
            NotificationManager.warning(message);
          }
        }
      }

      if (rejectedDropFiles.length) {
        let rejectedExtensionFiles = rejectedDropFiles.filter((file) => (file instanceof File));
        if (rejectedExtensionFiles.length) {
          let acceptedExt = allowedFileTypes.split(',').map((accept) => accept.trim());
          rejectedExtensionFiles.forEach((file) => {
            let name = file.name.split('.');
            if (name.length > 1 && !acceptedExt.includes(name[name.length - 1])) {
              NotificationManager.error(getMessage(
                messageCodes.UPLOAD_INVALID_EXTENSION,
                { fileName: file.name, validExtensions: allowedFileText },
              ));
            }
          });
        }
      }
    }

    if ((acceptedDropFiles.length + rejectedDropFiles.length) > 1) {
      let suffixText = formFieldName !== 'logo' ? ' file' : '';
      NotificationManager.error(getMessage(
        messageCodes.UPLOAD_ONLY_ONE_FILE_ALLOWED,
        { uploadName: `${ uploadName.toLocaleLowerCase() }${ suffixText }` },
      ));
    }
  }

  /**
   * @return {ReactElement}
   */
  render() {
    const {
      allowedFileTypes,
      allowedFileText,
      uploadName,
      onTemplateDownload,
      downloadTemplateText,
      uploadFileProcess,
    } = this.props;
    const dropzoneClassName = cn(
      styles.uploadWrp,
      { [uploadStyles.isDropZoneMaxSizeError]: this.state.showMaxSizeError },
      this.props.dropzoneHelperClassName,
    );
    const onDownloadTemplateClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      onTemplateDownload();
    };

    const onDropZoneClick = (event) => {
      if (uploadFileProcess.uuid) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    return (
      <>
        <Dropzone
          key="dropzone"
          multiple={ false }
          accept={ formatExtensionsByMimeType(allowedFileTypes) }
          onDrop={ this.onDropCallback }
          useFsAccessApi
        >
          { ({ getRootProps, getInputProps }) => (
            <div className={ dropzoneClassName } onClick={ onDropZoneClick } { ...getRootProps() }>
              <div className={ styles.contentWrp }>
                <div className={ styles.content }>
                  <img src={ tableSheet } />
                  <h1 className={ styles.ctaTitle }>Upload { uploadName }</h1>
                  <p>
                    { allowedFileText }
                  </p>
                  <Button
                    variant={ ButtonVariantType.action }
                    className={ cn(styles.ctaBtn, spacesStyles.mrn) }
                    title="Upload Excel"
                    dataTest="condorUploadDropzoneOneFileUploadButton"
                  />
                  <div>
                    <Button
                      onClick={ onDownloadTemplateClick }
                      variant={ ButtonVariantType.text }
                      className={ cn(styles.ctaBtn, spacesStyles.mtxl) }
                      dataTest="condorUploadDropzoneOneFileDownloadButton"
                    >
                      <Icon
                        type={ IconType.download }
                        className={ styles.downloadIcon }
                      />
                      { downloadTemplateText }
                    </Button>
                  </div>
                </div>
              </div>
              <input { ...getInputProps() } />
            </div>
          ) }
        </Dropzone>
        { this.state.showMaxSizeError && (
          <div className={ uploadStyles.error }>{ this.props.maxSizeErrorMessage }</div>
        ) }
      </>
    );
  }
}
// @ts-ignore
UploadDropzoneOneFile.defaultProps = defaultProps;

export default UploadDropzoneOneFile;
