import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import omit from 'lodash/omit';

import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useCreditFlowResearchListsFilters } from '@/condor/application/Finsight/CreditFlowResearch/Filters';
import { useSectorsIndustriesContext } from '@/condor/application/Dictionary/SectorsIndustries';
import { useArticlesThemesContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticlesThemes';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { mapArticleToDisplay } from './helpers';
import { IArticle, IArticleToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { ICreditFlowFilters } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';

const useArticles = () => {
  const { listFilters, productType } = useCreditFlowResearchListsFilters();
  const { sectors, industries, isInitialized: isSecIndInitialized } = useSectorsIndustriesContext();
  const { products, regions, isInitialized: isProductRegionInitialized } = useRegionsProductsContext();
  const { themes: themesCollection, isInitialized: isThemesInitialized } = useArticlesThemesContext();

  const [collection, setCollection] = useState<IArticleToDisplay[]>([]);

  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);

  const articlesCollection = useFetchCollection<IArticle, Partial<ICreditFlowFilters>>(
    creditFlowResearchRepository.getArticlesList,
    { filters: listFilters },
    { sortBy: 'createdAt', sortOrder: 'desc' },
  );

  useEffect(() => {
    if (!isSecIndInitialized || !isThemesInitialized || !isProductRegionInitialized || articlesCollection.isFetching) {
      return;
    }

    const mappedCollection = articlesCollection.collection.map((article) => mapArticleToDisplay(
      article,
      themesCollection,
      products,
      regions,
      sectors,
      industries,
      productType,
    ));

    setCollection(mappedCollection);
  }, [
    isSecIndInitialized,
    isThemesInitialized,
    isProductRegionInitialized,
    articlesCollection.collection,
    articlesCollection.isFetching,
  ]);

  useEffect(() => articlesCollection.reset, []);

  return {
    ...omit(articlesCollection, 'collection'),
    collection,
  };
};

const ArticlesListContext = createContext<ReturnType<typeof useArticles>>(null);

export const useArticlesListContext = () => {
  const context = useContext(ArticlesListContext);
  if (!context) {
    throw new Error('useArticlesListContext must be used within a ArticlesListContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const ArticlesListContextProvider = ({ children }: IProps) => (
  <ArticlesListContext.Provider value={ useArticles() }>
    { children }
  </ArticlesListContext.Provider>
);

export default ArticlesListContextProvider;
