import { injectable, inject } from 'inversify';
import UploadDispatcher from '@/dealroadshow/application/DI/FileUpload/UploadDispatcher';

@injectable()
class LogoRepository {
  constructor(@inject(UploadDispatcher) private uploadDispatcher: typeof UploadDispatcher) {}

  /**
   * Upload logo to backend
   */
  upload = (file: File) => {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_LOGO' });
  };
}

export default LogoRepository;
