import React from 'react';
import cn from 'classnames';
import { Icon } from '@dealroadshow/uikit';
import {
  getDropZoneTypeIcon,
} from '@/condor/ui/components/DealRoadshows/Roadshow/modules/AudioVideo/AudioVideoForm/helpers';

import styles from './CondorDropzoneInternalField.scss';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

interface ICondorDropzoneInternalFieldProps {
  uploadName: string,
  allowedFileText: string,
  fileName: string,
  children: React.ReactNode,
}

const CondorDropzoneInternalField = ({
  uploadName,
  allowedFileText,
  fileName,
  children,
}: ICondorDropzoneInternalFieldProps) => {
  const icon = getDropZoneTypeIcon(uploadName);

  return (
    <>
      <div className={ cn(alignStyles.flexCenter, styles.iconWrapper) }>
        <Icon type={ icon } className={ styles.icon } />
      </div>
      <div className={ styles.fileName }>{ fileName }</div>
      <div className={ styles.text }>
        Drag & drop or <span className={ linkStyles.link }>select file</span>
        <div className={ styles.tip }>{ allowedFileText }</div>
      </div>
      { children }
    </>
  );
};

export default CondorDropzoneInternalField;
