import React, { useEffect } from 'react';
import cn from 'classnames';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { useParams } from 'react-router-dom';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Input from '@dealroadshow/uikit/core/components/Input';
import validate from '../validators/customModalGroupValidators';
import RelatedRoadshowsContainer from '../RelatedRoadshows/RelatedRoadshowsContainer';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './styles.scss';

interface IProps {
  isEnableCustomModal: boolean,
  getData: (roadshowId: string) => void,
  resetData: () => void,
  handleSubmit: () => void,
  isFetching: boolean,

  // below should be inherited from redux-form types
  pristine: boolean,
  reset: () => void,
  change: () => void,
}

const CustomModalForm = (props: IProps) => {
  const { roadshowId } = useParams();

  useEffect(() => {
    props.getData(roadshowId);
    return props.resetData;
  }, []);

  return (
    <form
      onSubmit={ props.handleSubmit }
      autoComplete="off"
      data-test="customModalForm"
    >
      <div className={ cn(cardStyles.cardContainer, styles.formCard) }>
        <h3>Custom Modal</h3>
        <Field
          name="enableCustomModal"
          dataTest="enableCustomModal"
          label="Enable Custom Modal"
          className={ cn(styles.field, styles.enableModalCheckbox) }
          component={ CheckboxInput }
          type="checkbox"
        />
        { props.isEnableCustomModal && (
          <div>
            <Field
              label="Header Text"
              name="title"
              component={ Input }
              className={ styles.fieldCustomWidth }
              asterisk
              dataTest="customModalHeaderTextInput"
            />
            <Field
              label="Body Text"
              name="text"
              isNarrow
              component={ Textarea }
              dataTest="condorCustomModalTextarea"
              className={ cn(styles.field, styles.fieldCustomWidth) }
              textareaClassName={ styles.bodyText }
            />
            <FormField>
              <h3>Roadshows</h3>
              <FieldArray
                name="relatedRoadshows"
                component={ RelatedRoadshowsContainer }
                roadshowId={ roadshowId }
                onChangeLogo={ props.change }
                className={ styles.field }
              />
            </FormField>
            <div className={ styles.checkboxGroup }>
              <div className={ formStyles.formLabel }>Enable Footer Buttons</div>
              <Field
                name="enableIAgreeButton"
                dataTest="enableIAgreeButton"
                label="I agree"
                component={ CheckboxInput }
                type="checkbox"
              />
              <Field
                name="enableContinueButton"
                dataTest="enableContinueButton"
                label="Continue"
                component={ CheckboxInput }
                type="checkbox"
              />
              <Field
                name="enableCancelButton"
                dataTest="enableCancelButton"
                label="Cancel"
                component={ CheckboxInput }
                type="checkbox"
                className={ styles.field }
              />
              <div className={ formStyles.formLabel }>
                Note: If a footer button is not selected the user cannot move past the modal without selecting a
                linked roadshow.
              </div>
            </div>
          </div>

        ) }
      </div>
      <Button
        variant={ ButtonVariantType.action }
        type="submit"
        disabled={ props.isFetching || props.pristine }
        title="Save"
        dataTest="saveButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        disabled={ props.isFetching || props.pristine }
        onClick={ props.reset }
        title="Cancel"
        dataTest="cancelButton"
      />
    </form>
  );
};

export default reduxForm({
  form: 'customModalForm',
  enableReinitialize: true,
  validate,
  onSubmitFail: (errors) => {
    let key = Object.keys(errors)
      .reduce((k, l) => (
        (document.getElementsByName(k)[0].offsetTop < document.getElementsByName(l)[0].offsetTop)
          ? k
          : l
      ));
    document.getElementById(CONTENT_WRP_ID).scrollTo(0, document.getElementsByName(key)[0].offsetTop - 100);
  },
})(CustomModalForm);
