import ActionModal from 'src/condor/ui/components/Finsight/CreditFlowResearch/common/ActionModal';
import {
  useUnpublishModalContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/UnpublishModalContext';
import { ActionModalType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

const UnpublishActionModal = () => {
  const { isOpenModal, setIsOpenModal, article, onUnpublish, isUnpublishing } = useUnpublishModalContext();
  if (!isOpenModal) {
    return null;
  }

  return (
    <ActionModal
      isModalOpen={ isOpenModal }
      modalType={ ActionModalType.UNPUBLISH }
      closeModal={ () => setIsOpenModal(false) }
      title={ article.headline }
      authorName={ article.author.name }
      tags={ article.themes }
      handleAction={ onUnpublish }
      isRequestPerforming={ isUnpublishing }
    />
  );
};

export default UnpublishActionModal;
