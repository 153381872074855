export const ARTICLE_SUBSCRIPTION_ID = '75b570f9-0259-4848-ac2b-7bda8baa24c2';
export const ARTICLE_GROUP_ID = '99c6f0c6-357a-4cf8-8ef4-fba48064e6ff';
export const ARTICLE_SUBGROUP_ID = '46e3ae15-971f-4938-99bb-16164ce5e9a9';

export enum HostNameKey {
    finsight = 'finsight',
    condor = 'condor',
}

export const DEFAULT_OPTIONS = {
  sectorsList: [],
  regionsList: [],
  industriesList: [],
  countriesList: [],
  themesList: [],
  parentCompaniesList: [],
  issuerCompaniesList: [],
  productsList: [],
};
