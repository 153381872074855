import { Class, Transient, Value, Method } from 'constitute';
import ProcessingAdapter from './SocketProcessingAdapter';
import urls from '@/dealroadshow/infrastructure/url/drsUrl';

import { MultipartUploadStrategy, UploadDispatcher, MultipartUploadAdapter } from '@dealroadshow/file-uploader';

const UploadAdapter = new Class(MultipartUploadAdapter, Transient.with([
  new Value({ url: urls.getBaseFilesUrl() }),
]));

const factory = (processingAdapter, uploadAdapter) => {
  return new UploadDispatcher(new MultipartUploadStrategy(uploadAdapter, processingAdapter));
};

export default new Method(factory, [ProcessingAdapter, UploadAdapter]);
