import React, { forwardRef } from 'react';
import { Field } from 'redux-form';
import {
  set, getDate, getYear, getMonth, endOfDay,
} from 'date-fns';
import DatePicker from '@/Framework/UI/Molecules/Form/DatePicker/DatePicker';
import { getTimeZoneDisplayNameDate } from '@/Framework/TimeZone/State/Redux/helpers';
import { MONTH_DAY_YEAR_SLASH_FORMAT } from '@/Framework/DateTime/dateFormats';
import getZonedUTCDate from '@/Framework/DateTime/getZonedUTCDate';
import { IconType } from '@dealroadshow/uikit';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import styles from './activeDates.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

interface IProps {
  timeZone: ITimezone,
}

const CustomInput = forwardRef((
    { name, meta, ...inputProps }: { name: string, meta: IFinalFormFieldInputComponentProps['meta'] },
    ref: React.ForwardedRef<Input>,
) => (
  <Input
    // @ts-ignore
    input={ { ref, ...inputProps } }
    meta={ meta }
    name={ name }
    isReadOnly
    iconClassName={ IconType.calendar }
    isClearable={ false }
    dataTest="activeDatesFinishedAtInput"
  />
));

const ActiveDates = ({ timeZone }: IProps) => {
  const timeZoneName = timeZone ? timeZone.timeZone : 'America/New_York';
  const timeZoneDisplayName = timeZone ? getTimeZoneDisplayNameDate(timeZone) : '';

  const onSelectDate = (timeZone, value) => {
    let date = new Date(value);
    const endOfChosenDay = set(endOfDay(new Date()), {
      year: getYear(date),
      month: getMonth(date),
      date: getDate(date),
    });

    return getZonedUTCDate(timeZone, endOfChosenDay);
  };

  const formatSelectedDate = (value) => {
    if (value) {
      return utcToZonedDate(timeZoneName, value);
    }
    return null;
  };

  return (
    <div>
      <div className={ formStyles.formLabel }>Active Dates <span className="asterisk">*</span></div>
      <br />
      <div className={ styles.startedAt }>
        <Field
          name="startedAt"
          disabled
          component={ Input }
          dataTest="activeDatesStartedAtInput"
        />
      </div>
      <div className={ styles.divider }>–</div>
      <div className={ styles.finishedAt }>
        <Field
          name="finishedAt"
          component={ (fieldProps) => (
            <DatePicker
              customInput={ <CustomInput meta={ fieldProps.meta } name={ fieldProps.input.name } /> }
              selected={ formatSelectedDate(fieldProps.input.value) }
              minDate={ utcToZonedDate(timeZoneName) }
              dateFormat={ MONTH_DAY_YEAR_SLASH_FORMAT }
              onSelect={ (value) => {
                fieldProps.input.onChange(onSelectDate(timeZoneName, value));
              } }
              className={ formStyles.formInput }
              placeholderText="Select Expiration Date"
              popperPlacement="bottom-end"
              dataTest="roadshowActiveDatePicker"
            />
          ) }
        />
      </div>
      <div className={ styles.timeZoneName }>{ timeZoneDisplayName }</div>
    </div>
  );
};

export default ActiveDates;
