import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import { GroupSelectWithToolbarField } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import useToolbarSelectSync from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/useToolbarSelectSync';
import { RegionAbbreviations } from '@/finsight/domain/Region';

const SectorSelect = ({ disabled }: { disabled?: boolean }) => {
  const { options, sectorsOptions } = useCommentaryDealFlowContext();

  const { isFetchingSectors, isFetchingRegions, regions } = options;

  const defaultOptionValue = regions.find((region) => region.abbreviation === RegionAbbreviations.USOA)?.id;

  return (
    <Field
      name="articleParams.sectors"
      label="Select Sectors (abs only)"
      placeholder="Select sectors/subsectors"
      isLoading={ isFetchingSectors || isFetchingRegions }
      dataTest="commentaryDealFlowSelectSecorSubsector"
      isDisabled={ disabled }
      formatValue={ (option) => `${ option.regionName }: ${ option.label }` }
      { ...useToolbarSelectSync({ options: sectorsOptions, defaultOptionValue, dataTest: 'commentaryDealFlowSelectSecorSubsectorRegions' }) }
      component={ GroupSelectWithToolbarField }
    />
  );
};

export default SectorSelect;
