import cn from 'classnames';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import TextEditorField from '@/Framework/UI/Organisms/FinalForm/Fields/TextEditorField';
import StatProfileButtons from './StatProfileButtons';
import CategoriesSelect from './CategoriesSelect';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const StatProfileFields = () => (
  <>
    <div className={ cn(headerStyles.isH3, spacesStyles.mbxl) }>Details</div>
    <Field
      component={ Input }
      name="name"
      label="Name"
      dataTest="name"
      placeholder="Name"
    />
    <Field
      name="categories"
      component={ CategoriesSelect }
    />
    <Field
      name="description"
      component={ TextEditorField }
      label="Description"
      dataTest="description"
      placeholder="Description"
      maxCharactersLimit={ 65000 }
      isCharLimit={ false }
    />
    <StatProfileButtons />
  </>
);

export default StatProfileFields;
