import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import FileDownloadLinkGenerator from '@/dmPortal/application/DI/FileDownloadLinkGenerator/FileDownloadLinkGenerator';

@injectable()
class DocumentsRepository {
  constructor(
    @inject(RpcDispatcher) private rpc: typeof RpcDispatcher,
    // @ts-ignore
    @inject(UploadDispatcher) private uploadDispatcher: typeof UploadDispatcher,
    @inject(FileDownloadLinkGenerator) private fileDownloadLinkGenerator: typeof FileDownloadLinkGenerator,
  ) {}

  /**
   * Get temporary download url for a file
   */
  async getDownloadUrl(url: string, downloadName: string): Promise<void> {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.generate_document_download_link', { entityId, url, downloadName });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }

  /**
   * Get temporary download url for a file
   */
  async getViewOnlyUrl(url: string, downloadName: string): Promise<void> {
    const entityId = this.fileDownloadLinkGenerator.generateUniqueId();

    const request = new Request('roadshow.generate_document_view_only_link', { entityId, url, downloadName });

    return this.fileDownloadLinkGenerator.getFileLink(request, entityId);
  }

  /**
   * Get temporary download url for a file
   */
  async getAdminDownloadUrl(url: string, name: string): Promise<void> {
    let req = new Request('admin.roadshow.get_temporary_download_url', { url, originalName: name });
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }

  /**
   * Upload document to backend
   */
  upload = (file: File) => {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_DOCUMENT' });
  };

  /**
   * @param {Object} payload
   */
  async getDocumentWatermark(payload) {
    let req = new Request('roadshow.get_document_watermark_params', payload);
    let response = await this.rpc.request(req);

    return response.getResult().payload;
  }
}

export default DocumentsRepository;
