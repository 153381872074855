import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

export const getBwicColumns = () => ([
  {
    name: 'status',
    title: 'Status',
    sortable: true,
    width: 50,
  },
  {
    name: 'sector',
    title: 'Sector',
    sortable: true,
    className: alignStyles.alignLeft,
    width: 100,
  },
  {
    name: 'name',
    title: 'Bond',
    sortable: true,
    className: alignStyles.alignLeft,
    width: 140,
  },
  {
    name: 'originalFace',
    title: 'Orig Face',
    sortable: true,
    className: alignStyles.alignRight,
    width: 150,
    tooltipContent: 'Original Face',
  },
  {
    name: 'currentFace',
    title: 'Curr Face',
    sortable: true,
    className: alignStyles.alignRight,
    width: 110,
    tooltipContent: 'Current Face',
  },
  {
    name: 'wal',
    title: 'Wal',
    sortable: true,
    className: alignStyles.alignRight,
    tooltipContent: 'Weighted Average Life',
    width: 100,
  },
  {
    name: 'priceTalk',
    title: 'Price Talk',
    sortable: true,
    className: alignStyles.alignRight,
    width: 150,
  },
  {
    name: 'dealer',
    title: 'Dealer',
    sortable: true,
    className: alignStyles.alignRight,
    tooltipContent: 'Broker-Dealer',
    width: 100,
  },
  {
    name: 'dueBy',
    title: 'Due By (ET)',
    sortable: true,
    className: alignStyles.alignRight,
    width: 400,
  },
  {
    name: 'deleteIcon',
    title: '',
    sortable: false,
    className: alignStyles.alignRight,
    width: 100,
  },
]);
