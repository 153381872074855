/**
 * the "000000" string at the begining is in case the Math.random returns 0.
 * If that's the case the function would give an error. So the Math.random generates a random number,
 * then that number is converted into a base 16 string with letters and numbers, then only the charaters from
 * 2 to 7 are sliced, transformed to uppercase and then only the 6 characters from the right to the left are kept.
 */
export function getRandomColor(): string {
  return `#${ (`000000${ Math.random().toString(16).slice(2, 8).toUpperCase() }`).slice(-6) }`;
}

const extensionToColorMap = new Map<string, string>([
  ['pdf', '#E73737'],
  ['ppt', '#F9701E'],
  ['doc', '#2E8CE2'],
  ['xls', '#30AF63'],
  ['xlsx', '#30AF63'],
]);

export const mapExtensionToColor = (extension: string): string => {
  return extensionToColorMap.get(extension) || '#C0C0C0';
};
