import { createContext, ReactNode, useContext } from 'react';
import useStatProfile from './useStatProfile';

type StatProfileContextType = ReturnType<typeof useStatProfile>;

const StatProfileContext = createContext<StatProfileContextType>(null);

export const useStatProfileContext = () => {
  const context = useContext(StatProfileContext);

  if (!context) {
    throw new Error('useStatProfileContext must be used within the StatProfileContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
  statId?: string,
  setTitle: (title: string) => void,
}

export const StatProfileContextProvider = ({ children, ...otherProps }: IProps) => (
  <StatProfileContext.Provider value={ useStatProfile(otherProps) }>
    { children }
  </StatProfileContext.Provider>
);
