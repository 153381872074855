import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { getContactPermissions } from '@/users/application/actions/permissions/actions';
import Tabs from './Tabs';
import PermissionTable from './PermissionTable';
import PermissionsActions from './PermissionsActions';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import { Spinner } from '@dealroadshow/uikit';
import * as userDataSelector from '@/users/application/actions/data/selectors';

import permissionStyles from './userPermissions.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const UserPermissions = () => {
  const dispatch = useDispatch();
  const { currentUser: { id: currentUserId } } = useSessionContext();
  const { isFetching, modules, group } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const { id: contactId } = useSelector(userDataSelector.getUser);
  const { currentUserPermissions } = useSelector(userPermissionsSelectors.getUserPermissionsState);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(getContactPermissions(contactId));
  }, []);

  const updateOwnContact = contactId === currentUserId;
  const hasPermissionToUpdate = currentUserPermissions.find((permission) => permission.moduleKey === 'user_permissions');

  return (
    <div className={ permissionStyles.permissionsContainer }>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>User Permissions</div>
      <Spinner isVisible={ isFetching } overlay />
      {
        (modules && group) && (
          <>
            <Tabs
              activeTab={ activeTab }
              setActiveTab={ setActiveTab }
            />
            <PermissionTable
              activeTab={ activeTab }
              hasPermissionToUpdate={ hasPermissionToUpdate }
              updateOwnContact={ updateOwnContact }
            />
            <PermissionsActions hasPermissionToUpdate={ hasPermissionToUpdate } />
          </>
        )
      }
    </div>
  );
};

export default UserPermissions;
