import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import btnStyles from '@dealroadshow/uikit/core/components/Button/button.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './filtersBtn.scss';

interface IProps {
  className?: string,
  filtersCount: number,
  onClick: () => void,
}

function FilterBtn({ className = '', ...props }: IProps) {
  return (
    <Button
      variant={ ButtonVariantType.action }
      className={ cn(
        btnStyles.btnIcon,
        btnStyles.btnIconMobile,
        spacesStyles.mrn,
        styles.button,
        className,
      ) }
      onClick={ props.onClick }
      dataTest="filtersButton"
    >
      <span>Filters</span>
      { props.filtersCount > 0 && (
        <span className={ styles.filtersBtnCount }> ({ props.filtersCount })</span>
      ) }
      <Icon type={ IconType.filters } className={ styles.filtersIcon } />
    </Button>
  );
}

export default FilterBtn;
