import { getErrorMessage, getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import useRouter from '@/Framework/hooks/useNextRouter';
import {
  useRoadshowExisting,
} from '@/dealroadshow/application/dmPortal/roadshow/hooks/useRoadshowExisting';

export const useErrorOnInitHandler = () => {
  const { replace } = useRouter();
  const { isRoadshowExisting } = useRoadshowExisting();

  const errorOnInitHandler = (errorResponse) => {
    replace('/roadshow');

    const errorMessage = isRoadshowExisting
      ? getErrorMessage(errorResponse)
      : getMessageByErrorCode('fallback');

    NotificationManager.error(errorMessage);
  };
  return {
    errorOnInitHandler,
  };
};
