import React, { useEffect, useRef, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { PanelGroup, Panel } from '@dealroadshow/uikit/core/components/PanelGroup';

import Tranches from './Tranches';
import TranchesContext from './Tranches/TranchesContext';
import DealDetails from './DealDetails';
import DealComments from './DealComments';
import AdditionalDealProperties from './AdditionalDealProperties';
import DealProfileButtons from './DealProfileButtons';
import DealProfileHeaderLinks from './DealProfileHeaderLinks';
import SubmitDealModal from './SubmitDealModal';
import DeleteDealModal from './DeleteDealModal';

import { CONDOR_DEAL_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';

import useStickyHeader from './helpers/useStickyHeader';
import useDealInitialValues from './helpers/useDealInitialValues';
import useDealModalsStates from './helpers/useDealModalsStates';

import { panelArrowCollapsed, panelArrowExpanded } from '@/condor/ui/common/Layout/helpers';
import getDealSubmitPayload from '@/condor/ui/components/Finsight/DealProfile/helpers/getDealSubmitPayload';
import getDealPageTitle from './helpers/getDealPageTitle';
import validate from './validate';

import styles from './dealProfile.scss';
import hiddenStyles from '@dealroadshow/uikit/core/styles/helpers/hidden.scss';

const panelArrowCollapsedDefault = panelArrowCollapsed('Optional');
const panelArrowExpandedDefault = panelArrowExpanded('Optional');

const panelArrowOptional = panelArrowCollapsed('Optional');
const panelArrowOptionalExpanded = panelArrowExpanded('Optional');

interface IProps {
  isInitialized: boolean,
  isSecFiling?: boolean,
  isCreateNewDeal: boolean,
  dealId: string,
  item: any,
  ratingAgencies: any[],
  options: any,
  init: (dealId: string) => void,
  reset: () => void,
  updateDeal: (payload: any) => void,
  createDeal: (payload: any) => void,
  initialIssuer: any,
  initialDeal: any,
  createDealWithMapToFiling: (payload: any) => void,
  updateDealWithMapToFiling: (payload: any) => void,
  unmap: () => void,
  filingType?: string,
}

const DealProfile = (
  {
    isInitialized,
    isSecFiling = false,
    isCreateNewDeal,
    item,
    ratingAgencies,
    options,
    init,
    reset,
    initialIssuer,
    initialDeal,
    updateDeal,
    createDeal,
    dealId,
    createDealWithMapToFiling,
    updateDealWithMapToFiling,
    unmap,
    filingType = '',
  }: IProps,
) => {
  const containerRef = useRef();

  useEffect(
    () => {
      init(dealId);
      return isSecFiling ? undefined : reset;
    },
    [],
  );

  const {
    isStickyHeaderVisible,
    stickyHeaderWidth,
  } = useStickyHeader(containerRef);

  const {
    isSubmitModalVisible,
    openSubmitModal,
    closeSubmitModal,

    isDeleteModalVisible,
    openDeleteModal,
    closeDeleteModal,
  } = useDealModalsStates();

  const initialValues = useDealInitialValues(
    {
      item,
      ratingAgencies,
      options,
      isInitialized,
      isCreateNewDeal,
      initialIssuer,
      initialDeal,
      isSecFiling,
    },
  );

  const submit = useCallback(
    (values) => {
      const payload = getDealSubmitPayload(values, ratingAgencies);

      if (payload[DEAL_PROFILE_FIELDS.DEAL_ID.name]) {
        isSecFiling ? updateDealWithMapToFiling(payload) : updateDeal(payload);
      } else {
        isSecFiling ? createDealWithMapToFiling(payload) : createDeal(payload);
      }
    },
    [ratingAgencies.length],
  );

  if (!isInitialized || isEmpty(initialValues)) {
    return (
      <Spinner
        isAbsolute
        overlay
      />
    );
  }

  // @ts-ignore
  const pageTitle = getDealPageTitle({ isCreateNewDeal, isSecFiling, deal: item });

  return (
    <PageWrapper
      contentCls={ styles.pageContent }
      label={ pageTitle }
      headerComponent={ !isSecFiling && (
        <DealProfileHeaderLinks isCreateNewDeal={ isCreateNewDeal } />
      ) }
    >
      <div ref={ containerRef }>
        <div
          className={ cn(styles.stickyHeader, { [styles.isVisible]: isStickyHeaderVisible }) }
          style={ { width: stickyHeaderWidth } }
        >
          { pageTitle }
          <div className={ styles.stickyHeaderButtonsContainer }>
            <DealProfileButtons
              openDeleteModal={ openDeleteModal }
              openSubmitModal={ openSubmitModal }
              unmapFiling={ unmap }
              isCreateNewDeal={ isCreateNewDeal }
              isSecFiling={ isSecFiling }
              filingType={ filingType }
            />
          </div>
        </div>
        <FormWrapper
          buttonsHidden
          enableReinitialize
          form={ CONDOR_DEAL_PROFILE_FORM_NAME }
          dataTest={ CONDOR_DEAL_PROFILE_FORM_NAME }
          className={ cn(styles.formContainer, { [styles.isSecFiling]: isSecFiling }) }
          initialValues={ initialValues }
          onSubmit={ submit }
          validate={ validate }
        >
          <PanelGroup
            defaultActiveKey="dealDetails"
            dataTest="dealProfilePanelGroup"
          >
            <Panel
              key="dealDetails"
              id="dealDetails"
              dataTest="dealDetails"
              header="Deal Details"
              { ...(
                !isSecFiling
                  ? {
                    arrow: panelArrowCollapsedDefault,
                    arrowActive: panelArrowExpandedDefault,
                  }
                  : {
                    isActive: true,
                    disabled: true,
                  }
              ) }
            >
              <DealDetails
                isSecFiling={ isSecFiling }
                isCreateNewDeal={ isCreateNewDeal }
              />
              { !isSecFiling && (
                <TranchesContext isStickyHeaderVisible={ isStickyHeaderVisible }>
                  <Tranches />
                </TranchesContext>
              ) }
            </Panel>
            <Panel
              className={ cn({ [hiddenStyles.hidden]: isSecFiling }) }
              key="additionalDealProperties"
              id="additionalDealProperties"
              dataTest="additionalDealProperties"
              header="Additional Deal Properties"
              arrow={ panelArrowOptional }
              arrowActive={ panelArrowOptionalExpanded }
            >
              <AdditionalDealProperties />
            </Panel>
            <Panel
              className={ cn({ [hiddenStyles.hidden]: isSecFiling }) }
              key="dealComments"
              id="dealComments"
              dataTest="dealComments"
              header="Deal Comments"
              arrow={ panelArrowOptional }
              arrowActive={ panelArrowOptionalExpanded }
            >
              <DealComments />
            </Panel>
          </PanelGroup>
          <div className={ styles.formButtonsContainer }>
            <DealProfileButtons
              openDeleteModal={ openDeleteModal }
              openSubmitModal={ openSubmitModal }
              unmapFiling={ unmap }
              isCreateNewDeal={ isCreateNewDeal }
              isSecFiling={ isSecFiling }
              filingType={ filingType }
            />
          </div>
          <SubmitDealModal
            onClose={ closeSubmitModal }
            isVisible={ isSubmitModalVisible }
            isSecFiling={ isSecFiling }
          />
          { !isSecFiling && !isCreateNewDeal && (
            <DeleteDealModal
              onClose={ closeDeleteModal }
              isVisible={ isDeleteModalVisible }
            />
          ) }
        </FormWrapper>
      </div>
    </PageWrapper>
  );
};

export default DealProfile;
