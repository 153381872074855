export enum EIncludedStatuses {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ICreditFlowSubfilters {
  includedStatus: EIncludedStatuses,
  includedRegionIds: string[],
  includedProductIds: string[],
}

export interface ICreditFlowFilters {
  filters: ICreditFlowSubfilters,
  page: number,
  perPage: number,
  search: string,
  sortBy: 'createdAt' | 'updatedAt',
  sortOrder: 'asc' | 'desc',
}
