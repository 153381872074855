import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadPreview } from '@/Framework/UI/Organisms/Upload/components';
import { uploadPresentationSlide, uploadPresentationSlideCancel } from '../DealSlidesForm/actions/presentationSlides';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentationSlides.files,
    formFieldName: 'presentationSlide',
    uploadName: 'Slide',
    uploadForType: 'slide',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentationSlide,
    onUploadCancel: uploadPresentationSlideCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPreview);
