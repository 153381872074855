import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UploadOneFile } from '@/Framework/UI/Organisms/Upload/components';
import * as constants from '@/Framework/UI/Organisms/Upload/constants';
import { uploadPresentationMediaVideo, uploadPresentationMediaVideoCancel } from '@/Framework/UI/Organisms/Upload/actions';

function mapStateToProps(state) {
  return {
    uploadFile: state.upload.presentationMediaVideo,
    allowedFileTypes: constants.UPLOAD_PRESENTATION_MEDIA_VIDEO_ALLOWED_FILES,
    allowedFileText: constants.UPLOAD_PRESENTATION_MEDIA_VIDEO_ALLOWED_FILES_TEXT,
    formFieldName: 'media',
    uploadName: constants.UPLOAD_PRESENTATION_MEDIA_VIDEO_TEXT_NAME,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUpload: uploadPresentationMediaVideo,
    onUploadCancel: uploadPresentationMediaVideoCancel,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadOneFile);
