import ListTabs, { ETabType } from '@/condor/ui/components/Finsight/CreditFlowResearch/common/ListTabs';
import StatsTable from './StatsTable';

const StatsList = () => (
  <>
    <ListTabs type={ ETabType.stats } dataTest="condorStatsTabs" />
    <StatsTable />
  </>
);

export default StatsList;
