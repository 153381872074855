import { ActionModalType, ResearchType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';
import ActionModal from 'src/condor/ui/components/Finsight/CreditFlowResearch/common/ActionModal';
import {
  useStatActionsContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsList/StatActionsContext/StatActionsContext';

const DeleteStatModal = () => {
  const {
    isModalOpen,
    isRequestPerforming,
    closeModal,
    handleDeleteStat,
    stat,
  } = useStatActionsContext();
  if (!isModalOpen) {
    return null;
  }

  return (
    <ActionModal
      isModalOpen={ isModalOpen }
      modalType={ ActionModalType.DELETE_STAT }
      title={ stat.name }
      authorName={ stat.author.name }
      tags={ stat.categories }
      researchType={ ResearchType.STAT }
      isRequestPerforming={ isRequestPerforming }
      closeModal={ closeModal }
      handleAction={ handleDeleteStat }
    />
  );
};

export default DeleteStatModal;
