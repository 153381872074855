import { injectable, inject } from 'inversify';
import UploadDispatcher from '@/dealroadshow/application/DI/FileUpload/UploadDispatcher';

@injectable()
class CuePointsRepository {
  constructor(@inject(UploadDispatcher) private uploadDispatcher: typeof UploadDispatcher) {}

  /**
   * Upload cue points list to backend
   */
  upload(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_CUE_POINTS_LIST' });
  }
}

export default CuePointsRepository;
