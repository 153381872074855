import { createContext, ReactNode, useContext } from 'react';
import useCategories from './useCategories';

type CategoriesContextType = ReturnType<typeof useCategories>;

const CategoriesContext = createContext<CategoriesContextType>(null);

export const useCategoriesContext = () => {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategoriesContext must be used within the CategoriesContext');
  }

  return context;
};

interface IProps {
  children: ReactNode,
}

export const CategoriesContextProvider = ({ children }: IProps) => (
  <CategoriesContext.Provider value={ useCategories() }>
    { children }
  </CategoriesContext.Provider>
);
