export enum ActionModalType {
  DELETE_POST = 'deletePost',
  SEND_EMAIL = 'sendEmail',
  RESEND_EMAIL = 'resendEmail',
  UNPUBLISH = 'unpublish',
  DELETE_STAT = 'deleteStat',
}

export enum ResearchType {
  STAT = 'Stat',
  POST = 'Post',
}
