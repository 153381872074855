import React, { Component } from 'react';
import cn from 'classnames';
import Clearfix from '@dealroadshow/uikit/core/components/Clearfix';
import ImgFetching from '@dealroadshow/uikit/core/components/Loader/ImgFetching';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import UploadPresentationSlideContainer from './UploadPresentationSlideContainer';
import { NotificationManager } from '@/Framework/Notification';
import * as constants from '@/Framework/UI/Organisms/Upload/constants';
import files from '@/Framework/url/filesUrl';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import uploadStyles from '@/Framework/UI/Organisms/Upload/upload.scss';
import styles from './presentationDraggable.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

interface IProps {
  slideType: string,
  slideTypes: any[],
  presentation: any,
  presentationSlides: any,
  addSlideChange: (id: string) => void,
  removeSlideChange: (id: string) => void,
  uploadPresentationSlideCancel: (payload: { uuidOriginal: string, slideType: string, uuid: string }) => void,
  uploadPresentationSlidesOrder: () => void,
}

class PresentationDraggable extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      slideChanges: {},
      uploadModalVisibleRowUuid: null,
    };
    this.cellActionCallback = this.cellActionCallback.bind(this);
    this.cellPreviewCallback = this.cellPreviewCallback.bind(this);
    this.revertSlide = this.revertSlide.bind(this);
    this.resetModalUpload = this.resetModalUpload.bind(this);
    this.applyModalUpload = this.applyModalUpload.bind(this);
    this.getModal = this.getModal.bind(this);
    this.setChangeToSlide = this.setChangeToSlide.bind(this);
  }

  getModal() {
    return (
      <Modal
        onCloseClicked={ () => this.hideModal() }
        isVisible={ !!this.state.uploadModalVisibleRowUuid }
        showCloseButton={ false }
        closeOnEsc={ false }
        title="Upload One Slide"
        dataTest="uploadSlideModal"
        ref={ (ref) => {
          // @ts-ignore
          this.modal = ref;
        } }
        footer={ (
          <>
            <Button
              variant={ ButtonVariantType.success }
              // @ts-ignore
              value="save"
              onClick={ () => this.applyModalUpload(this.state.uploadModalVisibleRowUuid) }
              title="Save"
              dataTest="saveButton"
            />
            <Button
              variant={ ButtonVariantType.secondary }
              onClick={ () => this.resetModalUpload(this.state.uploadModalVisibleRowUuid) }
              title="Cancel"
              dataTest="cancelButton"
            />
          </>
        ) }
      >
        {
          this.props.slideTypes.map((type) => {
            const ALLOWED_FILES_TYPE = `UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_${ type.label }`;
            const ALLOWED_FILES_TYPE_TEXT = `UPLOAD_PRESENTATION_ADMIN_SLIDE_ALLOWED_FILES_TEXT_${ type.label }`;
            return (
              <div key={ type.value }>
                <div>
                  <div className={ formStyles.formLabel }>Upload One Slide { type.label }:</div>
                  <UploadPresentationSlideContainer
                    onChange={ this.setChangeToSlide }
                    uuid={ this.state.uploadModalVisibleRowUuid }
                    slideType={ type.value }
                    allowedFileTypes={ constants[ALLOWED_FILES_TYPE] }
                    allowedFileText={ constants[ALLOWED_FILES_TYPE_TEXT] }
                  />
                </div>
                <Clearfix />
              </div>
            );
          })
        }
      </Modal>
    );
  }

  setChangeToSlide(uuid) {
    this.setState((prevState) => ({
      slideChanges: {
        ...prevState.slideChanges,
        [uuid]: true,
      },
    }));
  }

  showModal(uuid) {
    this.setState({
      uploadModalVisibleRowUuid: uuid,
    });
  }

  hideModal() {
    this.setState({
      uploadModalVisibleRowUuid: null,
    });
  }

  applyModalUpload(uuid) {
    let error = 0;
    this.props.slideTypes.forEach((type) => {
      if (!this.props.presentationSlides.files[uuid].types[type.value].canSave) {
        error++;
        NotificationManager.warning(getMessage(
          messageCodes.CONDOR_FILE_UPLOADING,
          { name: this.props.presentationSlides.files[uuid].types[type.value].uploadFile.name },
        ));
      }
    });

    if (!error) {
      this.setState((prevState) => ({
        slideChanges: {
          ...prevState.slideChanges,
          [uuid]: false,
        },
        uploadModalVisibleRowUuid: null,
      }));
      this.props.addSlideChange(uuid);
    }
  }

  revertSlide(uuid) {
    this.props.slideTypes.forEach((type) => {
      if (this.props.presentationSlides.files[uuid].types[type.value].process.uuid) {
        this.props.uploadPresentationSlideCancel({
          uuidOriginal: uuid,
          slideType: type.value,
          uuid: this.props.presentationSlides.files[uuid].types[type.value].process.uuid,
        });
      }
    });
    this.props.removeSlideChange(uuid);
  }

  resetModalUpload(uuid) {
    if (this.state.slideChanges[uuid]) {
      this.revertSlide(uuid);
    }
    // @ts-ignore
    this.hideModal(uuid);
  }

  cellActionCallback(cellProps) {
    if (cellProps.row.uuid) {
      let hasChanges = false;
      let types = [];

      this.props.slideTypes.forEach((type) => {
        if (this.props.presentationSlides.files[cellProps.row.uuid].types[type.value].process.uuid) {
          // eslint-disable-next-line max-len
          if (cellProps.row.uuid !== this.props.presentationSlides.files[cellProps.row.uuid].types[type.value].process.uuid) {
            hasChanges = true;
            types.push(type.label);
          }
        }
      });

      return (
        <>
          <Tooltip content="Open Modal to upload slide of type">
            <Button
              variant={ ButtonVariantType.action }
              title="Upload"
              onClick={ () => this.showModal(cellProps.row.uuid) }
              dataTest="uploadButton"
            />
          </Tooltip>
          <Tooltip
            content={ `Revert slide: ${ types.join(', ') }` }
            disabled={ !hasChanges }
          >
            <Button
              variant={ ButtonVariantType.firm }
              disabled={ !hasChanges }
              onClick={ () => this.revertSlide(cellProps.row.uuid) }
              title="Revert"
              dataTest="revertButton"
            />
          </Tooltip>
        </>
      );
    }

    return undefined;
  }

  cellIdCallback({ rowIndex }) {
    return rowIndex + 1;
  }

  cellPreviewCallback(cellProps) {
    let file = this.props.presentationSlides.files[cellProps.row.uuid].types[this.props.slideType];
    return (
      <ImgFetching
        className={ uploadStyles.previewImg }
        src={ files.getFileUrl(file.preview.url) }
      />
    );
  }

  render() {
    let tableColumns = [
      {
        width: '15%',
        name: 'name',
        title: 'Unique ID',
        className: alignStyles.alignLeft,
        cellCallback: this.cellIdCallback,
      },
      {
        width: '50%',
        name: 'url',
        title: 'Slide Thumbnail',
        className: cn(alignStyles.alignCenter, styles.previewCell),
        cellCallback: this.cellPreviewCallback,
      },
      {
        width: '35%',
        name: 'action',
        title: 'Action',
        className: alignStyles.alignCenter,
        cellCallback: this.cellActionCallback,
      },
    ];
    return (
      <>
        <DataTable
          className={ styles.dealSlidesTable }
          dataTest="dealSlidesPresentationDraggableDataTable"
          columns={ tableColumns }
          orderCallback={ this.props.uploadPresentationSlidesOrder }
          data={
            !this.props.presentation.uploadFile &&
            this.props.presentationSlides.sort
              ? this.props.presentationSlides.sort
              : []
          }
          emptyMessage="A list of slides will be displayed after saving the presentation"
        />
        { this.state.uploadModalVisibleRowUuid && this.getModal() }
      </>
    );
  }
}

export default PresentationDraggable;
