import { useEffect } from 'react';
import { useFormsManagerContext } from '@/dealroadshow/ui/components/dmPortal/FormsManager';
import { useForm } from '@/Framework/UI/Organisms/FinalForm';
import { useFormInitialize } from './useFormInitialize';
import { addHttpToUrl } from '@/Framework/url/helpers/transformUrl';
import { DEAL_FILES_AND_COMMERCIAL_VIDEO_FORM_NAME } from '../constants';
import { IDealFilesAndCommercialVideoFormValues } from '../interfaces';

export const filterLinks = (links) => links
  .filter((link) => {
    if (!Object.keys(link).length) {
      return false;
    }
    return !!(link.url || link.name);
  })
  .map((link) => ({
    id: link.id || null,
    url: addHttpToUrl(link.url),
    name: link.name?.trim() || '',
  }));

const parsePayload = (values: IDealFilesAndCommercialVideoFormValues) => ({
  files: (values.uploadDocumentsList || []).map(({ uuid, ...item }) => item),
  commercialVideos: (values.uploadCommercialVideoList || []).map(({ uuid, ...item }) => item),
  links: filterLinks(values.externalLinks),
});

export const useRegisterForm = () => {
  const { registerForm } = useFormsManagerContext();
  useFormInitialize();
  const form = useForm<IDealFilesAndCommercialVideoFormValues>();

  useEffect(() => registerForm({
    name: DEAL_FILES_AND_COMMERCIAL_VIDEO_FORM_NAME,
    formInstance: form,
    onSubmitPayloadParser: parsePayload,
  }), []);
};
