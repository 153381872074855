import { injectable, inject } from 'inversify';
import { socketEvents } from '@dealroadshow/file-uploader';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import Request from '@/Framework/api/Rpc/Request';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';

@injectable()
class PresentationMediaRepository {
  constructor(
    @inject(SocketClient) private socket: typeof SocketClient,
    // @ts-ignore
    @inject(UploadDispatcher) private uploadDispatcher: typeof UploadDispatcher,
  ) {}

  uploadAudio(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_AUDIO' });
  }

  uploadVideo(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_VIDEO' });
  }

  // TODO need remove in https://finsight.myjetbrains.com/youtrack/issue/FIN-26611
  uploadThumbnail(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_PRESENTATION_THUMBNAIL' });
  }

  async subscribeOnProcessingStatusChange(fileId: string, callback: void) {
    const socketSubscription = await this.socket.subscribe(
      new Request('file.processing', { entityId: fileId }),
    );

    socketEvents.forEach((event) => socketSubscription.on(event, callbackWrapper(callback)));
  }
}

export default PresentationMediaRepository;
