import React from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';

import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import ColorPicker from '@/Framework/UI/Molecules/Form/ColorPicker';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import {
  useCustomRegistrationContext,
} from '@/evercall/application/condor/call/profile/customRegistration/CustomRegistrationContext';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';

import styles from './customRegistration.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';

const ColorFields = () => {
  const {
    state: {
      defaultSettings,
    },
  } = useCustomRegistrationContext();
  const form = useForm<ICustomRegistration>();

  const getColorValues = (registrationSettings: ICustomRegistration) => ({
    backgroundColor: registrationSettings.backgroundColor,
    tileColor: registrationSettings.tileColor,
    primaryTextColor: registrationSettings.primaryTextColor,
    secondaryTextColor: registrationSettings.secondaryTextColor,
    accentColor: registrationSettings.accentColor,
    highlightBackgroundColor: registrationSettings.highlightBackgroundColor,
    inputBorderColor: registrationSettings.inputBorderColor,
    registerButtonTextColor: registrationSettings.registerButtonTextColor,
    registerButtonColor: registrationSettings.registerButtonColor,
    addToCalendarAndCopyButtonsColor: registrationSettings.addToCalendarAndCopyButtonsColor,
  });

  const defaultSettingsColorValues = getColorValues(defaultSettings);

  const onResetColorsClick = () => form.batch(() => {
    Object.keys(defaultSettingsColorValues).forEach((key) => {
      form.change(key as keyof ICustomRegistration, defaultSettingsColorValues[key]);
    });
  });

  const headerCls = cn(headerStyles.isH3, styles.panelHeaderLabel);
  const commonColorPickerProps = {
    component: ColorPicker,
    containerClassName: formStyles.formInput,
  };

  return (
    <>
      <Field
        name="backgroundColor"
        dataTest="backgroundColorPicker"
        label="Background Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="tileColor"
        dataTest="tileColorPicker"
        label="Tile Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="primaryTextColor"
        dataTest="primaryTextColorPicker"
        label="Primary Text Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="secondaryTextColor"
        dataTest="secondaryTextColorPicker"
        label="Secondary Text Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="accentColor"
        dataTest="accentColorPicker"
        label="Accent Color (links)"
        { ...commonColorPickerProps }
      />
      <Field
        name="highlightBackgroundColor"
        dataTest="highlightBackgroundColorPicker"
        label="Highlight Background Color"
        { ...commonColorPickerProps }
      />
      <div className={ headerCls }>Input</div>
      <Field
        name="inputBorderColor"
        dataTest="inputBorderColorPicker"
        label="Input Border Color"
        { ...commonColorPickerProps }
      />
      <div className={ headerCls }>Button</div>
      <Field
        name="registerButtonTextColor"
        dataTest="registerButtonTextColorPicker"
        label="Register Button Text Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="registerButtonColor"
        dataTest="registerButtonColorPicker"
        label="Register Button Color"
        { ...commonColorPickerProps }
      />
      <Field
        name="addToCalendarAndCopyButtonsColor"
        dataTest="addToCalendarAndCopyButtonsColorPicker"
        label="Add to Calendar & Copy to Clipboard Buttons Color"
        isNarrow
        { ...commonColorPickerProps }
      />
      <Button
        dataTest="resetColorsToDefaultButton"
        title="Reset Colors to Default"
        variant={ ButtonVariantType.text }
        onClick={ onResetColorsClick }
        className={ styles.resetColorsBtn }
        disabled={ isEqual(getColorValues(form.getState().values), defaultSettingsColorValues) }
      />
    </>
  );
};

export default ColorFields;
