import { connect } from 'react-redux';
import memoize from '@/Framework/dataHelpers/memoize';
import { CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME } from '@/condor/ui/common/constants';
import { sort, getContactsByFilter } from '@/condor/application/actions/contacts/collection/actions';
import { setCurrentContactItem, submitContactProfile } from '@/condor/application/actions/contacts/item/actions';
import validate
  from '@/condor/ui/components/AccountsAndContacts/Contacts/ContactProfile/ProfileForm/profileFormValidators';
import EditableDataTable from '@/condor/ui/common/Layout/EditableDataTable';
import PhoneNumber from '@/users/domain/PhoneNumber';
import columns from './columns';
import * as userSelectors from '@/users/application/actions/data/selectors';
import styles from './contactsTable.scss';
import selectors from '@/condor/application/actions/contacts/collection/selectors';
import itemSelectors from '@/condor/application/actions/contacts/item/selectors';
import contactsStyles from '@/condor/ui/components/AccountsAndContacts/Contacts/contacts.scss';

/**
 * We need to memoize the phone initial value in order to avoid the reinitialization loop caused by:
 * 1. Initially incorrect phone value (try '3562345346').
 * 2. The fact that the initialValues object is being created inside mapStateToProps function;
 * 3. The reduxForm's enableReinitialize prop is set to 'true'
 * 4. The getPreparedPhoneNumber() and getPhoneCode() functions update initialValues.workPhone object.
 * This update triggers some magic inside Phone component which (combined with points 1-3) is the main reason of the
 * loop.
 *
 * @param {String} workPhone
 */
const getWorkPhoneMemoized = memoize((workPhone) => ({
  value: PhoneNumber.fixUsPhoneNumber(workPhone),
  code: PhoneNumber.getPhoneCode(workPhone),
}));

const mapStateToProps = (state) => {
  const itemSelectedForEditing = itemSelectors.getItemData(state);

  return {
    className: styles.dataTableContainer,
    columns,
    collection: selectors.getCollectionData(state),
    isCollectionFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    selectedItemId: itemSelectors.isInlineEditing(state) ? itemSelectedForEditing?.id : undefined,
    isUpdating: userSelectors.isUserUpdating(state),
    dataTest: 'condorContactsTable',
    formProps: {
      form: CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME,
      validate,
      className: styles.dataTableFormWrp,
      initialValues: !itemSelectedForEditing ? {} : {
        ...itemSelectedForEditing,
        jurisdiction: {
          value: itemSelectedForEditing.jurisdictionCountryId,
        },
        officePhone: getWorkPhoneMemoized(itemSelectedForEditing.workPhone),
        titleId: !itemSelectedForEditing.title ? null : {
          value: itemSelectedForEditing.title.id,
          label: itemSelectedForEditing.title.name,
        },
        companyId: !itemSelectedForEditing.company ? null : {
          value: itemSelectedForEditing.company.id,
          label: itemSelectedForEditing.company.name,
          className: { [contactsStyles.verifiedCompany]: itemSelectedForEditing.company.verified },
        },
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (formData) => {
    await dispatch(submitContactProfile(formData));
    dispatch(setCurrentContactItem({}));
    dispatch(getContactsByFilter());
  },
  onSortChange: (sortBy, sortOrder) => {
    dispatch(sort({ sortBy, sortOrder }));
    dispatch(getContactsByFilter());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableDataTable);
