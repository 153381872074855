import React from 'react';
import cn from 'classnames';
import { CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL } from '@/condor/ui/common/constants';
import { openProfileCellWrapper, commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import IndustryCell from './IndustryCell';
import ActionsCell from './ActionsCell';
import IndustrySubindustryField from '@/condor/ui/components/Finsight/Companies/IndustrySubindustryField';
import styles from './companiesTable.scss';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import { APPLICATION_WRP_ID } from '@/Framework/UI/Templates/ApplicationWrp/constants';
import { CONTENT_WRP_ID } from '@/Framework/UI/Templates/ContentWrp/constants';

/**
 * @param {Object} cellProps
 */
export const uniqueIdCellCallback = (cellProps) => (
  <div className="roadshow-id-clip">
    { commonCellCallback(cellProps) }
  </div>
);

/**
 * @param {Object} cellProps
 */
export const nameCellCallback = (cellProps) => openProfileCellWrapper({
  href: `${ CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL }/${ cellProps.row.id }`,
  className: cn(linkStyles.link, styles.nameCell),
  children: cellProps.row.name,
});

/**
 * @param {Object} cellProps
 */
export const industryCell = (cellProps) => (
  <IndustryCell row={ cellProps.row } />
);

/**
 * @param {Object} cellProps
 */
export const actionsCell = (cellProps) => (
  <ActionsCell row={ cellProps.row } />
);

/**
 * @param {Object} cellProps
 */
export const industrySubindustryEditableFieldCallback = (cellProps) => (
  <IndustrySubindustryField
    isNarrow
    isUsePortal
    bodyElement={ document.getElementById(APPLICATION_WRP_ID) }
    scrollableParentElements={ [cellProps.tableRef.current, document.getElementById(CONTENT_WRP_ID)] }
  />
);
