import React from 'react';
import cn from 'classnames';
import { DataTable } from '@dealroadshow/uikit';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import hText from '@dealroadshow/uikit/core/styles/helpers/text.scss';
import styles from './entryCodesTable.scss';

interface EntryCode {
  code: string,
  deletedAt: number,
  dialInCode: number | null,
  expiredAt: number,
  id: string,
  loginsMaxNumber: number | null,
  loginsTimeLimit: number | null,
  notes: string | null,
  startedAt: number,
  type: string,
}

interface IProps {
  reviewEntryCode: EntryCode[],
  tableName: string,
}

const ReviewEntryCode = ({
  reviewEntryCode = [],
  tableName,
}: IProps) => {
  if (!reviewEntryCode.length) {
    return null;
  }
  const tableColumns = [
    {
      name: 'id',
      title: 'Unique ID',
      sortable: false,
      width: '10%',
      height: '48px',
      className: cn('wrapper-id', hText.nowrap),
      cellCallback: (cellProps) => (
        <div className="roadshow-id-clip ">
          { cellProps.cellData }
        </div>
      ),
    },
    {
      name: 'code',
      title: 'Review Code',
      sortable: false,
    },
  ];

  return (
    <>
      <div className={ cn(formStyles.formLabel, styles.tableLablel) }>{ tableName }</div>
      <DataTable
        columns={ tableColumns }
        dataTest="dealSettingsEntryCodesDataTable"
        data={ reviewEntryCode }
        containerClassName={ cn(styles.dataTableContainer, styles.reviewTableContainer) }
      />
    </>
  );
};

export default ReviewEntryCode;
