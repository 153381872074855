import React from 'react';
import cn from 'classnames';
import { getIsAccountRestrictedToEdit } from '@/condor/ui/components/AccountsAndContacts/Accounts/helpers';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import accountSelectors from '@/condor/application/actions/accounts/item/selectors';
import { RestrictedEditAccountTooltip } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';

import { PanelGroup, Panel, PanelArrow, panelGroupStyles } from '@dealroadshow/uikit/core/components/PanelGroup';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import { Button, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useSelector } from 'react-redux';
import ContactsMappingsPanel from './MappingsPanels/ContactsMappingsPanel';
import DealsMappingsPanel from './MappingsPanels/DealsMappingsPanel';
import AllocationsMappingsPanel from './MappingsPanels/AllocationsMappingsPanel';
import EvercallsMappingsPanel from './MappingsPanels/EvercallsMappingsPanel';
import NotesMappingsPanel from './MappingsPanels/NotesMappingsPanel';
import LeisMappingsPanel from './MappingsPanels/LeisMappingsPanel';
import EmailDomainsMappingsPanel from './MappingsPanels/EmailDomainsMappingsPanel';
import WebDomainsMappingsPanel from './MappingsPanels/WebDomainsMappingsPanel';

import styles from './accountsMappings.scss';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';

const activeKeys = [
  'contacts',
  'deals',
  'evercalls',
  'allocations',
  'notes',
  'leis',
  'emailDomains',
  'webDomains',
];

interface IRemapArgument {
  mappingsTitle: string,
  mappingsTypes: string,
}

interface IProps {
  onRemap: (arg: IRemapArgument) => void,
}

const PANEL_MAP = [
  {
    prop: 'contacts',
    title: 'Contacts',
    component: ContactsMappingsPanel,
  },
  {
    prop: 'deals',
    title: 'Deals',
    component: DealsMappingsPanel,
  },
  {
    prop: 'evercalls',
    title: 'Evercalls',
    component: EvercallsMappingsPanel,
  },
  {
    prop: 'allocations',
    title: 'Allocations',
    component: AllocationsMappingsPanel,
  },
  {
    prop: 'notes',
    title: 'Notes',
    component: NotesMappingsPanel,
  },
  {
    prop: 'leis',
    title: 'Legal Entity Identifiers (LEIs)',
    component: LeisMappingsPanel,
  },
  {
    prop: 'emailDomains',
    title: 'Email Domains',
    component: EmailDomainsMappingsPanel,
  },
  {
    prop: 'webDomains',
    title: 'Web Domains',
    component: WebDomainsMappingsPanel,
  },
];

const reduxSelectors = (state) => ({
  userPermissions: userPermissionsSelectors.getUserPermissionsState(state),
  accountItem: accountSelectors.getItemData(state),
});

const AccountMappings = (props: IProps) => {
  const { userPermissions: { currentUserPermissions }, accountItem } = useSelector(reduxSelectors);
  const isAccountRestrictedToEdit = getIsAccountRestrictedToEdit(accountItem, currentUserPermissions);

  const getHeader = (mappingsTitle, mappingsTypes, data, dataTest) => (
    <div className={ styles.panelHeader }>
      <div>{ mappingsTitle }</div>
      <Tooltip content={ RestrictedEditAccountTooltip } disabled={ !isAccountRestrictedToEdit }>
        <Button
          dataTest={ dataTest }
          disabled={ !data.length || isAccountRestrictedToEdit }
          variant={ ButtonVariantType.outline }
          className={ cn(styles.mappingsButton, {
            [styles.disabledMappingsButton]: !data.length || isAccountRestrictedToEdit,
          }) }
          onClick={ () => props.onRemap({
            mappingsTitle,
            mappingsTypes,
          }) }
        >
          Re-Map
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <div className={ formStyles.formWrp }>
      <PanelGroup
        className={ panelGroupStyles.panelGroupWrapper }
        defaultActiveKey={ activeKeys }
        dataTest="condorAccountsPanelGroup"
      >
        { PANEL_MAP.map(({ prop, title, component: Component }) => (
          <Panel
            className={ styles.panel }
            id={ prop }
            key={ prop }
            header={ getHeader(
              title,
              [prop],
              props[prop],
              `${ prop }MappingBtn`,
            ) }
            arrow={ <PanelArrow /> }
            arrowActive={ <PanelArrow isActive /> }
          >
            <Component data={ props[prop] } />
          </Panel>
        )) }
      </PanelGroup>
    </div>
  );
};

export default AccountMappings;
