import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import { commonCellCallback, getFormattingNumberCellCallback } from '@/condor/ui/common/Layout/helpers';
import {
  dateOfImportCellCallback,
  getStatusCellCallback,
} from './cellCallbacks';

export default [
  {
    name: 'importNumber',
    title: '#',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 40,
  },
  {
    name: 'status',
    title: 'Status',
    cellCallback: getStatusCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 40,
  },
  {
    name: 'importId',
    title: 'import Id',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 300,
  },
  {
    name: 'fileName',
    title: 'File name',
    cellCallback: commonCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
    width: 300,
  },
  {
    name: 'companyCount',
    title: 'Company Count',
    cellCallback: getFormattingNumberCellCallback,
    className: alignStyles.alignRight,
    sortable: true,
    width: 100,
  },
  {
    name: 'importAt',
    title: 'date of import',
    cellCallback: dateOfImportCellCallback,
    className: alignStyles.alignRight,
    sortable: true,
    width: 250,
  },
];
