/**
 * Normalize whitespaces in a string
 *
 * - remove leading and trailing whitespaces,
 * - replace multiple whitespaces with a single whitespace
 *
 * @param {String} value
 * @returns {String}
 */
export default function normalizeWhitespace(value: string): string {
  return value.trim().replace(/\s\s+/g, ' ');
}
