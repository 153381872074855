import { useCallback, useRef } from 'react';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { AsyncSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import capitalize from '@/Framework/dataHelpers/string/capitalize';
import { components as SelectComponents, MultiValueProps } from 'react-select';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

const ParentIssuerSelect = ({ disabled }: { disabled?: boolean }) => {
    const { options, parentIssuerOptions, loadParentIssuerOptions } = useCommentaryDealFlowContext();
    const {
      isFetchingParentCompanies, isFetchingIssuerCompanies,
    } = options;
    const initialOptionsRef = useRef(parentIssuerOptions);

    const getOptions = useCallback(
      debounce(async (query: string, callback = noop) => {
        if (query.length < 3) {
          callback(initialOptionsRef.current);
          return;
        }

        if (isFetchingParentCompanies || isFetchingIssuerCompanies) {
          return;
        }

        const options = await loadParentIssuerOptions(query);
        callback(options);
      }, 2000),
      [isFetchingParentCompanies, isFetchingIssuerCompanies, loadParentIssuerOptions],
    );

    const MultiValue = ({ children, ...props }: MultiValueProps<any>) => (
      <SelectComponents.MultiValue { ...props }>
        { `${ props.data.label } - ${ capitalize(props.data.companyType) }` }
      </SelectComponents.MultiValue>
    );

    return (
      <Field
        name="articleParams.companies"
        isLoading={ isFetchingParentCompanies || isFetchingIssuerCompanies }
        isGrouped
        label="Select parent/issuer"
        placeholder="Select related parent company or issuer"
        dataTest="commentaryDealFlowSelectParentIssuer"
        defaultOptions={ initialOptionsRef.current }
        options={ parentIssuerOptions }
        isDisabled={ disabled }
        isMulti
        loadOptions={ getOptions }
        hideSelectedOptions={ false }
        component={ AsyncSelect }
        components={ { MultiValue } }
      />
    );
};

export default ParentIssuerSelect;
