import React, { useEffect, memo, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';

import BannerForm from './BannerForm';
import DeleteModal from './DeleteModal';
import BannerHeader from './BannerHeader';
import formStyles from '@/Framework/UI/Organisms/FinalForm/form.scss';
import { PanelGroup, Panel, PanelArrow, panelGroupStyles } from '@dealroadshow/uikit/core/components/PanelGroup';
import AddBannerButton from './AddBannerButton';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';

interface IProps {
  banners: any[],
  getBannersPositionsList: () => void,
  deleteBanner: (bannerId: string) => void,
  submit: (dataForm: any, callback: (oldData: any, newData: any) => void) => void,
}

const BannersList = (
  {
    banners,
    getBannersPositionsList,
    submit,
    deleteBanner,
  }: IProps,
) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [bannerId, setBannerId] = useState('');
  const [bannerMap, setBannerMap] = useState([]);
  const activeKeys = useRef([]);

  useEffect(() => {
    getBannersPositionsList();
  }, []);

  useEffect(() => {
    const updatedBanners = updateBanners(isEmpty(bannerMap) ? banners : bannerMap);
    const filteredBanners = uniqBy([...updatedBanners], 'bannerId');
    setBannerMap(filteredBanners);
  }, [banners]);

  /**
   * Deep update banners
   * @param {Array} bannerMap
   */
  const updateBanners = (bannerMap) => bannerMap.map((oldBanner) => {
    const newBanner = find(banners, { bannerId: oldBanner.bannerId });

    return (newBanner?.hash) ? { ...newBanner } : oldBanner;
  });

  /**
   * Add new form to Bannerlist
   * and generate uuid for new clear form
   */
  const onClick = () => {
    const bannerId = uuid();

    addActiveKey(bannerId);
    setBannerMap([{ bannerId, isNew: true }, ...bannerMap]);
  };

  /**
   * Show modal panel (Are you shure?)
   */
  const showDeleteModal = (bannerId) => {
    setIsDeleteModalVisible(true);
    setBannerId(bannerId);
  };

  /**
   * Hide modal panel (Are you shure?)
   */
  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  /**
   * Hide modal panel (Are you shure?)
   * Filter deleted panel banner list
   * Send delete request to server
   */
  const onDelete = () => {
    hideDeleteModal();

    const filteredBanners = bannerMap
      .filter((banner) => banner.bannerId !== bannerId);

    setBannerMap([...filteredBanners]);
    deleteBanner(bannerId);
  };

  /**
   * Change style for active panel
   * remove key to PanelGroup
   * @param {String} bannerId
   */
  const arrow = (bannerId) => {
    removeActiveKey(bannerId);

    return <PanelArrow />;
  };

  /**
   * Change style for active panel
   * add activete key to PanelGroup
   * @param {String} bannerId
   */
  const arrowActive = (bannerId) => {
    addActiveKey(bannerId);

    return <PanelArrow isActive />;
  };

  /**
   * Update banner panel after create or update banner
   * @param {Object} oldData
   * @param {Object} newData
   */
  const updateBannerById = (oldData, newData) => {
    const banUpdate = bannerMap.map((banner) => {
      if (banner.bannerId === oldData.bannerId) {
        removeActiveKey(oldData.bannerId);
        addActiveKey(newData.bannerId);
        return { ...newData };
      }
      return banner;
    });
    setBannerMap([...banUpdate]);
  };

  /**
   * Add active key for PanelGroup
   * @param {String} key
   */
  const addActiveKey = (key) => {
    if (activeKeys.current.includes(key)) return;
    activeKeys.current = [...activeKeys.current, key];
  };

  /**
   * Remove active key from PanelGroup
   * @param {String} key
   */
  const removeActiveKey = (key) => {
    const filteredKeys = activeKeys.current.filter((activeKey) => activeKey !== !key);
    activeKeys.current = [...filteredKeys];
  };

  /**
   * Submit form data to server
   * @param {Object} dataForm
   */
  const onSubmit = (dataForm) => {
    submit(dataForm, updateBannerById);
  };

  const buildBannerPanel = (banner, index) => (
    <Panel
      id={ banner.bannerId }
      key={ banner.bannerId }
      header={ <BannerHeader { ...banner } /> }
      destroyOnToggle={ false }
      arrow={ arrow(banner.bannerId) }
      arrowActive={ arrowActive(banner.bannerId) }
    >
      <div className={ cardStyles.cardInnerHorizontal }>
        <BannerForm
          submit={ onSubmit }
          data={ banner }
          index={ index }
          deleteById={ showDeleteModal }
        />
      </div>
    </Panel>
  );

  const result = bannerMap.map((item, index) => buildBannerPanel(item, bannerMap.length - index));
  return (
    <PageWrapper label="Banner Display">
      <div className={ formStyles.formWrp }>
        <PanelGroup
          dataTest="bannersListPanelGroup"
          defaultActiveKey={ activeKeys.current }
          className={ panelGroupStyles.panelGroupWrapper }
        >
          <AddBannerButton onClick={ onClick } />
          { result }
        </PanelGroup>
        <DeleteModal
          bannerId={ bannerId }
          isVisible={ isDeleteModalVisible }
          onDelete={ onDelete }
          onCancel={ hideDeleteModal }
        />
      </div>
    </PageWrapper>
  );
};

export default memo(BannersList);
