import ErrorCodeHelper from '@finsight/error-codes';

import sleep from '@/Framework/dataHelpers/sleep';
import { getItemActions } from '@/Framework/State/Redux/itemFactory';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';

import { getAccountsByFilter } from '@/condor/application/actions/accounts/collection/actions';

import UsersCompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import CompaniesRepository from '@/condor/infrastructure/repository/companies/CompaniesRepository';
import CurrencyRepository from '@/dealroadshow/infrastructure/repository/currencies/CurrencyRepository';

import { messageCodes } from '@/Framework/Message/messages';

import { NotificationManager } from '@/Framework/Notification';

import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { CONDOR_ACCOUNTS_LIST_BASE_URL } from '@/condor/ui/common/constants';
import actionTypes from './actionTypes';
import selectors from './selectors';
import { History } from '@/Framework/DI/Providers/History';

export const openAccountListPage = () => (dispatch, getState) => {
  const history = getState().container.get(History);

  history.push(CONDOR_ACCOUNTS_LIST_BASE_URL);
};

const itemAsyncRequestsConfig = {
  get: {
    method: (payload, dispatch, getState) => {
      const companiesRepository = getState().container.get(CompaniesRepository);
      return companiesRepository.listStatsById(payload);
    },
  },
  create: {
    method: async (payload, dispatch, getState) => {
      const companiesRepository = getState().container.get(UsersCompaniesRepository);
      await companiesRepository.create(payload);
      await sleep(2500);
    },
    callbacks: {
      onSuccess: ({ dispatch }) => dispatch(openAccountListPage()),
    },
  },
  update: {
    method: async (payload, dispatch, getState) => {
      const companiesRepository = getState().container.get(UsersCompaniesRepository);
      await companiesRepository.update(payload);
      await sleep(2500);
    },
    callbacks: {
      onSuccess: ({ dispatch }) => dispatch(openAccountListPage()),
    },
  },
  remove: {
    method: async (payload, dispatch, getState) => {
      const companiesRepository = getState().container.get(UsersCompaniesRepository);
      await companiesRepository.deleteById(payload);
      await sleep(2500);
    },
    callbacks: {
      onError: ({ error }) => {
        if (
          error.error.code === ErrorCodeHelper.getCodeByName('COMPANY_HAS_RELATED_ITEMS') ||
          error.error.code === ErrorCodeHelper.getCodeByName('COMPANY_HAS_USERS')
        ) {
          NotificationManager.error(getMessage(messageCodes.COMPANY_HAS_RELATED_ITEMS));
        } else {
          NotificationManager.error(getErrorMessage(error));
        }
      },
      onSuccess: ({ dispatch, getState }) => {
        const accountName = selectors.getItemData(getState()).name;
        NotificationManager.success(
          getMessage(messageCodes.DELETE_COMPANY_SUCCESS, { accountName }),
        );
        dispatch(setCondorAccountsIsDeleteModalVisible(false));
        dispatch(getAccountsByFilter());
      },
    },
  },
};

export const {
  getItem: getCondorAccountById,
  createItem: createCondorAccount,
  updateItem: updateCondorAccount,
  removeItem: deleteCondorAccount,
  setCurrentItem: setCondorAccountCurrentItem,
  reset: resetCondorAccountsItem,
} = getItemActions(actionTypes, itemAsyncRequestsConfig);

/**
 * @param {Object} payload
 * @return {Function}
 */
export const restoreCondorAccountsItem = (payload) => createAsyncRequest({
  payload: {
    id: payload.id,
  },
  types: [
    actionTypes.RESTORE_CONDOR_ACCOUNT,
    actionTypes.RESTORE_CONDOR_ACCOUNT_SUCCESS,
    actionTypes.RESTORE_CONDOR_ACCOUNT_ERROR,
  ],
  method: async (payload, dispatch, getState) => {
    const companiesRepository = getState().container.get(UsersCompaniesRepository);
    await companiesRepository.restore(payload);
    await sleep(2500);
  },
  callbacks: {
    onSuccess: ({ dispatch }) => {
      NotificationManager.success(
        getMessage(messageCodes.RESTORE_COMPANY_SUCCESS, { accountName: payload.name }),
      );
      dispatch(getAccountsByFilter());
    },
  },
});

/**
 * @param {Object} formData
 * @param {Boolean} isCreating
 * @return {Object}
 */
const getAccountSubmitPayload = (formData, isCreating) => {
  const defaultCurrencyId = '96e6bf40-3b92-4501-943d-282deee59a23';
  const currencyId = formData.aumCurrencyId ?? defaultCurrencyId;
  return (
    {
      ...(!isCreating && { id: formData.id }),
      accountTypeId: (typeof formData.accountTypeId === 'object') && formData.accountTypeId ? formData.accountTypeId.value : formData.accountTypeId,
      verified: formData.verified,
      name: formData.name,
      abbreviation: formData.abbreviation,
      topAccount: formData.topAccount,
      webDomains: formData.webDomains?.map((webDomain) => {
        if (isCreating || webDomain.__isNew__ || (webDomain.value === webDomain.domain)) {
          return {
            domain: webDomain.domain || webDomain.value,
          };
        }
        return webDomain;
      }),
      emailDomains: formData.emailDomains?.map((emailDomain) => {
        if (isCreating || emailDomain.__isNew__ || (emailDomain.value === emailDomain.domain)) {
          return {
            domain: emailDomain.domain || emailDomain.value,
          };
        }
        return emailDomain;
      }),
      leis: formData.leis?.map((lei) => {
        if (isCreating || (lei.value === lei.label)) {
          return {
            name: lei.name || lei.label,
          };
        }
        return {
          name: lei.name || lei.label,
          id: lei.id || lei.value,
        };
      }),
      description: formData.description || null,
      hqLocation: formData.hqLocation || null,
      aum: formData.aum ? formData.aum?.toString().replace(/,/g, '') : null,
      aumCurrencyId: formData.aum ? currencyId : null,
      countryId: formData.country?.value || null,
    }
  );
};

/**
 * @param {Object} formData
 */
export const submitAccountForm = (formData) => async (dispatch) => {
  const isCreating = !formData.id;
  const payload = getAccountSubmitPayload(formData, isCreating);
  if (isCreating) {
    await dispatch(createCondorAccount(payload));
  } else {
    await dispatch(updateCondorAccount(payload));
  }
};

export const getCurrencies = () => {
  return (dispatch, getState) => {
    const currencyRepository = getState().container.get(CurrencyRepository);
    return currencyRepository.getCurrencies();
  };
};

export function deleteAccount() {
  return async (dispatch, getState) => {
    const itemIdToDelete = selectors.getItemData(getState()).id;
    dispatch(deleteCondorAccount(itemIdToDelete));
  };
}

/**
 * @param {Object} payload
 */
export const setCondorAccountsIsInlineEditing = (payload) => createAction(
  actionTypes.SET_CONDOR_ACCOUNTS_IS_INLINE_EDITING,
  payload,
);

/**
 * @param {Object} payload
 */
export const setCondorAccountsIsDeleteModalVisible = (payload) => createAction(
  actionTypes.SET_CONDOR_ACCOUNTS_IS_DELETE_MODAL_VISIBLE,
  payload,
);
