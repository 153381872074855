import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CompanyRepository from '@/finsight/infrastructure/repository/CompanyRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import type ICompany from '@/finsight/domain/vo/ICompany';
import type { ICompaniesListPayload } from '@/finsight/domain/vo/Company/ICompany';

export const useCompaniesList = (innitial: ICompany[]) => {
    const [collection, setCollection] = useState<ICompany[]>(innitial || []);
    const [isFetching, setIsFetching] = useState(false);
    const { container } = useDIContext();

    const getCompaniesList = useCallback(async (params: Partial<ICompaniesListPayload> = {}) => {
      setIsFetching(true);
      try {
        const roundToValidPerPage = Math.ceil((params?.perPage || 20) / 10) * 10;
        const payload = { ...params, page: params?.page || 1, perPage: roundToValidPerPage };
        const companyRepository = container.get(CompanyRepository);
        const companies = await companyRepository.getCompaniesList(payload);
        setCollection(companies.collection);
        return companies.collection;
      } catch (error) {
        NotificationManager.error(getErrorMessage(error));
        return [];
      } finally {
        setIsFetching(false);
      }
    }, [container]);

    return {
      isFetching,
      collection,
      getCompaniesList,
    };
};
