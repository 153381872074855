import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import {
  validateRequiredFieldWithArrayValue,
} from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFieldWithArrayValue';

export default (values: StatFormData) => (
  groupValidators(
    validateRequiredField({ fieldName: 'name', fieldCaption: 'Name', value: values.name.trim() }),
    validateMinLength({ fieldName: 'name', fieldCaption: 'Name', value: values.name.trim(), length: 3 }),
    validateMaxLength({ fieldName: 'name', fieldCaption: 'Name', value: values.name.trim(), length: 255 }),
    validateRequiredFieldWithArrayValue({
      fieldName: 'categories',
      fieldCaption: 'Categories',
      value: values.categories,
    }),
  )
);
