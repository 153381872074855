import { useCallback, useEffect } from 'react';
import Head from 'next/head';
import cn from 'classnames';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Input, Icon, IconType, Modal, Spinner, Textarea } from '@dealroadshow/uikit';

import { useUploadInputsStatusContext } from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import UploadImageInput from '@/openMarket/ui/common/Condor/UploadImageInput';
import { useEventFormContext } from '@/openMarket/ui/components/Condor/Events/EventForm/EventFormContext';
import { EventTransactionTypes } from '@/openMarket/domain/EventTransactionTypes';
import StickyHeader, { useStickyHeader } from '@/dmPortal/ui/common/StickyHeader';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import Link from '@/Framework/Router/ReactRouter/Link';
import { FieldsLabel, FieldsNames } from '@/openMarket/ui/components/Condor/Events/EventForm/constants';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { Select } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import LinkToRoadshowField from './fields/LinkToRoadshowField';
import PricingDateField from './fields/PricingDateField';
import {
  abbreviationValueRenderer,
  formatOptionLabel,
  getParentsOptions,
  getRankingOptions,
  ratedOptions,
} from './helpers';
import { CONDOR_FINSIGHT_OPEN_MARKET_PROFILES } from '@/condor/ui/common/constants';
import DisplayOnFields from './fields/DisplayOnFields';
import { IFormValues } from './interfaces';

import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from '@/openMarket/ui/components/Condor/Events/EventForm/eventForm.scss';

const EventFields: TFinalFormRenderProp<IFormValues> = ({ form, handleSubmit, dirty }) => {
  const {
    isCreate,
    title,
    rankingsCollection,
    profilesCollection,
    isFetchingProfiles,
    isFetchingData,
    paginate,
    page,
    debouncedSearch,
    totalCount,
    roadshowData,
    isSubmitting,
    isFetchingRoadshowData,
    redirectToTable,
  } = useEventFormContext();
  const { isAllUploadCanSave } = useUploadInputsStatusContext();
  const { isStickyHeaderVisible, ref } = useStickyHeader();

  const changePublished = (published: boolean) => form.change('published', published);

  useEffect(() => {
    if (!isCreate && !dirty) {
      form.batch(() => {
        form.change(FieldsNames.ROADSHOW_LINK, roadshowData?.name ?? '');
        form.change(FieldsNames.ROADSHOW_ID, roadshowData?.id ?? null);
      });
    }

    if (dirty) {
      form.batch(() => {
        form.change(FieldsNames.ROADSHOW_ID, roadshowData?.id ?? null);
        form.change(FieldsNames.NAME, roadshowData?.name ?? '');
      });
    }
  }, [roadshowData]);

  const saveAsDraft = () => {
    changePublished(false);
    handleSubmit();
  };

  const saveAndPublish = () => {
    changePublished(true);
    handleSubmit();
  };

  const handleOnScrollBottom = () => {
    if (profilesCollection.length !== totalCount) {
      paginate(page + 1);
    }
  };

  const FieldComponent = useCallback(({ name, label, placeholder }) => (
    <Input
      name={ name }
      label={ label }
      dataTest={ name }
      placeholder={ placeholder }
      disabled
      meta={ {} }
      // @ts-ignore
      input={ {
        value: roadshowData?.[name],
      } }
    />
  ), [roadshowData]);

  const isLoading = isFetchingData || isSubmitting || isFetchingRoadshowData;
  const disabledButton = isLoading || !isAllUploadCanSave;

  const buttons = (
    <>
      <Button
        type="button"
        variant={ ButtonVariantType.action }
        onClick={ saveAsDraft }
        title="Save Draft"
        dataTest="saveDraftButton"
        disabled={ disabledButton }
      />
      <Button
        type="button"
        onClick={ saveAndPublish }
        variant={ ButtonVariantType.success }
        title="Save & Publish"
        dataTest="saveAndPublishButton"
        disabled={ disabledButton }
      />
    </>
  );

  return (
    <div ref={ ref }>
      <Head>
        <title>{ title } | OpenMarket</title>
      </Head>
      <StickyHeader title={ title } isVisible={ isStickyHeaderVisible } buttons={ buttons } />
      <PageWrapper
        contentCls={ cn(cardStyles.cardContainer, styles.cardContainer) }
      >
        <Spinner isVisible={ isLoading } overlay isFixed />
        <h3 className={ styles.title }>Event Details</h3>
        <div className={ styles.formWrapper }>
          <LinkToRoadshowField />
          <div className={ cn(styles.warning, spacesStyles.mbxl, spacesStyles.mtm) }>
            <Icon type={ IconType.info } />
            Linking to Deal Roadshow will pull Enhanced Tagging from the roadshow. Any change to these fields will
            need to be made from the roadshow.
          </div>
          <Field
            // @ts-ignore
            component={ FinalFormSelect }
            label={ FieldsLabel.DISPLAY_OF_PROFILE }
            name={ FieldsNames.DISPLAY_OF_PROFILE }
            dataTest={ FieldsNames.DISPLAY_OF_PROFILE }
            placeholder="Select Company"
            options={ getParentsOptions(profilesCollection) }
            isMulti
            components={ {
              MultiValueLabel: abbreviationValueRenderer,
            } }
            hideSelectedOptions={ false }
            closeMenuOnSelect={ false }
            formatOptionLabel={ formatOptionLabel }
            isOptionDisabled={ () => isFetchingProfiles }
            onInputChange={ debouncedSearch }
            isLoading={ isFetchingProfiles }
            onMenuScrollToBottom={ handleOnScrollBottom }
          />
          <DisplayOnFields />
          <Field
            label={ FieldsLabel.NAME }
            name={ FieldsNames.NAME }
            dataTest={ FieldsNames.NAME }
            placeholder="e.g. ACME Corporation 2024-1"
            component={ Input }
          />
          <div className={ styles.row }>
            <PricingDateField />
          </div>
          <Field
            label={ FieldsLabel.DESCRIPTION }
            name={ FieldsNames.DESCRIPTION }
            dataTest={ FieldsNames.DESCRIPTION }
            placeholder="Type event description here..."
            component={ Textarea }
            textareaClassName={ styles.description }
            isEnabledCountdownMessage
            maxLength={ 500 }
          />
          <Field
            label={ FieldsLabel.LOGO }
            name={ FieldsNames.LOGO }
            uploadName="logo"
            component={ UploadImageInput }
            dataTest={ FieldsNames.LOGO }
          />
          <Field
            label={ FieldsLabel.TILE_IMAGE }
            name={ FieldsNames.TILE_IMAGE }
            uploadName="logo"
            component={ UploadImageInput }
            dataTest={ FieldsNames.TILE_IMAGE }
          />
          <FieldComponent
            placeholder="Select Transaction Type"
            name={ FieldsNames.TRANSACTION_TYPE }
            label={ FieldsLabel.TRANSACTION_TYPE }
          />
          <div className={ styles.row }>
            <FieldComponent
              placeholder="Company Industry & Subindustry"
              name={ FieldsNames.SUBINDUSTRY }
              label={ FieldsLabel.INDUSTRY }
            />
            { EventTransactionTypes.hasSubsector(roadshowData?.transactionTypeId) && (
              <FieldComponent
                placeholder="ABS Sector & Subsector"
                name={ FieldsNames.SUBSECTOR }
                label={ FieldsLabel.SECTOR }
              />
            ) }
          </div>
          <div className={ styles.row }>
            <FieldComponent
              placeholder="Select Country"
              name={ FieldsNames.COUNTRY }
              label={ FieldsLabel.COUNTRY }
            />
          </div>
          <div className={ styles.row }>
            <FieldComponent
              placeholder="-"
              name={ FieldsNames.DEAL_SIZE }
              label={ FieldsLabel.DEAL_SIZE }
            />
            <FieldComponent
              placeholder="-"
              name={ FieldsNames.CURRENCY }
              label={ FieldsLabel.CURRENCY }
            />
          </div>
          <div className={ styles.row }>
            <Field
              label={ FieldsLabel.TENOR }
              name={ FieldsNames.TENOR }
              dataTest={ FieldsNames.TENOR }
              placeholder="e.g. 12.0"
              component={ Input }
              type="number"
            />
            <Field
              label={ FieldsLabel.EQUITY_TICKER }
              name={ FieldsNames.EQUITY_TICKER }
              dataTest={ FieldsNames.EQUITY_TICKER }
              placeholder="Equity Ticker"
              component={ Input }
            />
          </div>
          <div className={ cn(styles.row, spacesStyles.mbl) }>
            <Field
              label={ FieldsLabel.RANKING }
              name={ FieldsNames.RANKING }
              dataTest={ FieldsNames.RANKING }
              placeholder="Select Ranking"
              component={ Select }
              options={ getRankingOptions(rankingsCollection) }
            />
            <Field
              label={ FieldsLabel.RATED }
              name={ FieldsNames.RATED }
              dataTest={ FieldsNames.RATED }
              placeholder="Select Rated or Unrated"
              component={ Select }
              options={ ratedOptions }
            />
          </div>
        </div>
        <div className={ cn(styles.warning, styles.warningFullWidth) }>
          <Icon type={ IconType.info } />
          Modifications to this Event will reflect on all linked Profiles
        </div>
      </PageWrapper>
      <div className={ styles.buttonsWrapper } data-test="eventFormButtons">
        { buttons }
        <Link to={ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }>
          <Button
            variant={ ButtonVariantType.text }
            title="Cancel"
            dataTest="cancelButton"
          />
        </Link>
      </div>
      <NavigationPrompt disableNative when={ !isSubmitting && dirty } afterConfirm={ redirectToTable }>
        { ({ onConfirm, onCancel }) => (
          <Modal
            onCloseClicked={ onCancel }
            isVisible
            title="Discard Event Changes"
            className={ styles.discardModal }
            dataTest="cancelEventFormChangesModal"
            footer={ (
              <>
                <Button
                  variant={ ButtonVariantType.action }
                  onClick={ onConfirm }
                  title="Discard"
                  dataTest="eventFormYesButton"
                />
                <Button
                  variant={ ButtonVariantType.text }
                  onClick={ onCancel }
                  title="Cancel"
                  dataTest="eventFormNoButton"
                />
              </>
            ) }
          >
            Are you sure you want to leave this OpenMarket Event form?
            <br />
            Any changes you made will be lost.
          </Modal>
        ) }
      </NavigationPrompt>
    </div>
  );
};

export default EventFields;
