import cn from 'classnames';
import { checkUserPlanMatchRequired } from '@/finsight/application/User/UserPlan/helpers';
import DealFlowFieldGroup from '@/finsight/ui/components/SubscriptionForm/Base/components/CommentaryDealFlowSection/components/DealFlowFieldGroup';
import SubscriptionSubGroup from '@/finsight/ui/components/SubscriptionForm/Base/components/SubscriptionSubGroup';
import { UserPlanType } from '@/users/domain/UserPlan';
import { ARTICLE_GROUP_ID } from '@/finsight/ui/components/SubscriptionForm/const';
import type { ISubscriptionsDataSubgroup } from '@/finsight/domain/vo/Subscriptions';
import type { SubscriptionsSubGroup } from '@/condor/domain/vo/Finsight/Subscriptions/SubscriptionsData';
import styles from '@/finsight/ui/components/SubscriptionForm/Base/styles.scss';
import commonStyles from '@/finsight/ui/components/SubscriptionForm/styles.scss';

interface ISubscriptionGroupDataItem {
  id: string,
  groupId: string,
  groupName: string,
  name: string,
  groupRequiredPlan: string,
  requiredPlan: string,
  subgroups: SubscriptionsSubGroup[] | ISubscriptionsDataSubgroup[],
}

interface ISubscriptionGroupProps<T> {
  dataItem: T,
  values: Record<string, unknown>,
  email: string,
  selectedEmailsPlans: UserPlanType[],
}

const SubscriptionGroup = <T extends ISubscriptionGroupDataItem>({
  dataItem,
  values,
  email,
  selectedEmailsPlans,
}: ISubscriptionGroupProps<T>) => (
  <div
    key={ dataItem.groupId || dataItem.id }
    className={ cn(commonStyles.subscriptionsBlock, {
      [commonStyles.overlayVisible]: values.unsubscribeFromAll,
    }) }
  >
    <h3 className={ styles.heading }>{ dataItem.groupName || dataItem.name }</h3>
    { (dataItem.groupId || dataItem.id) === ARTICLE_GROUP_ID ? (
      <DealFlowFieldGroup />
    ) : (
      dataItem.subgroups.map((subgroup) => (
        <SubscriptionSubGroup
          key={ subgroup.id }
          subgroup={ subgroup }
          isDisabled={
            email
              ? !checkUserPlanMatchRequired(selectedEmailsPlans, dataItem.groupRequiredPlan)
              : selectedEmailsPlans.some(
                  (plan) => !checkUserPlanMatchRequired(plan, dataItem.requiredPlan),
                )
          }
        />
      ))
    ) }
  </div>
);

export default SubscriptionGroup;
