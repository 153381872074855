import React from 'react';
import cn from 'classnames';

import { Icon, IconType, Tooltip, dataTableStyles, Checkbox } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import EventField from './EventField';
import isDefined from '@/Framework/dataHelpers/isDefined';

import {
  IFinalFormFieldArrayInputComponentProps,
} from '@/Framework/UI/Organisms/FinalForm/interfaces';
import {
  IGroupEventsItemFormValue,
  IGroupFormValues,
  IGroupEventTableRowItem,
} from '../interfaces';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './eventsTable.scss';

const useColumns = (fields: IFinalFormFieldArrayInputComponentProps<IGroupEventsItemFormValue>['fields']) => {
  const form = useForm<IGroupFormValues>();
  const { values } = form.getState();

  const checkboxCellCls = cn(alignStyles.alignCenter, styles.checkboxCell);

  interface ICellCallbackPayload {
    row: IGroupEventTableRowItem,
  }

  return [
    {
      name: 'id',
      title: 'Event',
      className: dataTableStyles.inputCol,
      width: 515,
      maxWidth: 515,
      cellCallback: ({ row }: ICellCallbackPayload) => <EventField row={ row } />,
    },
    {
      name: 'useCustomization',
      title: 'Use Customization',
      className: checkboxCellCls,
      width: 160,
      maxWidth: 160,
      cellCallback: ({ row }: ICellCallbackPayload) => (
        <Field
          name={ `${ row.fieldName }.useCustomization` }
          dataTest={ `useCustomizationCheckbox-${ row.fieldIndex }` }
          type="checkbox"
          render={ (fieldRenderProps) => (
            // @ts-ignore
            <Checkbox
              { ...fieldRenderProps }
              { ...fieldRenderProps.input }
              checked={
                isDefined(fieldRenderProps.input.checked)
                  ? !!fieldRenderProps.input.checked
                  : !!fieldRenderProps.input.value
              }
              onChange={ (e) => {
                if (e.target.checked) {
                  const rowWithCheckedValueIndex = values.events.findIndex((item) => item.useCustomization);

                  if (rowWithCheckedValueIndex >= 0) {
                    fields.update(
                      rowWithCheckedValueIndex,
                      { ...values.events[rowWithCheckedValueIndex], useCustomization: false },
                    );
                  }
                }
              } }
            />
          ) }
        />
      ),
    },
    {
      name: 'deleteCell',
      title: '',
      className: cn(dataTableStyles.actionCol, styles.deleteRowCell),
      cellCallback: ({ row }: ICellCallbackPayload) => (
        (row.fieldIndex !== 0) && (
          <Button
            className={ cn(dataTableStyles.actionItem, styles.deleteRowBtn) }
            dataTest="deleteButton"
            variant={ ButtonVariantType.text }
            onClick={ () => fields.remove(row.fieldIndex) }
          >
            <Tooltip content="Delete event">
              <Icon type={ IconType.trash } />
            </Tooltip>
          </Button>
        )
      ),
    },
    {
      name: 'dragAndDropCell',
      className: cn(dataTableStyles.draggableCol, alignStyles.alignCenter),
      isDraggable: true,
      title: '',
      width: 40,
      maxWidth: 40,
    },
  ];
};

export default useColumns;
