import { useState } from 'react';
import { useHistory } from 'react-router';
import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { IStatPayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStatPayload';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST } from '@/condor/ui/common/constants';
import { defaultInitialFormData } from './constants';

interface IUseStatProfile {
  statId?: string,
  setTitle: (title: string) => void,
}

const useStatProfile = ({ statId }: IUseStatProfile) => {
  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const [initialFormData] = useState<StatFormData>(defaultInitialFormData);
  const { push } = useHistory();

  const createOrUpdateState = async (values: StatFormData) => {
    const payload: IStatPayload = {
      name: values.name,
      description: values.description || null,
      isActive: values.isActive,
      previewUploadId: null,
      attachment: null,
      categories: values.categories.map((category) => category.value),
    };

    try {
      await creditFlowResearchRepository.createStat(payload);
      NotificationManager.success('Stat has been successfully saved');
      push(CONDOR_FINSIGHT_CREDIT_FLOW_STATS_LIST);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return {
    isEdit: !!statId,
    createOrUpdateState,
    initialFormData,
  };
};

export default useStatProfile;
