import cn from 'classnames';
import React from 'react';
import { Paginator, Spinner, IColumn, HeaderWrapper, HeaderRow, BodyWrapper, dataTableStyles } from '@dealroadshow/uikit';
import { DEFAULT_EMPTY_MESSAGE } from '@/Framework/UI/Molecules/Tables/constants';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from '@/finsight/ui/components/bonds/common/BondTables/bondsTables.scss';
import getBwicsInventoryMetaSchema from '@/finsight/ui/components/bonds/getBwicsInventoryMetaSchema';
import MetaSchema from '@/finsight/ui/common/components/MetaSchema';
import { IAssetClass } from '@/finsight/domain/vo/IAssetClass';

interface IBwicProps {
  isFetching: boolean,
  isItemsPerPageVisible?: boolean,
  data: Record<string, any>,
  totalCount: number,
  sortBy: string,
  sortOrder: string,
  page: number,
  perPage: number,
  getHeaderColumns: () => IColumn[],
  bwicRow: any,
  tableFooterLabel: React.ReactNode,
  sort: (sortBy: string, sortOrder: string, currentAssetClassId?: IAssetClass['id']) => void,
  paginate: (selected: number, currentAssetClassId?: IAssetClass['id']) => void,
  itemsPerPage: (value: number, currentAssetClassId?: IAssetClass['id']) => void,
  pathname: string,
  withSchema?: boolean,
  currentAssetClass?: IAssetClass,
}

const Bwic = (props: IBwicProps) => {
  const {
    data,
    totalCount,
    page,
    perPage,
    sortBy,
    sortOrder,
    isFetching,
    withSchema = true,
    pathname,
    isItemsPerPageVisible,
    bwicRow: BwicRow,
    getHeaderColumns,
    currentAssetClass,
  } = props;
  const isCollection = !!data?.length;

  const handlePageChange = (page: { selected: number }) => {
    props.paginate(page.selected, currentAssetClass?.id);
  };

  const handlePerPageChange = (perPage: { value: number, label: string }) => {
    props.itemsPerPage(perPage.value, currentAssetClass?.id);
  };

  const handleSortChange = (sortBy: string, sortOrder: string) => {
    props.sort(sortBy, sortOrder, currentAssetClass?.id);
  };

  const tableClass = cn(
    dataTableStyles.dataTable,
    dataTableStyles.isHoverable,
    dataTableStyles.nowrapCells,
  );

  const emptyMessageClass = cn(
    dataTableStyles.emptyMessage,
    styles.emptyMessage,
    spacesStyles.ptxl,
    spacesStyles.pbxl,
  );

  let headerColumns = [];

  if (isCollection) {
    headerColumns = getHeaderColumns();
  }

  return (
    <>
      {
        withSchema && (
          <MetaSchema schema={ getBwicsInventoryMetaSchema(pathname, 'BWICS', currentAssetClass?.abbreviation) } />
        )
      }
      <div className={ cn(dataTableStyles.dataTableContainer, styles.tableContainer) }>
        { isFetching && (
          <Spinner overlay />
        ) }

        { !isCollection && !isFetching && (
          <div className={ emptyMessageClass }>
            { DEFAULT_EMPTY_MESSAGE }
          </div>
        ) }

        { isCollection && (
          <table className={ tableClass } data-test="bwicTable">
            <HeaderWrapper>
              <HeaderRow
                sortBy={ sortBy }
                sortOrder={ sortOrder }
                onSortChange={ handleSortChange }
                columns={ headerColumns }
              />
            </HeaderWrapper>

            <BodyWrapper>
              { data.length > 0 && data.map((row, index) => (
                <BwicRow
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={ index }
                  rowIndex={ index }
                  row={ row }
                />
              )) }
            </BodyWrapper>
          </table>
        ) }

        <Paginator
          className={ cn(cardStyles.cardInner, styles.tableFooter) }
          dataTest="bondsTableBwic"
          page={ page }
          perPage={ perPage }
          totalCount={ totalCount }
          onPageChange={ handlePageChange }
          onItemsPerPageChange={ handlePerPageChange }
          labelOfText={ props.tableFooterLabel }
          isItemsPerPageVisible={ isItemsPerPageVisible }
        />
      </div>
    </>
  );
};

export default Bwic;
