import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { useEffect, useMemo } from 'react';
import { IGroupCategoryData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IGroupCategory';
import { getOptionsForGroupSelectWithFilters } from '@/condor/application/Finsight/CreditFlowResearch/Articles/helpers';
import { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import { IOptionCategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { useCategoriesList } from '@/condor/application/hooks/apiHooks/useCategoriesList';

const useCategories = () => {
  const { regions, products } = useRegionsProductsContext();
  const { getCategoriesList, collection } = useCategoriesList();

  useEffect(() => {
    getCategoriesList(true);
  }, []);

  const categoriesOptions = useMemo<IOption<IOptionCategory>[]>(
    () => getOptionsForGroupSelectWithFilters(
      products,
      regions,
      collection as IGroupCategoryData[],
      'displayName',
      'categories',
    ),
    [products, regions, collection],
  );

  return { categoriesOptions };
};

export default useCategories;
