import React, { useRef, useMemo, useState, useEffect } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';
import TagsList from './TagsList';
import TagsInput from './TagsInput';

import inputStyles from '@dealroadshow/uikit/core/components/Input/input.scss';
import styles from './inputTags.scss';

export interface IInputTag {
  isValid: boolean,
  value: string,
  label?: string,
  customIcon?: React.FC,
}

interface IProps {
  tags: IInputTag[],
  wrapperClassName?: string,
  containerClassName?: string,
  errorMessageWrapperClassName?: string,
  prependComponent?: React.ReactNode,
  appendComponent?: React.ReactNode,
  isDisabled?: boolean,
  isFetching?: boolean,
  isNarrow?: boolean,
  placeholder?: string,
  errorMessage?: string,
  withErrors?: boolean,
  validate?: (value: string) => IValidationError | null,
  normalize?: (value: string) => string,
  normalizeChange?: (value: string) => string[],
  onChange?: (tags: IInputTag[], tagsList?: IInputTag[]) => void,
  onSubmitTags?: () => void,
  dataTest: string,
  autoFocus?: boolean,
  onFocusHandler?: () => void,
  onBlurHandler?: () => void,
}

const InputTags = ({
  tags,
  wrapperClassName,
  containerClassName,
  errorMessageWrapperClassName,
  prependComponent,
  appendComponent,
  isDisabled,
  isFetching,
  isNarrow,
  autoFocus = true,
  placeholder,
  errorMessage,
  withErrors,
  validate,
  normalize,
  normalizeChange,
  onChange = noop,
  onFocusHandler = noop,
  onBlurHandler = noop,
  onSubmitTags = noop,
  dataTest,
}: IProps) => {
  const inputRef = useRef(null) as React.MutableRefObject<HTMLInputElement>;
  const containerRef = useRef(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const inputPlaceholder = useMemo(() => (
    tags.length ? '' : placeholder
  ), [tags.length, placeholder]);

  useEffect(() => {
    setHasErrors(withErrors || tags.some(({ isValid }) => !isValid));
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
    isActive && inputRef.current.focus();
  }, [tags, withErrors]);

  const appendTags = (tagsList) => {
    onChange(tags.concat(tagsList), tagsList);
  };

  const removeTagByIndex = (tagIndex) => {
    onChange(tags.filter((tag, index) => index !== tagIndex));
    inputRef.current.focus();
  };

  const removeLastTag = () => {
    removeTagByIndex(tags.length - 1);
  };

  const submitTags = () => {
    !isFetching && !hasErrors && onSubmitTags();
  };

  const handleInputFocus = () => {
    setIsActive(true);
    onFocusHandler();
  };

  const handleInputBlur = () => {
    if (!isFetching) {
      setIsActive(false);
      onBlurHandler();
    }
  };

  return (
    <>
      <div
        data-test={ `${ dataTest }Wrapper` }
        className={ cn(styles.inputTagsWrp, wrapperClassName, {
          [styles.isPrepended]: !!prependComponent,
          [styles.isAppended]: !!appendComponent,
          [styles.isDisabled]: isDisabled,
          [styles.isInvalid]: hasErrors,
          [styles.isActive]: isActive,
          [styles.isNarrow]: isNarrow,
        }) }
      >
        { prependComponent }
        <div
          className={ cn(
            styles.inputTagsContainer,
            inputStyles.formInput,
            containerClassName,
          ) }
          ref={ containerRef }
        >
          <TagsList
            tags={ tags }
            isDisabled={ isDisabled }
            onRemoveTag={ removeTagByIndex }
          />
          { /* @ts-ignore */ }
          <TagsInput
            ref={ inputRef }
            tags={ tags }
            isDisabled={ isDisabled }
            isFetching={ isFetching }
            placeholder={ inputPlaceholder }
            onAppendTags={ appendTags }
            onRemoveTag={ removeLastTag }
            onSubmitTags={ submitTags }
            onFocus={ handleInputFocus }
            onBlur={ handleInputBlur }
            validate={ validate }
            normalize={ normalize }
            normalizeChange={ normalizeChange }
            dataTest={ dataTest }
            autoFocus={ autoFocus }
          />
        </div>
        { appendComponent }
      </div>
      { hasErrors && (
        <div className={ cn(styles.errorMessageWrapper, errorMessageWrapperClassName) }>
          { errorMessage }
        </div>
      ) }
    </>
  );
};

export default InputTags;
