import { createContext, useContext, useMemo } from 'react';
import { useCommentaryDealFlowOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/useCommentaryDealFlowOptions';
import { useLoadMissingCompanies } from '@/finsight/application/Subscriptions/CommentaryDealFlow/useLoadMissingCompanies';
import { createRegionOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/helpers/createRegionOptions';
import { createSectorOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/helpers/createSectorOptions';
import { createIndustryOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/helpers/createIndustryOptions';
import { createParentIssuerOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/helpers/createParentIssuerOptions';
import { createThemeOptions } from '@/finsight/application/Subscriptions/CommentaryDealFlow/helpers/createThemeOptions';
import { mapCountriesToOptions } from '@/users/ui/components/Profile/Common/helpers';
import type { ISubscriptionsPageProps } from '@/finsight/pages/subscriptions';
import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';
import type { ISelectOptions } from '@/finsight/ui/components/SubscriptionForm/types';

export interface ICommentaryDealFlowContextProps {
    options: ReturnType<typeof useCommentaryDealFlowOptions>,
    regionsOptions: ISelectOptions[],
    sectorsOptions: IOption[],
    industriesOptions: IOption[],
    parentIssuerOptions: ISelectOptions[],
    themesOptions: IOption[],
    countriesOptions: IOption[],
    loadParentIssuerOptions: (query: string) => Promise<ISelectOptions[]>,
    isLoading: boolean,
}

const useCommentaryDealFlow = (props?: ISubscriptionsPageProps): ICommentaryDealFlowContextProps => {
    const options = useCommentaryDealFlowOptions(props);

    const {
        regions,
        sectors,
        industries,
        parentCompanies,
        issuerCompanies,
        themes,
        products,
        countries,
        getParentCompaniesList,
        getIssuerCompaniesList,
        isLoading,
    } = options;

    const regionsOptions = useMemo(
        () => createRegionOptions(Array.isArray(regions) ? regions : []),
        [regions],
    );

    const sectorsOptions = useMemo(
        () => createSectorOptions(Array.isArray(regions) ? regions : [], Array.isArray(sectors) ? sectors : []),
        [regions, sectors],
    );

    const industriesOptions = useMemo(
        () => createIndustryOptions(Array.isArray(regions) ? regions : [], Array.isArray(industries) ? industries : []),
        [regions, industries],
    );

    const themesOptions = useMemo(
        () => createThemeOptions(
            Array.isArray(themes) ? themes : [],
            Array.isArray(products) ? products : [],
            Array.isArray(regions) ? regions : [],
        ),
        [themes, products, regions],
    );

    const countriesOptions = useMemo(
        () => mapCountriesToOptions(Array.isArray(countries) ? countries : []),
        [countries],
    );

    const parentIssuerOptions = useMemo(
        () => createParentIssuerOptions(
            Array.isArray(parentCompanies) ? parentCompanies : [],
            Array.isArray(issuerCompanies) ? issuerCompanies : [],
        ),
        [parentCompanies, issuerCompanies],
    );

    const loadParentIssuerOptions = async (query: string, includedIds: string[] = []) => {
        const [parentCompanies, issuerCompanies] = await Promise.all([
            getParentCompaniesList({ query, filter: { includedRole: 'parent', includedIds }, perPage: includedIds.length }),
            getIssuerCompaniesList({ query, filter: { includedRole: 'issuer', includedIds }, perPage: includedIds.length }),
        ]);

        return createParentIssuerOptions(parentCompanies, issuerCompanies);
    };

    useLoadMissingCompanies({ loadParentIssuerOptions, parentCompanies, issuerCompanies });

    return {
        options,
        regionsOptions,
        sectorsOptions,
        industriesOptions,
        parentIssuerOptions,
        themesOptions,
        countriesOptions,
        loadParentIssuerOptions,
        isLoading,
    };
};

export const CommentaryDealFlowContext = createContext<ICommentaryDealFlowContextProps | null>(null);

export const useCommentaryDealFlowContext = () => {
    const context = useContext(CommentaryDealFlowContext);
    if (!context) {
        throw new Error('useCommentaryDealFlowContext must be used within the CommentaryDealFlowContext');
    }
    return context;
};

interface ICommentaryDealFlowContextProviderProps extends ISubscriptionsPageProps {
    children?: React.ReactNode,
}

export const CommentaryDealFlowContextProvider = ({
    children,
    sectorsList,
    regionsList,
    industriesList,
    countriesList,
    themesList,
    parentCompaniesList,
    issuerCompaniesList,
    productsList,
}: ICommentaryDealFlowContextProviderProps) => (
  <CommentaryDealFlowContext.Provider
    value={ useCommentaryDealFlow({
        sectorsList,
        regionsList,
        industriesList,
        countriesList,
        themesList,
        parentCompaniesList,
        issuerCompaniesList,
        productsList,
    }) }
  >
    { children }
  </CommentaryDealFlowContext.Provider>
);

export default CommentaryDealFlowContextProvider;
