import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { CreatableSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';

const EmailSelectField = ({ emailRegexp }: { emailRegexp: RegExp }) => (
  <Field
    name="subscribersListSelect"
    component={ CreatableSelect }
    placeholder="Type Email address here (required)"
    noOptionsMessage={ () => null }
    formatCreateLabel={ (label) => `Create a "${ label }" by Enter` }
    isValidNewOption={ (label) => emailRegexp.test(label) }
    components={ { DropdownIndicator: () => null } }
    isMulti
    dataTest="subscribersListReduxFormSelect"
  />
);

export default EmailSelectField;
