import React from 'react';
import { Redirect, Switch } from 'react-router';
import cn from 'classnames';
import EnhancedTaggingFiltersContextProvider from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingFiltersContext';
import Route from '@/Framework/Router/ReactRouter/Route';
import * as constants from '@/condor/ui/common/constants';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import { EnhancedTaggingFiltersButton, EnhancedTaggingFiltersSidebar } from './components/EnhancedTaggingFilters';
import EnhancedTaggingRoadshows from './Roadshows';

import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import styles from './styles.scss';

const EnhancedTaggingRouter = () => {
  return (
    <EnhancedTaggingFiltersContextProvider>
      <EnhancedTaggingFiltersSidebar />
      <PageWrapper
        label="Enhanced Tagging"
        contentCls={ cn(cardStyles.cardContainer, styles.cardContainer) }
        headerComponent={ (
          <div className={ styles.actions }>
            <EnhancedTaggingFiltersButton />
          </div>
        ) }
      >
        <Switch>
          <Redirect
            exact
            from={ constants.CONDOR_ENHANCED_TAGGING_BASE_URL }
            to={ constants.CONDOR_ENHANCED_TAGGING_DRS }
          />
          <Route path={ constants.CONDOR_ENHANCED_TAGGING_DRS } component={ EnhancedTaggingRoadshows } />
        </Switch>
      </PageWrapper>
    </EnhancedTaggingFiltersContextProvider>
  );
};

export default EnhancedTaggingRouter;
