import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { updateInitialValues } from '@/finsight/ui/components/SubscriptionForm/utils';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import type { IUpdatedFormParams, IInitialValues } from '@/finsight/ui/components/SubscriptionForm/utils';

interface IFormEntryProps {
  styles: React.CSSProperties,
  formName: string,
  submitForm: (data: IUpdatedFormParams) => void,
  renderFields: (props: Record<string, any>) => React.ReactNode,
  initialValues: IInitialValues,
  validateWithData: (values: Record<string, any>) => void,
}

const FormEntry = ({
  styles,
  formName,
  submitForm,
  renderFields,
  initialValues,
  validateWithData,
}: IFormEntryProps) => {
  const {
    countriesOptions,
    parentIssuerOptions,
    sectorsOptions,
    industriesOptions,
    themesOptions,
  } = useCommentaryDealFlowContext();

  const updatedInitialValues = updateInitialValues(
    initialValues,
    countriesOptions,
    parentIssuerOptions,
    sectorsOptions,
    industriesOptions,
    themesOptions,
  );

  return (
    <div className={ styles.content }>
      <FinalForm
        dataTest={ formName }
        name={ formName }
        onSubmit={ submitForm }
        render={ renderFields }
        initialValues={ updatedInitialValues }
        validate={ validateWithData }
      />
    </div>
  );
};

export default FormEntry;
