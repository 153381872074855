import React from 'react';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import Country, { ICountryOption } from '@/dealroadshow/domain/vo/Country';
import { IUserProfileFormData } from '@/users/domain/vo/IUserProfileFormData';
import { mapOptionToValue } from '@/Framework/dataHelpers/selectOptionMappers';

import styles from './common.scss';

export const getJurisdictionLabel = () => (
  <span>Jurisdiction
    <span className={ styles.explanationIconWrapper }>
      <Tooltip
        content="Please identify your country of residencefor data protection regulations."
        className={ styles.tooltip }
        usePortal={ false }
      >
        <IconQuestion className={ styles.explanationIcon } />
      </Tooltip>
    </span>
  </span>
);

export const getLabelWithOptionalMark = (text: string) => (
  <span>{ text }<span className={ styles.optionalLabel }> (optional)</span></span>
);

export const mapCountriesToOptions = (countriesList: [Country?]): ICountryOption[] => (countriesList.map((country) => ({
      label: country.name,
      value: country.id,
      countryCode: country.code,
    }),
  )
);

export const prepareFormData = ({
  jurisdiction,
  officePhone,
  personalPhone,
  bloombergId,
  accountTypeId,
  ...formData
}: IUserProfileFormData) => {
  const jurisdictionCountryId = typeof jurisdiction === 'string'
    ? jurisdiction
    : jurisdiction.value;
  const accountType = mapOptionToValue(accountTypeId);
  const workPhone = officePhone?.value || '';
  const mobilePhone = personalPhone?.value || '';

  return {
    ...formData,
    accountTypeId: accountType,
    bloombergEmail: bloombergId || null,
    titleId: formData.titleId.value,
    workPhone: workPhone.length > 6 ? workPhone : '',
    personalPhone: mobilePhone.length > 6 ? mobilePhone : '',
    jurisdictionCountryId,
  };
};
