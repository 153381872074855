import type { ISelectOptions } from '@/finsight/ui/components/SubscriptionForm/types';

export const createParentIssuerOptions = (
  parentCompanies: { id: string, name: string }[],
  issuerCompanies: { id: string, name: string }[],
): ISelectOptions[] => {
  return [
    {
      label: 'Parent',
      value: 'parent',
      options: parentCompanies.map((company) => ({
        label: company.name,
        value: company.id,
        companyType: 'parent',
      })),
    },
    {
      label: 'Issuer',
      value: 'issuer',
      options: issuerCompanies.map((company) => ({
        label: company.name,
        value: company.id,
        companyType: 'issuer',
      })),
    },
  ];
};
