import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';

import styles from './deleteModal.scss';

interface IProps {
  isVisible: boolean,
  item: IAccountProfileItem,
  onDelete: () => void,
  onCancel: () => void,
  isSpinnerVisible: boolean,
}

const DeleteModal = (
  {
    item,
    isVisible,
    onDelete,
    onCancel,
    isSpinnerVisible,
  }: IProps,
) => {
  const account = (
    <b>{ item ? item.name : '' }</b>
  );

  return (
    <Modal
      title="Are you sure?"
      isVisible={ isVisible }
      onCloseClicked={ onCancel }
      dataTest="areYouSureDeleteAccountModal"
      className={ styles.deleteModal }
      modalTitleClassName={ styles.modalTitle }
      footer={ !isSpinnerVisible && (
        <>
          <Button
            disabled={ isSpinnerVisible }
            variant={ ButtonVariantType.warning }
            onClick={ onDelete }
            dataTest="condorAccountAndContactsDeleteModalButton"
          >
            Delete Account
          </Button>
          <Button
            variant={ ButtonVariantType.text }
            onClick={ onCancel }
            dataTest="condorAccountAndContactsDeleteModalCancelButton"
          >
            Cancel
          </Button>
        </>
      ) }
    >
      { isSpinnerVisible && <Spinner /> }
      <div>Are you sure you want to delete { account }?</div>
    </Modal>
  );
};

export default DeleteModal;
