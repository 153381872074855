import { SidebarWidth } from '@/dealroadshow/domain/SidebarWidth';

export enum VerificationState {
  BypassActivated = 'bypassActivated',
  BypassDeactivated = 'bypassDeactivated',
  PromptVerificationActivated = 'promptVerificationActivated',
}

export interface ISecurityAndDisclaimerFormValues {
  watermarkEnabled: boolean,
  pdfWatermarkEnabled: boolean,
  investorEmailVerification: VerificationState,
  caseSensitiveEntryCodes: boolean,
  mobileDisabled: boolean,
  corporateEmailChecked: boolean,
  sidebarWidth: SidebarWidth,
  disclaimer: string,
  watermarkOpacity: string,
  investorQuestionsEnabled: boolean,
  requestMeetingEnabled: boolean,
  /**
   * Next three fields are related to evercallChatLink
   * evercallChatLink - Full link to evercall chat that cames from backend and should be sended to backend.
   * Example: https://evercallqa.com/dash/{id}
   */
  evercallChatLink: null | string,
  /**
   * evercallChatLinkId - id that we take from evercallChatLink that can be edited by user.
   * Also can be saved immediately without saving main form.
   * We need to have this field as part of main form to be sure that in case error this field we could save main form
   */
  evercallChatLinkId: null | string,
  /**
   * evercallChatLinkIdError - field that we need to determine whether there is an error when editing evercallChatLinkId
   * If there is an error we should not save main form
   */
  evercallChatLinkIdError: null | string,
}
