import React, { forwardRef } from 'react';
import { MONTH_DAY_YEAR_FORMAT } from '@/Framework/DateTime/dateFormats';
import DatePicker from '@/Framework/UI/Molecules/Form/DatePicker/DatePicker';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';
import { Input } from '@dealroadshow/uikit/core/components/Input';
import styles from '@/Framework/UI/Organisms/Filters/FiltersPanel/sidebar.scss';

interface IProps {
  dateFrom: number,
  dateTo: number,
  dateFromMin?: number,
  dateToMax?: number,
  timeZone?: string | null,
  onDateFromChange?: (value: Date) => void,
  onDateToChange?: (value: Date) => void,
  isDateFromDisabled?: boolean,
  isDateToDisabled?: boolean,
}

const CustomInput = forwardRef((
    { name, dataTest, ...inputProps }: { name: string, dataTest: string },
    ref: React.ForwardedRef<Input>,
) => (
  <Input
    // @ts-ignore
    input={ { ref, ...inputProps } }
    icon={ IconCalendar }
    iconPosition="right"
    isNarrow
    isClearable={ false }
    name={ name }
    dataTest={ dataTest }
  />
));

function DatePeriod({ timeZone = null, ...props }: IProps) {
  let dateFromMin = null;
  let dateToMax = new Date();
  let dateFromSelected = dateFromMin;
  let dateToSelected = dateToMax;

  if (props.dateFromMin) {
    dateFromMin = getDateFromUnixTime(props.dateFromMin);
    if (timeZone) {
      dateFromMin = utcToZonedDate(timeZone, dateFromMin);
    }
  }

  if (props.dateToMax) {
    dateToMax = getDateFromUnixTime(props.dateToMax);
    if (timeZone) {
      dateToMax = utcToZonedDate(timeZone, dateToMax);
    }
  }

  if (props.dateFrom || props.dateFromMin) {
    dateFromSelected = getDateFromUnixTime(props.dateFrom || props.dateFromMin);
    if (timeZone) {
      dateFromSelected = utcToZonedDate(timeZone, dateFromSelected);
    }
  }

  if (props.dateTo) {
    dateToSelected = getDateFromUnixTime(props.dateTo);
    if (timeZone) {
      dateToSelected = utcToZonedDate(timeZone, dateToSelected);
    }
  }

  return (
    <div className={ styles.headerPeriod }>
      { (dateFromSelected && dateToSelected) && (
        <div className={ styles.headerPeriodWrp }>
          <div className={ styles.headerPeriodFrom } data-test="dateFromPicker">
            <DatePicker
              name="startDatePeriodInput"
              customInput={ (
                <CustomInput
                  name="startDatePeriodInput"
                  dataTest="startDatePeriodInput"
                />
              ) }
              disabled={ props.isDateFromDisabled }
              dateFormat={ MONTH_DAY_YEAR_FORMAT }
              placeholderText="Start date"
              selected={ dateFromSelected }
              minDate={ dateFromMin }
              maxDate={
                props.dateTo
                  ? dateToSelected
                  : dateToMax
              }
              onChange={ props.onDateFromChange }
              popperPlacement="bottom-start"
              dataTest="datePeriodStartDatePicker"
            />
          </div>
          <div className={ styles.headerPeriodLabel }>–</div>
          <div className={ styles.headerPeriodTo } data-test="dateToPicker">
            <DatePicker
              name="endDatePeriodInput"
              customInput={ (
                <CustomInput
                  name="endDatePeriodInput"
                  dataTest="endDatePeriodInput"
                />
              ) }
              disabled={ props.isDateToDisabled }
              dateFormat={ MONTH_DAY_YEAR_FORMAT }
              placeholderText="End date"
              selected={ dateToSelected }
              onChange={ props.onDateToChange }
              minDate={ dateFromSelected }
              maxDate={ dateToMax }
              popperPlacement="bottom-end"
              dataTest="datePeriodEnsDatePicker"
            />
          </div>
        </div>
      ) }
    </div>
  );
}

export default DatePeriod;
