import cn from 'classnames';

import { nameCellCallback, updatedAtCellCallback } from './cellCallbacks';
import {
  isActiveColumn,
  commonColumns,
} from '@/condor/ui/components/Finsight/CreditFlowResearch/common/listComponents/columns';

import {
  themeOrCategoryCellCallback,
} from '@/condor/ui/components/Finsight/CreditFlowResearch/common/listComponents/cellCallbacks';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

export default [
  isActiveColumn,
  {
    name: 'name',
    title: 'Name',
    maxWidth: 757,
    width: 757,
    cellCallback: nameCellCallback,
  },
  {
    name: 'categories',
    title: 'Themes',
    maxWidth: 250,
    minWidth: 250,
    cellCallback: themeOrCategoryCellCallback,
    className: alignStyles.alignRight,
  },
  ...commonColumns,
  {
    name: 'updatedAt',
    title: 'Last Updated',
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
    className: cn(spacesStyles.pln, alignStyles.alignRight),
    cellCallback: updatedAtCellCallback,
  },
];
