import React from 'react';

import { useForm } from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Checkbox } from '@dealroadshow/uikit';
import { useAddSubscribersFormContext } from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm';
import {
  useCheckEmailsSubscriptionContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const FooterModal = () => {
  const {
    isCustomMessageBlockVisible,
    setIsCustomMessageBlockVisible,
    isWithEmail,
    setIsAddSubscribersModalVisible,
    setIsWithEmail,
  } = useAddSubscribersFormContext();
  const { isChecking } = useCheckEmailsSubscriptionContext();
  const form = useForm('addSubscribersForm');
  const { values: { emails, plan } } = form.getState();

  const onSubmitClick = () => {
    if (isCustomMessageBlockVisible || !isWithEmail) {
      form.submit();
    } else {
      setIsCustomMessageBlockVisible(true);
    }
  };

  const isEmailsValid = emails?.length && !emails.some(({ isValid }) => !isValid);

  return (
    <div className={ alignStyles.flexSpaceBetween }>
      <div>
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          title="Add Subscriber(s)"
          dataTest="addSubscribersButton"
          onClick={ onSubmitClick }
          disabled={ !(isEmailsValid && plan && !isChecking) }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ () => {
            if (isCustomMessageBlockVisible) {
              setIsCustomMessageBlockVisible(false);
            } else {
              form.reset();
              setIsAddSubscribersModalVisible(false);
            }
          } }
          title="Cancel"
          dataTest="addSubscribersButtonCancel"
        />
      </div>
      { !isCustomMessageBlockVisible && (
        <Checkbox
          dataTest="addSubscribersCustomEmailCheckbox"
          label="Send email notification"
          onChange={ () => setIsWithEmail((prev) => !prev) }
          checked={ isWithEmail }
        />
      ) }
    </div>
  );
};

export default FooterModal;
