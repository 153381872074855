import { toSlug } from '@/Framework/url/helpers/toSlug';
import { updateToInitialValues } from '@/finsight/ui/components/SubscriptionForm/utils';
import { ARTICLE_GROUP_ID, ARTICLE_SUBSCRIPTION_ID } from '@/finsight/ui/components/SubscriptionForm/const';
import { IUserSubscriptionsResponse } from '@/finsight/domain/vo/Subscriptions/userSubscriptions';

export const prepareSubscriptionsData = (response: IUserSubscriptionsResponse) => Object.values(response).map(
    (groupItem) => ({
  ...groupItem,
  subgroups: Object.values(groupItem.subgroups).map((subGroup) => ({
    ...subGroup,
    fieldName: toSlug(subGroup.subgroupName).replace(/-([a-z])/g, (item) => item[1].toUpperCase()),
    subscriptions: Object.values(subGroup.subscriptions),
  })),
}));

export const prepareSubscriptionsFormValues = (response: IUserSubscriptionsResponse) => {
  const { [ARTICLE_GROUP_ID]: articleGroup, ...restResponse } = response;
  const initialValuesArray = Object.values(restResponse).reduce((acc, groupItem) => {
    const subGroups = Object.values(groupItem.subgroups).reduce((accSubGroup, subGroup) => {
      const fieldName = toSlug(subGroup.subgroupName).replace(/-([a-z])/g, (item) => item[1].toUpperCase());
      const filters = Object.values(subGroup.subscriptions)
        .filter((subscriptions) => subscriptions.isSubscribed)
        .map((subscriptions) => subscriptions.subscriptionId);
      accSubGroup.push([fieldName, { checked: !!(filters.length), filters }]);
      return accSubGroup;
    }, []);
    acc.push(...subGroups);
    return acc;
  }, []);

  const mapFromValues = new Map(initialValuesArray);

  const articleParams = articleGroup
    ? Object.values(articleGroup.subgroups)
        .flatMap((subGroup) => Object.values(subGroup.subscriptions)
        .flatMap((subscription) => subscription.articleParams || []))
    : [];

  return {
    ...Object.fromEntries(mapFromValues),
    // initial value for unsubscribeFromAll tile
    unsubscribeFromAll: false,
    articleParams: articleParams.length > 0 ? articleParams[0] : {},
  };
};

export const preparePayload = (formData: ReturnType<typeof prepareSubscriptionsFormValues>) => {
  if (formData.unsubscribeFromAll) {
    return [];
  }

  const data = Object.values(formData).reduce(
    (acc: string[], item: Record<string, string[]>) => {
      if (item.filters) {
        acc = [...acc, ...item.filters];
      }
      return acc;
    },
    [],
  ) as string[];

  if (formData.articleParams) {
    return [...data, ARTICLE_SUBSCRIPTION_ID];
  }

  return data;
};

export const prepareArticlePayload = (formData) => {
  if (formData.articleParams) {
    return { articleParams: updateToInitialValues(formData.articleParams) };
  }

  return {};
};
