import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Field } from 'redux-form';
import FormWrapper from '@/condor/ui/common/Layout/FormWrapper';
import Input from '@dealroadshow/uikit/core/components/Input';
import IndustrySubindustryField from '../../IndustrySubindustryField';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';

import {
  CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL,
  CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME,
  CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL,
} from '@/condor/ui/common/constants';
import validate from './profileFormValidators';
import { getCompanyInitialValues } from '@/condor/ui/components/Finsight/Companies/helpers';

import layoutStyles from '@/condor/ui/common/Layout/layout.scss';
import styles from './companiesProfileForm.scss';
import formWrapperStyles from '@/condor/ui/common/Layout/FormWrapper/formWrapper.scss';

interface IProps {
  item: any,
  additionalValidateFields?: any,
  industries: any[],
  onSubmit: (formData: any, options: any) => void,
  reduxFormSubmit: () => void,
  onMigrateClick: () => void,
  dealsInformation: any,
  isSubmitDisabled: boolean,
  isCreateCompanyModal?: boolean,
  onSelect: () => void,
}

const CompaniesProfileForm = (
  {
    onSubmit,
    item,
    industries,
    dealsInformation,
    onMigrateClick,
    isSubmitDisabled,
    reduxFormSubmit,
    isCreateCompanyModal = false,
    onSelect,
    additionalValidateFields = {},
  }: IProps,
) => {
  const [isWithCreateDeal, setIsWithCreateDeal] = useState(false);
  const { push } = useHistory();

  const onCancelClick = () => push(CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL);

  useEffect(
    () => {
      if (isWithCreateDeal) {
        reduxFormSubmit();
      }
    },
    [isWithCreateDeal],
  );
  const { pathname } = useLocation();

  const isCreating = isCreateCompanyModal || pathname === CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL;

  const isCompanyDealsExist = !isCreateCompanyModal ? (
    dealsInformation?.deal?.issuer > 0
    || dealsInformation?.deal?.parent > 0
    || dealsInformation?.deal?.underwriter > 0
    || dealsInformation?.deal?.dealer > 0
    || dealsInformation?.research?.issuer > 0
    || dealsInformation?.research?.parent > 0
  ) : false;

  const commonInputProps = {
    placeholder: '',
    component: Input,
    className: cn({ [layoutStyles.inputField]: !isCreateCompanyModal }),
  };

  const formColumnCls = cn(styles.formColumn, { [styles.formColumnModal]: isCreateCompanyModal });

  const submit = (formData) => {
    if (isCreateCompanyModal) {
      onSubmit(formData, onSelect);
    } else {
      onSubmit(formData, isWithCreateDeal);
    }
  };

  return (
    <ProfileWrapper
      isSpinnerVisible={ false }
      contentClassName={ cn(styles.form, { [styles.formModal]: isCreateCompanyModal }) }
    >
      <FormWrapper
        form={ CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME }
        dataTest={ CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME }
        headerText={ !isCreateCompanyModal ? 'Company Details' : null }
        enableReinitialize
        validate={ validate(additionalValidateFields) }
        buttonsHidden
        onSubmit={ submit }
        // @ts-ignore
        initialValues={ getCompanyInitialValues({
          isCreating,
          isCreateCompanyModal,
          item,
          industries,
          dealsInformation,
        }) }
        withAreYouSureModal={ !isCreateCompanyModal }
      >
        <Field
          { ...commonInputProps }
          name="name"
          label="Company Name"
          dataTest="companyProfileFieldName"
        />
        <IndustrySubindustryField
          // @ts-ignore
          label="Industry / Subindustry"
          className={ cn({ [layoutStyles.inputField]: !isCreateCompanyModal }) }
        />
        <div className={ cn(styles.formRow, { [styles.formRowModal]: isCreateCompanyModal }) }>
          <div className={ formColumnCls }>
            <Field
              { ...commonInputProps }
              name="publicExchange"
              label="Public Exchange"
              dataTest="companyProfileFieldPublicExchange"
            />
          </div>
          <div className={ formColumnCls }>
            <Field
              { ...commonInputProps }
              name="publicTicker"
              label="Public Ticker"
              dataTest="companyProfileFieldPublicTicker"
            />
          </div>
        </div>
        <div className={ cn(styles.formRow, { [styles.formRowModal]: isCreateCompanyModal }) }>
          <div className={ formColumnCls }>
            <Field
              { ...commonInputProps }
              name="abbreviation"
              label="Underwriter 3-letter abbreviation"
              dataTest="companyProfileFieldAbbreviation"
            />
          </div>
          <div className={ formColumnCls }>
            <Field
              { ...commonInputProps }
              name="issuerTicker"
              label="Issuer Ticker"
              isNarrow={ isCreating && !isCreateCompanyModal }
              dataTest="companyProfileFieldIssuerTicker"
            />
          </div>
        </div>
        { !isCreating && (
          <div className={ styles.dealsData }>
            <div className={ styles.dealsAmountInput }>
              <Field
                { ...commonInputProps }
                name="dealsTotalCount"
                label="# of Deals"
                placeholder={ dealsInformation.parentAndIssuer }
                dataTest="companyProfileFieldDealsTotalCount"
                disabled
                isNarrow
              />
            </div>
            <Button
              variant="outline"
              title="Migrate Deals"
              onClick={ onMigrateClick }
              disabled={ !isCompanyDealsExist }
              dataTest="condorCompanyProfileFormMigrateButton"
            />
          </div>
        ) }
        <div className={ cn(formWrapperStyles.formButtonsContainer, {
          [styles.formButtonsContainerModal]: isCreateCompanyModal,
        }) }
        >
          <Button
            type="submit"
            variant={ ButtonVariantType.action }
            className={ cn(formWrapperStyles.successBtn, formWrapperStyles.saveAndCreateButton) }
            disabled={ isSubmitDisabled }
            title="Save"
            dataTest="condorCompanyProfileFormSaveButton"
          />
          { isCreating && !isCreateCompanyModal && (
            <Button
              onClick={ () => setIsWithCreateDeal(true) }
              variant={ ButtonVariantType.action }
              disabled={ isSubmitDisabled }
              title="Save & Add New Deal"
              dataTest="condorCompanyProfileFormSaveAddButton"
            />
          ) }
          <Button
            variant={ ButtonVariantType.text }
            onClick={ onCancelClick }
            title="Cancel"
            dataTest="condorCompanyProfileFormCancelButton"
          />
        </div>
      </FormWrapper>
    </ProfileWrapper>
  );
};

export default CompaniesProfileForm;
