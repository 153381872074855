import React, { useState } from 'react';
import format from 'date-fns/format';
import IconKebabMenu from '@dealroadshow/uikit/core/components/Icon/IconKebabMenu';
import { Dropdown, DropdownItem, DropdownItemType } from '@dealroadshow/uikit/core/components/Menu/Dropdown';
import { ArchiveByOptions } from '@/condor/domain/vo/types';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import {
  CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL,
  CONDOR_TABLE_DEFAULT_DATE_FORMAT_2,
} from '@/condor/ui/common/constants';
import { isScreenXs } from '@dealroadshow/uikit/core/styles/screen/screen';
import ArchiveModal from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/ArchiveModal';
import ArchivedActionButtons
  from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/CellItems/ArchivedActionButtons';
import ActionButtons from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/CellItems/ActionButtons';

import styles from '../traceTable.scss';
import { Link } from '@/Framework/Router/Next/Link';

export const DateTimeCell = ({ row: trade, archived, cellTitle }) => {
  const { setTradeToMap, setSearchValue, archiveTrade, restoreTrade } = useTraceContext();
  const isMapped = trade.mappedData?.dealId;
  const [archiveModalOpened, setArchiveModalOpened] = useState<boolean>(false);

  const handleClickButton = () => {
    if (!isMapped) {
      setTradeToMap(trade);
      setSearchValue(trade.issuerName?.split(' ')[0]);
    }
  };

  const openArchiveModal = (e) => {
    e.stopPropagation();
    setArchiveModalOpened(true);
  };

  const handleActionButton = async (archiveBy?: ArchiveByOptions) => {
    if (archived) {
      await restoreTrade(trade.cusip);
    } else {
      const cusip = archiveBy === ArchiveByOptions.Cusip ? trade.cusip : null;
      const issuerName = archiveBy === ArchiveByOptions.Issuer ? trade.issuerName : null;
      await archiveTrade(cusip, archiveBy, issuerName);
    }
    setArchiveModalOpened(false);
  };

  return (
    <div>
      <span className={ styles.mobileColName }>{ cellTitle }</span>
      <LastTableCell
        className={ styles.dateTimeCell }
        childrenToShowOnHover={
          archived ? (
            <ArchivedActionButtons onClick={ openArchiveModal } />
          ) : (
            <ActionButtons
              isMapped={ isMapped }
              dealId={ trade.mappedData?.dealId }
              onArchiveClick={ openArchiveModal }
              onMapClick={ handleClickButton }
            />
          )
        }
      >
        { format(new Date(trade.tradeDate), CONDOR_TABLE_DEFAULT_DATE_FORMAT_2) }
      </LastTableCell>
      { isScreenXs() && trade && (
        <div className={ styles.actionButtonsSection }>
          <Dropdown
            className={ styles.mobileAdditionalOptionsTrigger }
            data-test="mobileAdditionalOptionsDropdown"
            trigger={ <IconKebabMenu /> }
          >
            { !isMapped && (
              <DropdownItem
                type={ DropdownItemType.button }
                onClick={ handleClickButton }
                dataTest="tradeMapButton"
              >
                Map
              </DropdownItem>
            ) }
            { isMapped && (
              <DropdownItem
                dataTest="viewDealProfileLink"
                type={ DropdownItemType.nextNavLink }
                to={ `${ CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL }/${ trade?.mappedData?.dealId }` }
                NextNavLinkComponent={ Link }
              >
                View deal profile
              </DropdownItem>
            ) }
          </Dropdown>
        </div>
      ) }
      { archiveModalOpened && (
        <ArchiveModal
          archived={ archived }
          cancelClick={ () => setArchiveModalOpened(false) }
          submitClick={ handleActionButton }
          isEmptyIssuerName={ !trade.issuerName }
        />
      ) }
    </div>
  );
};
