import { useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useFormState } from '@/Framework/UI/Organisms/FinalForm';
import { CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST } from '@/condor/ui/common/constants';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import {
  UnpublishModalContextProvider, useUnpublishModalContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile/UnpublishModalContext';
import AreYouSureModal from '@/condor/ui/components/Finsight/CreditFlowResearch/common/AreYouSureModal';
import UnpublishActionModal from './UnpublishActionModal';
import PreviewModal from '@/condor/ui/components/Finsight/CreditFlowResearch/Articles/ArticleProfile/PreviewModal';

import styles from './styles.scss';

const ArticlesProfileButtons = () => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const { push } = useHistory();
  const { invalid, dirty, submitting, submitSucceeded } = useFormState();
  const { isEdit, isActive, isClone } = useArticleProfileContext();
  const { setIsOpenModal, isUnpublishing } = useUnpublishModalContext();
  const isDisabled = invalid || (!dirty && !isClone) || submitting || isUnpublishing || isPreviewOpen;
  const isDisabledPublish = isEdit ? (isActive && isDisabled) : isDisabled;

  return (
    <>
      <div className={ styles.formButtonsContainer }>
        <div>
          <Button
            variant={ ButtonVariantType.action }
            dataTest="previewAndPublish"
            title={ isEdit ? 'Preview & Update' : 'Preview & Publish' }
            className={ cn({ [styles.previewAndPublishButton]: !isActive }) }
            disabled={ isDisabledPublish }
            onClick={ () => setIsPreviewOpen(true) }
          />
          {
          !isActive && (
          <Button
            variant={ ButtonVariantType.action }
            dataTest="saveDraft"
            title="Save Draft"
            type="submit"
            disabled={ isDisabled }
          />
          )
        }
          <Button
            variant={ ButtonVariantType.text }
            dataTest="cancel"
            title="Cancel"
            disabled={ submitting || isUnpublishing }
            onClick={ () => push(CONDOR_FINSIGHT_CREDIT_FLOW_ARTICLES_LIST) }
          />
        </div>
        {
          (isEdit && isActive) && (
          <Button
            variant={ ButtonVariantType.text }
            dataTest="unpublish"
            title="Unpublish"
            className={ styles.unpublishButton }
            onClick={ () => setIsOpenModal(true) }
            disabled={ submitting || isUnpublishing }
          />
          )
        }
      </div>
      <AreYouSureModal
        when={ dirty && !submitting && !submitSucceeded }
        disableNative={ false }
      />
      <UnpublishActionModal />
      { isPreviewOpen && (
        <PreviewModal setIsPreviewOpen={ setIsPreviewOpen } />
      ) }
    </>
  );
};

export default () => (
  <UnpublishModalContextProvider>
    <ArticlesProfileButtons />
  </UnpublishModalContextProvider>
);
