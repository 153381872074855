import * as actionTypes from './actionTypes';
import createAction from '@/Framework/State/createAction';
import { TUploadPreviewFormValue } from '../interfaces';
import { TUploadMethod } from '@/Framework/UI/Organisms/Upload/interfaces';
import { ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

interface IProps {
  imageProcessUuid: string,
  uploadMethod: TUploadMethod,
}

export default (
  {
    imageProcessUuid,
    uploadMethod,
  }: IProps,
  dispatch,
) => {
  const initUploadImage = (payload: TUploadPreviewFormValue) => dispatch(createAction(
    actionTypes.UPLOAD_IMAGE_INIT,
    payload,
  ));
  const uploadStart = (payload: {
    uuid: string,
    uploadFile: File,
    callback: any,
  }) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_ADD, payload));
  const uploadCancelSuccess = (payload: { uuid: string }) => dispatch(createAction(
    actionTypes.UPLOAD_IMAGE_CANCEL_SUCCESS,
    payload,
  ));

  const uploadImageCancel = () => {
    if (imageProcessUuid) {
      uploadCancelSuccess({ uuid: imageProcessUuid });
    }
  };

  const uploadImage = async (files: File[]) => {
    uploadImageCancel();

    const upload = await uploadMethod(files[0]);

    uploadStart({
      uploadFile: files[0],
      callback: upload,
      uuid: upload.getUuid(),
    });

    upload
      .on(UploadEvent.uploadChunkDone, (data) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_CHUNK_SUCCESS, data)))
      .on(UploadEvent.uploadDone, (data) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_SUCCESS, data)))
      .on(ProcessingEvent.processing, (data) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_PROCESSING, data)))
      .on(ProcessingEvent.processingDone, (data) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_DONE, data)))
      .on(UploadEvent.error, (data) => dispatch(createAction(actionTypes.UPLOAD_IMAGE_ERROR, data)))
      .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
  };

  return {
    initUploadImage,
    uploadImage,
    uploadImageCancel,
  };
};
