import { combineReducers } from 'redux';
import
  presentationSlides
  from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSlides/DealSlidesForm/reducers/presentationSlides';
import presentation from '@/condor/ui/components/DealRoadshows/Roadshow/modules/DealSlides/DealSlidesForm/reducers/presentation';
import logo from './logo';
import presentationMediaThumbnail from '@/dealroadshow/application/actions/presentationMedia/reducers/presentationMediaThumbnail';
import presentationMediaVideo from '@/dealroadshow/application/actions/presentationMedia/reducers/presentationMediaVideo';
import presentationMediaAudio from '@/dealroadshow/application/actions/presentationMedia/reducers/presentationMediaAudio';
import relatedRoadshowLogo from './relatedRoadshowLogo';
import disclaimerLogo from './disclaimerLogo';
import disclaimerBackgroundImage from './disclaimerBackgroundImage';
import presentationCuePoints from '@/dealroadshow/application/actions/cuePoints/presentationCuePointsReducer';
import viewerCustomFiles from './viewerCustomFiles';

export default combineReducers({
  presentationSlides,
  presentation,
  viewerCustomFiles,
  logo,
  presentationMediaThumbnail,
  presentationMediaVideo,
  presentationMediaAudio,
  relatedRoadshowLogo,
  disclaimerLogo,
  disclaimerBackgroundImage,
  presentationCuePoints,
});
