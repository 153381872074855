import { useParams } from 'react-router';
import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import { IAdminCall } from '@/evercall/domain/vo/call/admin/AdminCall';
import EvercallAdminRepository from '@/evercall/infrastructure/repository/EvercallAdminRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import ErrorCodeHelper from '@finsight/error-codes';
import { getMessageByErrorCode, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { TGroupSuggestion } from '@/evercall/domain/vo/call/admin/Group';

interface IProps {
  setIsUpdating: (payload: boolean) => void,
  setIsUpdateSucceeded: (payload: boolean) => void,
  setItem: (payload: IAdminCall) => void,
}

type IAdminCallFieldsErrors = {
  [K in keyof IAdminCall]?: string;
};

const submitErrorsMap: { [K in keyof IAdminCall]?: number[] } = {
  participantConferenceId: [
    ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED'),
  ],
  roadshowId: [
    ErrorCodeHelper.getCodeByName('EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED'),
  ],
};

const getSubmitErrorsByErrorCode = (
  errorCode: number,
): IAdminCallFieldsErrors => {
  const errors: IAdminCallFieldsErrors = {};

  Object.keys(submitErrorsMap).forEach((key) => {
    if (submitErrorsMap[key].includes(errorCode)) {
      errors[key] = getMessageByErrorCode(errorCode);
    }
  });

  return errors;
};

export default ({ setIsUpdating, setIsUpdateSucceeded, setItem }: IProps) => {
  const { container } = useDIContext();
  const evercallAdminRepository: EvercallAdminRepository = container.get(EvercallAdminRepository);

  const { callId: callIdFromUrl } = useParams();

  const updateCallData = async (payload: IAdminCall): Promise<IAdminCallFieldsErrors> => {
    let submitErrors: IAdminCallFieldsErrors = {};
    const request = createAsyncRequest<typeof evercallAdminRepository.updateCallData>({
      method: evercallAdminRepository.updateCallData,
      callbacks: {
        onSuccess: ({ response }) => {
          setItem(response);
          setIsUpdating(false);
          setIsUpdateSucceeded(true);
          NotificationManager.success(getMessage(messageCodes.CONDOR_SAVED));
        },
        onError: ({ error }) => {
          const errorCode = error?.error?.code;

          submitErrors = getSubmitErrorsByErrorCode(errorCode);

          setIsUpdating(false);
          setIsUpdateSucceeded(false);
        },
      },
    });
    setIsUpdating(true);
    await request({ ...payload, id: callIdFromUrl });
    return submitErrors;
  };

  const fetchGroupsSuggestions = async (search: string) => {
    let groupsList: TGroupSuggestion[] = null;
    const request = createAsyncRequest<typeof evercallAdminRepository.getGroupsSuggestions>(
      {
        method: evercallAdminRepository.getGroupsSuggestions,
        callbacks: {
          onSuccess: ({ response }) => {
            groupsList = response;
          },
        },
      },
    );
    await request({ search });
    return groupsList;
  };

  return {
    updateCallData,
    fetchGroupsSuggestions,
  };
};
