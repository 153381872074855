import React, { Component } from 'react';
import { format } from 'date-fns';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { Filter, Search } from '@/condor/ui/common/FilterGroup';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import DataTable, { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Paginator from '@dealroadshow/uikit/core/components/Paginator';
import { Icon, IconType } from '@dealroadshow/uikit';
import * as constants from '@/condor/ui/common/constants';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './loginLimits.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';

interface IProps {
  match: any,
  search: (value: string) => void,
  paginate: (page: number) => void,
  itemsPerPage: (perPage: number) => void,
  sort: (sortBy: string, sortOrder: SortOrder) => void,
  getLoginLimits: (payload: any) => void,
  resetAdminLoginLimits: (resetParams: any, filtersPayload: any) => void,
  resetAdminInvestorLoginsCountByEntryCode: (resetParams: any, filtersPayload: any) => void,
  resetLoginLimits: () => void,
  loginLimits: any,
}

class LoginLimits extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalVisibleRow: null,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTableSortChange = this.handleTableSortChange.bind(this);
    this.cellActionCallback = this.cellActionCallback.bind(this);
    this.applyModalRoadshowLevel = this.applyModalRoadshowLevel.bind(this);
    this.applyModalEntryCodeLevel = this.applyModalEntryCodeLevel.bind(this);
    this.getModalResetButton = this.getModalResetButton.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerPageChange = this.handlePerPageChange.bind(this);
  }

  componentDidMount() {
    this.getLoginLimits(this.getFilterState(this.props.loginLimits));
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.getFilterState(this.props.loginLimits), this.getFilterState(prevProps.loginLimits))) {
      this.getLoginLimits(this.getFilterState(this.props.loginLimits));
    }
  }

  componentWillUnmount() {
    this.props.resetLoginLimits();
  }

  getFilterState(loginLimits) {
    return {
      search: loginLimits.search,
      paginationGroup: {
        perPage: loginLimits.perPage,
        activePage: loginLimits.page,
      },
      sort: {
        sortBy: loginLimits.sortBy,
        sortOrder: loginLimits.sortOrder,
      },
    };
  }

  getFilterStateToRequest(filterState) {
    return {
      roadshowId: this.props.match.params.roadshowId,
      sortBy: filterState.sort.sortBy,
      sortOrder: filterState.sort.sortOrder,
      search: filterState.search,
      page: filterState.paginationGroup.activePage,
      perPage: filterState.paginationGroup.perPage,
    };
  }

  getLoginLimits(filterState) {
    const filtersPayload = this.getFilterStateToRequest(filterState);
    this.props.getLoginLimits(filtersPayload);
  }

  getModal() {
    let isEntryCodeModal = false;
    let user = {};

    if (!!this.state.deleteModalVisibleRow && !!this.state.deleteModalVisibleRow.entryCodeId) {
      // eslint-disable-next-line prefer-destructuring
      user = this.props.loginLimits.collection
        .filter((user) => (user.userId === this.state.deleteModalVisibleRow.userId
          && user.entryCodeId === this.state.deleteModalVisibleRow.entryCodeId))[0];
      isEntryCodeModal = true;
    } else {
      // eslint-disable-next-line prefer-destructuring
      user = this.props.loginLimits.collection
        .filter((user) => user.userId === this.state.deleteModalVisibleRow.userId)[0];
    }
    // @ts-ignore
    let title = `Reset ${ user.name } Login Limits views?`;
    return (
      <Modal
        onCloseClicked={ () => this.hideModal() }
        isVisible={ !!this.state.deleteModalVisibleRow }
        title={ title }
        dataTest="resetLoginLimitsModal"
        footer={ (
          <div>
            { this.getModalResetButton(isEntryCodeModal) }
            <Button
              variant={ ButtonVariantType.secondary }
              onClick={ () => this.hideModal() }
              dataTest="loginLimitHideModalButton"
            >
              Cancel
            </Button>
          </div>
        ) }
      />
    );
  }

  getModalResetButton(isEntryCodeDelete) {
    return (
      <Button
        variant={ ButtonVariantType.success }
        onClick={ () => (isEntryCodeDelete ? this.applyModalEntryCodeLevel() : this.applyModalRoadshowLevel()) }
        title="Reset"
        dataTest="loginLimitResetButton"
      />
    );
  }

  showModal(row) {
    this.setState({
      deleteModalVisibleRow: row,
    });
  }

  hideModal() {
    this.setState({
      deleteModalVisibleRow: null,
    });
  }

  applyModalRoadshowLevel() {
    let user = this.props.loginLimits.collection
      .filter((user) => user.userId === this.state.deleteModalVisibleRow.userId)[0];
    this.resetAdminLoginLimits({
      roadshowId: this.props.match.params.roadshowId,
      userId: user.userId,
      type: 'reset',
      value: true,
    });
    this.hideModal();
  }

  applyModalEntryCodeLevel() {
    let user = this.props.loginLimits.collection
      .filter((user) => (user.userId === this.state.deleteModalVisibleRow.userId
        && user.entryCodeId === this.state.deleteModalVisibleRow.entryCodeId))[0];
    this.resetAdminInvestorLoginsCountByEntryCode({
      roadshowEntryCodeId: this.state.deleteModalVisibleRow.entryCodeId,
      userId: user.userId,
      type: 'reset',
      value: true,
    });
    this.hideModal();
  }

  handleFilterChange(type, value) {
    switch (type) {
      case 'search':
        this.props.search(value);
        break;
      default:
        break;
    }
  }

  handleTableSortChange(sortBy, sortOrder) {
    this.props.sort(sortBy, sortOrder);
  }

  cellActionCallback({ row }) {
    const resetWrapper = cn(styles.action, {
      [styles.isDisabledAction]: !row.totalViews,
    });
    return (
      <div className={ styles.action }>
        <span
          className={ resetWrapper }
          onClick={ () => {
            if (row.totalViews) {
              this.showModal(row);
            }
          } }
        >
          <Tooltip
            content="Reset investor login limit"
            disabled={ !row.totalViews }
          >
            <Icon type={ IconType.refresh } />
          </Tooltip>
        </span>
      </div>
    );
  }

  resetAdminInvestorLoginsCountByEntryCode(resetParams) {
    const filtersPayload = this.getFilterStateToRequest(this.getFilterState(this.props.loginLimits));
    this.props.resetAdminInvestorLoginsCountByEntryCode(resetParams, filtersPayload);
  }

  resetAdminLoginLimits(resetParams) {
    const filtersPayload = this.getFilterStateToRequest(this.getFilterState(this.props.loginLimits));
    this.props.resetAdminLoginLimits(resetParams, filtersPayload);
  }

  /**
   * @param {Object} page
   */
  handlePageChange(page) {
    this.props.paginate(page.selected);
  }

  /**
   * @param {Object} perPage
   */
  handlePerPageChange(perPage) {
    this.props.itemsPerPage(perPage.value);
  }

  render() {
    const tableColumns = [
      {
        name: 'companyName',
        title: 'Company',
        sortable: true,
      },
      {
        name: 'name',
        title: 'Name',
        sortable: true,
      },
      {
        name: 'email',
        title: 'Corporate Email',
        sortable: true,
      },
      {
        name: 'lastLoginAt',
        title: 'Last Login',
        cellCallback: ({ row }) => format(
          getDateFromUnixTime(row.lastLoginAt),
          constants.CONDOR_DEAL_FILES_DATE_TIME_FORMAT,
        ),
        sortable: true,
      },
      {
        name: 'entryCodeCode',
        title: 'Entry Code',
        sortable: true,
      },
      {
        name: 'totalViews',
        title: 'Login Limits views',
        sortable: true,
      },
      {
        name: 'action',
        title: 'Actions',
        className: alignStyles.alignCenter,
        cellCallback: this.cellActionCallback,
      },
    ];
    const tableFilter = {
      search: {
        placeholder: 'Filter by Company, Name or Email',
        debounceValue: 500,
      },
      paginationGroup: {
        perPage: {
          list: [50, 100, 200],
        },
      },
    };
    return (
      <PageWrapper>
        <TableWrapper
          label="Login Limits"
          filterComponent={ (
            <Filter
              config={ tableFilter }
              state={ this.getFilterState(this.props.loginLimits) }
              onChange={ this.handleFilterChange }
            >
              <Search />
            </Filter>
          ) }
        >
          <DataTable
            columns={ tableColumns }
            dataTest="loginLimitsDataTable"
            data={ this.props.loginLimits.collection }
            loadingComponent={ Spinner }
            isFetching={ this.props.loginLimits.isFetching }
            sortBy={ this.props.loginLimits.sortBy }
            sortOrder={ this.props.loginLimits.sortOrder }
            onSortChange={ this.handleTableSortChange }
          />
          <Paginator
            className={ cardStyles.cardInner }
            page={ this.props.loginLimits.page }
            perPage={ this.props.loginLimits.perPage }
            totalCount={ this.props.loginLimits.totalCount }
            onPageChange={ this.handlePageChange }
            onItemsPerPageChange={ this.handlePerPageChange }
            dataTest="loginLimits"
          />
        </TableWrapper>
        { !!this.state.deleteModalVisibleRow && this.getModal() }
      </PageWrapper>
    );
  }
}

export default LoginLimits;
