import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState, ChangeEvent, forwardRef, ForwardedRef, useImperativeHandle } from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';
import { IconType, Input } from '@dealroadshow/uikit';
import styles from './search-input.scss';

interface IProps {
  debounceWaitTime?: number,
  value: string,
  placeholder: string,
  isNarrow?: boolean,
  isClearable?: boolean,
  inputClassName?: string,
  iconType?: IconType,
  formFieldClassName?: string,
  dataTest?: string,
  disabled?: boolean,
  className?: string,
  onInit?: () => void,
  onChange: (value: any) => void,
  reset?: () => void,
}

export type TSearchInputRef = {
  handleClearInput: () => void,
};

const SearchInput = forwardRef((
  props: IProps,
  parentRef: ForwardedRef<TSearchInputRef>,
) => {
  const {
    value,
    isNarrow = true,
    isClearable = true,
    iconType = IconType.filtering,
    debounceWaitTime = 500,
    onInit = noop,
    onChange = noop,
    reset = noop,
  } = props;

  const [localValue, setLocalValue] = useState<string>(value);
  const debouncedOnChange = debounce(onChange, debounceWaitTime);

  useImperativeHandle<TSearchInputRef, TSearchInputRef>(
    parentRef,
    () => ({ handleClearInput }),
    [],
  );

  useEffect(() => {
    return () => {
      reset();
      setLocalValue(null);
    };
  }, []);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    debouncedOnChange(e.target.value);
  }, [debounceWaitTime]);

  const handleClearInput = () => {
    setLocalValue('');
    onChange('');
  };

  return (
    // @ts-ignore
    <Input
      { ...props }
      className={ cn(styles.searchInput, props.className) }
      onChange={ handleSearchChange }
      value={ localValue }
      dataTest="formSearchInput"
      isNarrow={ isNarrow }
      isClearable={ isClearable }
      iconType={ iconType }
      onInit={ onInit }
    />
  );
});

export default SearchInput;
