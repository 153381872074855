import { groupValidators, applyValidatorToMultipleFields } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateCheckboxWithFilters } from '@/Framework/UI/Organisms/FinalForm/validators/validateCheckboxWithFilters';
/**
 * @param {Array} data
 * @return {Array}
 */

export const getFieldList = (data = []) => {
  if (!data.length) {
    return [];
  }

  return data.reduce((fieldsList, groupItem) => {
    const subGroups = groupItem.subgroups.reduce((accSubGroup, subGroup) => {
      accSubGroup.push({ fieldName: subGroup.fieldName });
      return accSubGroup;
    }, []);
    fieldsList.push(...subGroups);
    return fieldsList;
  }, []);
};

export default (values, data) => {
  return groupValidators(
    applyValidatorToMultipleFields({
      values,
      fields: getFieldList(data),
      validator: validateCheckboxWithFilters,
    }),
  );
};
