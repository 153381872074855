import React from 'react';

import { useEnhancedTaggingRoadshowsContext } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import {
  StatusCallback,
  TransactionTypeCallback,
  DealNameCallback,
  CompanyCallback,
  SectorCallback,
  CountryCallback,
  UnderwritersCallback,
  ListActionButtons,
  DateCallback,
  IndustryCallback,
  CurrencyCallback,
  DealSizeCallback,
  CheckboxCallback,
} from './cellCallbacks';
import CheckAll from '@/Framework/UI/Molecules/Tables/Columns/CheckAll';
import TaggingCell from './cellCallbacks/TaggingCell';
import { IColumn } from '@dealroadshow/uikit';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from './roadshows.scss';

const CheckAllComponent = () => {
  const { editableRowId: isSingleEdit } = useEnhancedTaggingRoadshowsContext();

  return <CheckAll dataTest="checkAll" disabled={ Boolean(isSingleEdit) } />;
};

const columns: IColumn[] = [
  {
    name: 'checkboxes',
    title: <CheckAllComponent />,
    width: 45,
    stickyLeft: true,
    cellCallback: CheckboxCallback,
  },
  {
    name: 'id',
    title: 'Unique id',
    maxWidth: 96,
    stickyLeft: true,
    cellCallback: (cellProps) => <div className={ styles.oneLine }>{ cellProps.cellData }</div>,
  },
  {
    name: 'published',
    title: 'Status',
    stickyLeft: true,
    cellCallback: StatusCallback,
  },
  {
    name: 'taggingState',
    title: 'Tagged',
    stickyLeft: true,
    cellCallback: TaggingCell,
  },
  {
    name: 'transactionType',
    title: 'Trns Type',
    className: alignStyles.alignCenter,
    minWidth: 95,
    maxWidth: 95,
    stickyLeft: true,
    sortable: true,
    cellCallback: TransactionTypeCallback,
  },
  {
    name: 'name',
    title: 'Deal Name',
    minWidth: 300,
    maxWidth: 300,
    stickyLeft: true,
    sortable: true,
    cellCallback: DealNameCallback,
  },
  {
    name: 'parent',
    title: 'Company / Parent',
    minWidth: 190,
    maxWidth: 240,
    sortable: true,
    cellCallback: CompanyCallback,
  },
  {
    name: 'sector',
    title: 'Sector / Subsector',
    minWidth: 300,
    maxWidth: 400,
    sortable: true,
    cellCallback: SectorCallback,
  },
  {
    name: 'industry',
    title: 'Industry / Subindustry',
    minWidth: 300,
    maxWidth: 400,
    sortable: true,
    cellCallback: IndustryCallback,
  },
  {
    name: 'country',
    title: 'Country',
    minWidth: 185,
    sortable: true,
    cellCallback: CountryCallback,
  },
  {
    name: 'ccy',
    title: 'CCY',
    minWidth: 240,
    sortable: true,
    cellCallback: CurrencyCallback,
  },
  {
    name: 'dealSize',
    title: 'Size',
    className: alignStyles.alignRight,
    minWidth: 135,
    sortable: true,
    cellCallback: DealSizeCallback,
  },
  {
    name: 'enhancedTaggingUnderwriters',
    title: 'UW',
    className: alignStyles.alignRight,
    minWidth: 60,
    maxWidth: 100,
    cellCallback: UnderwritersCallback,
  },
  {
    name: 'createdAt',
    title: 'Date created',
    className: alignStyles.alignRight,
    minWidth: 130,
    sortable: true,
    cellCallback: (props) => {
      return <DateCallback date={ props.cellData } actions={ <ListActionButtons { ...props } /> } />;
    },
  },
  {
    name: 'mobileActions',
    className: styles.mobileActions,
    cellCallback: ListActionButtons,
  },
];

export default columns;
