import React, { memo } from 'react';
import cn from 'classnames';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';
import dataTableStyles from '@dealroadshow/uikit/core/components/Table/DataTable/dataTable.scss';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import TableWrapper from '@/Framework/UI/Molecules/Tables/TableWrapper';
import CompanySelectModal from '../CompanySelectModal';
import CompanyCreateModal from '../CompanyCreateModal';
import formStyles from '@/condor/ui/common/Layout/FormWrapper/formWrapper.scss';
import styles from './validationTable.scss';
import * as correctionTypes from '@/condor/ui/components/Finsight/Import/modules/Validation/correctionTypes';

interface IProps {
  canReimportFile: boolean,
  activeCellCorrectionType: string,
  activeColumnName: string,
  activeCellValue: string,
  companyCreateModalItem: any,
  companySelectModalOptions: any[],
  columns: any[],
  rows: any[],
  companySelectModalVisible: boolean,
  companyCreateModalVisible: boolean,
  onCreateModalOpen: () => void,
  onCreateModalClose: () => void,
  onSelectModalClose: () => void,
  onSelect: () => void,
  reimportFile: () => void,
  resetAll: () => void,
}

const ValidationTable = ({
  companyCreateModalItem,
  companySelectModalOptions,
  activeCellCorrectionType,
  activeColumnName,
  activeCellValue,
  columns,
  rows,
  companySelectModalVisible,
  companyCreateModalVisible,
  canReimportFile,
  onCreateModalOpen,
  onCreateModalClose,
  onSelectModalClose,
  onSelect,
  reimportFile,
  resetAll,
}: IProps) => (
  <TableWrapper
    label="Upload Validation Errors"
    className={ styles.tableWrapper }
    contentClassName={ styles.tableContent }
  >
    { !!rows.length && (
      <DataTable
        containerClassName={ styles.tableContainer }
        className={ cn(styles.dataTable, dataTableStyles.isHoverable) }
        dataTest="validationDataTable"
        columns={ columns }
        data={ rows }
      />
    ) }
    { companySelectModalVisible && !companyCreateModalVisible && (
      <CompanySelectModal
        options={ companySelectModalOptions }
        activeColumnName={ activeColumnName }
        activeCellValue={ activeCellValue }
        onCreateModalOpen={ onCreateModalOpen }
        onClose={ onSelectModalClose }
        onSelect={ onSelect }
      />
    ) }
    { companyCreateModalVisible && (
      <CompanyCreateModal
        item={ companyCreateModalItem }
        activeColumnName={ activeColumnName }
        onClose={ onCreateModalClose }
        onSelect={ onSelect }
        additionalValidateFields={ {
          industrySubindustry: correctionTypes.isCompanyParent(activeCellCorrectionType),
          abbreviation: correctionTypes.isCompanyLead(activeCellCorrectionType),
        } }
      />
    ) }
    <div className={ styles.buttonsContainer }>
      <Button
        type="submit"
        onClick={ reimportFile }
        variant={ ButtonVariantType.action }
        className={ formStyles.successBtn }
        disabled={ !canReimportFile }
        title="Reupload XLS"
        dataTest="reuploadXlsButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ resetAll }
        title="Cancel"
        dataTest="cancelButton"
      />
    </div>
  </TableWrapper>
);

export default memo(ValidationTable);
