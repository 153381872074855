import { useEffect, useCallback } from 'react';
import CheckEmailsSubscriptionContextProvider, {
  useCheckEmailsSubscriptionContext,
} from '@/condor/application/FinsightManager/Subscribers/AddSubscribersForm/CheckEmailsSubscriptionContext';
import CommentaryDealFlowContextProvider from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import { useRenderFields } from '@/finsight/ui/components/SubscriptionForm/Base/useRenderFields';
import FormEntry from '@/finsight/ui/components/SubscriptionForm/FormEntry';
import type { IBaseSubscriptionFormProps } from '@/finsight/ui/components/SubscriptionForm/types';
import styles from '@/finsight/ui/components/SubscriptionForm/Base/styles.scss';

export const BaseSubscriptionForm = ({
  isSubmitting,
  subscriptionsData,
  email,
  withEmailSelect,
  withUnsubscribe,
  submitButtonTitle,
  formName,
  initialValues,
  validate,
  submitForm,
  onCancel,
}: IBaseSubscriptionFormProps) => {
  const { checkNewEmails, checkedEmails, isChecking } = useCheckEmailsSubscriptionContext();

  useEffect(() => {
    if (email) {
      checkNewEmails([{ isValid: true, label: email, value: email }]);
    }
  }, [email]);

  const validateWithData = useCallback((values) => {
    if (values?.subscribersListSelect) {
      const emails = values?.subscribersListSelect.map((email) => ({ ...email, isValid: true }));
      checkNewEmails(emails);
    }

    validate(values, subscriptionsData);
  }, [subscriptionsData]);

  const renderFields = useRenderFields({
    isSubmitting,
    subscriptionsData,
    checkedEmails,
    email,
    isChecking,
    withEmailSelect,
    withUnsubscribe,
    submitButtonTitle,
    onCancel,
  });

  return (
    <FormEntry
      styles={ styles }
      formName={ formName }
      submitForm={ submitForm }
      renderFields={ renderFields }
      initialValues={ initialValues }
      validateWithData={ validateWithData }
    />
  );
};

export default (props) => (
  <CheckEmailsSubscriptionContextProvider>
    <CommentaryDealFlowContextProvider>
      <BaseSubscriptionForm { ...props } />
    </CommentaryDealFlowContextProvider>
  </CheckEmailsSubscriptionContextProvider>
);
