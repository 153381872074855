import React from 'react';
import { BANNER_FORM_FIELDS } from '../constants';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import modalStyles from './deleteModal.scss';

interface IProps {
  isVisible: boolean,
  bannerId: string,
  onDelete: (bannerId: string) => void,
  onCancel: () => void,
}

const DeleteModal = ({
  isVisible,
  bannerId,
  onCancel,
  onDelete,
}: IProps) => (
  <Modal
    hideOnOverlayClicked
    { ...BANNER_FORM_FIELDS.ARE_YOU_SURE }
    isVisible={ isVisible }
    onCloseClicked={ onCancel }
    footer={ (
      <>
        <Button
          variant={ ButtonVariantType.action }
          { ...BANNER_FORM_FIELDS.DELETE }
          onClick={ () => onDelete(bannerId) }
          dataTest="condorBannersDeleteModalButton"
        />
        <Button
          variant={ ButtonVariantType.text }
          { ...BANNER_FORM_FIELDS.CANCEL }
          onClick={ onCancel }
          dataTest="condorBannersDeleteModalCancelButton"
        />
      </>
    ) }
  >
    <span className={ modalStyles.areYouSureLabel }>
      Deleting this banner will remove it from all applicable sites (if live) and also delete it from this page.
    </span>
  </Modal>
);

export default DeleteModal;
