import React, { createContext, useCallback, useContext, useState } from 'react';

import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { IStatToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';
import { useStatsListContext } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsList';
import { getUrlAndDownloadAttachment } from '@/finsight/application/helpers';

interface IModalsState {
  isModalOpen: boolean,
  stat: IStatToDisplay | null,
}

const initialModalsState: IModalsState = {
  isModalOpen: false,
  stat: null,
};

const useStatActions = () => {
  const { getCollection } = useStatsListContext();
  const [modalData, setModalData] = useState<IModalsState>(initialModalsState);
  const [isRequestPerforming, setIsRequestPerforming] = useState(false);

  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);

  const openModal = useCallback((stat: IStatToDisplay) => {
    setModalData({
      stat,
      isModalOpen: true,
    });
  }, []);

  const handleDeleteStat = async () => {
    setIsRequestPerforming(true);
    try {
      await creditFlowResearchRepository.deleteStatById(modalData.stat.id);
      NotificationManager.success('Stat has been successfully deleted');
      closeModal();
      getCollection();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsRequestPerforming(false);
    }
  };

  const closeModal = useCallback(() => {
    setIsRequestPerforming(false);
    setModalData(initialModalsState);
  }, []);

  const downloadAttachment = async (stat: IStatToDisplay) => {
    setIsRequestPerforming(true);
    try {
      await getUrlAndDownloadAttachment(creditFlowResearchRepository.getAttachmentDownloadUrl, {
        uploadId: stat.attachment.uploadId,
        displayName: stat.attachment.displayName,
      });
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsRequestPerforming(false);
    }
  };

  return {
    ...modalData,

    openModal,
    closeModal,
    handleDeleteStat,
    downloadAttachment,

    isRequestPerforming,
  };
};

const StatActionsContext = createContext<ReturnType<typeof useStatActions>>(null);

export const useStatActionsContext = () => {
  const context = useContext(StatActionsContext);
  if (!context) {
    throw new Error('useArticlesActionsContext must be used within a ArticlesActionsContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const StatActionsContextProvider = ({ children }: IProps) => (
  <StatActionsContext.Provider value={ useStatActions() }>
    { children }
  </StatActionsContext.Provider>
);

export default StatActionsContextProvider;
