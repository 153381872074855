export enum EntryCodeTypes {
  Rotating = 'Rotating',
  Single = 'Single',
  Review = 'Review',
}

class EntryCodeType {
  static isRotating(type: EntryCodeTypes) {
    return type === EntryCodeTypes.Rotating;
  }

  static isSingle(type: EntryCodeTypes) {
    return type === EntryCodeTypes.Single;
  }

  static isReview(type: EntryCodeTypes) {
    return type === EntryCodeTypes.Review;
  }
}

export default EntryCodeType;
