import { useRef } from 'react';
import Head from 'next/head';
import cn from 'classnames';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { Modal } from '@dealroadshow/uikit/core/components/Modal';
import { PanelGroup, Panel, PanelArrow, panelGroupStyles } from '@dealroadshow/uikit/core/components/PanelGroup';

import ProfileFormContextProvider, {
  IUseProfileFormProps,
  useProfileFormContext,
} from './ProfileFormContext';
import UploadInputsStatusContextProvider, {
  useUploadInputsStatusContext,
} from '@/Framework/UI/Organisms/Upload/components/UploadInputsStatusContext';
import FinalForm, { FormSpy } from '@/Framework/UI/Organisms/FinalForm';
import StickyHeader, { useStickyHeader } from '@/dmPortal/ui/common/StickyHeader';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Link from '@/Framework/Router/ReactRouter/Link';
import { focusFieldWithError } from '@/Framework/UI/Organisms/FinalForm/focusFieldWithError';
import ProfileFormHeaderButtons from '@/openMarket/ui/common/Condor/ProfileFormHeaderButtons';

import Details from './sections/Details';
import Customization from './sections/Customization';
import LoginAndDisclaimer from './sections/LoginAndDisclaimer';

import { CONDOR_FINSIGHT_OPEN_MARKET_PROFILES } from '@/condor/ui/common/constants';
import { PanelName, PROFILE_FORM, mapFieldToGroup } from './constants';
import validate from './validation';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { IFormValues } from './interfaces';
import styles from './profileForm.scss';
import { useProfileDataContext } from '@/openMarket/application/Condor/ProfileDataContext';

const ProfileFormFields: TFinalFormRenderProp<IFormValues> = ({
  invalid,
  submitFailed,
  handleSubmit,
  hasValidationErrors,
  errors,
  pristine,
  form,
}) => {
  const panelsRef = useRef();
  const { title, isSubmitting, isInitialized } = useProfileFormContext();
  const { redirectToList, profileId } = useProfileDataContext();

  const { isAllUploadCanSave } = useUploadInputsStatusContext();
  const { isStickyHeaderVisible, ref } = useStickyHeader();

  const focusOnError = (errors: any) => {
    focusFieldWithError(errors, panelsRef.current, mapFieldToGroup);
  };

  const changePublished = (published: boolean) => form.change('published', published);

  const saveAndPublish = () => {
    changePublished(true);
    handleSubmit();
  };

  const saveAsDraft = () => {
    changePublished(false);
    handleSubmit();
  };

  const isLoading = !isInitialized || isSubmitting;
  const disabledButton = isLoading || !isAllUploadCanSave || (submitFailed && invalid);

  const buttons = (
    <>
      <Button
        type="button"
        variant={ ButtonVariantType.action }
        onClick={ saveAsDraft }
        title="Save Draft"
        dataTest="saveDraftButton"
        disabled={ disabledButton }
      />
      <Button
        type="button"
        onClick={ saveAndPublish }
        variant={ ButtonVariantType.success }
        title="Save & Publish"
        dataTest="saveAndPublishButton"
        disabled={ disabledButton }
      />
    </>
  );

  return (
    <div ref={ ref }>
      <Head>
        <title>{ title } | OpenMarket</title>
      </Head>
      <Spinner isVisible={ isLoading } overlay isFixed />
      <StickyHeader title={ title } isVisible={ isStickyHeaderVisible } buttons={ buttons } />
      { profileId && (
        <ProfileFormHeaderButtons className={ styles.headerButtons } />
      ) }
      <PanelGroup
        ref={ panelsRef }
        className={ cn(panelGroupStyles.panelWithHoverOnHeader, panelGroupStyles.formPanelGroupWrapper) }
        dataTest="profileFormPanelGroup"
        defaultActiveKey={ [PanelName.details, PanelName.customization, PanelName.loginAndDisclaimer] }
      >
        <Panel
          header="Profile Details"
          id={ PanelName.details }
          dataTest={ PanelName.details }
          arrow={ <PanelArrow /> }
          arrowActive={ <PanelArrow isActive /> }
        >
          <Details />
        </Panel>
        <Panel
          header="Profile Customization"
          id={ PanelName.customization }
          dataTest={ PanelName.customization }
          arrow={ <PanelArrow /> }
          arrowActive={ <PanelArrow isActive /> }
        >
          <Customization />
        </Panel>
        <Panel
          header="Login & Disclaimer "
          id={ PanelName.loginAndDisclaimer }
          dataTest={ PanelName.loginAndDisclaimer }
          arrow={ <PanelArrow /> }
          arrowActive={ <PanelArrow isActive /> }
        >
          <LoginAndDisclaimer />
        </Panel>
      </PanelGroup>
      <FormSpy
        subscription={ { submitFailed: true } }
        onChange={ ({ submitFailed }) => {
          return submitFailed && hasValidationErrors && focusOnError(errors);
        } }
      />
      <div data-test="formButtons">
        { buttons }
        <Link to={ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }>
          <Button variant={ ButtonVariantType.text } title="Cancel" dataTest="cancelButton" />
        </Link>
      </div>
      <NavigationPrompt disableNative when={ !pristine && !isSubmitting } afterConfirm={ redirectToList }>
        { ({ onConfirm, onCancel }) => (
          <Modal
            onCloseClicked={ onCancel }
            isVisible
            title="Discard Profile Changes"
            className={ styles.discardModal }
            footer={ (
              <>
                <Button
                  variant={ ButtonVariantType.action }
                  onClick={ onConfirm }
                  title="Discard"
                  dataTest="profileFormYesButton"
                />
                <Button variant={ ButtonVariantType.text } onClick={ onCancel } title="Cancel" dataTest="profileFormNoButton" />
              </>
            ) }
            dataTest="cancelProfileFormChangesModal"
          >
            Are you sure you want to leave this OpenMarket Profile form?
            <br />
            Any changes you made will be lost.
          </Modal>
        ) }
      </NavigationPrompt>
    </div>
  );
};

const ProfileForm = () => {
  const { initialFormValues, handleSubmitForm } = useProfileFormContext();

  return (
    <FinalForm
      keepDirtyOnReinitialize
      name={ PROFILE_FORM }
      dataTest={ PROFILE_FORM }
      initialValues={ initialFormValues }
      validate={ validate }
      onSubmit={ handleSubmitForm }
      render={ ProfileFormFields }
    />
  );
};

export default (props: IUseProfileFormProps) => (
  <UploadInputsStatusContextProvider>
    <ProfileFormContextProvider { ...props }>
      <ProfileForm />
    </ProfileFormContextProvider>
  </UploadInputsStatusContextProvider>
);
