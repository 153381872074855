/* eslint-disable react/no-unused-prop-types */
import React, { FC } from 'react';
import { BodyCellPure } from '@dealroadshow/uikit';
import cn from 'classnames';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import PermissionCheckBox from './PermissionCheckBox';
import permissionStyles from './userPermissions.scss';

interface IPermissionRowProps {
  id: string,
  functionality: string,
  description: string,
  name: string,
  title: string,
  width: number,
  className: string,
  hasPermissionToUpdate: boolean,
  updateOwnContact: boolean,
  rowIndex: number,
}

const PermissionRow: FC<IPermissionRowProps> = (props) => {
  const { functionality, description, id, hasPermissionToUpdate, updateOwnContact, rowIndex } = props;
  return (
    <tr>
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="functionality"
        className={ alignStyles.alignLeft }
        cellCallback={ () => <>{ functionality }</> }
      />
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="description"
        className={ cn(alignStyles.alignLeft, permissionStyles.descriptionRow) }
        cellCallback={ () => <>{ description }</> }
      />
      <BodyCellPure
        rowIndex={ rowIndex }
        rowData={ props }
        name="tree"
        className={ cn(alignStyles.alignRight, permissionStyles.checkBoxRow) }
        cellCallback={ () => (
          <PermissionCheckBox
            moduleKey={ id }
            groupIndex={ 0 }
            hasPermissionToUpdate={ hasPermissionToUpdate }
            updateOwnContact={ updateOwnContact }
          />
        ) }
      />
    </tr>
  );
};

export default PermissionRow;
