import { ActionModalType, ResearchType } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ActionModalType';

export const mapTypeToDescription = {
  [ActionModalType.DELETE_POST]: 'You are going to delete this Post. Please confirm.',
  [ActionModalType.DELETE_STAT]: 'You are going to delete this Stat. Please confirm.',
  [ActionModalType.SEND_EMAIL]: 'You are going to send email alert to all subscribed users for this Post. Please confirm.',
  [ActionModalType.RESEND_EMAIL]: 'You are going to send email alert to all subscribed users for this Post. Please confirm.',
  [ActionModalType.UNPUBLISH]: 'You are going to unpublish this Post. Please confirm.',
};

export const stringsConfig = {
  [ResearchType.POST]: {
    title: 'Headline',
    author: 'Author',
    tagsTitle: 'Themes',
  },
  [ResearchType.STAT]: {
    title: 'Name',
    author: 'Author',
    tagsTitle: 'Categories',
  },
};
