import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { useCommentaryDealFlowContext } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import { GroupSelectWithToolbarField } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import useToolbarSelectSync from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/useToolbarSelectSync';
import { RegionAbbreviations } from '@/finsight/domain/Region';

const IndustriesSelect = ({ disabled }: { disabled?: boolean }) => {
    const { options, industriesOptions } = useCommentaryDealFlowContext();

    const { isFetchingIndustries, regions } = options;

    const defaultOptionValue = regions.find((region) => region.abbreviation === RegionAbbreviations.USOA)?.id;

    return (
      <Field
        name="articleParams.industries"
        label="Select industries (hyc/igc only)"
        placeholder="Select industries/subindustries"
        isLoading={ isFetchingIndustries }
        dataTest="commentaryDealFlowSelectIndustries"
        formatValue={ (option) => `${ option.regionName }: ${ option.label }` }
        { ...useToolbarSelectSync({ options: industriesOptions, defaultOptionValue, dataTest: 'commentaryDealFlowSelectIndustriesRegions' }) }
        component={ GroupSelectWithToolbarField }
        isDisabled={ disabled }
      />
    );
};

export default IndustriesSelect;
