import { Component } from 'react';
import cn from 'classnames';
import { format, differenceInSeconds, fromUnixTime } from 'date-fns';
import { Spinner, Tooltip, DataTable, dataTableStyles, Icon, IconType } from '@dealroadshow/uikit';
import * as constants from '@/condor/ui/common/constants';
import { diffTimeFormat } from './helpers/filterRoadshowFormData';
import LastUpdateTime from './components/LastUpdate';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import PageTitle from '@/condor/ui/common/PageTitle/PageTitleContainer';
import Breadcrumb from '@/condor/ui/common/Breadcrumb/BreadcrumbContainer';

import styles from './eventLogOffset.scss';
import buttonStyles from '@dealroadshow/uikit/core/components/Button/button.scss';

interface IProps {
  breadcrumbName: string,
  breadcrumbUrl: string,
  setBreadcrumb: (
    options: {
      name: string,
      url: string,
    },
  ) => void,
  removeBreadcrumb: (url: string) => void,
  list: { [key: string]: any }[],
  lastUpdateTime: Date,
  maxId: number,
  isFetching: boolean,
  getList: () => void,
  resetState: () => void,
}

class TechnicalSupport extends Component<IProps> {
  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.cellDateCallback = this.cellDateCallback.bind(this);
    this.cellSubscriberKeyCallback = this.cellSubscriberKeyCallback.bind(this);
    this.cellDiffOffsetCallback = this.cellDiffOffsetCallback.bind(this);
  }

  componentDidMount() {
    this.props.setBreadcrumb({
      name: this.props.breadcrumbName,
      url: this.props.breadcrumbUrl,
    });
    this.getList();
  }

  componentWillUnmount() {
    this.props.removeBreadcrumb(this.props.breadcrumbUrl);
    this.props.resetState();
  }

  getList() {
    this.props.getList();
  }

  cellDateCallback({ row, cellName }) {
    return (
      <div>
        { format(fromUnixTime(row[cellName]), constants.CONDOR_EVENT_LOG_DATE_TIME_FORMAT) }
      </div>
    );
  }

  cellSubscriberKeyCallback({ row, cellName }) {
    const removeNameSpace = (subscriber) => subscriber.split('\\').slice(4).join('\\');
    return (
      <div>
        { removeNameSpace(row[cellName]) }
      </div>
    );
  }

  cellDiffOffsetCallback({ row }) {
    let className = '';
    let diffId = this.props.maxId - row.currentOffset;
    const difTime = Math.abs(differenceInSeconds(fromUnixTime(row.updatedAt), this.props.lastUpdateTime));

    if (diffId > 0) {
      if (difTime > 30) {
        className = styles.errorColor;
      } else if (difTime > 10) {
        className = styles.warningColor;
      }
    }

    return (
      <div className={ className }>
        { diffId }
      </div>
    );
  }

  render() {
    const tableColumns = [
      {
        name: 'currentOffset',
        title: 'Last processed id',
      },
      {
        name: 'diff',
        title: 'Diff Offset',
        cellCallback: this.cellDiffOffsetCallback,
      },
      {
        name: 'subscriberKey',
        title: 'Subscriber Key',
        cellCallback: this.cellSubscriberKeyCallback,
      },
      {
        name: 'createdAt',
        title: 'Created At (ET)',
        cellCallback: this.cellDateCallback,
      },
      {
        name: 'working',
        title: 'Working',
        cellCallback: ({ row }) => (
          <span>{ diffTimeFormat(this.props.lastUpdateTime, fromUnixTime(row.createdAt)) }</span>
        ),
      },
      {
        name: 'updatedAt',
        title: 'Updated At (ET)',
        cellCallback: this.cellDateCallback,
      },
      {
        name: 'diffUpdatedAt',
        title: 'Last update',
        cellCallback: ({ row }) => (
          <span>{ diffTimeFormat(this.props.lastUpdateTime, fromUnixTime(row.updatedAt)) }</span>
        ),
      },
    ];
    return (
      <>
        <>
          <PageTitle />
          <Breadcrumb />
        </>
        <Tooltip
          placement="right"
          content="Refresh list"
        >
          <Button
            variant={ ButtonVariantType.success }
            disabled={ this.props.isFetching }
            onClick={ this.props.getList }
            className={ cn(buttonStyles.btnIcon, buttonStyles.btnIconRight) }
            dataTest="eventLogOffsetGetListButton"
          >
            <LastUpdateTime
              lastUpdateTime={ this.props.lastUpdateTime }
              onChange={ this.getList }
            />
            <Icon type={ IconType.refresh } />
          </Button>
        </Tooltip>
        <DataTable
          className={ cn(dataTableStyles.dataTable, dataTableStyles.isHoverable) }
          dataTest="eventLogOffsetDataTable"
          containerClassName={ styles.dataTableContainer }
          columns={ tableColumns }
          data={ this.props.list }
          loadingComponent={ Spinner }
          isFetching={ this.props.isFetching }
        />
        <div>
          { this.props.maxId } - Max id from Event Log
        </div>
      </>
    );
  }
}

export default TechnicalSupport;
