import { createContext, useContext, useEffect, useState } from 'react';
import omit from 'lodash/omit';

import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import useFetchCollection from '@/Framework/State/useFetchCollection';
import { useCreditFlowResearchListsFilters } from '@/condor/application/Finsight/CreditFlowResearch/Filters';
import { useRegionsProductsContext } from '@/condor/application/Dictionary/RegionsProducts';
import { useStatsCategoriesContext } from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatsCategories';
import { mapCategoriesProductsRegions } from './helpers';
import { ICreditFlowFilters } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';
import { IStat, IStatToDisplay } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';

const useStats = () => {
  const { listFilters } = useCreditFlowResearchListsFilters();
  const { products, regions, isInitialized: isProductRegionInitialized } = useRegionsProductsContext();
  const { categories, isInitialized: isCategoriesInitialized } = useStatsCategoriesContext();

  const [collection, setCollection] = useState<IStatToDisplay[]>([]);

  const { container } = useDIContext();
  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);

  const statsCollection = useFetchCollection<IStat, Partial<ICreditFlowFilters>>(
    creditFlowResearchRepository.getStatsList,
    { filters: listFilters },
    { sortBy: 'createdAt', sortOrder: 'desc' },
  );

  useEffect(() => {
    if (!isCategoriesInitialized || !isProductRegionInitialized || statsCollection.isFetching) {
      return;
    }

    const mappedCollection = statsCollection.collection.map((stat) => mapCategoriesProductsRegions(
      stat,
      categories,
      products,
      regions,
    ));

    setCollection(mappedCollection);
  }, [
    isCategoriesInitialized,
    isProductRegionInitialized,
    statsCollection.collection,
    statsCollection.isFetching,
  ]);

  useEffect(() => statsCollection.reset, []);

  return {
    ...omit(statsCollection, 'collection'),
    collection,
  };
};

const StatsListContext = createContext<ReturnType<typeof useStats>>(null);

export const useStatsListContext = () => {
  const context = useContext(StatsListContext);
  if (!context) {
    throw new Error('useArticlesListContext must be used within a ArticlesListContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const StatsListContextProvider = ({ children }: IProps) => (
  <StatsListContext.Provider value={ useStats() }>
    { children }
  </StatsListContext.Provider>
);

export default StatsListContextProvider;
