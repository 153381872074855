import cn from 'classnames';
import { Field, FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { Input } from '@dealroadshow/uikit';
import { useArticleProfileContext } from '@/condor/application/Finsight/CreditFlowResearch/Articles/ArticleProfile';
import ParentIssuerSelect from './ParentIssuerSelect';
import DealSelect from './DealSelect';
import ThemeSelect from './ThemeSelect';
import TextEditorField from '@/Framework/UI/Organisms/FinalForm/Fields/TextEditorField';
import UploadAttachments from './UploadAttachments';
import ArticlesProfileButtons from './ArticleProfileButtons';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import styles from './styles.scss';

const ArticleProfileFields = () => {
  const { initialFormData } = useArticleProfileContext();

  return (
    <>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbxl) }>Details</div>
      <Field
        component={ Input }
        name="headline"
        label="Headline"
        dataTest="headline"
        placeholder="Headline"
      />
      <Field
        component={ ThemeSelect }
        name="themes"
      />
      <Field
        name="description"
        component={ TextEditorField }
        label="Description"
        dataTest="description"
        placeholder="Description"
        maxCharactersLimit={ 65000 }
        isCharLimit={ false }
      />
      <div data-test="attachments" className={ styles.attachmentsFieldContainer }>
        <FieldArray
          name="attachments"
          component={ UploadAttachments }
        />
      </div>
      <div className={ cn(headerStyles.isH3, spacesStyles.mbxl) }>Related</div>
      <Field
        component={ DealSelect }
        name="deals"
      />
      <Field
        component={ ParentIssuerSelect }
        name="parentAndIssuer"
        initialFormData={ initialFormData }
      />
      <ArticlesProfileButtons />
    </>
  );
};

export default ArticleProfileFields;
