import React from 'react';
import cn from 'classnames';
import { IUpdateProgress } from '@/condor/application/EnhancedTaggingContexts/EnhancedTaggingRoadshowsContext';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import { IRowData } from '@dealroadshow/uikit/core/components/Table/DataTable';
import ProgressBar from '@dealroadshow/uikit/core/components/Loader/ProgressBar';

import styles from './bulkEditingModal.scss';

interface IProps {
  updateStatus: IUpdateProgress,
  confirm: () => void,
  collection: IRowData[],
}

const BulkEditingUpdateProgressModal = ({ updateStatus, confirm, collection }: IProps) => {
  const isThereUpdating = Boolean(updateStatus);
  const { fail, failed, success, total } = updateStatus ?? { fail: 0, failed: {}, success: 0, total: 0 };
  const processed = fail + success;
  const isFinished = processed === total;
  const isError = fail > 0;
  const isSuccess = success > 0;

  const failedRoadshows = Object.entries(failed).map(([key, value]) => {
    const collectionItem = collection.find((item) => item.id === key);

    return {
      name: collectionItem?.name ?? key,
      error: value,
    };
  });

  return (
    <Modal
      title="Bulk editing update"
      dataTest="bulkEditingProgressModal"
      isVisible={ isThereUpdating }
      closeOnEsc={ isFinished }
      hideOnOverlayClicked={ isFinished }
      showCloseButton={ isFinished }
      onCloseClicked={ confirm }
      footer={ (
        <>
          <Button
            onClick={ confirm }
            variant={ ButtonVariantType.action }
            title="Close"
            dataTest="close"
            disabled={ !isFinished }
          />
        </>
      ) }
    >
      <p className={ styles.progressTotal }>
        Processed <b>{ processed }</b> of <b>{ total }</b> { total === 1 ? 'item' : 'items' }
      </p>
      <ProgressBar
        value={ total ? Math.ceil((processed / total) * 100) : 0 }
        filledClassName={ cn(
          styles.progressBar,
          isFinished && {
            [styles.finishSuccess]: !isError,
            ...(isError && {
              [styles.finishWithError]: isSuccess,
              [styles.finishFullyErrors]: !isSuccess,
            }),
          },
        ) }
      />
      <table className={ styles.progressDetailsTable }>
        <tr>
          <th>Success:</th>
          <td>{ success }</td>
        </tr>
        <tr>
          <th>Failed:</th>
          <td>{ fail }</td>
        </tr>
      </table>
      { failedRoadshows.length > 0 && (
        <>
          <p>
            { failedRoadshows.length === 1 ? "This item wasn't" : "These items weren't" } saved due to the following
            reasons:
          </p>
          <table className={ styles.progressErrorsTable }>
            { failedRoadshows.map(({ error, name }) => (
              <tr key={ name }>
                <th>{ name }</th>
                <td>{ error }</td>
              </tr>
            )) }
          </table>
        </>
      ) }
    </Modal>
  );
};

export default BulkEditingUpdateProgressModal;
