import { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';

const useStatsCategories = () => {
  const { container } = useDIContext();

  const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
  const [isInitialized, setIsInitialized] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const loadCategories = async () => {
    try {
      const categories = await creditFlowResearchRepository.getStatsCategories();
      setIsInitialized(true);
      setCategories(categories);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  return { categories, isInitialized };
};

type StatsCategoriesContextType = ReturnType<typeof useStatsCategories>;

const StatsCategoriesContext = createContext<StatsCategoriesContextType>(null);

export const useStatsCategoriesContext = () => {
  const context = useContext(StatsCategoriesContext);

  if (!context) {
    throw new Error('useStatsCategoriesContext must be used within a StatsCategoriesContextProvider');
  }

  return context;
};

const StatsCategoriesContextProvider = ({ children }) => (
  <StatsCategoriesContext.Provider value={ useStatsCategories() }>
    { children }
  </StatsCategoriesContext.Provider>
);

export default StatsCategoriesContextProvider;
