import React from 'react';
import cn from 'classnames';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import interactionStyles from '@dealroadshow/uikit/core/styles/helpers/interaction.scss';

interface IProps {
  match: any,
  assetClassCollection: any[],
  handleAssetClassClick: (assetClass: any) => void,
  isCollectionsFetching: boolean,
}

const AssetClasses = (
  {
    handleAssetClassClick,
    assetClassCollection,
    match,
    isCollectionsFetching,
  }: IProps,
) => {
  if (assetClassCollection.length) {
    return assetClassCollection
      .filter((assetClass) => assetClass.active)
      .map((assetClass) => {
        if ((assetClass.totalBwic > 0 || assetClass.totalInventory > 0) && !isCollectionsFetching) {
          return (
            <a
              onClick={ () => handleAssetClassClick(assetClass) }
              key={ assetClass.id }
              className={ cn({
                [pageStyles.isActiveNavLink]: match.params.assetClass === toSlug(assetClass.abbreviation),
              }) }
            >
              <span>{ assetClass.name }</span>
            </a>
          );
        }
        return (
          <a
            key={ assetClass.id }
            className={ cn(pageStyles.isDisabledNavLink, interactionStyles.cursorDefault) }
          >
            { assetClass.name }
          </a>
        );
      });
  }

  return null;
};

export default AssetClasses;
