import { createContext, useContext, useEffect, useState } from 'react';
import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';
import ProductRepository from '@/finsight/infrastructure/repository/ProductRepository';
import RegionRepository from '@/finsight/infrastructure/repository/RegionRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

const useRegionsProducts = () => {
  const { container } = useDIContext();

  const productRepository = container.get<ProductRepository>(ProductRepository);
  const regionRepository = container.get<RegionRepository>(RegionRepository);

  const [regions, setRegions] = useState<IRegion[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const loadRegionsProducts = async () => {
    try {
      const [regions, products] = await Promise.all([
        regionRepository.getList(),
        productRepository.getList(),
      ]);
      setIsInitialized(true);
      setRegions(regions);
      setProducts(products);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (regions.length || products.length) return;
    loadRegionsProducts();
  }, [regions.length, products.length]);

  return { regions, products, isInitialized };
};

type RegionsProductsContextType = ReturnType<typeof useRegionsProducts>;

const RegionsProductsContext = createContext<RegionsProductsContextType>(null);

export const useRegionsProductsContext = () => {
  const context = useContext(RegionsProductsContext);

  if (!context) {
    throw new Error('useRegionsProductsContext must be used within a RegionsProductsContextProvider');
  }

  return context;
};

export const RegionsProductsContextProvider = ({ children }) => (
  <RegionsProductsContext.Provider value={ useRegionsProducts() }>
    { children }
  </RegionsProductsContext.Provider>
);
