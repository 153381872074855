import UpdatedAtCell from './UpdatedAtCell';
import SectorIndustryLabel from '@/condor/ui/components/Finsight/CreditFlowResearch/common/SectorIndustryLabel';
import config from '@/Framework/config';
import finsightUrl from '@/finsight/Router/urlConstants';
import { Link } from '@/Framework/Router/Next/Link';

import styles from './styles.scss';

const getArticleUrl = (viewId: string) => (
  `${ config.protocol }//${ config.globalDomain }${ finsightUrl.CREDIT_FLOW_ARTICLE }-${ viewId }`
);

export const sectorIndustryCellCallback = ({ cellData, row }) => (
  <SectorIndustryLabel sectorOrIndustry={ cellData } subsectorOrSubindustry={ row.subsectorOrSubindustry } />
);

export const headlineCellCallback = ({ cellData, row }) => {
  if (!row.isActive) {
    return (
      <span className={ styles.headline }>{ cellData || '-' }</span>
    );
  }
  return (
    <Link
      to={ getArticleUrl(row.viewId) }
      className={ styles.headline }
      target="_blank"
    >
      { cellData || '-' }
    </Link>
  );
};

export const updatedAtCellCallback = (props) => (
  <UpdatedAtCell { ...props } />
);
