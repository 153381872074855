import cn from 'classnames';
import React, { memo } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip } from '@dealroadshow/uikit';
import {
  CONDOR_CREATE_ACCOUNT_BASE_URL,
  CONDOR_ACCOUNT_PROFILE_BASE_URL,
  CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL,
  CONDOR_ACCOUNT_PROFILE_SSO_BASE_URL,
} from '@/condor/ui/common/constants';
import { getUserPermissionsState } from '@/users/application/actions/permissions/selectors';
import { IAccountProfileItem } from '@/condor/domain/vo/Accounts/AccountProfileItem';
import { getIsAccountRestrictedToEdit } from '@/condor/ui/components/AccountsAndContacts/Accounts/helpers';
import { RestrictedEditAccountTooltip } from '@/condor/ui/components/AccountsAndContacts/Accounts/constants';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Route from '@/Framework/Router/ReactRouter/Route';
import AccountProfileForm from './AccountProfileForm';
import AccountMappings from './AccountMappings';
import MappingsModal from './AccountMappings/MappingsModal';
import MappingsProgressModal from './AccountMappings/MappingsProgressModal';
import AccountSSO from './AccountSSO';

import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import styles from './accountProfile.scss';

interface IPropTypes {
  item: IAccountProfileItem,
  init: () => void,
  reset: () => void,
  isMappingsModalVisible: boolean,
  isMappingsProgressModalVisible: boolean,
  openMappingsModal: () => void,
  isRemapAllBtnDisabled: boolean,
  isCreating: boolean,
}

const AccountProfile = ({
  item,
  init,
  reset,
  isMappingsModalVisible,
  openMappingsModal,
  isRemapAllBtnDisabled,
  isMappingsProgressModalVisible,
  isCreating,
}: IPropTypes) => {
  const isMappingsPage = window.location.pathname.includes('mappings');

  const { currentUserPermissions } = useSelector(getUserPermissionsState);

  const isSSOTabAvailable = currentUserPermissions.length > 0
      && currentUserPermissions.find((permission) => permission.moduleKey === 'user_permissions');

  const isAccountRestrictedToEdit = getIsAccountRestrictedToEdit(item, currentUserPermissions);

  return (
    <PageWrapper
      label={ isCreating ? 'Create Account' : item.name }
      onMount={ init }
      onUnmount={ reset }
      contentCls={ isMappingsPage ? styles.mappingsPage : '' }
      headerComponent={
        !isCreating && (
          <div className={ cn(styles.accountProfileHeader) }>
            <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
              <NavLink
                activeClassName={ pageStyles.isActiveNavLink }
                to={ `${ CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ item.id }` }
                dataTest="profileTab"
              >
                Profile
              </NavLink>
              <NavLink
                activeClassName={ pageStyles.isActiveNavLink }
                to={ `${ CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/${ item.id }` }
                dataTest="mappingsTab"
              >
                Mappings
              </NavLink>
              { isSSOTabAvailable && (
                <NavLink
                  activeClassName={ pageStyles.isActiveNavLink }
                  to={ `${ CONDOR_ACCOUNT_PROFILE_SSO_BASE_URL }/${ item.id }` }
                  dataTest="ssoTab"
                >
                  SSO
                </NavLink>
              ) }
            </div>
            { isMappingsPage && (
              <Tooltip content={ RestrictedEditAccountTooltip } disabled={ !isAccountRestrictedToEdit }>
                <Button
                  disabled={ isRemapAllBtnDisabled || isAccountRestrictedToEdit }
                  variant={ ButtonVariantType.action }
                  onClick={ openMappingsModal }
                  dataTest="mappingAllBtn"
                >
                  Re-Map All to Other Account
                </Button>
              </Tooltip>
            ) }
          </div>
        )
      }
    >
      <Switch>
        <Route
          path={ [
            CONDOR_CREATE_ACCOUNT_BASE_URL,
            `${ CONDOR_ACCOUNT_PROFILE_BASE_URL }/${ item.id }`,
          ] }
          component={ AccountProfileForm }
        />
        <Route
          path={ `${ CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL }/${ item.id }` }
          component={ AccountMappings }
        />
        <Route
          path={ `${ CONDOR_ACCOUNT_PROFILE_SSO_BASE_URL }/${ item.id }` }
          component={ AccountSSO }
        />
      </Switch>
      { isMappingsModalVisible && (
        <MappingsModal />
      ) }
      { isMappingsProgressModalVisible && (
        <MappingsProgressModal />
      ) }
    </PageWrapper>
  );
};

export default memo(AccountProfile);
