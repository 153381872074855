import { injectable, inject } from 'inversify';
import FinsightRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { IGroupThemeData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ThemesData';
import {
  IArticlePayload,
  IArticleUpdatePayload,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';
import { IDealPayload, IDealResponse, IDealInfoPayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Deal';
import { IArticle, ISingleArticle } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import {
  IPreviewData,
  IPreviewRequestPayload,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/PreviewData';
import { ICreditFlowFilters } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticlesFilters';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';
import { IGroupCategoryData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IGroupCategory';
import { IStatPayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStatPayload';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import { IStat } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IStat';

@injectable()
class CreditFlowResearchRepository {
  constructor(@inject(FinsightRpcDispatcher) protected readonly finsightRpc: typeof FinsightRpcDispatcher) {
  }

  // Articles
  getGroupThemesData = async(): Promise<IGroupThemeData[]> => {
    const request = new Request('finsight_web.research.theme.get_all', { isGrouped: true });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  createArticle = async(payload: IArticlePayload): Promise<null> => {
    const request = new Request('finsight_web.research.article.create', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getArticleCategories = async (payload: IPreviewRequestPayload): Promise<IPreviewData> => {
    const request = new Request('finsight_web.research.article.get_categories', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  updateArticle = async(payload: IArticleUpdatePayload): Promise<null> => {
    const request = new Request('finsight_web.research.article.update', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDeals = async(payload: IDealPayload): Promise<IDealResponse> => {
    const request = new Request('finsight_web.research.article.search_deals', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getArticlesList = async (payload: ICreditFlowFilters): Promise<{
    collection: IArticle[],
    totalCount: number,
  }> => {
    let request = new Request('finsight_web.research.article.get_all', payload);
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticlesThemes = async (): Promise<ITheme[]> => {
    let request = new Request('finsight_web.research.theme.get_all', {});
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  deleteArticleById = async (id: string): Promise<void> => {
    let request = new Request('finsight_web.research.article.delete', { id });
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  sendEmailNotification = async (id: string): Promise<void> => {
    let request = new Request('finsight_web.research.article.request_mailing', { id });
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getArticleById = async (id: string): Promise<ISingleArticle> => {
    const request = new Request('finsight_web.research.article.get_by_id', { id });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  unpublishArticleById = async (id: string): Promise<ISingleArticle> => {
    const request = new Request('finsight_web.research.article.unpublish', { id });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getDealInfoList = async (payload: IDealInfoPayload): Promise<IDealResponse> => {
    const request = new Request('finsight_web.deal_transaction.deal.get_info', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  // Stats
  getStatsCategories = async (payload = {}): Promise<ICategory[]> => {
    const request = new Request('finsight_web.research.stat_category.get_all', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getStatsList = async (payload: ICreditFlowFilters): Promise<{
    collection: IStat[],
    totalCount: number,
  }> => {
    let request = new Request('finsight_web.research.stat.get_all', payload);
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  deleteStatById = async (id: string): Promise<void> => {
    let request = new Request('finsight_web.research.stat.delete', { id });
    let response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getAttachmentDownloadUrl = async (
    { uploadId, displayName }: { uploadId: string, displayName: string },
  ): Promise<{ url: string }> => {
    const request = new Request('finsight_web.attachment.get_downloading_url', { uploadId, displayName });
    const response = await this.finsightRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getStatCategories = async (isGrouped?: boolean): Promise<IGroupCategoryData[] | ICategory[]> => {
    const request = new Request('finsight_web.research.stat_category.get_all', { isGrouped });
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  createStat = async (payload: IStatPayload): Promise<null> => {
    const request = new Request('finsight_web.research.stat.create', payload);
    const response = await this.finsightRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default CreditFlowResearchRepository;
