import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import {
  CategoriesContextProvider,
  useCategoriesContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/CategoriesContext';
import GroupSelectWithToolbar, { useToolbarSelectSync } from '@/finsight/ui/common/components/GroupSelectWithToolbar';
import { USOA_REGION_ID } from '@/finsight/domain/Region';

const CategoriesSelect = ({
  input,
  ...otherProps
}: IFinalFormFieldInputComponentProps) => {
  const { categoriesOptions } = useCategoriesContext();

  return (
    <GroupSelectWithToolbar
      { ...otherProps }
      name={ input.name }
      value={ input.value }
      onBlur={ input.onBlur }
      onChange={ input.onChange }
      label="Categories"
      dataTest="categories"
      placeholder="Select categories"
      { ...useToolbarSelectSync({
        options: categoriesOptions,
        defaultOptionValue: USOA_REGION_ID,
        dataTest: 'categoriesFilters',
      }) }
      formatValue={ (option) => `${ option.regionName } ${ option.label }` }
    />
  );
};

export default (props: IFinalFormFieldInputComponentProps) => (
  <CategoriesContextProvider>
    <CategoriesSelect { ...props } />
  </CategoriesContextProvider>
);
