import React from 'react';
import MapTradeCell from './CellItems/MapTradeCell';
import CusipCell from './CellItems/CusipCell';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

enum CusipType {
  Cusip144 = 'cusips',
  CusipRegS = 'cusipsRegs',
}

const columns = [
  {
    name: 'class',
    title: 'CLASS',
    width: 113,
    className: alignStyles.alignLeft,
  },
  {
    name: CusipType.Cusip144,
    title: '144A CUSIP',
    className: alignStyles.alignLeft,
    cellCallback: (cellProps) => <CusipCell { ...cellProps } cusipType={ CusipType.Cusip144 } />,
  },
  {
    name: CusipType.CusipRegS,
    title: 'REG S CUSIP',
    className: alignStyles.alignLeft,
    cellCallback: (cellProps) => <CusipCell { ...cellProps } cusipType={ CusipType.CusipRegS } />,
  },
  {
    name: 'date',
    title: 'PRICING DATE',
    className: alignStyles.alignRight,
    cellCallback: (cellProps) => <MapTradeCell { ...cellProps } />,
  },
];

export default columns;
