import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import type { IBaseSubscriptionFormProps } from '@/finsight/ui/components/SubscriptionForm/types';
import styles from '@/finsight/ui/components/SubscriptionForm/Base/styles.scss';
import commonStyles from '@/finsight/ui/components/SubscriptionForm/styles.scss';

interface IFooterButtonsProps {
  submitButtonTitle: string,
  invalid: boolean,
  pristine: boolean,
  isSubmitting: boolean,
  onCancel: IBaseSubscriptionFormProps['onCancel'],
}

const FooterButtons = ({ submitButtonTitle, invalid, pristine, isSubmitting, onCancel }: IFooterButtonsProps) => (
  <div className={ styles.footer }>
    <Button
      type="submit"
      title={ submitButtonTitle }
      variant={ ButtonVariantType.action }
      disabled={ invalid || pristine || isSubmitting }
      dataTest="submitButton"
    />
    <Button
      type="button"
      title="Cancel"
      dataTest="cancelButton"
      variant={ ButtonVariantType.link }
      onClick={ onCancel }
      className={ commonStyles.cancelBtn }
      disabled={ isSubmitting }
    />
  </div>
);

export default FooterButtons;
