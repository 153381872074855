import moveElementInArray from '@/Framework/dataHelpers/array/moveElementInArray';
import * as actionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import * as uploadTypes from '@/Framework/UI/Organisms/Upload/uploadTypes';

const initialStateSlideType = {
  slide: '',
  preview: {
    url: '',
  },
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
  canSave: false,
};

const initialState = {
  sort: [],
  files: {},
};

// eslint-disable-next-line complexity
export default function presentation(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_PRESENTATION_SLIDE_ADD:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.uuidOriginal]: {
            ...state.files[action.payload.uuidOriginal],
            types: {
              ...state.files[action.payload.uuidOriginal].types,
              [action.payload.slideType]: {
                ...initialStateSlideType,
                process: {
                  progress: 0,
                  status: uploadTypes.UPLOAD_START,
                  uuid: action.payload.uuid,
                },
                uploadFile: action.payload.uploadFile,
                callback: action.payload.callback,
                name: action.payload.uploadFile.name,
              },
            },
          },
        },
      };

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_CHUNK_SUCCESS:
      if (
        state.files[action.payload.uuidOriginal] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType].process.uuid === action.payload.uuid
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...state.files[action.payload.uuidOriginal],
              types: {
                ...state.files[action.payload.uuidOriginal].types,
                [action.payload.slideType]: {
                  ...state.files[action.payload.uuidOriginal].types[action.payload.slideType],
                  process: {
                    ...state.files[action.payload.uuidOriginal].types[action.payload.slideType].process,
                    progress: parseInt(action.payload.progress),
                    status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
                  },
                },
              },
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_SUCCESS:
      if (
        state.files[action.payload.uuidOriginal] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType].process.uuid === action.payload.uuid
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...state.files[action.payload.uuidOriginal],
              types: {
                ...state.files[action.payload.uuidOriginal].types,
                [action.payload.slideType]: {
                  ...state.files[action.payload.uuidOriginal].types[action.payload.slideType],
                  process: {
                    ...state.files[action.payload.uuidOriginal].types[action.payload.slideType].process,
                    status: uploadTypes.UPLOAD_SUCCESS,
                  },
                  uploaded: true,
                },
              },
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_ERROR:
      if (
        state.files[action.payload.uuidOriginal] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType].process.uuid === action.payload.uuid
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...state.files[action.payload.uuidOriginal],
              types: {
                ...state.files[action.payload.uuidOriginal].types,
                [action.payload.slideType]: {
                  ...state.files[action.payload.uuidOriginal].types[action.payload.slideType],
                  process: {
                    ...state.files[action.payload.uuidOriginal].types[action.payload.slideType].process,
                    status: uploadTypes.UPLOAD_ERROR,
                  },
                  uploaded: false,
                  canSave: false,
                },
              },
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_PROCESSING:
      let fileState = state.files[action.payload.uuidOriginal];
      if (
        fileState &&
        fileState.types[action.payload.slideType] &&
        fileState.types[action.payload.slideType].process.uuid === action.payload.uuid &&
        fileState.types[action.payload.slideType].process.status !== uploadTypes.UPLOAD_DONE
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...fileState,
              types: {
                ...fileState.types,
                [action.payload.slideType]: {
                  ...fileState.types[action.payload.slideType],
                  process: {
                    ...fileState.types[action.payload.slideType].process,
                    progress: 0,
                    status: uploadTypes.UPLOAD_PROCESSING_START,
                  },
                },
              },
            },
          },
        };
      }
      return state;
    case actionTypes.UPLOAD_PRESENTATION_SLIDE_DONE:
      if (
        state.files[action.payload.uuidOriginal] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType].process.uuid === action.payload.uuid
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...state.files[action.payload.uuidOriginal],
              types: {
                ...state.files[action.payload.uuidOriginal].types,
                [action.payload.slideType]: {
                  ...state.files[action.payload.uuidOriginal].types[action.payload.slideType],
                  process: {
                    ...state.files[action.payload.uuidOriginal].types[action.payload.slideType].process,
                    progress: 100,
                    status: uploadTypes.UPLOAD_DONE,
                  },
                  slide: `/${ action.payload.data[0] }`,
                  preview: {
                    url: `/${ action.payload.data[0].replace('/', '/preview/') }`,
                  },
                  uploaded: true,
                  canSave: true,
                },
              },
            },
          },
        };
      }

      return state;

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_CANCEL_SUCCESS:

      if (
        state.files[action.payload.uuidOriginal] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType] &&
        state.files[action.payload.uuidOriginal].types[action.payload.slideType].process.uuid === action.payload.uuid
      ) {
        let slide = state.files[action.payload.uuidOriginal].initialFile.urls[action.payload.slideType];
        let revertSlide = null;
        if (slide) {
          revertSlide = {
            slide,
            preview: {
              url: slide.replace('/slideshow', '/preview/slideshow'),
            },
            process: {
              progress: 100,
              status: uploadTypes.UPLOAD_DONE,
              uuid: state.files[action.payload.uuidOriginal].initialFile.id,
            },
            uploadFile: null,
            callback: null,
            name: '',
            uploaded: true,
            canSave: true,
          };
        } else {
          revertSlide = {
            ...initialStateSlideType,
            uploaded: true,
            canSave: true,
          };
        }
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.uuidOriginal]: {
              ...state.files[action.payload.uuidOriginal],
              types: {
                ...state.files[action.payload.uuidOriginal].types,
                [action.payload.slideType]: revertSlide,
              },
            },
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_INIT:
      let files = {};
      let sort = [];
      action.payload.slides.forEach((slide) => {
        let types = {};
        action.payload.slideType.forEach((type) => {
          if (slide.urls[type.value]) {
            types[type.value] = {
              slide: slide.urls[type.value],
              preview: {
                url: slide.urls[type.value].replace('/slideshow', '/preview/slideshow'),
              },
              process: {
                progress: 100,
                status: uploadTypes.UPLOAD_DONE,
                uuid: slide.id,
              },
              uploadFile: null,
              callback: null,
              name: '',
              uploaded: true,
              canSave: true,
            };
          } else {
            types[type.value] = {
              ...initialStateSlideType,
              uploaded: true,
              canSave: true,
            };
          }
        });
        files[slide.id] = {
          initialFile: {
            ...slide,
          },
          types: {
            ...types,
          },
        };
        sort.push({ uuid: slide.id });
      });
      return {
        files,
        sort,
      };

    case actionTypes.UPLOAD_PRESENTATION_SLIDE_ORDER:
      const newSortOrder = moveElementInArray(state.sort.slice(), action.payload.oldIndex, action.payload.newIndex);
      return {
        ...state,
        sort: newSortOrder,
      };
    default:
      return state;
  }
}
