import { groupValidators, applyValidatorToMultipleFields } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateCheckboxWithFilters } from '@/Framework/UI/Organisms/FinalForm/validators/validateCheckboxWithFilters';
import { getFieldList } from '@/finsight/ui/components/SubscriptionForm/validate';

const validateCreatableFields = (requiredFields, values) => {
  let errors = {};
  requiredFields.forEach((field) => {
    if (!values[field.fieldName]?.length) {
      errors[field.fieldName] = `${ field.fieldCaption || 'Field' } ${ (field.isPlural && field.fieldCaption) ? 'are' : 'is' } required`;
    }
  });
  return errors;
};

const validateAtLeastOneSelected = (fields, values) => {
  let isSelected = false;
  fields.forEach((field) => {
    if (values[field.fieldName]?.checked && values[field.fieldName]?.filters?.length) {
      isSelected = true;
    }
  });
  return isSelected ? null : { globalError: 'Select at least one subscription' };
};

export default (values, data) => groupValidators(
  applyValidatorToMultipleFields({
    values,
    fields: getFieldList(data),
    validator: validateCheckboxWithFilters,
  }),
  validateAtLeastOneSelected(getFieldList(data), values),
  validateCreatableFields([
    { fieldName: 'subscribersListSelect', fieldCaption: 'Email' },
  ], values),
);
