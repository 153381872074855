import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { Icon, IconType } from '@dealroadshow/uikit';

import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import styles from './breadcrumb.scss';

interface IProps {
  breadcrumb: any[],
  setPageTitle: (name: string) => void,
}

class Breadcrumb extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumb: [],
    };
  }

  /**
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.breadcrumb, prevProps.breadcrumb)) {
      this.props.setPageTitle(this.props.breadcrumb[this.props.breadcrumb.length - 1].name);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        breadcrumb: this.props.breadcrumb,
      });
    }
  }

  render() {
    return (
      <div className={ styles.breadcrumb }>
        <ul>
          { this.state.breadcrumb.map((page, index) => {
            const lastPage = (this.state.breadcrumb.length - 1) === index;
            return ([
              <li key={ page.url }>
                { !lastPage && (
                  <Link
                    className={ linkStyles.link }
                    to={ page.url }
                  >
                    { page.name }
                  </Link>
                ) }
                { lastPage && (
                  <span>
                    { page.name }
                  </span>
                ) }
              </li>,
              !lastPage && (
                // eslint-disable-next-line react/no-array-index-key
                <li key={ index }>
                  <Icon
                    type={ IconType.arrowRight }
                    className={ styles.arrowIcon }
                  />
                </li>
              ),
            ]);
          }) }
        </ul>
      </div>
    );
  }
}

export default Breadcrumb;
