import ProfileWrapper from '@/condor/ui/common/Layout/ProfileWrapper';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { StatFormData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/StatFormData';
import { CONDOR_FINSIGHT_CREDIT_FLOW_STAT_PROFILE_FORM_NAME } from '@/condor/ui/common/constants';
import {
  useStatProfileContext,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';
import StatProfileFields from './StatProfileFields';
import validate from './validate';

const StatProfile = () => {
  const { initialFormData, createOrUpdateState } = useStatProfileContext();

  return (
    <ProfileWrapper>
      <FinalForm<StatFormData>
        name={ CONDOR_FINSIGHT_CREDIT_FLOW_STAT_PROFILE_FORM_NAME }
        dataTest={ CONDOR_FINSIGHT_CREDIT_FLOW_STAT_PROFILE_FORM_NAME }
        render={ () => <StatProfileFields /> }
        onSubmit={ createOrUpdateState }
        validate={ validate }
        initialValues={ initialFormData }
      />
    </ProfileWrapper>
  );
};

export default StatProfile;
