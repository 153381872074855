import { useCallback, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { ICategory } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ICategory';
import { IGroupCategoryData } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Stats/IGroupCategory';
import CreditFlowResearchRepository
  from '@/condor/infrastructure/repository/creditFlowResearch/CreditFlowResearchRepository';

export const useCategoriesList = () => {
  const [collection, setCollection] = useState<IGroupCategoryData[] | ICategory[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { container } = useDIContext();

  const getCategoriesList = useCallback(async (isGrouped?: boolean) => {
    setIsFetching(true);

    try {
      const creditFlowResearchRepository = container.get<CreditFlowResearchRepository>(CreditFlowResearchRepository);
      const categories = await creditFlowResearchRepository.getStatCategories(isGrouped);
      setCollection(categories);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    collection,
    getCategoriesList,
  };
};
