import { inject, injectable } from 'inversify';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import { getUnixTimestampInMilliseconds } from '@/Framework/DateTime/getUnixTimestamp';

@injectable()
class SlideshowRepository {
  // @ts-ignore
  constructor(@inject(UploadDispatcher) private uploadDispatcher: typeof UploadDispatcher) {}

  /**
   * Upload roadshow slideshow to backend
   */
  upload(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_SLIDESHOW' });
  }

  /**
   * Upload roadshow slide to backend
   */
  uploadSlide(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_SLIDE' });
  }

  /**
   * Get slide svg content
   */
  async getSlide(slideUrl: string, addTimestamp = false) {
    let slideContent;

    let requestParams = {
      credentials: 'include' as RequestCredentials,
      headers: {
        'X-Content-Transfer-Encoding': 'base64',
      },
    };
    let timestamp = '';

    if (addTimestamp) {
      timestamp = `?t=${ getUnixTimestampInMilliseconds() }`;
    }

    let response = await fetch(`${ slideUrl }${ timestamp }`, requestParams);

    if (response.status >= 400) {
      throw response;
    }

    slideContent = await response.text();

    return slideContent;
  }
}

export default SlideshowRepository;
