import { Field } from '@/Framework/UI/Organisms/FinalForm';
import CheckboxWithFilters from '@/finsight/ui/common/components/CheckboxWithFilters';
import type { ISubscriptionsDataSubgroup } from '@/finsight/domain/vo/Subscriptions';
import type { SubscriptionsSubGroup } from '@/condor/domain/vo/Finsight/Subscriptions/SubscriptionsData';

export interface ISubGroupFieldProps {
  subgroup: ISubscriptionsDataSubgroup | SubscriptionsSubGroup,
  isDisabled?: boolean,
}

const SubGroupField = ({ subgroup, isDisabled = false }: ISubGroupFieldProps) => (
  <Field
    name={ subgroup.fieldName }
    label={ subgroup.subgroupName || subgroup.name }
    isDisabled={ isDisabled }
    component={ CheckboxWithFilters }
    filterSet={ {
        key: subgroup.subgroupName || subgroup.name,
        items: subgroup.subscriptions,
      } }
  />
);

export default SubGroupField;
