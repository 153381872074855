import type { ICommentaryDealFlowContextProps } from '@/finsight/application/Subscriptions/CommentaryDealFlow/CommentaryDealFlowContext';
import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';

interface IBaseOption {
  value: string,
  label: string,
  [key: string]: any,
}

interface ILookupMap {
  [key: string]: IBaseOption,
}

interface ITransformConfig {
  getKey: (item: string | ICompanyItem | ISectorItem | IIndustryItem | IThemeItem) => string,
  createItem: (item: string | ICompanyItem | ISectorItem | IIndustryItem | IThemeItem, match: IBaseOption) => IOption,
}

interface IArticleParams {
  countryIds?: string[],
  companies?: Array<{ companyId: string, companyType: 'parent' | 'issuer' }>,
  sectors?: Array<{ regionId: string, subSectorId: string }>,
  industries?: Array<{ regionId: string, subIndustryId: string }>,
  themes?: Array<{ regionId: string, productId: string, themeId: string }>,
  [key: string]: any,
}

export interface IUpdatedFormParams extends IArticleParams {}

interface ICompanyItem {
  companyId: string,
  companyType: string,
}

interface ISectorItem {
  regionId: string,
  subSectorId: string,
}

interface IIndustryItem {
  regionId: string,
  subIndustryId: string,
}

interface IThemeItem {
  regionId: string,
  productId: string,
  themeId: string,
}

const createLookupMap = (options: IBaseOption[], key = 'value'): ILookupMap => {
  return options.reduce<ILookupMap>((acc, option) => ({
    ...acc,
    [option[key]]: option,
  }), {});
};

const createNestedLookupMap = (
  groups: any[],
  getOptions: (group: any) => IBaseOption[],
  keyFn: (option: IBaseOption) => string,
): ILookupMap => {
  return groups.reduce<ILookupMap>((acc, group) => {
    const groupOptions = getOptions(group).reduce<ILookupMap>((optAcc, option) => ({
      ...optAcc,
      [keyFn(option)]: { ...option, groupId: group.value },
    }), {});
    return { ...acc, ...groupOptions };
  }, {});
};

const transformRegionItems = (
  items: Array<string | ICompanyItem | ISectorItem | IIndustryItem | IThemeItem>,
  lookup: ILookupMap,
  config: ITransformConfig,
) => {
  return items.reduce((acc: IOption[], item) => {
    const match = lookup[config.getKey(item)];
    if (match) acc.push(config.createItem(item, match));
    return acc;
  }, []);
};

const transformConfigs = {
  countries: {
    getKey: (id: string) => id,
    createItem: (_: any, match: IBaseOption) => ({
      value: match.value,
      label: match.label,
    }),
  },
  companies: {
    getKey: (company: any) => company.companyId,
    createItem: (company: any, match: IBaseOption) => ({
      value: match.value,
      label: match.label,
      companyType: company.companyType,
    }),
  },
  sectors: {
    getKey: (item: any) => item.subSectorId,
    createItem: (item: any, match: IBaseOption) => ({
      regionId: item.regionId,
      subSectorId: item.subSectorId,
      value: match.value,
      label: match.label,
      inputValue: match.inputValue,
      regionName: match.regionName,
    }),
  },
  industries: {
    getKey: (item: any) => item.subIndustryId,
    createItem: (item: any, match: IBaseOption) => ({
      regionId: item.regionId,
      subIndustryId: item.subIndustryId,
      value: match.value,
      label: match.label,
      inputValue: match.inputValue,
      regionName: match.regionName,
    }),
  },
  themes: {
    getKey: (item: any) => `${ item.themeId }_${ item.productId }`,
    createItem: (item: any, match: IBaseOption) => ({
      regionId: item.regionId,
      productId: item.productId,
      themeId: item.themeId,
      value: match.value,
      label: match.label,
      regionName: match.regionName,
    }),
  },
} as const;

const createAllLookupMaps = (options: {
  countriesOptions: any[],
  parentIssuerOptions: any[],
  sectorOptions: any[],
  industriesOptions: any[],
  themesOptions: any[],
}) => {
  return {
    countries: createLookupMap(options.countriesOptions),
    companies: createNestedLookupMap(
      options.parentIssuerOptions,
      (group) => group.options || [],
      (option) => option.value,
    ),
    sectors: createNestedLookupMap(
      options.sectorOptions,
      (group) => group.groups?.flatMap((g) => g.options) || [],
      (option) => option.inputValue,
    ),
    industries: createNestedLookupMap(
      options.industriesOptions,
      (group) => group.groups?.flatMap((g) => g.options) || [],
      (option) => option.inputValue,
    ),
    themes: createNestedLookupMap(
      options.themesOptions,
      (group) => group.groups?.flatMap((g) => g.options) || [],
      (option) => `${ option.value }_${ option.productId }`,
    ),
  };
};

export interface IInitialValues {
  articleParams?: IArticleParams,
  [key: string]: unknown,
}

export const updateInitialValues = (
  initialValues: IInitialValues,
  countriesOptions: ICommentaryDealFlowContextProps['countriesOptions'],
  parentIssuerOptions: ICommentaryDealFlowContextProps['parentIssuerOptions'],
  sectorOptions: ICommentaryDealFlowContextProps['sectorsOptions'],
  industriesOptions: ICommentaryDealFlowContextProps['industriesOptions'],
  themesOptions: ICommentaryDealFlowContextProps['themesOptions'],
): IUpdatedFormParams => {
  if (!initialValues?.articleParams) return initialValues || {};

  const lookups = createAllLookupMaps({
    countriesOptions,
    parentIssuerOptions,
    sectorOptions,
    industriesOptions,
    themesOptions,
  });

  const transformParams: Record<keyof IArticleParams, any> = {
    countryIds: initialValues.articleParams.countryIds?.length && transformRegionItems(
      initialValues.articleParams.countryIds,
      lookups.countries,
      transformConfigs.countries,
    ),
    companies: initialValues.articleParams.companies?.length && transformRegionItems(
      initialValues.articleParams.companies,
      lookups.companies,
      transformConfigs.companies,
    ),
    sectors: initialValues.articleParams.sectors?.length && transformRegionItems(
      initialValues.articleParams.sectors,
      lookups.sectors,
      transformConfigs.sectors,
    ),
    industries: initialValues.articleParams.industries?.length && transformRegionItems(
      initialValues.articleParams.industries,
      lookups.industries,
      transformConfigs.industries,
    ),
    themes: initialValues.articleParams.themes?.length && transformRegionItems(
      initialValues.articleParams.themes,
      lookups.themes,
      transformConfigs.themes,
    ),
  };

  return {
    ...initialValues,
    articleParams: {
      ...initialValues.articleParams,
      ...Object.fromEntries(
        Object.entries(transformParams).filter(([, value]) => value),
      ),
    },
  };
};

export const updateToInitialValues = (data: Record<string, any[]>) => {
  const mapValues = (key: string, idKey: string) => {
    return data[key]?.map((item) => {
      const mappedItem: Record<string, string | number> = {
        [idKey]: item.inputValue || item.value,
      };
      if (item.regionId) mappedItem.regionId = item.regionId;
      if (item.productId) mappedItem.productId = item.productId;
      if (item.companyType) mappedItem.companyType = item.companyType;
      return mappedItem;
    }) || [];
  };

  return {
    countryIds: data.countryIds?.map((country) => country.value) || [],
    sectors: mapValues('sectors', 'subSectorId'),
    themes: mapValues('themes', 'themeId'),
    companies: mapValues('companies', 'companyId'),
    industries: mapValues('industries', 'subIndustryId'),
  };
};
