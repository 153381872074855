import React, { memo } from 'react';
import cn from 'classnames';
import { StatusCell, SectorCell, AgingCell } from '@/finsight/ui/common/components/TableCells';
import { BodyCellPure } from '@dealroadshow/uikit';
import {
  getCurrentFaceCell,
  getDealerCell,
  getOriginalFaceCell,
  getWalCell,
  getPriceCell,
  getAddedAtCell,
} from '@/finsight/ui/components/bonds/common/BondTables/cellCallbacks';
import { getBondNameCell, getDeleteCell } from '../cellCallbacks';

import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';
import styles from '../bondList.scss';

interface IProps {
  row: any,
  rowIndex: number,
  changeDeleteBondModalVisible: (visible: boolean) => void,
  setSelectedBondIdAndName: (payload: { id: string, name: string }) => void,
}

const InventoryRow = (props: IProps) => (
  <tr className={ styles.row }>
    <BodyCellPure
      name="status"
      className={ alignStyles.alignCenter }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ StatusCell }
    />
    <BodyCellPure
      name="sector"
      className={ alignStyles.alignLeft }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ SectorCell }
    />
    <BodyCellPure
      name="name"
      className={ alignStyles.alignLeft }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getBondNameCell }
    />
    <BodyCellPure
      name="originalFace"
      className={ alignStyles.alignCenter }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getOriginalFaceCell }
    />
    <BodyCellPure
      name="currentFace"
      className={ alignStyles.alignCenter }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getCurrentFaceCell }
    />
    <BodyCellPure
      name="wal"
      className={ alignStyles.alignLeft }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getWalCell }
    />
    <BodyCellPure
      name="price"
      className={ alignStyles.alignLeft }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getPriceCell }
    />
    <BodyCellPure
      name="dealer"
      className={ alignStyles.alignLeft }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getDealerCell }
    />
    <BodyCellPure
      name="aging"
      className={ alignStyles.alignRight }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ AgingCell }
    />
    <BodyCellPure
      name="addedAt"
      className={ alignStyles.alignRight }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      cellCallback={ getAddedAtCell }
    />
    <BodyCellPure
      name="deleteIcon"
      className={ cn(styles.deleteIcon, alignStyles.alignRight) }
      rowIndex={ props.rowIndex }
      rowData={ props.row }
      // @ts-ignore
      onClick={ () => {
        props.changeDeleteBondModalVisible(true);
        props.setSelectedBondIdAndName({
          id: props.row.id,
          name: props.row.name,
        });
      } }
      cellCallback={ getDeleteCell }
    />
  </tr>
);

export default memo(InventoryRow);
