import { connect } from 'react-redux';

import selectors from '@/condor/application/actions/accounts/mappings/emailDomains/selectors';
import {
  itemsPerPage,
  getMappingsEmailDomainsByFilter,
  paginate,
  sort,
  resetMappingsEmailDomainsCollection,
} from '@/condor/application/actions/accounts/mappings/emailDomains/actions';

import BaseMappingsPanel from '../BaseMappingsPanel';
import { domainCellCallback } from '../cellCallbacks/domainCellCallback';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const columns = [
  {
    name: 'domain',
    title: 'Name',
    cellCallback: domainCellCallback,
    className: alignStyles.alignLeft,
    sortable: true,
  },
];

const mapStateToProps = (state) => (
  {
    page: selectors.getPage(state),
    perPage: selectors.getPerPage(state),
    isFetching: selectors.isFetching(state),
    sortBy: selectors.getSortBy(state),
    sortOrder: selectors.getSortOrder(state),
    totalCount: selectors.getTotalCount(state),
    columns,
    dataTableDataTest: 'emailDomainsMappingsTable',
    paginatorDataTest: 'emailDomainsMappingsTablePaginator',
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    getCollection: () => {
      dispatch(getMappingsEmailDomainsByFilter());
    },
    onItemsPerPageChange: (perPage) => {
      dispatch(itemsPerPage(perPage.value));
      dispatch(getMappingsEmailDomainsByFilter());
    },
    onPageChange: (page) => {
      dispatch(paginate(page.selected));
      dispatch(getMappingsEmailDomainsByFilter());
    },
    reset: () => {
      dispatch(resetMappingsEmailDomainsCollection());
    },
    onSortChange: (sortBy, sortOrder) => {
      dispatch(sort({ sortBy, sortOrder }));
      dispatch(getMappingsEmailDomainsByFilter());
    },
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseMappingsPanel);
