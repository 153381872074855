import React from 'react';
import cn from 'classnames';
import { Input, Icon, IconType, Tooltip, dataTableStyles } from '@dealroadshow/uikit';

import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { IFinalFormFieldArrayInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from './customSectionSubfieldsTable.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const getColumns = (fields: IFinalFormFieldArrayInputComponentProps['fields']) => [
  {
    name: 'title',
    title: 'Subfield Title',
    className: dataTableStyles.inputCol,
    width: 350,
    cellCallback: ({ row }) => (
      <Field
        name={ `${ row.fieldName }.title` }
        component={ Input }
        dataTest="titleInput"
        isNarrow
      />
    ),
  },
  {
    name: 'text',
    title: 'Subfield Text',
    className: dataTableStyles.inputCol,
    width: 350,
    cellCallback: ({ row }) => (
      <Field
        name={ `${ row.fieldName }.text` }
        component={ Input }
        dataTest="textInput"
        isNarrow
      />
    ),
  },
  {
    name: 'hyperlinkText',
    title: 'Hyperlink Text',
    className: dataTableStyles.inputCol,
    width: 350,
    cellCallback: ({ row }) => (
      <Field
        name={ `${ row.fieldName }.hyperlinkText` }
        component={ Input }
        dataTest="hyperlinkTextInput"
        isNarrow
      />
    ),
  },
  {
    name: 'id',
    title: '',
    className: styles.deleteRowCell,
    cellCallback: ({ row }) => (row.fieldIndex !== 0) && (
      <Button
        className={ styles.deleteRowBtn }
        dataTest="deleteSubfieldButton"
        variant={ ButtonVariantType.text }
        onClick={ () => fields.remove(row.fieldIndex) }
      >
        <Tooltip content="Delete subfield">
          <Icon type={ IconType.trash } />
        </Tooltip>
      </Button>
    ),
  },
  {
    name: 'dragAndDrop',
    className: cn(dataTableStyles.draggableCol, alignStyles.alignCenter),
    isDraggable: true,
    title: '',
    width: 40,
    maxWidth: 40,
  },
];

export default getColumns;
