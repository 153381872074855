import React, { memo, useEffect } from 'react';
import cn from 'classnames';
import { Switch, NavLink, useLocation } from 'react-router-dom';

import {
  CONDOR_FINSIGHT_FILING_CREATE_URL,
  CONDOR_FINSIGHT_FILING_PROFILE_URL,
  CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_URL,
} from '@/condor/ui/common/constants';

import Filing from '@/finsight/domain/Filing';
import {
  getFilingProfileUrl,
  getFilingCreateUrl,
  getFilingLinkToExistingDealUrl,
} from '@/condor/ui/components/Finsight/Filings/helpers';
import { getFilingPageTitle } from './helpers';

import Route from '@/Framework/Router/ReactRouter/Route';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import { Spinner } from '@dealroadshow/uikit';
import { DealProfile } from '@/condor/ui/components/Finsight';

import floatStyles from '@dealroadshow/uikit/core/styles/helpers/floats.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';

import ExistingDealForm from './ExistingDealForm';

interface IProps {
  isLinkToExistingDeal: boolean,
  existingDeal: any,
  deal: any,
  dealId: string,
  filingId: string,
  reset: () => void,
  init: () => void,
}

const FilingProfile = (
  {
    isLinkToExistingDeal,
    existingDeal,
    deal,
    dealId,
    filingId,
    init,
    reset,
  }: IProps,
) => {
  const filingType = Filing.getFilingType(useLocation().pathname);

  useEffect(
    () => {
      init();
      return reset;
    },
    [],
  );

  // @ts-ignore
  const pageTitle = getFilingPageTitle({ filingType, deal, existingDeal, isLinkToExistingDeal });

  if (!filingId) {
    return (
      <Spinner
        isAbsolute
        overlay
      />
    );
  }

  return (
    <PageWrapper
      label={ pageTitle }
      headerComponent={ (
        <div className={ cn(pageStyles.pageNavigation, floatStyles.pullLeft, spacesStyles.mbn) }>
          <div className={ cn(pageStyles.pageNavigationLinks, spacesStyles.mts, spacesStyles.ptm) }>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={
                dealId ? getFilingProfileUrl(filingType, filingId) : getFilingCreateUrl(filingType, filingId)
              }
            >
              { dealId ? 'Edit Deal' : 'Create Deal' }
            </NavLink>
            <NavLink
              activeClassName={ pageStyles.isActiveNavLink }
              to={ getFilingLinkToExistingDealUrl(filingType, filingId) }
            >
              Link to Existing Deal
            </NavLink>
          </div>
        </div>
      ) }
    >
      <Switch>
        <Route
          path={ [
            `${ CONDOR_FINSIGHT_FILING_CREATE_URL }/:filingId`,
            `${ CONDOR_FINSIGHT_FILING_PROFILE_URL }/:filingId`,
          ] }
          render={ (props) => (
            <DealProfile
              isSecFiling
              dealId={ dealId }
              { ...props }
            />
          ) }
        />
        <Route
          path={ `${ CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_URL }/:filingId` }
          component={ ExistingDealForm }
        />
      </Switch>
    </PageWrapper>
  );
};

export default memo(FilingProfile);
