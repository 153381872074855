import { useEffect } from 'react';
import useReduxSelector from '@/Framework/hooks/useReduxSelector';
import selectors from '@/condor/application/actions/finsight/subscriptions/metadata/selectors';
import { getSubscriptionsFormInitialValues } from '@/finsight/application/actions/subscriptions/selectors';

const stateSelector = (state) => {
    try {
        return {
            initialValues: selectors.getSubscriptionsFormValues(state),
        };
    } catch (error) {
        return {
            initialValues: getSubscriptionsFormInitialValues(state),
        };
    }
};

export const useLoadMissingCompanies = ({ loadParentIssuerOptions, parentCompanies, issuerCompanies }) => {
    const { initialValues } = useReduxSelector(stateSelector);
    const companiesLength = initialValues?.articleParams?.companies.length;
    const parentCompaniesLength = parentCompanies.length;
    const issuerCompaniesLength = issuerCompanies.length;

    const shouldLoadMissingCompanies = () => {
        const companies = initialValues?.articleParams?.companies || [];

        const parentCompanyIds = new Set(parentCompanies.map((parent) => parent.id));
        const issuerCompanyIds = new Set(issuerCompanies.map((issuer) => issuer.id));

        if (parentCompanyIds.size === 0 || issuerCompanyIds.size === 0) return false;

        const hasParentMatch = companies.some((company) => company.companyType === 'parent' && parentCompanyIds.has(company.companyId));

        const hasIssuerMatch = companies.some((company) => company.companyType === 'issuer' && issuerCompanyIds.has(company.companyId));

        return !(hasParentMatch || hasIssuerMatch);
    };

    const loadMissingCompanies = async () => {
        const includedIds = initialValues?.articleParams?.companies.map((company) => company.companyId) || [];
        const parentCompaniesIds = parentCompanies.map((company) => company.id) || [];
        const issuerCompaniesIds = issuerCompanies.map((company) => company.id) || [];

        await loadParentIssuerOptions('', [...parentCompaniesIds, ...issuerCompaniesIds, ...includedIds]);
    };

    useEffect(() => {
        if (!companiesLength) return;
        if (shouldLoadMissingCompanies()) loadMissingCompanies();
    }, [companiesLength, parentCompaniesLength, issuerCompaniesLength]);
};
