import { useEffect, useState } from 'react';
import { useCompaniesList } from '@/finsight/application/hooks/apiHooks/useCompaniesList';
import { useIndustryList } from '@/finsight/application/hooks/apiHooks/useIndustryList';
import useCountries from '@/Framework/hooks/useCountries';
import { useRegionsList } from '@/finsight/application/hooks/apiHooks/useRegions';
import { useSectorsList } from '@/finsight/application/hooks/apiHooks/useSectorsList';
import { useThemesList } from '@/finsight/application/hooks/apiHooks/useThemesList';
import { useProductsList } from '@/finsight/application/hooks/apiHooks/useProductsList';
import type { ISubscriptionsPageProps } from '@/finsight/pages/subscriptions';

export const useCommentaryDealFlowOptions = (props?: ISubscriptionsPageProps) => {
    const {
      sectorsList,
      regionsList,
      industriesList,
      countriesList,
      themesList,
      parentCompaniesList,
      issuerCompaniesList,
      productsList,
    } = props;
    const {
      collection: products, isFetching: isFetchingProducts, getProductsList,
    } = useProductsList(productsList);
    const {
      collection: parentCompanies, isFetching: isFetchingParentCompanies, getCompaniesList: getParentCompaniesList,
    } = useCompaniesList(parentCompaniesList);
    const {
      collection: issuerCompanies, isFetching: isFetchingIssuerCompanies, getCompaniesList: getIssuerCompaniesList,
    } = useCompaniesList(issuerCompaniesList);
    const {
      collection: industries, isFetching: isFetchingIndustries, getIndustriesList,
    } = useIndustryList(industriesList);
    const {
      collection: sectors, isFetching: isFetchingSectors, getSectorsList,
    } = useSectorsList(sectorsList);
    const {
      collection: regions, isFetching: isFetchingRegions, getRegionsList,
    } = useRegionsList(regionsList);
    const {
      collection: countries, isFetching: isFetchingCountries, getCountriesList,
    } = useCountries(countriesList);
    const {
      collection: themes, isFetching: isFetchingThemes, getThemesList,
    } = useThemesList(themesList);

    const [isLoading, setIsLoading] = useState(false);

    const refetchIfSSRfails = async (data: ISubscriptionsPageProps) => {
      const fetchMap = {
        sectorsList: getSectorsList,
        regionsList: getRegionsList,
        industriesList: getIndustriesList,
        countriesList: getCountriesList,
        themesList: getThemesList,
        parentCompaniesList: () => getParentCompaniesList({ filter: { includedRole: 'parent' } }),
        issuerCompaniesList: () => getIssuerCompaniesList({ filter: { includedRole: 'issuer' } }),
        productsList: getProductsList,
      };

      const promisesToFetch = Object.entries(fetchMap)
        .filter(([key]) => {
          const value = data[key as keyof ISubscriptionsPageProps];
          return !value || (typeof value === 'object' && 'hasServerError' in value && value.hasServerError);
        })
        .map(([, fetch]) => fetch());

      if (promisesToFetch.length > 0) {
        setIsLoading(true);
        try {
          await Promise.all(promisesToFetch);
        } finally {
          setIsLoading(false);
        }
      }
    };

    useEffect(() => {
      refetchIfSSRfails(props);
    }, []);

    return {
      isLoading,
      countries,
      getCountriesList,
      isFetchingCountries,
      sectors,
      getSectorsList,
      isFetchingSectors,
      regions,
      getRegionsList,
      isFetchingRegions,
      industries,
      getIndustriesList,
      isFetchingIndustries,
      themes,
      getThemesList,
      isFetchingThemes,
      parentCompanies,
      getParentCompaniesList,
      isFetchingParentCompanies,
      issuerCompanies,
      getIssuerCompaniesList,
      isFetchingIssuerCompanies,
      products,
      getProductsList,
      isFetchingProducts,
    };
};
