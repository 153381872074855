import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import AreYouSureModal from '@/condor/ui/common/Layout/FormWrapper/AreYouSureModal';
import { IFinalFormProps, TDefaultFormValues, TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from './formWrapper.scss';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps {
  headerText?: string,
  buttonsWrapperClassname?: string,
  submitButtonText?: string,
  onCancelClick?: () => void,
  withAreYouSureModal?: boolean,
  customActionButton?: React.ReactNode,
}

const FinalFormWrapper = <
  FormValues extends TDefaultFormValues = TDefaultFormValues,
  InitialValues = Partial<FormValues>,
>(
  {
    headerText,
    customActionButton: CustomActionButton,
    buttonsWrapperClassname,
    submitButtonText = 'Save',
    onCancelClick = noop,
    withAreYouSureModal = true,
    ...finalFormProps
  }: IProps & IFinalFormProps<FormValues, InitialValues>,
) => {
  const renderForm: TFinalFormRenderProp<FormValues, InitialValues> = (formProps, api) => {
    const { submitting, pristine, invalid, submitSucceeded } = formProps;

    return (
      <>
        { headerText && <div className={ cn(headerStyles.isH3, spacesStyles.mbl) }>{ headerText }</div> }
        { finalFormProps.render(formProps, api) }
        <div className={ cn(styles.formButtonsContainer, buttonsWrapperClassname) }>
          { CustomActionButton || (
            <Button
              type="submit"
              variant={ ButtonVariantType.action }
              className={ styles.successBtn }
              disabled={ submitting || pristine || invalid }
              title={ submitButtonText }
              dataTest="condorFormWrapperSubmitButton"
            />
          ) }
          <Button
            variant={ ButtonVariantType.text }
            onClick={ onCancelClick }
            title="Cancel"
            dataTest="condorFormWrapperCancelButton"
          />
        </div>
        { withAreYouSureModal && (
          <AreYouSureModal
            when={ !pristine && !submitting && !submitSucceeded }
            disableNative={ false }
          />
        ) }
      </>
    );
  };

  return (
    <FinalForm
      { ...finalFormProps }
      className={ cn(cardStyles.cardContent, finalFormProps.className) }
      render={ renderForm }
    />
  );
};

export default FinalFormWrapper;
