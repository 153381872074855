import keyBy from 'lodash/keyBy';
import { IRegion } from '@/finsight/domain/vo/Region';
import { ICompany } from '@/finsight/domain/vo/Company/ICompany';
import {
  ArticleFormData,
  IGroupParentIssuer,
  IOptionDeal,
  IOptionParentIssuer,
  IOptionTheme,
  Attachment,
} from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/ArticleFormData';
import { IArticlePayload } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Article';
import { ISingleArticle } from '@/condor/domain/vo/Finsight/CreditFlowResearch/IArticle';
import { ITheme } from '@/condor/domain/vo/Finsight/CreditFlowResearch/ITheme';
import { IDeal } from '@/condor/domain/vo/Finsight/CreditFlowResearch/Articles/Deal';

export const getParentOptions = (parents: ICompany[]): IOptionParentIssuer[] => {
  return parents.map(({ id, name }) => ({
    label: name,
    value: `${ id }-parent`,
    id,
    isParent: true,
  }));
};

export const getIssuerOptions = (issuers: ICompany[]): IOptionParentIssuer[] => {
  return issuers.map(({ id, name }) => ({
    label: name,
    value: `${ id }-issuer`,
    id,
    isParent: false,
  }));
};

export const getParentIssuerGroupMapper = (parents: ICompany[], issuers: ICompany[]): IGroupParentIssuer[] => {
  const parentsOptions = getParentOptions(parents);
  const issuersOptions = getIssuerOptions(issuers);

  return [
    {
      label: 'Parents',
      options: parentsOptions,
    },
    {
      label: 'Issuers',
      options: issuersOptions,
    },
  ];
};

export const getNewParentIssuerIds = (
  deals: IOptionDeal[],
  parentIssuersIds: string[],
): { parentIds: string[], issuerIds: string[] } => {
  const parentIds = [];
  const issuerIds = [];

  deals.forEach(({ parentId, issuerId }) => {
    const parentValue = `${ parentId }-parent`;
    const issuerValue = `${ issuerId }-issuer`;

    if (!parentIssuersIds.includes(parentValue)) {
      parentIds.push(parentId);
    }

    if (!parentIssuersIds.includes(issuerValue)) {
      issuerIds.push(issuerId);
    }
  });

  return { parentIds, issuerIds };
};

interface IGetArticleFormDataByArticle {
  article: ISingleArticle,
  themes: ITheme[],
  deals: IDeal[],
  regions: IRegion[],
  parents: ICompany[],
  issuers: ICompany[],
}

export const getArticleFormDataByArticle = ({
  article: {
    id,
    headline,
    description,
    isActive,
    themes: themeIds,
    attachments: attachmentsList,
  },
  themes: themesList,
  deals: dealsList,
  regions,
  parents,
  issuers,
}: IGetArticleFormDataByArticle): ArticleFormData => {
  const themes: IOptionTheme[] = [];
  const parentAndIssuer: IOptionParentIssuer[] = [
    ...getParentOptions(parents),
    ...getIssuerOptions(issuers),
  ];
  const regionMap = keyBy(regions, 'id');
  const attachments = attachmentsList.map(({ uploadId, displayName, url }) => ({
    uuid: uploadId,
    name: displayName,
    url,
  }));
  const deals: IOptionDeal[] = dealsList.map(({ id, name, productId, parentId, issuerId }) => ({
    label: name,
    value: id,
    productId,
    parentId,
    issuerId,
  }));

  themesList.forEach((theme) => {
    if (themeIds.includes(theme.id)) {
      themes.push({
        label: theme.themeName,
        value: theme.id,
        regionName: regionMap[theme.regionId]?.abbreviation || '',
      });
    }
  });

  return {
    id,
    headline,
    description,
    isActive,
    themes,
    deals,
    parentAndIssuer,
    attachments,
    sendEmail: false,
  };
};

export const getValues = (options: { value: string }[]): string[] => {
  return options.map((option) => option.value);
};

export const getIds = (options: { id: string }[]): string[] => {
  return options.map((option) => option.id);
};

export const getParentsAndIssuers = (parentAndIssuer) => {
  const parents = [];
  const issuers = [];

  parentAndIssuer.forEach((item) => {
    if (item.isParent) {
      parents.push(item.id);
    } else {
      issuers.push(item.id);
    }
  });

  return { parents, issuers };
};

export const getAttachments = (attachments: Attachment[]) => {
  return attachments.map(({ uuid, name }) => ({ uploadId: uuid, displayName: name }));
};

export const getSavePayload = (
  { themes, parentAndIssuer = [], attachments = [], deals, isActive, sendEmail, ...otherFields }: ArticleFormData,
): IArticlePayload => {
  const { parents, issuers } = getParentsAndIssuers(parentAndIssuer);

  return {
    themes: getValues(themes),
    attachments: getAttachments(attachments),
    parents,
    issuers,
    isActive,
    sendEmail,
    deals: getValues(deals),
    ...otherFields,
  };
};
