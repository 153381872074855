import React from 'react';
import { format } from 'date-fns';
import { LastTableCell } from '@/Framework/UI/Molecules/Tables/TableWrapper';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useTraceContext } from '@/condor/application/Trace/TraceContextProvider';
import isEmpty from 'lodash/isEmpty';
import {
  isValidHRegSCusip,
} from '@/condor/ui/components/Finsight/TraceManager/common/TraceTable/MapTrade/MapTradeExpandableRowContent/CellItems/helpers';

import styles from './styles.scss';

const MapTradeCell = ({ row: tranche }) => {
  const { mapTrade, tradeToMap } = useTraceContext();
  const payload = {
    tradeId: tradeToMap.id,
    dealId: tranche.dealId,
    trancheId: tranche.id,
  };

  const isButtonDisabled = isValidHRegSCusip(tradeToMap.cusip)
    ? !isEmpty(tranche.cusipsRegs)
    : !isEmpty(tranche.cusips);

  return (
    <>
      <LastTableCell
        childrenToShowOnHover={ (
          <Button
            variant={ ButtonVariantType.action }
            className={ styles.mapButton }
            title="Map"
            dataTest="mapTradeExpandableRowContentButton"
            onClick={ () => mapTrade(payload) }
            disabled={ isButtonDisabled }
          />
        ) }
      >
        { tranche.pricingDate && format(new Date(tranche.pricingDate), 'MMM dd, yyyy') }
      </LastTableCell>
    </>
  );
};

export default MapTradeCell;
