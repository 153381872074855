import { useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import DataroomRepository from '@/dataroom/infrastructure/repository/DataroomRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';

/**
 * @typedef { {
 *    isDeactivating: Boolean,
 *    isTruncationModalVisible: Boolean,
 *    setTruncationModalVisible: Boolean,
 *    deactivate: Function(): Promise<void>
 * } } DeactivateType
 */
const useDeactivate = (dataroomId: number) => {
  const { container } = useDIContext();
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [isTruncationModalVisible, setTruncationModalVisible] = useState<boolean>(false);

  const deactivate = async (
    actions: string[],
    hash: string = null,
    truncationMode: string = null,
    stagingTruncationMode: string = null,
  ): Promise<boolean> => {
    setIsDeactivating(true);

    try {
      const payload = {
        dataroomId,
        prepare: actions,
        hash,
        ...(truncationMode ? { truncationMode } : null),
        ...(stagingTruncationMode ? { stagingTruncationMode } : null),
      };

      const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
      await dataroomRepository.deactivate(payload);
      return true;
    } catch (error) {
      if (error.error.code === ErrorCodeHelper.getCodeByName('DATAROOM_DEACTIVATE_ARCHIVE_HAS_LONG_PATHS')) {
        setTruncationModalVisible(true);
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }
      return false;
    } finally {
      setIsDeactivating(false);
    }
  };

  return {
    isDeactivating,
    isTruncationModalVisible,
    setTruncationModalVisible,
    deactivate,
  };
};

export default useDeactivate;
