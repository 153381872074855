import {
  commonCellCallbackWithWrapper,
  accountTypeCellCallback,
  createdAtCellCallback,
  domainsCellCallback,
  emailDomainsEditableFieldCallback,
  nameCellCallback,
  statusCellCallback,
  typeEditableFieldCallback,
  updatedAtCellCallback,
  verifiedCellCallback,
  verifiedEditableFieldCallback,
  webDomainsEditableFieldCallback,
  bulkDeleteCellCallback,
  bulkDeleteHeadCellCallback,
} from './cellCallbacks';
import styles from './accountsTable.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const columns = [
  {
    name: 'bulkDelete',
    className: alignStyles.alignCenter,
    editableClassName: styles.checkboxEditableCell,
    isEditable: false,
    minWidth: 50,
    width: 50,
    headCallback: bulkDeleteHeadCellCallback,
    cellCallback: bulkDeleteCellCallback,
  },
  {
    name: 'verified',
    title: 'Status',
    width: 38,
    sortable: true,
    cellCallback: statusCellCallback,
  },
  {
    name: 'name',
    title: 'Name',
    sortable: true,
    isEditable: true,
    cellCallback: nameCellCallback,
  },
  {
    name: 'webDomains',
    title: 'Web Domains',
    isEditable: true,
    minWidth: 130,
    sortable: true,
    editableFieldCallback: webDomainsEditableFieldCallback,
    cellCallback: domainsCellCallback,
  },
  {
    name: 'emailDomains',
    title: 'Email Domains',
    isEditable: true,
    minWidth: 130,
    sortable: true,
    editableFieldCallback: emailDomainsEditableFieldCallback,
    cellCallback: domainsCellCallback,
  },
  {
    name: 'accountLeisCount',
    title: '# LEIS',
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: commonCellCallbackWithWrapper,
  },
  {
    name: 'accountType',
    title: 'Type',
    isEditable: true,
    minWidth: 130,
    sortable: true,
    editableFieldCallback: typeEditableFieldCallback,
    cellCallback: accountTypeCellCallback,
  },
  {
    name: 'contactCount',
    title: '# Contacts',
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: commonCellCallbackWithWrapper,
  },
  {
    name: 'dealCount',
    title: '# Deals',
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: commonCellCallbackWithWrapper,
  },
  {
    name: 'evercallCount',
    title: '# EVC',
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: commonCellCallbackWithWrapper,
  },
  {
    name: 'allocationCount',
    title: '# Alloc',
    className: alignStyles.alignRight,
    sortable: true,
    cellCallback: commonCellCallbackWithWrapper,
  },
  {
    name: 'verifiedCheckbox',
    title: 'Verified',
    className: alignStyles.alignCenter,
    editableClassName: styles.checkboxEditableCell,
    isEditable: true,
    minWidth: 50,
    width: 50,
    cellCallback: verifiedCellCallback,
    editableFieldCallback: verifiedEditableFieldCallback,
  },
  {
    name: 'createdAt',
    title: 'Created At',
    className: alignStyles.alignRight,
    sortable: true,
    minWidth: 145,
    width: 145,
    cellCallback: createdAtCellCallback,
  },
  {
    name: 'updatedAt',
    title: 'Updated At',
    className: alignStyles.alignRight,
    minWidth: 145,
    width: 145,
    cellCallback: updatedAtCellCallback,
    sortable: true,
  },
];

export default columns;
