import PresentationMediaRepository from '@/dealroadshow/infrastructure/repository/PresentationMediaRepository';
import * as actionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import { IUploadProcessing, ProcessingEvent, UploadEvent } from '@dealroadshow/file-uploader';

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadStart(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_ADD,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadChunkSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_CHUNK_SUCCESS,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_SUCCESS,
    payload,
  };
}

/**
 * chunk or combination
 * @param {Object} payload
 * @return {Object}
 */
function uploadError(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_ERROR,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadProcessing(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadDone(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_DONE,
    payload,
  };
}

/**
 * @param {Object} payload
 * @return {Object}
 */
function uploadCancelSuccess(payload) {
  return {
    type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL_SUCCESS,
    payload,
  };
}

/**
 * @param {String} uuid
 */
export function uploadPresentationMediaVideoCancel(uuid) {
  return async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL,
      payload: {
        uuid,
      },
    });

    if (getState().upload.presentationMediaVideo.callback
        && typeof getState().upload.presentationMediaVideo.callback.cancel === 'function') {
      getState().upload.presentationMediaVideo.callback.cancel();
    } else if (getState().upload.presentationMediaVideo.process.uuid) {
      dispatch(uploadCancelSuccess({
        uuid: getState().upload.presentationMediaVideo.process.uuid,
      }));
    }
  };
}

/**
 * @param {Array} payload
 */
export function initUploadPresentationMediaVideo(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_INIT,
      payload,
    });
  };
}

/**
 * @param {Array.<File>} files
 */
export function uploadPresentationMediaVideo(files) {
  return async (dispatch, getState) => {
    if (getState().upload.presentationMediaVideo.process.uuid) {
      uploadPresentationMediaVideoCancel(getState().upload.presentationMediaVideo.process.uuid);
    }

    const presentationMediaRepository = getState().container.get(PresentationMediaRepository);
    const upload: IUploadProcessing = await presentationMediaRepository.uploadVideo(files[0]);

    dispatch(uploadStart({
      uploadFile: files[0],
      callback: upload,
      uuid: upload.getUuid(),
    }));

    upload
      .on(UploadEvent.uploadChunkDone, (data) => dispatch(uploadChunkSuccess(data)))
      .on(UploadEvent.error, (data) => dispatch(uploadError(data)))
      .on(UploadEvent.uploadDone, (data) => dispatch(uploadSuccess(data)))
      .on(ProcessingEvent.processing, (data) => dispatch(uploadProcessing(data)))
      .on(ProcessingEvent.processingDone, (data) => dispatch(uploadDone(data)))
      .on(UploadEvent.cancel, (data) => dispatch(uploadCancelSuccess(data)));
  };
}
