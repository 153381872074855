import { useState, useCallback } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import RegionRepository from '@/finsight/infrastructure/repository/RegionRepository';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import type { IRegion } from '@/finsight/domain/vo/Region';

export const useRegionsList = (initial: IRegion[]) => {
  const [collection, setCollection] = useState<IRegion[]>(initial || []);
  const [isFetching, setIsFetching] = useState(false);
  const { container } = useDIContext();

  const getRegionsList = useCallback(async () => {
    setIsFetching(true);
    try {
      const regionRepository = container.get(RegionRepository);
      const regions = await regionRepository.getList();
      setCollection(regions);
    } catch (error) {
        NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  }, [container]);

  return {
    isFetching,
    collection,
    getRegionsList,
  };
};
