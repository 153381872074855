import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@dealroadshow/uikit/core/components/Tabs';
import { ARTICLES_TABS, STATS_TABS } from './constants';

import styles from './listTabs.scss';
import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';

export enum ETabType {
  articles = 'articles',
  stats = 'stats',
}

interface IListTabsProps {
  type: ETabType,
  dataTest: string,
}

const ListTabs = ({ type, dataTest }: IListTabsProps) => {
  const { push, location } = useHistory();

  const onClickTab = (path: string) => {
    if (location.pathname !== path) {
      push(path);
    }
  };
  const TABS = type === 'articles' ? ARTICLES_TABS : STATS_TABS;
  const defaultTab = TABS.find(({ path }) => location.pathname === path)?.title || TABS[0].title;

  return (
    <>
      <Tabs
        dataTest={ dataTest }
        className={ styles.tabs }
        defaultTab={ defaultTab }
      >
        { TABS.map(({ title, path, dataTest }) => (
          <Tab
            key={ title }
            onClick={ () => onClickTab(path) }
            tabFor={ title }
            className={ tabsStyles.tab }
            activeClassName={ tabsStyles.isTabActive }
            name={ dataTest }
          >
            { title }
          </Tab>
        )) }
      </Tabs>
      <div className={ tabsStyles.tabsFooter } />
    </>
  );
};

export default ListTabs;
