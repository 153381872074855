import React from 'react';
import { Modal } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useUploadSsoCertificateContext } from '@/condor/application/Accounts/AccountProfile/AccountSSO/UploadSsoSertificate';

import styles from './styles.scss';

const ViewCertificateModal = () => {
  const { certificateForView, setCertificateForView } = useUploadSsoCertificateContext();
  const content = certificateForView
    ? certificateForView?.content.split(/\\n/).map((row) => <p key={ row }>{ row }</p>)
    : '';

  const buttons = (
    <Button
      title="Close"
      variant={ ButtonVariantType.action }
      dataTest="ssoDeleteCertificateModalButtonCancel"
      onClick={ () => setCertificateForView(null) }
    />
  );

  return (
    <Modal
      title={ `Certificate #${ certificateForView?.number }` }
      dataTest="ssoViewCertificateModal"
      isVisible={ !!certificateForView }
      className={ styles.viewModal }
      onCloseClicked={ () => setCertificateForView(null) }
      footer={ buttons }
    >
      <div className={ styles.viewModalContent }>
        { content }
      </div>
    </Modal>
  );
};

export default ViewCertificateModal;
