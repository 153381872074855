import { createGroupedOptions } from './commonHelpers';
import type { IOption } from '@/finsight/ui/common/components/GroupSelectWithToolbar/RadioButtonsToolbar/interfaces';

export const createSectorOptions = (
  regions: { id: string, abbreviation: string }[],
  sectors: { name: string, subsectorList: { id: string, name: string }[] }[],
): IOption[] => {
  return createGroupedOptions(regions, sectors.map((sector) => ({
    name: sector.name,
    subItems: sector.subsectorList,
  })));
};
