import cn from 'classnames';
import { commonCellCallback } from '@/condor/ui/common/Layout/helpers';
import {
  uniqueIdCellCallback,
  nameCellCallback,
  industryCell,
  actionsCell,
  industrySubindustryEditableFieldCallback,
} from './cellCallbacks';
import styles from './companiesTable.scss';
import alignStyles from '@dealroadshow/uikit/core/styles/helpers/align.scss';

const columns = [
  {
    name: 'id',
    title: 'Unique Id',
    width: 130,
    sortable: true,
    cellCallback: uniqueIdCellCallback,
  },
  {
    name: 'viewId',
    title: 'Id',
    width: 96,
    sortable: true,
    cellCallback: commonCellCallback,
  },
  {
    name: 'name',
    title: 'Name',
    sortable: true,
    isEditable: true,
    className: styles.nameCell,
    cellCallback: nameCellCallback,
  },
  {
    name: 'industryId',
    title: 'Industry / Subindustry',
    width: 130,
    minWidth: 130,
    sortable: true,
    isEditable: true,
    editableFieldCallback: industrySubindustryEditableFieldCallback,
    cellCallback: industryCell,
  },
  {
    name: 'abbreviation',
    title: 'UW ABBR',
    width: 110,
    minWidth: 110,
    sortable: true,
    isEditable: true,
    cellCallback: commonCellCallback,
    className: alignStyles.alignCenter,
  },
  {
    name: 'issuerTicker',
    title: 'Issuer Ticker',
    width: 110,
    minWidth: 110,
    sortable: true,
    isEditable: true,
    cellCallback: commonCellCallback,
    className: alignStyles.alignRight,
  },
  {
    name: 'countDeals',
    title: '# Deals',
    width: 80,
    sortable: true,
    isEditable: false,
    className: alignStyles.alignRight,
    cellCallback: commonCellCallback,
  },
  {
    name: 'actions',
    title: '',
    width: 0,
    minWidth: 0,
    className: cn(alignStyles.alignRight, styles.actionsCell),
    cellCallback: actionsCell,
  },
];

export default columns;
