import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import dataroomConfig from '@/dataroom/application/config/config';
import FeatureToggle from '@/Framework/UI/Organisms/FeatureToggle/Components';
import { RESEARCHROOM_FEATURE } from '@/condor/application/config/featureToggle';

import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import styles from '@/finsight/ui/components/bonds/BondsTile/BondTypes/bondTypes.scss';
import permissionStyles from './userPermissions.scss';
import { modulesNameMapping } from './constants';

interface ITabsProps {
  activeTab: number,
  setActiveTab: (index: number) => void,
}

const Tabs: FC<ITabsProps> = ({ activeTab, setActiveTab }) => {
  const { modules } = useSelector(userPermissionsSelectors.getUserPermissionsState);

  const getTabsContent = () => (
    modules && Object.keys(modules).map((module, index) => {
      const tabClassName = cn(tabsStyles.tab, {
          [tabsStyles.isTabActive]: index === activeTab,
        [tabsStyles.isTabDisabled]: false,
      }, styles.buttonsTypes);

      if (module === dataroomConfig.tenant.tenantResearchRoom.code) {
        return (
          <FeatureToggle
            featureName={ RESEARCHROOM_FEATURE }
            key={ module }
          >
            <button
              type="button"
              key={ module }
              onClick={ () => setActiveTab(index) }
              className={ tabClassName }
            >
              <span>{ modulesNameMapping[module] || `${ module.charAt(0).toUpperCase() }${ module.slice(1) }` }</span>
            </button>
          </FeatureToggle>
        );
      }

      return (
        <button
          type="button"
          key={ module }
          onClick={ () => setActiveTab(index) }
          className={ tabClassName }
        >
          <span>{ modulesNameMapping[module] || `${ module.charAt(0).toUpperCase() }${ module.slice(1) }` }</span>
        </button>
      );
    })
  );

  return (
    <>
      <div className={ permissionStyles.tabsContainer }>
        { getTabsContent() }
      </div>
      <div className={ tabsStyles.tabsFooter } />
    </>
  );
};

export default Tabs;
