import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { getContactPermissions, subScribeToUpdateContactPermissions } from '@/users/application/actions/permissions/actions';
import * as userDataSelector from '@/users/application/actions/data/selectors';
import * as userPermissionsSelectors from '@/users/application/actions/permissions/selectors';
import permissionStyles from './userPermissions.scss';

interface IPermissionsActionsProps {
  hasPermissionToUpdate: boolean,
}

const PermissionsActions: FC<IPermissionsActionsProps> = ({ hasPermissionToUpdate }) => {
  const dispatch = useDispatch();
  const { id: contactId } = useSelector(userDataSelector.getUser);
  const { contactPermissions } = useSelector(userPermissionsSelectors.getUserPermissionsState);

  const onCancelClick = () => dispatch(getContactPermissions(contactId));

  const onSaveClick = () => {
    dispatch(subScribeToUpdateContactPermissions({
      contactId,
      userPermissions: contactPermissions,
    }));
  };

  if (!hasPermissionToUpdate) return null;

  return (
    <div className={ permissionStyles.actionsContainer }>
      <Button
        variant={ ButtonVariantType.action }
        title="Save"
        dataTest="userPermissionsSaveButton"
        onClick={ onSaveClick }
      />
      <Button
        variant={ ButtonVariantType.text }
        title="Cancel"
        dataTest="userPermissionsCancelButton"
        onClick={ onCancelClick }
      />
    </div>
  );
};

export default PermissionsActions;
