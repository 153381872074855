import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { getUnixTimestamp } from '@/Framework/DateTime/getUnixTimestamp';
import {
  filterLinks,
} from '@/dealroadshow/ui/components/dmPortal/RoadshowForm/DealFilesAndCommercialVideoForm/hooks/useRegisterForm';
import EntryCodeType from '@/dealroadshow/domain/EntryCodeType';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';
import normalizeWhitespace from '@/Framework/dataHelpers/string/normalizeWhitespace';

/**
 * @param {number} dateAt
 * @param timeZoneApplied
 * @return {Number}
 */
function filterDataAt(dateAt, timeZoneApplied) {
  if (dateAt) {
    return getUnixTimestamp(utcToZonedDate(timeZoneApplied.timeZone, new Date(dateAt * 1000)));
  }
  return getUnixTimestamp(utcToZonedDate(timeZoneApplied.timeZone));
}

/**
 * @param {moment} dateAt
 * @return {Number}
 */
function filterExpireAt(dateAt) {
  return getUnixTimestamp(new Date(dateAt));
}

export function addUuidToLink(links) {
  return links.map((link) => ({
    ...link,
    uuid: link.id,
  }));
}

const filterFiles = (files, formData) => {
  let filesSave = [];
  files.sort.forEach((file, index) => {
    filesSave.push({
      id: files.files[file.uuid].initialFile ? files.files[file.uuid].initialFile.id : null,
      url: files.files[file.uuid].url,
      name: formData[index].name?.trim() || '',
      originalName: files.files[file.uuid].originalName || null,
      customDisclaimer: !isEmptyString(formData[index].disclaimer) ? formData[index].customDisclaimer : false,
      disclaimer: formData[index].disclaimer || '',
      viewOnly: formData[index].viewOnly || false,
    });
  });
  return filesSave;
};

/**
 * @param {Object} formData
 * @return {Object}
 */
export function filterDealSettingsFormData(formData) {
  let logo = null;
  if (formData.upload.logo.uploaded && formData.upload.logo.canSave) {
    logo = {
      name: formData.upload.logo.name || null,
      url: formData.upload.logo.url,
    };
    if (formData.upload.logo.initialFile) {
      logo.id = formData.upload.logo.initialFile.id;
      logo.name = logo.name || formData.upload.logo.initialFile.name;
    }
  }
  let singleEntryCode = null;
  if (EntryCodeType.isSingle(formData.appliedEntryCodeType)) {
    singleEntryCode = {
      id: formData.singleEntryCode.id,
      code: formData.singleEntryCode.code,
      startedAt: filterDataAt(formData.startedAt, formData.timeZone),
      expiredAt: filterExpireAt(formData.finishedAt),
    };
  }

  let {
    viewerCustomizationEnabled,
    createTopButton,
    viewerDisclaimerCustomizationEnabled,
  } = formData;

  if (!formData.roadshowViewerCustomizeEnabled) {
    viewerCustomizationEnabled = false;
    createTopButton = false;
    viewerDisclaimerCustomizationEnabled = false;
  }

  const viewerCustomSettings = {
    presentationBackgroundColor: formData.presentationBackgroundColor,
    customSectionText: formData.customSectionText || null,
    customSectionHeaderText: formData.customSectionHeaderText || null,
    toolbarColor: formData.toolbarColor,
    toolbarButtonsColor: formData.toolbarButtonsColor,
    buttonTextColor: formData.buttonTextColor,
    dealNameColor: formData.dealNameColor,
    rightPanelBackgroundColor: formData.rightPanelBackgroundColor,
    rightPanelIconsColor: formData.rightPanelIconsColor,
    sectionHeaderColor: formData.sectionHeaderColor,
    customSectionHeaderBackgroundColor: formData.customSectionHeaderBackgroundColor,
    rightPanelTextAndDealRoadshowLogoColor: formData.rightPanelTextAndDealRoadshowLogoColor,
    toolbarAccentColor: formData.toolbarAccentColor,
    rightPanelAccentColor: formData.rightPanelAccentColor,
    createTopButton,
    viewerDisclaimerCustomizationEnabled,
  };

  const createTopButtonSettings = {
    topButtonType: formData.topButtonType,
    topButtonColor: formData.topButtonColor,
    topButtonTextColor: formData.topButtonTextColor,
    topButtonText: formData.topButtonText || null,
  };

  let disclaimerLogo = null;
  let disclaimerBackgroundImage = null;
  if (formData.upload.disclaimerLogo.uploaded && formData.upload.disclaimerLogo.canSave) {
    disclaimerLogo = {
      name: formData.upload.disclaimerLogo.name || null,
      url: formData.upload.disclaimerLogo.url,
    };
    if (formData.upload.disclaimerLogo.initialFile) {
      disclaimerLogo.id = formData.upload.disclaimerLogo.initialFile.id;
      disclaimerLogo.name = disclaimerLogo.name || formData.upload.disclaimerLogo.initialFile.name;
    }
  }
  if (formData.upload.disclaimerBackgroundImage.uploaded && formData.upload.disclaimerBackgroundImage.canSave) {
    disclaimerBackgroundImage = {
      name: formData.upload.disclaimerBackgroundImage.name || null,
      url: formData.upload.disclaimerBackgroundImage.url,
    };
    if (formData.upload.disclaimerBackgroundImage.initialFile) {
      disclaimerBackgroundImage.id = formData.upload.disclaimerBackgroundImage.initialFile.id;
      disclaimerBackgroundImage.name = disclaimerBackgroundImage.name ||
        formData.upload.disclaimerBackgroundImage.initialFile.name;
    }
  }

  const viewerDisclaimerSettings = {
    disclaimerBackgroundColor: formData.disclaimerBackgroundColor,
    disclaimerTileColor: formData.disclaimerTileColor,
    disclaimerPrimaryTextColor: formData.disclaimerPrimaryTextColor,
    disclaimerSecondaryTextColor: formData.disclaimerSecondaryTextColor,
    disclaimerLinkColor: formData.disclaimerLinkColor,
    disclaimerLabelTextColor: formData.disclaimerLabelTextColor,
    disclaimerButtonColor: formData.disclaimerButtonColor,
    disclaimerButtonTextColor: formData.disclaimerButtonTextColor,
    disclaimerAccentColor: formData.disclaimerAccentColor,
    customMessage: formData.customMessage,
    loginPrimaryText: formData.loginPrimaryText,
    loginSecondaryText: formData.loginSecondaryText,
    firstTimeFormPrimaryText: formData.firstTimeFormPrimaryText,
    firstTimeFormSecondaryText: formData.firstTimeFormSecondaryText,
    disclaimerLogo,
    disclaimerBackgroundImage,
  };

  let saveData = {
    id: formData.id,
    name: normalizeWhitespace(formData.name ?? ''),
    disclaimer: formData.disclaimer,
    originalVersion: formData.version,
    type: typeof formData.type === 'object'
      ? formData.type.value
      : formData.type,
    moderated: formData.moderated,
    /* slideType: formData.slideType, */
    mediaSkippingAheadDisabled: formData.mediaSkippingAheadDisabled,
    hideAudioDialIns: formData.hideAudioDialIns,
    displayParticipantsListEnabled: formData.displayParticipantsListEnabled,
    transactionTypeId: formData.transactionTypeId,
    finishedAt: filterExpireAt(formData.finishedAt),
    underwritersInTopBarShown: formData.underwritersInTopBarShown || false,
    rightPanelDescriptionShown: formData.rightPanelDescriptionShown || false,
    rightPanelDescription: formData.rightPanelDescription || '',
    loginsMaxNumber: formData.loginsMaxNumber ? formData.loginsMaxNumber : null,
    loginsTimeLimit: formData.loginsTimeLimit ? formData.loginsTimeLimit : null,
    public: formData.public || false,
    inRetailTable: formData.inRetailTable || false,
    retailTableLogo: logo,
    singleEntryCode,
    retailTableDescription: formData.retailTableDescription,
    dealSizeMillions: formData.dealSizeMillions || null,
    expectedPricingDate: formData.expectedPricingDate
      ? filterExpireAt(formData.expectedPricingDate)
      : null,
    useProxy: formData.useProxy,
    cdnTuple: formData.cdnTuple,
    viewerCustomizationEnabled,
    viewerCustomSettings,
    createTopButton,
    createTopButtonSettings,
    viewerDisclaimerCustomizationEnabled,
    viewerDisclaimerSettings,
    viewerCustomFiles: filterFiles(formData.upload.viewerCustomFiles, formData.viewerCustomFiles),
    viewerCustomLinks: filterLinks(formData.viewerCustomLinks),
    internalNotes: formData.internalNotes || null,
    variation: formData.variation,
  };

  saveData.entryCodeLoginLimits = [];
  if (EntryCodeType.isRotating(formData.appliedEntryCodeType)) {
    let rotatingEntryCodesLoginLimits = formData.entryCodes
      .filter((entryCode) => !entryCode.deletedAt)
      .map((entryCode) => ({
        entryCodeId: entryCode.id,
        loginsMaxNumber: entryCode.loginsMaxNumber,
        loginsTimeLimit: entryCode.loginsTimeLimit || null,
      }));
    if (rotatingEntryCodesLoginLimits.length > 0) {
      saveData.entryCodeLoginLimits = rotatingEntryCodesLoginLimits;
    }
  }
  return {
    ...saveData,
  };
}

/**
 * @param {Object} formData
 * @return {Object}
 */
export function filterRoadshowMappingFormData(formData) {
  let { id, subsectorId, subindustryId, variation } = formData;
  return {
    roadshowId: id,
    subsectorId,
    subindustryId,
    variation,
  };
}
