import { useState } from 'react';
import cn from 'classnames';
import PageWrapper from '@/condor/ui/common/Layout/PageWrapper';
import { RegionsProductsContextProvider } from '@/condor/application/Dictionary/RegionsProducts';
import {
  StatProfileContextProvider,
} from '@/condor/application/Finsight/CreditFlowResearch/Stats/StatProfile/StatProfileContext';
import StatProfile from './StatProfile';

import styles from './styles.scss';

interface IStatProfilePageProps {
  statId?: string,
}

const StatProfilePage = (props: IStatProfilePageProps) => {
  const [title, setTitle] = useState(props.statId ? '' : 'Create New Stat');

  return (
    <PageWrapper
      label={ title }
      className={ cn({ [styles.pageWrapper]: !title }) }
    >
      <RegionsProductsContextProvider>
        { /* TODO: setTitle will be use when will add edit stat */ }
        <StatProfileContextProvider { ...props } setTitle={ setTitle }>
          <StatProfile />
        </StatProfileContextProvider>
      </RegionsProductsContextProvider>
    </PageWrapper>
  );
};

export default StatProfilePage;
