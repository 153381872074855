import type { SubscriptionsSubGroup } from '@/condor/domain/vo/Finsight/Subscriptions/SubscriptionsData';
import SubGroupField from '@/finsight/ui/components/SubscriptionForm/Base/components/SubGroupField';
import commonStyles from '@/finsight/ui/components/SubscriptionForm/styles.scss';

interface ISubscriptionSubGroupProps {
  subgroup: SubscriptionsSubGroup,
  isDisabled: boolean,
}

const SubscriptionSubGroup = ({ subgroup, isDisabled }: ISubscriptionSubGroupProps) => (
  <div
    key={ subgroup.subgroupId || subgroup.id }
    className={ commonStyles.tileRow }
  >
    <SubGroupField subgroup={ subgroup } isDisabled={ isDisabled } />
    { !!subgroup.accessMessage && (
      <span className={ commonStyles.tileInfo }>{ subgroup.accessMessage }</span>
    ) }
  </div>
);

export default SubscriptionSubGroup;
