import { v4 as uuid } from 'uuid';
import * as actionTypes from '@/Framework/UI/Organisms/Upload/actionTypes';
import * as uploadTypes from '@/Framework/UI/Organisms/Upload/uploadTypes';
import * as processingTypes from '@/Framework/UI/Organisms/Upload/processingTypes';

const initialState = {
  video: null,
  slideShow: [],
  process: {
    progress: 0,
    status: '',
    uuid: '',
  },
  uploadFile: null,
  callback: null,
  name: '',
  uploaded: false,
  canSave: false,
  backgroundProcessingStatus: null,
};

export default function presentationMediaVideo(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_ADD:
      return {
        video: {},
        process: {
          progress: 0,
          status: uploadTypes.UPLOAD_START,
          uuid: action.payload.uuid,
        },
        uploadFile: action.payload.uploadFile,
        callback: action.payload.callback,
        name: action.payload.uploadFile.name,
        uploaded: false,
        canSave: false,
      };

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_CHUNK_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: parseInt(action.payload.progress),
            uuid: action.payload.uuid,
            status: uploadTypes.UPLOAD_CHUNK_SUCCESS,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_SUCCESS,
          },
          uploaded: true,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_ERROR:
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            status: uploadTypes.UPLOAD_ERROR,
          },
          uploaded: false,
          canSave: false,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING:
      if (state.process.uuid === action.payload.uuid &&
          state.process.status !== uploadTypes.UPLOAD_DONE) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 0,
            status: uploadTypes.UPLOAD_PROCESSING_START,
          },
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_DONE:
      const isAllFileTypesProcessed = !Array.isArray(action.payload.data);
      let urls = {};
      isAllFileTypesProcessed ? (
        Object.keys(action.payload.data).forEach((extension) => {
          urls[extension] = `/${ action.payload.data[extension][0] }`;
        })
      ) : (
        urls.mp4 = `/${ action.payload.data[0] }`
      );
      if (state.process.uuid === action.payload.uuid) {
        return {
          ...state,
          process: {
            ...state.process,
            progress: 100,
            status: uploadTypes.UPLOAD_DONE,
          },
          video: {
            urls: {
              ...urls,
            },
            name: state.uploadFile.name,
          },
          uploaded: true,
          canSave: true,
          backgroundProcessingStatus: isAllFileTypesProcessed ? processingTypes.COMPLETED : processingTypes.PROCESSING,
        };
      }
      return state;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_CANCEL_SUCCESS:
      if (state.process.uuid === action.payload.uuid) {
        return initialState;
      }
      return state;

    case actionTypes.UPLOAD_CLEAR:
      return initialState;

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_INIT:
      return {
        ...state,
        video: action.payload,
        process: {
          progress: 100,
          status: uploadTypes.UPLOAD_DONE,
          uuid: uuid(),
        },
        uploadFile: null,
        callback: null,
        name: action.payload.originalName,
        uploaded: true,
        canSave: true,
      };

    case actionTypes.UPLOAD_PRESENTATION_MEDIA_VIDEO_PROCESSING_STATUS_UPDATE:
      return {
        ...state,
        backgroundProcessingStatus: action.payload,
      };

    default:
      return state;
  }
}
