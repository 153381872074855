import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ICellProps, IColumn } from '@dealroadshow/uikit/core/components/Table/DataTable';
import { useWhitelistContext } from '../WhitelistContext';
import { IWhitelistFull } from '../interfaces';

import styles from './whitelistTable.scss';
import { useUsersContext } from '@/openMarket/application/Condor/UsersContext';

const ActionButtons = ({ row }: ICellProps<IWhitelistFull>) => {
  const { removeWhitelist } = useWhitelistContext();

  return (
    <span className={ styles.actionButtonsCellContent }>
      <Button
        dataTest="delete"
        variant={ ButtonVariantType.link }
        className={ styles.button }
        onClick={ () => removeWhitelist(row) }
      >
        <Icon type={ IconType.trash } />
      </Button>
    </span>
  );
};

const NameCell = ({ row }: ICellProps<IWhitelistFull>) => {
  const { getUserInfo } = useUsersContext();

  const userInfo = getUserInfo(row.email);

  if (!userInfo) {
    return null;
  }

  return `${ userInfo.firstName } ${ userInfo.lastName }`;
};

const CompanyCell = ({ row }: ICellProps<IWhitelistFull>) => {
  const { getUserInfo } = useUsersContext();

  return getUserInfo(row.email)?.company?.name ?? '';
};

export const columns: IColumn[] = [
  {
    name: 'name',
    title: 'Name',
    width: 177,
    cellCallback: NameCell,
    className: styles.cellData,
  },
  {
    name: 'emailOrDomain',
    title: 'Email/domain',
    width: 206,
    className: styles.cellData,
  },
  {
    name: 'company',
    title: 'Company',
    width: 372,
    cellCallback: CompanyCell,
    className: styles.cellData,
  },
  {
    name: 'actionButtons',
    width: 45,
    cellCallback: ActionButtons,
  },
];
