import { getOptionMapper, mapToDefaultOption } from '@/Framework/dataHelpers/selectOptionMappers';
import { DEAL } from '@/finsight/application/actions/pageFilters/constants';
import { DEAL_PROFILE_FIELDS } from '@/condor/ui/components/Finsight/DealProfile/constants';
import {
  createCompanyOptionWithIssuerTicker,
  createCompanyOptionWithIssuerTickerAndAbbreviation,
} from '@/condor/ui/components/Finsight/DealProfile/helpers/createCompanyOption';

/**
 * @param {Array} countries
 * @param {Array} products
 * @param {Array} regions
 * @param {Array} sectors
 * @param {Array} currencies
 * @param {Array} rankings
 * @param {Array} couponTypes
 * @param {Array} registrations
 * @param {Array} benchmarks
 */
export const getDealProfileFieldsOptions = (
  {
    countries = [],
    products = [],
    regions = [],
    sectors = [],
    currencies = [],
    rankings = [],
    couponTypes = [],
    registrations = [],
    benchmarks = [],
    statuses = [],
  },
) => {
  /**
   * @param {Object} benchmark
   * @return {Object}
   */
  const mapBenchmarkToAbbreviationOption = (benchmark) => (
    {
      value: benchmark.id,
      label: benchmark.abbreviation,
      disabled: !benchmark.trusted,
    }
  );

  const mapToAbbreviationOption = getOptionMapper({ pathToLabel: 'abbreviation' });
  const mapCurrenciesOption = getOptionMapper({ labelRenderer: (item) => `${ item.displayName } (${ item.name })` });
  const regionsOptions = regions.map(
    (region) => (
      {
        ...mapToAbbreviationOption(region),
        displayName: region.displayName,
      }
    ),
  );

  return {
    currencies: currencies.map(mapCurrenciesOption),
    rankings: rankings.map(mapToDefaultOption),
    couponTypes: couponTypes.map(mapToDefaultOption),
    registrations: registrations.map(mapToDefaultOption),
    statuses: statuses.map(mapToDefaultOption),
    regions: regionsOptions,
    products: products.map(mapToAbbreviationOption),
    benchmarks: benchmarks.map(mapBenchmarkToAbbreviationOption),
    countries: countries.map((country) => (
      {
        ...mapToDefaultOption(country),
        region: regionsOptions.find((regionOption) => regionOption.value === country.marketRegionId),
      }
    )),
    sectors: sectors.reduce(
      (resultOptions, currentSector) => [
        ...resultOptions,
        ...currentSector.subsectorList.map((subsector) => (
          {
            value: subsector.id,
            label: `${ currentSector.name } - ${ subsector.name }`,
            sectorId: currentSector.id,
          }
        )),
      ],
      [],
    ),
  };
};

/**
 * @param {String} fieldName
 * @return {Function}
 */
export const getCompanyFieldOptionMapperByFieldName = (fieldName) => {
  switch (fieldName) {
    case DEAL_PROFILE_FIELDS.PRIMARY_ISSUER.name:
      return createCompanyOptionWithIssuerTicker;
    case DEAL_PROFILE_FIELDS.ADDITIONAL_ISSUERS.name:
    case DEAL_PROFILE_FIELDS.STRUCTURING_LEADS.name:
    case DEAL_PROFILE_FIELDS.JOINT_LEADS.name:
      return createCompanyOptionWithIssuerTickerAndAbbreviation;
    default:
      return mapToDefaultOption;
  }
};

/**
 * @param {String} id
 * return {Object}
 */
export const prepareGetDealByIdPayload = (id) => ({
  filterType: DEAL,
  filters: {
    includedDealIds: id ? [id] : [],
  },
});
